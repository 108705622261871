.authModal {
    position: fixed;
    inset: 0;
    z-index: 10000;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(4px);
}

.authModal .authModal__container {
    display: flex;
    flex-direction: column;
    width: 480px;
    background-color: white;
    padding: 40px;
    height: auto;
    border-radius: 8px;
}

.authModal__container .authModal__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 30px;
}

.authModal__header .authModal__header__title {
    font-size: 26px;
    font-weight: 600;
    color: #383838;
    letter-spacing: 0.2px;
}

.authModal__header .authModal__header__message {
    font-size: 14px;
    font-weight: 300;
    color: #565656;
    letter-spacing: 0.15px;
}

.authModal__container .authAgreement {
    display: flex;
    gap: 6px;
    align-items: start;
    margin-top: 25px;
}

.authModal__container .authAgreement-checkbox { margin-top: 2px; }

.authAlternatives .authAlternatives-message .authAlternatives-action {
    background-color: transparent;
    border: none;
    margin-left: 4px;
    text-decoration: underline;
    color: #8C52FF;
}

@media only screen and (max-width: 414px) {
    .authModal { align-items: flex-end; }
    .authModal .authModal__container {
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 30px;
    }
    .authModal__header .authModal__header__title { font-size: 22px; }
}