.features__container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.features__input-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.features__input {
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  outline: none;
  background: #fff;
  width: 100%;
  padding: 5px 10px;
  line-height: normal;
  font-size: 14px;
}

textarea.features__input {
  height: 100px;
}

textarea.features__input:disabled {
  background: #eee;
  color: #333;
}

.features__toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.features__switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
}

.features__switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.features__switch .slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: #8c8c8c;
  border-radius: 9px;
  transition: 0.4s;
}

.features__switch .slider::before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 2px;
  top: 2px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.4s;
}

.features__switch input:checked + .slider {
  background-color: #8c52ff;
}

.features__switch input:focus + .slider {
  box-shadow: 0 0 1px #8c52ff;
}

.features__switch input:checked + .slider:before {
  transform: translateX(100%);
}

.features__option-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 0.25rem;
}

.features__option-btn-grp {
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  gap: 3px;
  justify-content: end;
}
.option-visible {
  display: flex;
}
.option-hidden {
  display: none;
}
.features__option-btn {
  background-color: #fff;
  border: 1px solid #c3a5ff;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.features__option-wrapper textarea {
  height: 80px;
}
.features__option-field-container {
  display: flex;
  gap: 0.5rem;
}
.features__option-field-input {
  accent-color: #8c52ff;
  cursor: pointer;
}
.features__option-field-box {
  background: #e9e9e9;
  border-radius: 4px;
  width: 100%;
  padding: 0.5rem;
  min-height: 30px;
}
.features__option-field-input:checked + .features__option-field-box {
  background: #24aa42;
  color: #fff;
}
.add__btn {
  color: #24aa42;
}
.delete__btn {
  color: #f61c1c;
}

.features__btn-addMore {
  background: transparent;
  margin-top: 0.5rem;
  text-align: left;
  border: none;
  outline: none;
  color: #8c52ff;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
