.videoUpload__heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 8px;
}
.videoUpload__sub-heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c8c8c;
  margin-bottom: 32px;
}
.videoUpload__btn {
  width: 262px;
  height: 40px;
  background: #8c52ff;
  border-radius: 4px;
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}
.videoUpload__clearn-btn{
  position: absolute;
  top: 20px;
  right: 15px;
}
.modal-dialog{
  max-width: 650px;
}
