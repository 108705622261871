.dialog--wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(10px);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog--wrapper .dialog__container {
    width: 450px;
    padding: 35px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #9B9B9B;
}

.dialog__container .container__heading {
    font-size: 20px;
    font-weight: 500;
    color: #231F20;
    margin-bottom: 12px;
}

.dialog__container .container__heading .container__heading--highlight { color: #8C52FF; }

.dialog__container .container__description {
    font-size: 16px;
    font-weight: 400;
    color: #231F20;
}

.dialog__container .container__description .container__description--highlight { font-weight: 500; }

.dialog__container .container__action {
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0;
    border-radius: 8px;
    border: none;
}

.dialog__container .container__action--primary { background-color: #8C52FF; color: white; }
.dialog__container .container__action--secondary { background-color: #E7DBFF; }

.dialog__container.container__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.dialog__container .dialog__successIcon {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: #FBE3FF;
    margin-bottom: 20px;
}

@media only screen and (max-width: 414px) {
    .dialog--wrapper {
        align-items: end;
    }
    .dialog--wrapper .dialog__container {
        padding: 45px 35px;
        border-radius: 0;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        border: none;
    }
    .dialog__container .container__heading { font-size: 18px; }
    .dialog__container .container__description { font-size: 14px; }
    .dialog__container .container__action { font-size: 15px; }
    .dialog__container.container__success { padding: 55px 35px; }
}