.authInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
}

.authInput .authInput-label {
    font-size: 16px;
    color: #383838;
}

.authInput .authInput-label.authInput-label--error { color: #F55555; }

.authInput .authInput-label .authInput-label--required { color: #F55555; }

.authInput .authInput-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #A7A7A7;
    border-radius: 8px;
    padding: 12px;
    gap: 12px;
}

.authInput .authInput-wrapper .authInput-dialCode {
    font-size: 16px;
    color: #383838;
    font-weight: 500;
    letter-spacing: 0.15px;
}

.authInput .authInput-input {
    width: 100%;
    font-size: 16px;
    color: #383838;
    font-weight: 500;
    border: none;
    background-color: transparent;
}

.authInput .authInput-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

.authInput .authInput-wrapper:has(.authInput-input.authInput-input--error) {
    background-color: rgba(245, 85, 85, 0.10);
    border-color: #F55555;
}

.authInput .authInput-input::placeholder {
    color: #A7A7A7;
    font-weight: 400;
}

.authInput .authInput-errorBlock {
    display: flex;
    align-items: center;
    gap: 5px;
}

.authInput .authInput-errorBlock .errorBlock-message {
    font-size: 12px;
    color: #F55555;
}