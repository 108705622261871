.profile__edit-status{
  position: relative;
}
.checklist__card__container {
  position: relative;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
}
.checklist__paragraph--grey {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #c4c4c4;
  padding-bottom: 10px;
  cursor: pointer;
}
.checklist__paragraph--green {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #24aa42;
  padding-bottom: 10px;
  cursor: pointer;
}
.checklist__card--tick {
  margin-right: 5px;
}
.checklist__card--plus-icon {
  position: absolute;
  right: 8.67%;
  margin-top: -5px;
}
