*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --bg-light: #F3FAFB;
    --bg-white: #FFFFFF;
    --bg-purple-1: #8C52FF;
    --bg-purple-2: #7B45E7;
    --text-white: #FFFFFF;
}

.notFound--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    background-color: var(--bg-light);
    letter-spacing: 0.03em;
}

.notFound {
    width: 600px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 16px;
    padding: 40px 60px;
    background-color: var(--bg-white);
    border-radius: 4px;
    box-shadow: 3px 3px 8px rgba(159, 98, 221, 0.25);
}

.notFound h2,
.notFound p {
    margin: 0;
}

.notFound__status {
    font-size: 66px;
    font-weight: 700;
    background: linear-gradient(90deg, #373737 -2.07%, #B0B0B0 102.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.notFound__info {
    font-size: 20px;
    font-weight: 600;
}

.notFound__message {
    font-size: 16px;
    font-weight: 400;
    color: #8C8C9C;
}

.notFound__button {
    display: flex;
    align-items: center;
    gap: 4px;
    border: none;
    outline: none;
    background-color: var(--bg-purple-1);
    padding: 8px 20px;
    color: var(--text-white);
    border-radius: 4px;
    transition: 0.2s ease;
}

.notFound__button img {
    width: 20px;
    height: 20px;
    transition: 0.2s ease;
}

.notFound__button:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    background-color: var(--bg-purple-2);
}

.notFound__button:hover img { transform: translateX(-4px); }