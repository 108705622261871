*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.webinar--wrapper {
    width: 100%;
    margin-top: 50px;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.03em;
}
