.featuredWorkUploader__heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
}
.featuredWorkUploader__sub-heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.featuredWorkUploader__dropArea {
  align-items: center;
  padding: 32px 16px;
  width: 100%;
  height: 188.89px;
  border: 1px dashed #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
}
.featuredWorkUploader__dropArea--text-primary{
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 140%;
align-items: center;
text-align: center;
letter-spacing: 0.03em;
color: #444444;
}
.featuredWorkUploader__dropArea--text-secondary{
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 140%;
align-items: center;
text-align: center;
letter-spacing: 0.03em;
color: #444444;
}
.featuredWorkUploader__dropArea--text-secondary > span{
  color: #8C52FF;
}
.featuredWorkUploader__img-box{
width: 100%;
height: 172px;
background: linear-gradient(0deg, #E5E5E5, #E5E5E5);
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 8px;
}
.featuredWorkUploader__delete--image{
position: absolute;
right: 4.25%;
top: -4.25%;
background-color: #E5E5E5;
border-radius: 50px;
cursor: pointer;
}