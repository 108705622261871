:root {
    --bg-white-medium: #F9F9F9;
    --bg--white: #FFFFFF;
    --text-black: #000000;
    --text-black-med: #444444;
    --text-white: rgba(255, 255, 255, 0.85);
    --text-blue: #55ACEE;
    --icon--color: #8c8c8c;
    --color--purple: #8C52FF;
    --color--purple-dark: #7B45E7;
}

.availibility {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    background-color: var(--bg-white-medium);
}

.availibility__container {
    background-color: var(--bg--white);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin: 100px 0 60px 0;
    padding: 40px 60px 30px 60px;
    position: relative;
}

.availibility__container .close-button {
    position: absolute;
    top: 12px;
    right: 16px;
    text-decoration: none;
    background-color: transparent;
}

.availibility__head {
    display: flex;
    flex-direction: column;
    gap: 20px;
    letter-spacing: 0.04em;
}

.availibility__head--heading {
    display: block;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 40px;
    text-align: center;
    color: var(--text-black);
}

.availibility__image {
    margin-bottom: 30px;
}

.availibility__image img {
    width: 100%;
    background-size: cover;
}

.availibility__head--label {
    display: block;
    font-size: 16px;
    font-weight: 500;
}

.availibility .dropdown-toggle {
    font-weight: 400 !important;
}

.availibility .dropdown-toggle::before,
.availibility .dropdown-toggle::after {
    content: none;
}

/* ---------- SLOTS */

.availibility__slots { 
    padding: 30px 0; 
}

.availibility__slots form {
    display: flex;
    flex-direction: column;
    row-gap: 22px;
    margin: 0;
}

.availibility__slot {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(max-content, 100px) 2fr 1fr;
}

.availibility__start {
    display: flex;
    align-items: flex-start;
    padding: 12px 0;
}

.availibility__start--label {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-black);
    letter-spacing: 0.04em;
    cursor: pointer;
    position: relative;
}

.availibility__start--checkbox[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

.availibility__start--label::before {
    content: '';
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c4c4c4;
    border-radius: 2px;
    margin-right: 6px;
}

.availibility__start--checkbox:checked + .availibility__start--label::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: var(--color--purple);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 1.5px;
}

.availibility__second {
    display: flex;
    flex-direction: column;
}

.availibility__second .availibility__not-available {
    font-size: 14px;
    font-weight: 500;
    color: var(--icon--color);
    padding: 10px 4px;
    letter-spacing: 0.03em;
}

.availibility__timeslot {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
}

.availibility__slotbox {
    width: 100%;
    height: 100%;
    max-width: 200px;
}

.availibility__timeslot--slot-1,
.availibility__timeslot--slot-2 {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: '';
    border: none;
    outline: none;
    background-color: transparent;
    text-align: center;
    width: 44%;
    padding: 10px 8px;
    border: 1px solid #8c8c8c;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
}

.availibility__timeslot--slot-2 { margin-right: 4px; }

.availibility__timeslot--slot-1:not(:last-child),
.availibility__timeslot--slot-2:not(:last-child) {
    margin-bottom: 6px;
}

.availibility__timeslot--slot-1::-webkit-scrollbar,
.availibility__timeslot--slot-2::-webkit-scrollbar {
    display: none;
}

.availibility__timeslot .trash--button {
    border: none;
    outline: none;
    background-color: transparent;
    transform: translateY(-2px);
    transition: 0.2s ease;
}

.availibility__timeslot .trash--button:hover { 
    transform: translateY(-4px); 
}

.availibility__third {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
    gap: 10px;
}

.availibility__third .add--button,
.availibility__third .copy--button {
    border: none;
    outline: none;
    background-color: transparent;
}

.availibility__third .dropdown span { padding: 0; }

/* --------- SYNC CALENDAR */

.sync__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 18px;
}

.sync__header {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-black);
    margin-bottom: 8px;
}

.sync__desc {
    font-size: 12px;
    font-weight: normal;
    color: var(--text-black-med);
}

.sync__button { 
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.sync__service {
    display: flex;
    align-items: center;
    gap: 10px;
}

.sync__service .sync__text {
    font-size: 14px;
    font-weight: 600;
    color: var(--text-black-med);
    margin: 0;
}

.sync__service img { 
    width: 20px;
    height: 20px;
}

.sync__link {
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    color: var(--color--purple);
    padding: 8px 20px;
    border-radius: 4px;
    background: #8C52FF33;
    transition: 0.2s linear;
    cursor: pointer;
}
.sync__link:hover { color: var(--color--purple-dark); }

.sync__button button {
    padding: 8px 20px;
    border: none;
    outline: none;
    background: #8C52FF33;
    border-radius: 4px;
    color: var(--color--purple);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.03em;
    backface-visibility: hidden;
    transition: 0.2s ease;
}

.sync__link:hover,
.sync__button button:hover {
    transform: translateY(-2px);
    box-shadow: 8px 8px 6px -4px rgba(0,0,0,0.08);
}

.sync__link:active,
.sync__button button:active {
    box-shadow: unset;
    transform: scale(0.96);
}

.submit--button { margin-top: 10px; }

.submit--button button {
    border: none;
    outline: none;
    background: var(--color--purple);
    color: var(--text-white);
    padding: 8px 0;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    transition: 0.2s linear;
}

.submit--button button:hover {
    transform: translateY(-4px);
    background: var(--color--purple-dark);
}

.submit--button button:active{
    transform: scaleX(0.98);
}

.slot-overlap {
    color: #CC0000;
    font-weight: 600;
    border-color: #CC0000;
}

.availibility__overlapped {
    position: fixed;
    right: 10px;
    bottom: 12px;
}

@media only screen and (max-width: 414px) {
    .availibility {
        display: block;
        max-width: 100vw;
        height: 100%;
    }

    .availibility__container {
        display: block;
        width: 100%;
        height: 100%;
        box-shadow: unset;
        border-radius: unset;
        padding: 15px;
    }

    .availibility__container .close-button {
        display: none;
    }

    .availibility__head--heading {
        font-size: 20px;
    }

    .availibility__head--label {
        font-size: 16px;
        font-weight: 500;
        color: var(--text-black-med);
    }

    .availibility .dropdown-toggle {
        font-size: 14px;
        color: var(--text-black-med);
        padding: 0;
    }

    /* ---------- SLOTS  */
    .availibility__slot {
        grid-template-columns: minmax(60px, 80px) 230px 1fr;
    }

    .availibility__timeslot {
        gap: 2px;
    }

    .availibility__third {
        justify-content: center;
        gap: 2px;
        margin-left: 12px;
    }

    .availibility__timeslot--slot-1,
    .availibility__timeslot--slot-2 {
        text-align: center;
        color: var(--text-black-med);
        padding: 9px;
    }

    /* -------- SYNC CALENDAR */
    .sync__container {
        margin-bottom: 20px;
    }
}
.availability-checkbox{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
}
.btn-apply-timing{
    width: 90%;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    color: var(--color--purple);
    padding: 8px 20px;
    border-radius: 4px;
    background: #8C52FF33;
    transition: 0.2s linear;
    cursor: pointer
}

.btn-apply-timing:hover{
    transform: translateY(-2px);
    box-shadow: 8px 8px 6px -4px rgba(0,0,0,0.08);
    color: var(--color--purple-dark);
}
.timeCopier-checkbox{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: left;
    padding: 5px 10px 5px 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
}
.timeCopier__checkbox-btn{
    height: 16px;
    width: 16px;
    cursor: pointer;
}
.timeCopier__checkbox-btn::after {
    background-color: #8c52ff;
}