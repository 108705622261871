.card__body {
  width: 100%;
}
.card__body > body {
  background-image: none;
}

.card__extras {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.card__input-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}
.card__input-label {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.card__input-field {
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  outline: none;
  background: #fff;
  width: 100%;
  padding: 5px 10px;
  line-height: normal;
  font-size: 14px;
}

.card__btn-grp {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
}
.card__btn-grp button {
  height: 40px;
  width: 50%;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 6px;
}
.card__btn-secondary {
  background: #d9d9d9;
  color: #000;
}
.card__btn-secondary:hover {
  background: #dfdfdf;
}
.card__btn-secondary:disabled {
  cursor: auto;
  background: #dfdfdf;
  color: #666;
}
.card__btn-primary {
  background: #8c52ff;
  color: #fff;
}
.card__btn-primary:hover {
  background: #763de9;
}
.card__btn-primary:disabled {
  cursor: auto;
  background: #905ff1;
  color: #ddd;
}
