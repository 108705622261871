.footer__container {
  padding: 50px 73px 20px 73px;
  background: linear-gradient(
    0deg,
    rgba(243, 243, 243, 0.5),
    rgba(243, 243, 243, 0.5)
  );
}
.footer__logo {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: 0.03em;
  color: #444444;
}
.logo--footer{
  margin-bottom: 0px ;
}
.footer__social--icons {
  height: 40px;
  width: 40px;
  margin-left: 20px;
  text-align: end;
  margin-top: -10px;
}
.footer__bar {
  width: 100%;
  height: 0px;
  border: 1px solid #c4c4c4;
  margin: 32px 0px;
}
.footer__left--menu {
  list-style-type: none;
  display: block;
  padding: 0px;
}
.footer__left--menu > li {
  float: left;
  margin-right: 38px;
}
.footer__left--menu > li > a {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.03em;
  color: #444444;
  text-decoration: none;
}
.footer__right--menu {
  list-style-type: none;
  display: inline-block;
  padding: 0px;
}
.footer__right--menu > li {
  float: left;
  margin-left: 38px;
}
.footer__right--menu > li > a {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.03em;
  color: #444444;
  text-decoration: none;
}
.footer__copyright {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.footer__policy--menu {
    list-style-type: none;
    display: inline-block;
    padding: 0px;
  }
  .footer__policy--menu > li {
    float: left;
    margin-left: 16px;
  }
  .footer__policy--menu > li > a {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 110%;
    letter-spacing: 0.03em;
    color: #8C8C8C;
    text-decoration: none;
  }
  .footer__text-end{
    text-align: right !important;
}
.hide-on-big-policy{
  display: none;
}
  @media only screen and (max-width: 744px){
    .footer__container {
      padding: 20px;
      background: linear-gradient(
        0deg,
        rgba(243, 243, 243, 0.5),
        rgba(243, 243, 243, 0.5)
      );
    }
    .footer__text-end{
        text-align: left !important;
    }
    .footer__left--menu > li {
      float: none;
      margin-bottom: 26px;
    }
    .footer__right--menu > li {
        margin-left: 0px;
        margin-right: 10px;
        float: none;
        margin-bottom: 26px;
      }
      .footer__policy--menu > li {
        margin-left: 0px;
        margin-right: 10px;
      }
      .footer__social--icons {
        height: 40px;
        width: 40px;
        margin-left: 0px;
        margin-right: 20px;
        text-align: end;
        margin-top: 0px;
      }
      .hide-on-big-policy{
        display: block;
      }
      .hide-on-small-policy {
        display: none;
      }
      .logo--footer{
        margin-bottom: 20px ;
      }
}