.card-renderer__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.card-renderer__header {
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #cdcdcd;
  background: #fff;
}

.card-renderer__page-number {
  height: 70%;
  width: 65px;
  background: #825cff;
  color: #fff;
  display: flex;
  padding-right: 14px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  position: relative;
}
.card-renderer__page-number::after {
  content: '';
  position: absolute;
  right: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-right: 14px solid #fff;
}

.card-renderer__topic {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-renderer__tools {
  padding: 0 0.5rem;
  display: flex;
  gap: 0.5rem;
}
.card-renderer__tool {
  cursor: pointer;
}
.card-renderer__tool.icon-disabled {
  opacity: 0.5;
  cursor: auto;
}

.card-renderer__card {
  position: relative;
  height: calc(100% - 40px);
}

.card-renderer-hint__layer {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: #8172a180;
  backdrop-filter: blur(7.5px);
  overflow: hidden;
}

.card-renderer-hint__container {
  border-radius: 0px 0px 6px 6px;
  background: #fff;
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 80%;
  translate: 0 -110%;
  transition: translate 0.4s ease-in-out;
}

.card-renderer-hint__container span {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.card-renderer-hint__container p {
  padding-right: 0.5rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow-y: auto;
}
.card-renderer-hint__container p::-webkit-scrollbar {
  width: 0.25rem;
  background: #f2ebff;
}
.card-renderer-hint__container p::-webkit-scrollbar-thumb {
  border-radius: 0.2rem;
  background: #cfbcff;
}
.card-renderer-hint__container button {
  margin-left: auto;
  width: fit-content;
  color: #fff;
  font-size: 14px !important;
  padding: 0.25rem 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: #8c52ff;
}
