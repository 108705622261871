.socials__form--label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.03em;
  color: #000000;
  margin-left: 65px;
}
.checkbox-position{
  position: relative;
}
input[type=checkbox].socials--checkbox{
  cursor: pointer;
    opacity: 0;
    position: absolute;
    height: 25px;
    width: 25px;
    z-index: 1000;
}
.socials--checkbox-label::before {
  content: '';
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  margin-right: 6px;
}
.socials--checkbox:checked + .socials--checkbox-label::after{
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  margin-top: -12px;
  margin-left: -7px;
  background-color: #8c52ff;
  border-radius: 1.5px;
}

.videoUpload__btn-grey{
  width: 262px;
    height: 40px;
    background: #8c8c8c;
    border-radius: 4px;
    color: #fff;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
}
@media only screen and (max-width: 991px){
  .socials__form--label {
      margin-left: 40px;
    }
}
@media only screen and (max-width: 414px){
    .socials__form--label {
        margin-left: 30px;
      }
}
