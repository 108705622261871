@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
:root {
  --text-light: #8c8c8c;
  --text-bold: #000000;
  --text-medium: #444444;
  --text-purple-dark: #8c52ff;
  --text-gray: #c4c4c4;
  --bg-purple: #8c52ff;
  --bg-white: #ffffff;
  --bg-gray-light: #f9f9f9;
  --bg-blue: #0a66c2;
}
.background-form {
  min-height: 100vh;
  background-color: var(--bg-gray-light);
}

.form-container {
  background: var(--bg-white);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.first__session--sub-container{
  max-width: 860px;
}
.first__session--top-margin{
  margin-top: '134px';
}
.mentor-form-box {
  padding: 60px 120px;
  position: relative;
}

.mentor-form-box .close-button {
  position: absolute;
  top: 12px;
  right: 16px;
  text-decoration: none;
  background-color: transparent;
}

.mentor-form-box > h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #000000;
  text-align: center;
}

.mentor-form-box > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}

.form-label__v2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  align-items: center;
  letter-spacing: 0.03em;
  color: var(--text-bold);
}

.form-label__v2.form-label-heading { font-weight: normal; }

.tooltip--img { margin: 4px 8px; cursor: pointer; }

.form-input {
  width: 100%;
  height: 42px;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 6px 0px;
  padding: 5px;
}

.form-input:focus {
  outline: 2px solid var(--bg-purple);
}

.form-textarea {
  width: 100%;
  height: 105px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 4px;
}

.form-textarea:focus {
  outline: 2px solid var(--bg-purple);
}

.creatable--field #react-select-3-placeholder,
.form-input::placeholder,
.form-textarea::placeholder {
  font-family: 'Poppins', sans-serif;
  font-size: 14px !important;
  letter-spacing: 0.03em;
}

.btn-primary__v2 {
  width: 100%;
  height: 42px;
  background: var(--bg-purple);
  border-radius: 4px;
  color: var(--bg-white);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}
.add-more-session__text{
  font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 150%;
letter-spacing: 0.03em;
color: #444444;
}
.first__session-form-select {
  width: 100%;
  border: none;
}
.first__session-form-select:focus {
  border: none;
}
@media only screen and (max-width: 747px) {
  .mentor-form-box {
    padding: 20px 20px;
  }
}
