.whatsapp__plugin {
    position: fixed;
    bottom: 28px;
    right: 24px;
    width: 30px;
    height: 30px;
    z-index: 100000;
}

.whatsapp__plugin svg {
    width: 100%;
    height: 100%;
    background-size: cover;
    filter: opacity(0.7) drop-shadow(0px 0px 6px rgb(0 0 0 / 0.2));
    animation: 2s infinite alternate bounce;
    transition: 0.2s ease;
}

.whatsapp__plugin svg:hover {
    transform: scale(1.1) translateY(-3px);
    filter: opacity(1) drop-shadow(0px 0px 8px rgb(0 0 0 / 0.3));
}

@keyframes bounce {
    0% { transform: scale(1) translateY(0); }
    100% { transform: scale(1.1) translateY(-6px); }
}