.media-responsive__v2{
    width: 100% ;
    height: 370px;
    border-radius: 8px;
}
.media-responsive__v2--video-uploader{
    width: 100% ;
    height: 263px;
    border-radius: 8px;
}
.media-responsive__v2--videoLayout{
    width: 100% ;
}
@media only screen and (max-width: 414px){
    .media-responsive__v2{
        width: 100% ;
        height: 203px;
    }
    .media-responsive__v2--video-uploader{
        width: 100% ;
        height: 203px;
        border-radius: 8px;
    }
}