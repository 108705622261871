.announcement__btn {
  width: 160px;
  height: 40px;
  background: #8c52ff;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.85);
  float: right;
}
