* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --text-green: #48E56E;
}

.promotedCard h1, h2, h3, h4, h5, h6 { margin: 0; }

.promotedCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    height: 205px;
    background: linear-gradient(132.17deg, #FBFFFD 0%, #E5FFF6 100%);
    border-radius: 6px;
    padding: 16px;
    transition: 0.3s ease-in-out;
}

.promotedCard:hover { box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15); }

.promotedCard .promotedCard__heading {
    font-size: 10px;
    font-weight: 600;
    color: var(--text-green);
    text-transform: uppercase;
}

.promotedCard .promotedCard__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 6px;
}

.promotedCard__info .info__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: var(--neutral-400);
}

.promotedCard__info .info__subtext {
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    color: var(--neutral-400);
}

.promotedCard .promotedCard__link {
    display: inline-block;
    color: var(--black);
    padding: 6px 16px;
    font-size: 12px;
    border: none;
    background-color: var(--white);
    border-radius: 100px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 414px) {
    .promotedCard {
        padding: 14px;
        height: 160px;
    }
    .promotedCard__info .info__title { font-size: 14px; }
}