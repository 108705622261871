* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --white: #ffffff;
    --black: #000000;
    --neutral-100: #F5F5F5;
    --neutral-200: #3E3E3E;
    --neutral-300: #434343;
    --neutral-400: #201D1D;
    --purple-100: #8C52FF38;
    --purple-200: #8C52FF;
    --red-100: #FFE9E9;
    --red-200: #F61C1C;
    --placeholder: #6b6b6b;
}

.sdashboard__wrapper {
    display: block;
    width: 100%;
    height: 100%;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.03em;
}

.sdashboard {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 100vh;
    position: relative;
    margin-top: 50px;
    padding-right: 20px;
    background-color: var(--white);
}

.sdashboard .sdashboard__sidebar {
    width: 260px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50px;
    bottom: 0;
    padding: 20px 20px 40px 20px;
    border-right: 1px solid #ECECEC;
    overflow: hidden;
}

.sdashboard .sdashboard__sidebar--responsive {
    position: fixed;
    top: 50px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 414px;
    background-color: white;
    transform: translateX(-100%);
    z-index: 2;
    transition: transform .3s ease-in-out;
}

.sdashboard__sidebar--responsive.open {
    transform: translateX(0);
    transition: all .3s ease-in-out;
}

.sdashboard__sidebar--responsive .responsive__navigation {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 120px;
}

.sdashboard__sidebar--responsive .sidebar__action--close {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background: var(--neutral-100);
}

.sdashboard__sidebar--responsive .sidebar__action--logout {
    display: flex;
    width: 140px;
    height: 48px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: var(--red-100);
    border-radius: 30px;
    border: none;
    margin-top: 80px;
    color: var(--neutral-400);
}

.sdashboard__sidebar .userProfile { margin-bottom: 40px; }

.sdashboard__sidebar .sidebar__navigation .navButton:not(:last-child) {
    margin-bottom: 6px;
}

.sdashboard__sidebar .sidebar__promotions {
    margin-top: auto;
}

.sdashboard .sdashboard__container {
    width: 100%;
    height: auto;
    margin-left: 265px;
    padding: 25px;
    overflow: hidden;
}

.sdashboard__container .container__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;
}

.container__header .header__greetings {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.header__greetings .greetings__welcome {
    font-size: 30px;
    font-weight: 500;
    color: var(--neutral-400);
}

.header__greetings .greetings__message {
    font-size: 16px;
    font-weight: 400;
    color: var(--neutral-300);
}

/* ------------ SEARCH BOX STYLES 👇 ------------ */

.header__searchbox {
    display: flex;
    gap: 6px;
}

.searchbox__menu {
    width: 60px;
    height: 40px;
    border-radius: 4px;
    border: none;
}

.searchbox__search {
    display: flex;
    justify-content: space-between;
    width: 340px;
    background-color: var(--neutral-100);
    overflow: hidden;
    border-radius: 4px;
}

.searchbox__search .search__input {
    width: 100%;
    border: none;
    padding: 8px 16px 8px 18px;
    background-color: transparent;
    font-size: 16px;
    color: var(--neutral-400);
}

.searchbox__search .search__button {
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px 0 15px;
    background-color: #ECECEC
}

.searchbox__search .search__input::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: var(--placeholder);
}

.searchbox__filter {
    border: none;
}

.sdashboard__container .search__result-heading {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 24px;
    font-weight: 500;
    color: var(--neutral-400);
    margin: 50px 0;
}

.sdashboard__container .search__result-heading .search__term { color: var(--purple-200); }

.sdashboard__container .sdashboard__entityContainer .entityContainer__heading {
    font-size: 24px;
    font-weight: 500;
    color: var(--neutral-400);
    margin-left: 12px;
}

.sdashboard__container .sdashboard__entityContainer .favourites__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.sdashboard__entityContainer .favourites__container .favourites__header {
    font-size: 24px;
    color: var(--neutral-400);
    font-weight: 600;
}

.sdashboard__entityContainer .favourites__container .favourites__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 25px;
    row-gap: 35px;
}

@media only screen and (max-width: 414px) {
    .sdashboard {
        padding-right: unset;
    }
    .sdashboard__container .container__header { width: 100%; margin-bottom: 70px; }
    .sdashboard .sdashboard__sidebar { display: none; }
    .sdashboard .sdashboard__container { margin-left: 0; padding: 20px; }
    .container__header .header__greetings { display: none; }
    /* searchbox styles */
    .header__searchbox { width: 100%; justify-content: space-between; }
    .searchbox__search { width: 100%; margin-right: auto; }
    .searchbox__search .search__input { padding: 8px 12px; }
    .searchbox__search .search__button { padding: 0 12px 0 12px; }
    /* sidebar styles */
}