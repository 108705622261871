*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* ------------- COLOR PALETTE */
:root {
    --font-dark: #000000;
    --font-medium: #444444;
    --font-light-1: #8C8C8C;
    --font-light-2: #C4C4C4;
    --font-green: #44ab43;
    --font-purple: #8C52FF;
    --font-orange: #FD8E4A;
    --container-bg: #F9F9F9;
    --card-bg: #FFFFFF;
    --bg-purple-dark: #7B45E7;
    --gray-lite: #E5E5E5;
}

/* ------------ MARGIN UTILITY CLASSES */
.marginHelper-bottom-small { margin-bottom: 8px !important; }
.marginHelper-bottom-med { margin-bottom: 22px !important; }
.marginHelper-bottom-lg { margin-bottom: 30px !important; }

.auth,
.auth__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    background-color: var(--container-bg);
    letter-spacing: 0.03em;
}

/* TO REMOVE THE MARGIN COMING FROM REBOOT & OTHER FILES */
.auth__container p,
.auth__container h2,
.auth__form {
    margin: 0;
}

.auth__container .auth__card {
    display: flex;
    width: 100%;
    max-width: 990px;
    height: 500px;
    margin-top: 30px;
    background-color: var(--card-bg);
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.auth__card .card__sec--1 { border-right: 1px solid #D3D3D3; }

.auth__image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth__image img {
    width: 360px;
    height: 270px;
    background-size: contain;
    object-fit: contain;
}

.auth__card .card__sec { width: 50%; }

.auth__card .card__sec--2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 60px;
    gap: 10px; 
}

.card__sec .auth__message {
    font-size: 24px;
    font-weight: 600;
    color: var(--font-dark);
}

.auth__otp--subheading {
    display: flex;
    gap: 8px;
    align-items: center;
}

.card__sec .auth__subheading {
    font-size: 16px;
    font-weight: 500;
    color: var(--font-medium);
    margin-bottom: 12px;
}

.card__sec .auth__subheading--link {
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--font-orange);
    font-weight: 500;
}

/* ---------------- BACK BUTTON */
.auth__card .card__sec--2 .auth__back-button {
    align-self: flex-start;
    margin-bottom: 14px;
}

/* --------------- FORM */
.auth__form {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-bottom: 6px;
}

/*--------------- LABELS, INPUT FIELD FOR PHONE NO., EMAIL, NAME, OTP */
.auth__card .auth__contact--field {
    display: flex;
    gap: 8px;
    padding: 8px;
    border: 1px solid var(--font-light-2);
    border-radius: 4px;
    font-size: 16px;
}

.auth__card .auth__email--field,
.auth__card .auth__otp--field,
.auth__card .auth__name--field,
.auth__card .auth__signupEmail--field {
    width: 100%;
    display: block;
    padding: 8px;
    border: 1px solid var(--font-light-2);
    border-radius: 4px;
    font-size: 16px;
}

.auth__card .auth__contact--field .auth__dialing-code,
.auth__card .auth__contact--field .auth__contact-no,
.auth__card .auth__email--field .auth__email,
.auth__card .auth__otp--field .auth__otp,
.auth__card .auth__name--field .auth__name,
.auth__card .auth__signupEmail--field .auth__signupEmail,
.auth__card .card__sec--2 .auth__back-button  {
    border: none;
    outline: none;
    background-color: transparent;
}

.auth__card .auth__contact--field .auth__dialing-code option { 
    font-size: 14px;
    text-align: center;
}

.auth__card .auth__contact--field,
.auth__card .auth__otp--field,
.auth__card .auth__signupEmail--field,
.auth__card .auth__email--field {
    position: relative;
}

.auth__card .auth__contact--field .auth__contact-no, 
.auth__card .auth__email--field .auth__email,
.auth__card .auth__otp--field .auth__otp,
.auth__card .auth__name--field .auth__name,
.auth__card .auth__signupEmail--field .auth__signupEmail {
    width: 100%;
    font-size: 16px;
    letter-spacing: 0.05em;
    font-weight: 500;
    color: var(--font-medium);
}

.auth__card .auth__error-msg {
    position: absolute;
    font-size: 12px;
    color: #F61C1C;
    left: 0;
    bottom: -21px;
}

.auth__card .auth__error-msg img {
    width: 13px;
    height: 13px;
    margin-right: 3px;
    transform: translateY(-1px);
}

.auth__card .auth__contact--field .auth__contact-no::placeholder,
.auth__card .auth__email--field .auth__email::placeholder,
.auth__card .auth__otp--field .auth__otp::placeholder { 
    font-size: 16px; 
}

.auth__card .auth__form .auth__name--label,
.auth__card .auth__form .auth__email--label {
    font-size: 14px;
    font-weight: 500;
    color: var(--font-dark);
}

/* ----------------- WRONG DATA HIGHLIGHT */

.auth__card .auth__contact--field.warning,
.auth__card .auth__email--field.warning,
.auth__card .auth__otp--field.warning,
.auth__card .auth__name--field.warning,
.auth__card .auth__signupEmail--field.warning {
    border: 1px solid red;
}

/* ---------------- INPUT FIELD + LABEL CONTAINER */
.auth__card .auth__form .auth__field-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* ---------------- BUTTON DISABLED, ENABLED */
.auth__card .auth__button {
    display: block;
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--card-bg);
    padding: 10px 0;
    border-radius: 4px;
    transition: 0.2s ease;
    background-color: var(--font-purple);
}

.auth__card .auth__button:hover { 
    transform: translateY(-3px);
    background-color: var(--bg-purple-dark);
}
.auth__card .auth__button:active { transform: translateY(0); }
.auth__card .auth__button:focus { background-color: var(--bg-purple-dark); }
.auth__card .auth__button:disabled {
    pointer-events: none;
    color: var(--font-light-1);
    background-color: var(--gray-lite);
    transform: none;
    box-shadow: none;
}

/* --------------- AUTH SWITCH LINK */
.auth__card .auth__switch-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.auth__card .auth__switch-link button {
    border: none;
    outline: none;
    background-color: transparent;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 500;
    color: var(--font-light-1);
} 

/* ------------------ AGREEMENT TEXT AND LINKS */
.auth__card .auth__agreement {
    display: block;
    width: 100%;
    margin-bottom: 16px;
}

.auth__card .auth__agreement p {
    font-size: 11px;
    font-weight: normal;
    color: var(--font-light-1);
    line-height: 160%;
    text-align: center;
}

.auth__card .auth__agreement .auth__agreement--link {
    color: var(--font-green);
    font-weight: 500;
    text-decoration: none;
}

/* --------------- OTP INFO BOX -> TEXT + COUNTER */
.auth__card .auth__otpInfo {
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 400;
    color: var(--font-light-1);
    padding: 0 6px;
}

.auth__otpInfo .auth__otpInfo--text { font-size: 14px; }

.auth__otpInfo .auth__otpInfo--resend {
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--font-light-1);
    text-decoration: underline;
    font-weight: 500;
}

.auth__otpInfo .auth__otpInfo--timer {
    font-size: 16px;
    font-weight: 500;
    padding: 0 3px;
}

/*------------------ ALTERNATIVE METHODS TEXT AND LINKS */
.auth__card .auth__alternatives {
    display: flex;
    justify-content: center;
    gap: 8px;
}

.auth__card .auth__alternatives .auth__alternatives--link {
    text-decoration: none;
    color: var(--font-purple);
    font-size: 14px;
    font-weight: 500;
    transition: 0.2s ease;
}

/* ------------------ MENTOR SIGNUP PART */

.auth__mentor-signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.auth__mentor-signup .auth__mentor-icon {
    width: 96px;
    height: 96px;
}

.auth__mentor-benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.auth__mentor-benefits .mentor__benefits--header {
    font-size: 20px;
    font-weight: 600;
    color: var(--font-dark);
    margin-bottom: 20px;
}

.auth__mentor-signup .mentor__benefit--box {
    display: flex;
    width: 100%;
    gap: 16px;
    padding: 0 40px;
}

.mentor__benefit--box .mentor__benefit--img {
    width: 32px;
    height: 32px;
}

.mentor__benefit--box .mentor__benefit--text {
    font-size: 16px;
    font-weight: normal;
    color: var(--font-medium);
}

.mentor__benefit--box .mentor__benefit--highlighted { font-weight: 600; }

/*------------------------------- RESPONSIVE LAYOUT FOR USER & MENTOR LOGIN/SIGNUP  */

@media only screen and (max-width: 414px) {
    .auth,
    .auth__container {
        background-color: var(--card-bg);
        max-height: 100vh;
        align-items: flex-start;
    }
    .auth__otp--subheading { 
        text-align: center;
        justify-content: center;
    }
    .auth__container .auth__card {
        max-width: unset;
        max-height: unset;
        margin-top: 80px;
        height: auto;
        box-shadow: unset;
        border-radius: unset;
    }
    .auth__card .card__sec { width: 100%; }
    .auth__card .card__sec--1 {
        border-right: unset;
        display: none;
    }
    .auth__card .card__sec--2 {
        width: 100%;
        justify-content: unset;
        padding: 0 20px;
    }
    .card__sec .auth__message { 
        width: 100%;
        line-height: 28px;
        text-align: center;
        font-size: 20px; 
    }
    .auth__form { gap: 30px; }
    .auth__card .auth__alternatives {
        flex-wrap: wrap;
        text-align: center;
        gap: 6px; 
    }
    .auth__card .auth__contact--field {
        gap: 3px;
        padding: 8px 4px;
    }
    .auth__card .auth__contact--field .auth__contact-no { width: 200px; }
    .auth__card .auth__contact--field .auth__dialing-code { font-size: 16px; }
    .auth__card .auth__button:hover { transform: none; }
    .auth__card .auth__button:active { transform: translateY(-3px); }

    /* ----- PLACEHOLDERS */
    .auth__card .auth__contact--field .auth__contact-no::placeholder { color: var(--font-light-2); }
}