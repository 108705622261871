* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.entity__showcase {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.entity__showcase .showcase__header {
    display: flex;
    align-items: center;
    gap: 20px;
}

.showcase__header .showcase__title {
    font-size: 24px;
    color: var(--neutral-400);
    font-weight: 500;
}

.showcase__header .showcase__action { cursor: pointer; }

.entity__showcase .showcase__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.entity__showcase .showcase__pagination { margin-left: auto; }

@media only screen and (max-width: 414px) {
    .entity__showcase .showcase__container {
        justify-content: center;
    }
    .entity__showcase .showcase__pagination { margin-right: auto; }
}