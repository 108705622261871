.single-choice__input-box-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}

.single-choice__add--more {
  border: none;
  background-color: #fff;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #24aa42;
}

.single-choice__remove {
  border: none;
  background-color: #fff;
  font-family: 'Poppins';
  font-style: normal;
}

.single-choice__input--field {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  font-family: 'Poppins';
  position: relative;
  display: inline-block;
  height: 36px;
  width: 75%;
  min-width: 0;
  padding: 4px 11px;
  color: #000000d9;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

.single-choice__input--field:hover {
  border: 1px solid #8c52ff;
}

.single-choice__preview {
  background: #ffffff;
  box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  height: 510px;
  overflow: hidden;
  padding-top: 40px;
}
