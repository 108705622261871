.announcementCard__container {
  padding: 20px;
  width: 310px;
  height: auto;
  left: 0px;
  background: #e5e5e5;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
}
.announcementCard__container--paragraph {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c8c8c;
  margin: 0;
}
@media only screen and (max-width: 1366px) {
  .announcementCard__container {
    width: 100%;
  }
}
@media only screen and (max-width: 775px){
  .announcementCard__container {
    width: 100%;
  }
}
@media only screen and (max-width: 414px){
  .announcementCard__container {
    width: 100%;
  }
}