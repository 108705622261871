.edit__right-side--width-control {
  position: relative;
  width: 310px;
}
.profile__edit-status {
  display: flow-root;
}
.profile__edit-status--text {
  float: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #444444;
}
.profile__edit-status--percentage {
  float: right;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #fd8e4a;
}
.profile__edit-addsession {
  display: inline-block;
  width: 100%;
}
.profile__edit-addsession--text {
  float: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #444444;
}
.profile__edit-addsession--edit {
  float: right;
  padding: 5px 10px 10px 10px;
}
.modal__heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #000000;
}
.view__public__profile--button {
  width: 220px;
  border: 1px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 100%;
letter-spacing: 0.03em;
color: #8C8C8C;
padding: 12px 0px;
}
@media only screen and (max-width: 1366px) {
  .edit__right-side--width-control {
    position: relative;
    width: 100%;
  }
}
@media only screen and (max-width: 775px) {
  .edit__right-side--width-control {
    position: relative;
    width: 100%;
  }
}
@media only screen and (max-width: 414px) {
  .edit__right-side--width-control {
    position: relative;
    width: 100%;
  }
}
