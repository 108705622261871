* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navButton {
    display: flex;
    align-items: center;
    width: 100%;
    height: 46px;
    border: none;
    font-size: 16px;
    color: var(--black);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: transparent;
    border-left: 4px solid transparent;
    padding: 0 10px;
    transition: 0.2s ease-in;
}

.navButton:disabled {
    cursor: not-allowed;
    background: transparent;
}
.navButton:disabled:hover { background: transparent; }
.navButton.navButton--active:hover { background: var(--purple-100); }

.navButton--responsive {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 48px;
    border: none;
    font-size: 16px;
    color: var(--black);
    border-radius: 4px;
    background: transparent;
    transition: 0.2s ease-in;
}

.navButton:hover {
    background: var(--purple-100);
}

.navButton.navButton--active {
    color: var(--black);
    background: var(--purple-100);
    border-left: 4px solid var(--purple-200);
}

.navButton--responsive.navButton--responsive--active {
    color: var(--purple-200);
    background: var(--purple-100);
}

