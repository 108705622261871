.floating-message__container {
  max-width: 1300px;
  margin: auto;
  padding: 2rem 1rem;
}
.floating-message__wrapper {
  padding: 1rem;
  display: flex;
  gap: 14rem;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(
    82deg,
    #eca231 0%,
    #fdc673 82.29%,
    #f2cf9b 91.15%,
    #ffb23c 98.96%
  );
}
.floating-message__pulsating-circle {
  position: relative;
  width: 25px;
  height: 25px;
}
.floating-message__pulsating-circle::before {
  content: '';
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #ffd08c;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.floating-message__pulsating-circle::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #f9e3c3;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}
@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.floating-message__contents {
  display: flex;
  gap: 6rem;
  align-items: center;
}
.floating-message__message {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 1.2px;
  background: linear-gradient(90deg, #6129bf 0%, #3b3fc2 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.floating-message__btn {
  color: #3d3dc1;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.9px;
  border-radius: 6px;
  border: 2px solid #5d2bbf;
  background: rgba(140, 82, 255, 0);
  padding: 0 1rem;
  width: max-content;
}
.floating-message__btn:hover {
  background-color: #5d2bbf;
  color: #ffffff;
}

.modal-open {
  padding: 0 !important;
}
.custom-modal-style {
  padding: 0;
}
.video-modal__title {
  margin-bottom: 1rem;
  font-weight: 600;
  letter-spacing: 0.8px;
}
.video-modal__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 !important;
}
.video-modal__iframe {
  border-radius: 4px;
}

@media only screen and (max-width: 640px) {
  .floating-message__wrapper {
    gap: 2rem;
  }
  .floating-message__contents {
    flex-direction: column;
    gap: 0.5rem;
  }
  .floating-message__message {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    text-align: center;
  }
  .floating-message__btn {
    font-size: 14px;
  }
}
