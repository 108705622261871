.landing__screen--landing--page {
  position: relative;
  height: 556px;
  width: 100%;
  background-position: center;
  overflow: hidden;
  background-size: cover;
}
.landing__screen--landing--page:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 526px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
    45deg,
    rgba(140, 82, 255, 1),
    rgba(140, 82, 255, 0.58)
  ) !important;
  border-radius: 0 0 50% 50%/0 0 100% 100%;
  transform: scaleX(2.4);
  z-index: -1;
}
.landing__page--wave {
  position: absolute;
  top: 25%;
  left: 0;
  height: 410px;
  width: 100%;
  background-size: cover;
  background-image: url('../AssetV2/landingWave.svg');
  z-index: -1;
}
.landing__screen-container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.landing__screen--slide {
  display: flex;
  flex-direction: row;
}
.landing__screen--box {
  max-width: 515px;
  margin: 4rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.landing__screen--text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.landing__screen--animation {
  animation: slide 15s ease-in-out infinite;
}
.landing__screen--box-primary-heading {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1.44px;
  color: #ffffff;
}
.landing__screen--box-secondary-heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.6px;
  color: #ffffffbf;
}
.landing__screen--media {
  flex: 1;
  margin: 1rem;
  padding: 0.5rem;
  max-height: 425px;
  max-width: 554px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing__screen--media img {
  min-width: 50%;
  height: 100%;
}

@keyframes slide {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }
  5% {
    transform: translateX(0);
    opacity: 1;
  }
  96% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-10%);
    opacity: 0;
  }
}
.landing__screen--pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.landing__screen--bullets {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #d2bbff;
  cursor: pointer;
}
.landing__screen--bullets.active {
  background-color: #6520ee;
}

@media only screen and (max-width: 640px) {
  .landing__screen-container {
    height: 100%;
  }
  .landing__screen--slide {
    height: 80%;
  }
  .landing__screen--box {
    margin: 4rem 1rem;
  }
  .landing__screen--box-primary-heading {
    font-size: 32px;
    letter-spacing: 0.03em;
    line-height: 150%;
  }
  .landing__screen--box-secondary-heading {
    font-size: 18px;
  }
  .landing__screen--landing--page {
    position: relative;
    height: 560px;
    width: 100%;
  }
  .landing__screen--landing--page:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 520px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: linear-gradient(
      45deg,
      rgba(140, 82, 255, 1),
      rgba(140, 82, 255, 0.58)
    ) !important;
    border-radius: 0 0 50% 50%/0 0 100% 100%;
    transform: scaleX(3.2);
    z-index: -1;
  }
  .landing__page--wave {
    position: absolute;
    top: 60%;
    left: 0;
    height: 200px;
    width: 100%;
    background-size: cover;
    background-image: url('../AssetV2/landingWave.svg');
  }
}
