.settings__form-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  -webkit-align-items: center;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000;
  color: var(--text-bold);
  margin-top: 10px;
  margin-bottom: 10px;
}
.setting__image-round {
  width: 120px;
  height: 120px;
  background: #e5e5e5;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 50%;
}
.setting__upload-button {
  height: 36px;
  background: #d3d3d3;
  border-radius: 4px;
  margin: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
.settings__btn--save {
  display: block;
  background: #8c52ff;
  border-radius: 4px;
  color: #fff;
  margin-top: 40px;
  transition: .4s ease;
}
.settings__btn--save:hover {
    color: #fff;
    transform: translateY(-4px);
}
.settings__btn--save--disabled{
    display: block;
  background: #8c8c8c;
  border-radius: 4px;
  color: #000;
  margin-top: 40px;
}