.searchMentor__main--container {
  max-width: 1080px;
}
.searchMentor__input--container {
  display: block;
}
.searchMentor__inputBox {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  align-items: center;
}
.searchMentor__form {
  display: inline-flex;
  width: 640px;
}
.searchMentor__form--input {
  width: 90%;
  height: 52px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px 0px 0px 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: 0.03em;
  padding-left: 20px;
  border-right: none;
}
.searchMentor__form--input::-webkit-input-placeholder {
  /* Edge */
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: 0.03em;
}

.searchMentor__form--input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: 0.03em;
}

.searchMentor__form--input::placeholder {
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: 0.03em;
}
.searchMentor__form--button {
  width: 10%;
  padding: 3px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 0px 8px 8px 0px;
  border-left: none;
  box-sizing: border-box;
}
.searchMentor__form--button > i {
  width: 18.71px;
  color: #444444;
}
.searchMentor__select--option {
  align-items: center;
  padding: 16px 24px;
  width: 265px;
  height: 52px;
  background: #f3f3f3;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 40px;
  align-self: flex-end;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #444444;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.searchMentor__filter-option {
  justify-content: center;
  align-items: center;
  padding: 18px 40px 18px 20px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  background-color: #ffffff;
  letter-spacing: 0.03em;
  color: #444444;
  margin-left: 32px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.searchMentor__filters {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 22px;
}
.searchMentor__filters .reset__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #868686;
  background-color: transparent;
  color: #676767;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 14px;
  border-radius: 4px;
  transition: 0.2s ease-in;
}
.reset__filters:hover { transform: scale(1.02); }
.searchMentor__select-container {
  position: relative;
  display: inline-flex;
}
.searchMentor__filterArrow {
  position: absolute;
  top: 45%;
  right: 10%;
}
.search__mentor--card-container {
  display: flex;
  width: 100%;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15), 2px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.search__mentor--profile-box {
  padding: 0px 31px;
}
.search__mentor--proile-pic {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 0.5px solid #8c8c8c;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 24px;
}
.search__mentor--detail-box {
  padding: 0px 30.5px;
  width: 499px;
}
.search__mentor--detail-box--user-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 8px;
}
.search__mentor--detail-box--tagLine {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #444444;
  margin-bottom: 8px;
}
.search__mentor--Rating{
  margin-bottom: 12px;
}
.search__mentor--detail-box--bio {
  display: block;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.03em;
  margin-bottom: 16px;
  color: #444444;
}
.search__mentor--skills-button {
  padding: 8px 16px;
  background: #e5e5e5;
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #444444;
  float: left;
  margin-right: 3px;
}
.search__mentor--session-box {
  padding: 0px 30.5px;
  border-left: 0.5px solid #c4c4c4;
}
.search__mentor--session-box-btn {
  width: 170px;
  padding: 10px;
  background: #8c52ff;
  border-radius: 4px;
  color: #ffffff;
  transition: 0.2s ease;
  text-align: center;
}
.search__mentor--session-box-btn:hover{
  color: #fff;
  transform: translateY(-4px);
  background-color: #7b45e7;
}
.search__mentor--session-box--exp-and-lang {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  color: #8c8c8c;
  
}
.search__mentor--session-box--year {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #444444;
  margin-bottom: 15px;
}
.search__mentor--session-box--price {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.03em;
  color: #24aa42;
  margin-bottom: 52px;
}
.searchMentor__load-more{
  width: 262px;
height: 48px;
background: #FFFFFF;
border: 1px solid #8C52FF;
box-sizing: border-box;
border-radius: 4px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 100%;
letter-spacing: 0.03em;
color: #8C52FF;
transition: 0.2s ease;
}
.searchMentor__load-more:hover{
  color: #ffffff;
  transform: translateY(-4px);
  background-color: #8C52FF;
}
.search__mentor--session-and-timeZone-Box{
  display: flex;
  width: 100%;
  padding-top: 32px;
  justify-content: space-between;
}
.search__mentor--time-zone-and-session{
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
/* identical to box height, or 24px */

display: flex;
align-items: center;
letter-spacing: 0.03em;

color: #8C8C8C;
}
.searchMentor__show-box{
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15), 2px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
  z-index: 2000;
}
.searchMentor__btn-option-styling{
  width: 100%;
  display: flex;
  text-align: start;
}
.searchMentor__btn-option-styling:hover{
  background-color: #f1f1f1;
}
.searchMentor__show-box>p{
  margin-bottom: 0px;
  padding: 5px;
}
.searchMentor__Skills{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #444444;
  margin-bottom: 15px;
}
.searchMentor__checkbox{
  width: 20px;
  margin-right: 5px;
}
.mentorSearchCardMobile__container{
  width: 100%;
height: auto;
background: #FFFFFF;
box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15), 2px 0px 8px rgba(0, 0, 0, 0.15);
border-radius: 8px;
padding: 32px 24px;
}
.divider{
  width: 100%;
height: 0px;
margin: 32px 0px;
border: 0.5px solid #C4C4C4;
}
.btn-filter{
background: #F3F3F3;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 8px;
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 100%;
letter-spacing: 0.03em;
color: #444444;
transition: 0.4s ease;
}
.btn-filter:hover{
  color: #1a1a1a;
  transform: translateY(-4px);
}
.searchMentor__hide-one-big{
  display: none;
}
.searchMentor__myDeck-btn{
  height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: none;
    background: #8C52FF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    color: #FFF;
    padding: 10px;
    box-shadow: 2px 4px 4px rgb(0 0 0 / 25%);
    transition: .2s ease;
}
@media only screen and (max-width: 614px) {
.searchMentor__hide{
  display: none;
}
.searchMentor__hide-one-big{
  display: block;
}
.searchMentor__filter-option {
  align-items: center;
  padding: 18px 40px 18px 20px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  background-color: #ffffff;
  letter-spacing: 0.03em;
  color: #444444;
  margin-left: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.searchMentor__form {
  display: inline-flex;
  width: 70%;
}
}
@media only screen and (max-width: 393px) {
  .searchMentor__form {
    display: inline-flex;
    width: 70%;
  }
}