.pagination__container {
  margin: 2rem;
  width: auto;
  height: 37px;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  border: 0.5px solid #e1e1e1;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
}
.pagination__content {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #909090;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.pagination__current-page {
  color: #000;
}
.pagination__buttons {
  border-color: transparent;
  border-left-color: #e1e1e1;
  background-color: #fff;
  height: 100%;
  width: 36px;
  cursor: pointer;
}
.disabled {
  opacity: 0.5;
  cursor: auto !important;
}
