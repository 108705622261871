@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  background: #ffffff;
  overflow: visible !important;
}
/*///////////////////////////////////////////////////

                  colors and backgrounds

 /////////////////////////////////////////////////*/
.orange {
  color: #e18044;
}
.grey {
  color: #535461;
}
.green {
  color: #0f9d58
}
.purple {
  color: #8c52ff !important;
}
.purple-gradient-bg {
  background: linear-gradient(-45deg, #8c52ff, #0c041d);
}
.gb-white {
  background-color: #fff;
}
.bg-purple {
  background: #8c52ff;
}
/*///////////////////////////////////////////////////

                  buttons

 /////////////////////////////////////////////////*/
.btn:focus {
  box-shadow: none !important;
}
.white-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-radius: 4px;
  color: #8c52ff;
}
.purple-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #8c52ff;
  border-radius: 4px;
  color: #fff;
}
.purple-btn:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #8c52ff;
  color: #8c52ff;
}
.green-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #0f9d58;
  border-radius: 4px;
  color: #fff;
}
.white-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-radius: 4px;
  color: #DB4437;
}

.greybtn ,
.greybtn:hover{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #949494;
  border-radius: 4px;
  color: #fff;
}
.green-btn:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #0f9d58;
  color: #0f9d58;
}
.orange-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #e18044;
  border-radius: 4px;
  color: #fff;
}
.orange-btn:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e18044;
  color: #e18044;
}
.orange-outline-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e18044;
  color: #e18044;
}
.orange-outline-btn:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #e18044;
  border-radius: 4px;
  color: #fff;
}

.purple-outline-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  border: 1px solid #8c52ff;
  color: #8c52ff;
}
.purple-outline-btn:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #8c52ff;
  border-radius: 4px;
  color: #fff;
}
.grey-outline-btn {
  border: 1px solid  #C4C4C4;
  border-radius: 4px;
  width:95%;
  box-sizing:border-box;
}
/*///////////////////////////////////////////////////

 CSS For Create Account and Login

 /////////////////////////////////////////////////*/
.round-boarder {
  border-radius: 15px;
}
.login-box {
  margin: 0 auto;
  margin-top: 50px;
  width: 368px;
  height: 420px;
}
.btn-verify {
  width: 100%;
  background-color: #949494;
  color: white;
}
.btn-signin {
  background-color: #8c52ff;
  color: #fff;
}
label {
  color: #949494;
}
.form-label {
  margin-bottom: 0rem;
}
.btn-otp {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #8c52ff;
  text-decoration: underline;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.resend {
  font-size: 16px;
  text-align: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #949494;
}
.resend-time {
  font-size: 16px;
  text-align: center;
  color: #949494;
}
.form-control {
  border: none;
  border-bottom: 2px solid #949494;
  border-radius: 0;
  box-shadow: none !important;
  outline: none !important;
}
.form-control-error {
  border: none;
  width: 100%;
  border-bottom: 2px solid #e60000;
  border-radius: 0;
  box-shadow: none !important;
  outline: none !important;
  display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-appearance: none;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control-otp {
  display: inline-block;
  width: 50%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom: 2px solid #949494;
  border-radius: 0;
  -webkit-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control-otp-error{
  display: inline-block;
  width: 50%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom: 2px solid #e60000;
  border-radius: 0;
  -webkit-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control-otp:focus {
  border: none;
  border-bottom: 2px solid #8c52ff;
  outline: none;
  transition-duration: 0.4s linear ease;
}
.input-group {
  margin-bottom: 56px;
}
.login-bg {
  background: linear-gradient(0deg, rgba(53, 53, 53, 1), rgba(0, 0, 0, 1));
  color: #fff;
  height: 100vh;
}
.orange-color {
  color: #fd7e31;
  font-family: Asap Condensed;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: none;
  border-bottom: 2px solid #8c52ff;
  background-color: #fff;
  border-radius: 0;
}
.input-group-text-error {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: none;
  border-bottom: 2px solid #e60000;
  background-color: #fff;
  border-radius: 0;
}
.form-control:focus {
  outline: none !important;
  border: none;
  border-bottom: 2px solid #8c52ff;
  border-radius: 0;
}
.send-otp {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #0f9d58;
  padding: 20px;
}
/*////////////////////////////////////

  courses page

 ////////////////////////////////////*/
.course {
  text-align: center;
  width: 100%;
  list-style-type: none;
  margin-bottom: 0px;
}
.course > li {
  padding: 5.5px 15px;
  display: inline-block;
}
.course > li > a {
  text-decoration: none;
  color: #fff;
}
.courseli::before {
  content: "\2022";
  color: #e18044;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/*////////////////////////////////////

 Course-Content

 ////////////////////////////////////*/
.course-content {
  width: 320px;
  border-radius: 15px;
  background-color: #fff;
  margin: 0 auto;
}

.video-frame {
  border-radius: 10px;
  padding: 20px;
}
/* accordian */
.course-sub-heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
}

.icon {
  width: 20px;
  display: flex;
  color: #666;
  float: right;
}
.icon .fa {
  font-size: 20px;
  transition: all 0.1s;
}
.icon .fa.up {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.modal-line {
  width: 95%;
  height: 1px;
  background: black;
  margin: 0 auto;
}
.text-dec {
  text-decoration: none;
}
.error {
  color: red;
  font-size: 12px;
}
.modal-header{
  border-bottom: none;
}
.modal-open .modal{
  z-index: 4000;
}
.progress{
  height: 6px;
}
.progress-bar{
  background: #24aa42;
}
blockquote {
  background: #f3f3f3;
  border-left: 4px solid #ccc;
  padding: 5px;
  border-radius: 2px;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-font-serif {
  font-family: serif;
}
.ql-font-monospace {
  font-family: 'Courier New', Courier, monospace;
}
.justify-content-space-between {
  justify-content: space-between !important;
}
pre {
  text-wrap: wrap;
  line-break: anywhere;
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
  border-radius: 8px;
}
.mentor-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.03em;
}
.mentor-profile-box {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.mentor-proile-pic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 0.5px solid #8c8c8c;
  box-sizing: border-box;
  margin: 0 auto;
}
.icon-box {
  display: block;
  margin: 0 auto;
  text-align: center;
  margin: 10px 0px;
}
.icon-img {
  width: 24px;
  height: 24px;
  margin: 0 10px;
}
.skills-box {
  display: block;
  width: 200px;
  margin-top: 20px;
  margin-left: 50px;
}
.paragraph {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0px;
  letter-spacing: 0.03em;
  color: #444444;
}
.paragraph__semi-bold{
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
letter-spacing: 0.03em;
color: #444444;
}
.button-box {
  display: block;
  height: auto;
  margin-left: 40px;
  padding-right: 20px;
}
.skills-button {
  padding: 8px 16px;
  background: #e5e5e5;
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #444444;
  float: left;
  margin: 3px;
}
.icon-company-box {
  display: block;
  margin-left: 45px;
}
.icon-company-img {
  height: 24px;
  margin: 0 5px;
  aspect-ratio: 1 / 1;
}
.icon-company-img-rect{
  height: 24px;
  width: auto;
  margin: 0 5px;
}
.booking-card {
  width: 310px;
  height: auto;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: (1px 1px 10px rgba(0, 0, 0, 0.62));
  border-radius: 8px;
  padding: 20px;
}
.one-on-one {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
}
.price {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */
  letter-spacing: 0.03em;
  color: #24aa42;
}
.off {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */
  letter-spacing: 0.03em;
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
  color: #8c8c8c;
}
.duration {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.annuncement-card {
  width: 100%;
  max-width: 300px;
  height: auto;
  left: 1062px;
  top: 523px;
  background: rgba(36, 170, 66, 0.34);
  border-radius: 8px;
  padding: 20px;
  margin-left: 10px;
}
.announcement-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #0f8028;
}
.announcement-box {
  margin-top: -35px;
  margin-left: -20px;
}
.demo-book {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.spacing {
  margin-top: 55px;
}
.slider {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.image-slider-container {
  display: inline-block;
  width: 318px;
  height: 203px;
  margin-right: 10px;
}
.slider::-webkit-scrollbar {
  display: none !important;
}
.hide-on-big-screen {
  display: none;
}
.scroll-card {
  position: static;
  height: auto;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scroll-card::-webkit-scrollbar {
  display: none;
}
.scroll-card-see-more {
  position: static;
  height: 90vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scroll-card-see-more::-webkit-scrollbar {
  display: none;
}
.scroll-right-section__v2 {
  position: static;
  height: 82vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scroll-right-section__v2::-webkit-scrollbar {
  display: none;
}
.scroll-card-see-more-desktop {
  position: static;
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scroll-card-see-more-desktop::-webkit-scrollbar {
  display: none;
}
.centerlized-card {
  align-self: center;
}
.fixed-Right-section {
  position: fixed;
  width: 310px;
  height: auto;
}
.btn-outline-green {
  width: 310px;
  padding: 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  background-color: #0f802812;
  border-radius: 8px;
  color: #0f8028;
}
.btn-outline-green:hover {
  background-color: #0f802812;
  color: #0f8028;
}

.card-bg {
  width: 100%;
  padding: 0px 15px;
  border-radius: 8px;
}
.justify-content-center-profile {
  justify-content: center !important;
}
.divider-bar {
  width: 80%;
  height: 0.5px;
  margin: 0 auto;
  background-color: #c4c4c4;
  margin-top: 25px;
}
.show-hide-text {
  background-color: transparent;
  border: 1px solid transparent;
  color: #8c52ff;
}
@media only screen and (max-width: 1366px) {
  .booking-card {
    width: 100%;
  }
  .btn-outline-green {
    width: 100%;
  }
}
@media only screen and (max-width: 747px) {
  .scroll-right-section__v2 {
    height: auto;
  }
  .divider-bar {
    display: none;
  }
  .hide-on-big-screen {
    display: block;
  }
  .annuncement-card {
      width: 100%;
      max-width: 95%;
      margin: 10px;
  }
  .mentor-proile-pic {
    margin: 0px;
  }
  .mentor-proile-pic {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    border: 0.5px solid #8c8c8c;
    box-sizing: border-box;
  }
  .hide-on-small-screen {
    display: none;
  }
  .icon-box {
    text-align: left;
    margin: 0px;
    margin-top: 10px;
  }
  .icon-img {
    width: 24 px;
    height: 24 px;
    margin: 0;
    margin-right: 10px;
  }
  .scroll-card {
    position: static;
  }
  .booking-card {
    width: 100%;
    background: white;
  }
  .card-bg {
    width: 95%;
    margin: 140px 10px 10px 10px;
    padding: 20px;
    background: #f3f3f3;
    border-radius: 8px;
  }
  .fixed-Right-section {
    position: static;
  }
  .profile-pic-on-mobile {
    width: 140px;
  }
  .skills-box {
    display: block;
    width: 200px;
    margin-top: 20px;
    margin-left: 0px;
  }
  .button-box {
    display: block;
    width: 100%;
    height: auto;
    margin-left: 0px;
  }
  .scroll-card {
    position: static;
    height: auto;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .scroll-card {
    position: static;
}
}

@media only screen and (max-width: 414px) {
  .scroll-right-section__v2 {
    height: auto;
  }
  .justify-content-center-profile {
    justify-content: left !important;
  }
  .hide-on-big-screen {
    display: block;
  }
  .annuncement-card {
    width: 100%;
    max-width: 95%;
    margin: 10px;
  }
  .mentor-proile-pic {
    margin: 0px;
  }
  .mentor-proile-pic {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    border: 0.5px solid #8c8c8c;
    box-sizing: border-box;
  }
  .scroll-card {
    position: fixed;
    height: auto;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .hide-on-small-screen {
    display: none;
  }
  .icon-box {
    text-align: left;
    margin: 0px;
    margin-top: 10px;
  }
  .icon-img {
    width: 24 px;
    height: 24 px;
    margin: 0;
    margin-right: 10px;
  }
  .scroll-card {
    position: static;
  }
  .booking-card {
    width: 100%;
    background: white;
  }
  .card-bg {
    margin: 10px;
    padding: 20px;
    background: #f3f3f3;
    border-radius: 8px;
  }
  .fixed-Right-section {
    position: static;
  }
  .profile-pic-on-mobile {
    width: 140px;
  }
  .skills-box {
    display: block;
    width: 200px;
    margin-top: 20px;
    margin-left: 0px;
  }
  .button-box {
    display: block;
    width: 100%;
    height: auto;
    margin-left: 0px;
  }
}

.shadow__v2 {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 4px 0px 8px rgba(0, 0, 0, 0.15);
}
.Booking__session--sessiontype {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  float: left;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #8c8c8c;
  margin-bottom: 8px;
}
.Booking__session--one-on-one {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
}

.demo-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  letter-spacing: 0.03em;
  color: #000000 !important;
}
.demo-input-field {
  width: 100%;
  height: 42px;
  left: 0px;
  top: 28px;
  padding: 0 6px;
  letter-spacing: 0.04em;
  font-family: 'Poppins', sans-serif;

  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 4px;
}
.requestDemo-btn {
  width: 270px;
  height: 40px;
  left: 540px;
  top: 660px;
  border-radius: 4px;
  background: #9281fc;
}
.demo-textarea-field {
  width: 100%;
  height: 75px;
  left: 0px;
  top: 28px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 4px;
  letter-spacing: 0.04em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.featuredWorkUploader__heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
}
.featuredWorkUploader__sub-heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.featuredWorkUploader__dropArea {
  align-items: center;
  padding: 32px 16px;
  width: 100%;
  height: 188.89px;
  border: 1px dashed #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
}
.featuredWorkUploader__dropArea--text-primary{
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 140%;
align-items: center;
text-align: center;
letter-spacing: 0.03em;
color: #444444;
}
.featuredWorkUploader__dropArea--text-secondary{
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 140%;
align-items: center;
text-align: center;
letter-spacing: 0.03em;
color: #444444;
}
.featuredWorkUploader__dropArea--text-secondary > span{
  color: #8C52FF;
}
.featuredWorkUploader__img-box{
width: 100%;
height: 172px;
background: linear-gradient(0deg, #E5E5E5, #E5E5E5);
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 8px;
}
.featuredWorkUploader__delete--image{
position: absolute;
right: 4.25%;
top: -4.25%;
background-color: #E5E5E5;
border-radius: 50px;
cursor: pointer;
}
.media-responsive__v2{
    width: 100% ;
    height: 370px;
    border-radius: 8px;
}
.media-responsive__v2--video-uploader{
    width: 100% ;
    height: 263px;
    border-radius: 8px;
}
.media-responsive__v2--videoLayout{
    width: 100% ;
}
@media only screen and (max-width: 414px){
    .media-responsive__v2{
        width: 100% ;
        height: 203px;
    }
    .media-responsive__v2--video-uploader{
        width: 100% ;
        height: 203px;
        border-radius: 8px;
    }
}
.socials__form--label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.03em;
  color: #000000;
  margin-left: 65px;
}
.checkbox-position{
  position: relative;
}
input[type=checkbox].socials--checkbox{
  cursor: pointer;
    opacity: 0;
    position: absolute;
    height: 25px;
    width: 25px;
    z-index: 1000;
}
.socials--checkbox-label::before {
  content: '';
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  margin-right: 6px;
}
.socials--checkbox:checked + .socials--checkbox-label::after{
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  margin-top: -12px;
  margin-left: -7px;
  background-color: #8c52ff;
  border-radius: 1.5px;
}

.videoUpload__btn-grey{
  width: 262px;
    height: 40px;
    background: #8c8c8c;
    border-radius: 4px;
    color: #fff;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
}
@media only screen and (max-width: 991px){
  .socials__form--label {
      margin-left: 40px;
    }
}
@media only screen and (max-width: 414px){
    .socials__form--label {
        margin-left: 30px;
      }
}

:root {
    --bg-white: #FFFFFF;
    --bg-purple-lite: #E6DBFD;
    --bg-purple-med: #D8C5FD;
    --bg-gray-lite: #EFEFEF;
    --border-primary: #AFAFAF;
    --border-success: #7BC27A;
    --border-warning: #F65050;
    --text-gray-dark: #454545;
    --text-gray-med: #7a7a7a;
    --text-gray-lite: #9E9E9E;
    --text-purple: #8C52FF;
    --text-warning: #E82B1F;
}

.socials--wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.03em;
    background: #00000057;
    -webkit-backdrop-filter: blur(2px) brightness(80%);
            backdrop-filter: blur(2px) brightness(80%);
    z-index: 100000;
}

.socials--wrapper p, .socials--wrapper h2, .socials--wrapper h3, .socials--wrapper h4 { margin: 0; }

.socials__box {
    width: 600px;
    padding: 26px;
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
    background-color: #FFFFFF;
    background-color: var(--bg-white);
    border-radius: 6px;
}

.socials__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.socials__header .header__heading {
    font-size: 18px;
    font-weight: 500;
    color: #7a7a7a;
    color: var(--text-gray-med);
}

.header__heading .header__heading--highlighted { color: #8C52FF; color: var(--text-purple); }

.socials__header .header__closeBtn {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    background-color: transparent;
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
}

.socials__block {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}

/* ------ VERIFIED LIST  */

.socials__verifiedList {
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    gap: 6px;
}

.socials__verifiedList .verified__profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    background-color: #EFEFEF;
    background-color: var(--bg-gray-lite);
}

.verified__profile .profile__info {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.profile__info .profile__info--platform {
    width: 22px;
    height: 22px;
}

.profile__info .profile__info--url {
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    color: #454545;
    color: var(--text-gray-dark);
}

.verified__profile .profile__action button {
    border: none;
    background-color: transparent;
    padding: 2px 4px;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
}

.verified__profile .profile__action button:hover {
    background-color: #e82c1f4e;
}

.social__input--block {
    display: flex;
    flex-direction: column;
    grid-gap: 3px;
    gap: 3px;
}

.social__input--block .block--wrapper {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    padding: 5px 6px;
    border: 1.2px solid #AFAFAF;
    border: 1.2px solid var(--border-primary);
    border-radius: 4px;
    position: relative;
}

.socials__block .block--wrapper.warning { border-color: #F65050; border-color: var(--border-warning); }
.socials__block .block--wrapper.success { border-color: #7BC27A; border-color: var(--border-success); }

.block--wrapper .socials__option {
    display: flex;
    justify-content: center;
    align-items: center;
}

.socials__option .socials__option--icon{
    width: 20px;
    height: 20px;
}

.block--wrapper .socials__input {
    border: none;
    background: transparent;
    font-size: 18px;
    padding: 2px 4px;
    color: #454545;
    color: var(--text-gray-dark);
}

.socials__input--invalid {
    color: #E82B1F;
    color: var(--text-warning);
    font-size: 14px;
    margin-top: 1.5px;
}

.block--wrapper .socials__confirmButton {
    position: absolute;
    top: 50%;
    right: 2%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 26px;
    font-weight: 400;
    color: #7a7a7a;
    color: var(--text-gray-med);
    transition: 0.2s ease-in-out;
}
.block--wrapper .socials__confirmButton.green { color: #7BC27A; color: var(--border-success) }

.block--wrapper .socials__confirmButton:hover,
.block--wrapper .socials__confirmButton:focus {
    color: #454545;
    color: var(--text-gray-dark);
}

.socials__addButton {
    align-self: flex-start;
    border: none;
    background-color: #E6DBFD;
    background-color: var(--bg-purple-lite);
    padding: 6px 16px;
    border-radius: 4px;
    color: #8C52FF;
    color: var(--text-purple);
    font-size: 16px;
    font-weight: 500;
    transition: 0.2s ease-in-out;    
}

.socials__addButton:hover { 
    background: #D8C5FD; 
    background: var(--bg-purple-med);
    -webkit-transform: scale(0.94);
            transform: scale(0.94);
}
.navbar__desktop--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 50px;
  box-shadow: 0px 1px 4px rgba(140, 140, 140, 0.5);
  background: #ffffff;
  z-index: 2000;
}

.navbar__desktop--logo {
  float: left;
}
.navbar__desktop--user-box {
  float: right;
}
.log__out--btn-V2{
  background: inherit;
  border: none;
  padding: 5px;
  width: 100%;
  display: block;
  text-align: left;
}
.dropdown-item{
  padding: 0px;
}
.whatsapp__plugin {
    position: fixed;
    bottom: 28px;
    right: 24px;
    width: 30px;
    height: 30px;
    z-index: 100000;
}

.whatsapp__plugin svg {
    width: 100%;
    height: 100%;
    background-size: cover;
    -webkit-filter: opacity(0.7) drop-shadow(0px 0px 6px rgb(0 0 0 / 0.2));
            filter: opacity(0.7) drop-shadow(0px 0px 6px rgb(0 0 0 / 0.2));
    -webkit-animation: 2s infinite alternate bounce;
            animation: 2s infinite alternate bounce;
    transition: 0.2s ease;
}

.whatsapp__plugin svg:hover {
    -webkit-transform: scale(1.1) translateY(-3px);
            transform: scale(1.1) translateY(-3px);
    -webkit-filter: opacity(1) drop-shadow(0px 0px 8px rgb(0 0 0 / 0.3));
            filter: opacity(1) drop-shadow(0px 0px 8px rgb(0 0 0 / 0.3));
}

@-webkit-keyframes bounce {
    0% { -webkit-transform: scale(1) translateY(0); transform: scale(1) translateY(0); }
    100% { -webkit-transform: scale(1.1) translateY(-6px); transform: scale(1.1) translateY(-6px); }
}

@keyframes bounce {
    0% { -webkit-transform: scale(1) translateY(0); transform: scale(1) translateY(0); }
    100% { -webkit-transform: scale(1.1) translateY(-6px); transform: scale(1.1) translateY(-6px); }
}
/* Custom properties */
:root {
    --tooltip-text-color: #8c8c8c;
    --tooltip-background-color: #FFFFFF;
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 6px;
}
  
/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 8px 10px;
  color: #8c8c8c;
  color: var(--tooltip-text-color);
  background: #FFFFFF;
  background: var(--tooltip-background-color);
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #C4C4C4;
}

/* CSS border triangles */
/* .Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
} */

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(30px * -1);
  top: calc(var(--tooltip-margin) * -1);
}

/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: #FFFFFF;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + 30px);
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(6px * -1);
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%);
  border-right-color: #FFFFFF;
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(30px * -1);
  bottom: calc(var(--tooltip-margin) * -1);
}

/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: #FFFFFF;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + 30px);
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(6px * -2);
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%);
  border-left-color: #FFFFFF;
  border-left-color: var(--tooltip-background-color);
}
body {
    font-family: 'Poppins';
    background-color: #FBFAFF;
    ;
}

.notes__container {
    max-width: 1440px;
    width: 100%;
}

.notes__header {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.notes__header>p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 48px;
}

.notes__header>button {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
}

.card__container {
    width: 100%;
    overflow: scroll;
}

.slider {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.notes__bottom--layer {

    position: relative;
    display: inline-block;
    width: 340px;
    height: 150px;
    margin-right: 10px;
    border-radius: 8px;
    background: #EEEEEE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin: 20px;
}

.notes__bottom--layer-mentor--profile {

    position: relative;
    display: inline-block;
    width: 290px;
    height: 140px;
    margin-right: 10px;
    border-radius: 8px;
    background: #EEEEEE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin: 20px;
}

.notes__middle--layer {
    position: absolute;
    width: 340px;
    height: 150px;
    top: -8px;
    left: -8px;
    background: #EDEDED;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.notes__middle--layer-mentor--profile {
    position: absolute;
    width: 290px;
    height: 140px;
    top: -8px;
    left: -8px;
    background: #EDEDED;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.notes__top--layer {
    position: absolute;
    width: 340px;
    height: 150px;
    top: -8px;
    left: -8px;
    background: #F8F8F8;
    border: 1px solid #8C52FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.notes__top--layer-mentor--profile {
    position: absolute;
    width: 290px;
    height: 140px;
    top: -8px;
    left: -8px;
    background: #F8F8F8;
    border: 1px solid #8C52FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.img-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 130px;
    height: 150px;
    border-radius: 8px 0px 0px 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-container-anki__mentor-Profile {
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 138px;
    border-radius: 8px 0px 0px 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-container>img {
    height: 100%;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #7B45E7;
    margin-top: -1.2px;
}
.img-container-anki__mentor-Profile>img {
    height: 100%;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #7B45E7;
    margin-top: -1.2px;
}
.notes__privacy {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    margin: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.03em;
    color: #8C8C8C;
}

.notes__content--container {
    position: absolute;
    display: flex;
    width: 220px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-20%, -50%);
            transform: translate(-20%, -50%);
    white-space: pre-line;
    align-items: center;
}

.notes__content--container-mentor--profile {
    position: absolute;
    display: flex;
    top: 55%;
    left: 50%;
    -webkit-transform: translate(-20%, -50%);
            transform: translate(-20%, -50%);
    white-space: pre-line;
    align-items: center;
}

.notes__icon--container {
    width: 20px;
    top: 10%;
    left: 50%;
    -webkit-transform: translate(-20%, -80%);
            transform: translate(-20%, -80%);
    margin: 10px;
}

.notes__deck-name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.03em;
    margin-bottom: 10px;
    color: #454545;
}

.notes__deck-likes {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.03em;
    color: #8C52FF;
    margin-bottom: 4px;
}

.notes__card--count {
    font-weight: 500;
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.03em;
    color: #223447;
}

.slider::-webkit-scrollbar {
    display: none;
}

.card>img {
    width: 279px;
    height: 100%;
    border-radius: 8px;
}

.notes__arrow-btn {
    font-weight: 500;
    background-color: #37993a3b;
    border-radius: 50%;
    color: #1B811F;
    margin: 10px 0px 0px 10px;
}

.my__notes__header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my__notes__header>p {
    margin: 0px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 29px;
    color: #5A5A5A;
}

.cursor-pointer {
    cursor: pointer;
}

.anki__card {
    position: relative;
    width: 259px;
    height: 200px;
    padding: 0px;
    border-radius: 8px;
    margin: 10px;
}

.anki__card>img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.anki__overlay--card {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #fff;
    background: rgba(4, 4, 4, 0.53);
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.anki__kabab-menu {
    float: left;
    margin: 10px;
}

.bar {
    width: 100%;
    height: 1px;
    background-color: #5A5A5A;
}

.anki__card--name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    color: #5A5A5A;
}

.anki__create-btn {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: none;
    background: #8C52FF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #FFF;
    padding: 10px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    transition: .2s ease;
}

.anki__create-btn:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

.previewCard__card--container {
    position: relative;
    width: 250px;
    height: 400px;
    background: #FFFFFF;
    box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 0px;
}

.anki_preview--image-card {
    width: 100%;
    height: 130px;
    border-radius: 8px;
}

.ans-que {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 20px;
    color: #000000;
    margin-bottom: 0px;
}

.hint {
    position: absolute;
    display: flex;
    width: 92%;
    bottom: 34px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #4EBD66;
    margin: 0px 10px 10px 10px;
    background: #E5F0EB;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
}

.anki__previewCard--group-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.anki__previewCard--btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
}

.hard {
    width: 120px;
    height: 40px;
    border: none;
    left: 49px;
    top: 625px;
    background: rgba(255, 107, 74, 0.21);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5em;
    color: #F71C1C;
    margin-bottom: 10px;
}

.easy {
    width: 120px;
    height: 40px;
    border: none;
    left: 222px;
    top: 625px;
    background: rgba(36, 170, 66, 0.34);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5em;
    color: #0F8028;
    margin-bottom: 10px;
}

.anki__card--slider-container {
    position: absolute;
    max-width: 320px;
    width: 100%;
    height: 85vh;
    padding: 20px;
    margin-top: 20px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 8px;
    box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.25);
}
.anki__card--slider-container-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.slick-prev {
    left: 0px !important;
}

.slick-next {
    right: 0px !important;
}

.slick-prev,
.slick-next {
    display: none !important;
    width: 34px !important;
    height: 32px !important;
}

.anki__heading--para {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
}

.anki__card-top--layer {
    position: relative;
    width: 350px;
    height: 150px;
    background: #FFFFFF;
    border: 1px solid #8C52FF;
    box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin: 15px;
}

.anki__card-content--container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 220px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-20%, -50%);
            transform: translate(-20%, -50%);
    white-space: pre-line;
    margin: 10px;
}

.anki__card-content--container>img {
    width: 20px;
    height: 18px;
}

.anki__card--question-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.03em;

    color: #444444;
}

.anki__card-question {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    color: #000000;
    margin-bottom: 0px;
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.anki__card-action--btn {
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.notes__add--create-card-btn {
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    background: #7B45E7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #fff;
    transition: .2s;
}

.notes__add--create-card-btn:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

.notes_dropdown {
    position: absolute;
    top: 1%;
    right: 1%;
    z-index: 200;
}

.notes_dropdown .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: none !important;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.notes_dropdown .btn-primary {
    color: #fff;
    transition: .2s ease;
    background-color: #00000000;
    border-color: #00000000;
}

.notes_dropdown .btn-primary:hover {
    color: #fff;
    background-color: #00000000;
    border-color: #00000000;
}

.notes_dropdown .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #00000000;
    border-color: #00000000;
}

.notes__menu-item {
    padding: 5px;
}

.notes__card-menu-style {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #646464;
}

.deck_delete-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3efff;
    height: 40px;
    width: 40px;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: .2s ease;
    margin: 10px;
}

.deck_delete-icon:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

.my__notes__header-text {
    position: absolute;
    top: -5%;
}

.my__notes__header-text>p {
    margin: 0px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #5A5A5A;
}

.notes-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 25px;
    background: rgba(140, 82, 255, 0.15);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 120%;
    letter-spacing: 0.03em;
    color: #8C52FF !important;
}

.confirmation-success-container {
    display: block;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    min-height: auto;
    background-color: #f9f9f9;
    position: relative;
    z-index: 20000000000;
    border-radius: 8px;
}

.confirmation-modal-box {
    display: block;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    min-height: auto;
    background-color: #f9f9f9;
    position: relative;
    z-index: 20000000000;
    border-radius: 8px;
}

.notes__text--over-flow--with-image-and-hint {
    height: 192px;
    overflow: hidden;
}
.notes__text--over-flow--without-image {
    height: 310px;
    overflow: hidden;
}
.notes__text--over-flow--with-image {
    height: 230px;
    overflow: hidden;
}
.notes__text--over-flow--without-image-and-hint {
    height: 355px;
    overflow: hidden;
}
.notes__text--over-flow--with-hint {
    height: 335px;
    overflow: hidden;
}
.toast__Message--success {
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 150000;
  background: #f9f9f9;
  color: #24AA42;
  border: 1px solid #24AA42;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 140%;
}
.toast__Message--warning {
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 150000;
  background: #f9f9f9;
  color: #ffae00;
  border: 1px solid #ffae00;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 140%;
}
.toast__Message--error {
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 150000;
  background: #f9f9f9;
  color: #dc3545;
  border: 1px solid #dc3545;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 140%;
}

.session-edit-card__container {
  display: block;
  padding: 20px;
  position: static;
  width: 100%;
  height: auto;
  left: 0px;
  top: 52px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
}
.session__edit__card__buttons {
  float: right;
}
.session__edit__card__buttons--button {
  border: none;
  background: #ffffff;
  padding: 10px;
}
.session__edit__card__buttons--button:hover {
  border: none;
  background: #e2e2e2;
  border-radius: 20px;
}
.session-edit-card--sessiontype {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  float: left;
  margin-top: 10px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #8c8c8c;
}
.session-edit-card {
  display: inline-block;
  width: 100%;
}
.sessionCardEdit__body {
  position: relative;
}
.sessionCardEdit__body--sessionName {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #444444;
}
.sessionCardEdit__body--price {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.03em;
  color: #24aa42;
}
.sessionCardEdit__body--off {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.03em;
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
  color: #8c8c8c;
}
.sessionCardEdit__body--duration {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.sessionCardEdit__body--paragraph {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.sessionCard__deleteMessage{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  margin-bottom: 0.5rem;
  color: #000000;
}
.sessionCard__deleteMessage-sub-text{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8C8C8C; 
  margin-bottom: 32px;
}
.sessionCard__delete-btn{
  width: 212px;
height: 40px;
color: #ffffff;
background: #D4574C;;
border-radius: 4px;
float: right;
}
.sessionCard__cancel-btn{
  width: 212px;
height: 40px;
border: 1px solid #8C8C8C;
box-sizing: border-box;
border-radius: 4px;
float: left;
}
.custom-modal-style {
  position: absolute;
  max-width: 504px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  }
  @media only screen and (max-width: 506px){
    .sessionCard__delete-btn{
      width: 150px;
    }
    .sessionCard__cancel-btn{
      width: 150px;
    }
}
:root {
  --text-light: #8c8c8c;
  --text-bold: #000000;
  --text-medium: #444444;
  --text-purple-dark: #8c52ff;
  --text-gray: #c4c4c4;
  --bg-purple: #8c52ff;
  --bg-white: #ffffff;
  --bg-gray-light: #f9f9f9;
  --bg-blue: #0a66c2;
}
.background-form {
  min-height: 100vh;
  background-color: #f9f9f9;
  background-color: var(--bg-gray-light);
}

.form-container {
  background: #ffffff;
  background: var(--bg-white);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.first__session--sub-container{
  max-width: 860px;
}
.first__session--top-margin{
  margin-top: '134px';
}
.mentor-form-box {
  padding: 60px 120px;
  position: relative;
}

.mentor-form-box .close-button {
  position: absolute;
  top: 12px;
  right: 16px;
  text-decoration: none;
  background-color: transparent;
}

.mentor-form-box > h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #000000;
  text-align: center;
}

.mentor-form-box > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}

.form-label__v2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
  color: var(--text-bold);
}

.form-label__v2.form-label-heading { font-weight: normal; }

.tooltip--img { margin: 4px 8px; cursor: pointer; }

.form-input {
  width: 100%;
  height: 42px;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 6px 0px;
  padding: 5px;
}

.form-input:focus {
  outline: 2px solid #8c52ff;
  outline: 2px solid var(--bg-purple);
}

.form-textarea {
  width: 100%;
  height: 105px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 4px;
}

.form-textarea:focus {
  outline: 2px solid #8c52ff;
  outline: 2px solid var(--bg-purple);
}

.form-input::-webkit-input-placeholder, .form-textarea::-webkit-input-placeholder {
  font-family: 'Poppins', sans-serif;
  font-size: 14px !important;
  letter-spacing: 0.03em;
}

.creatable--field #react-select-3-placeholder,
.form-input::placeholder,
.form-textarea::placeholder {
  font-family: 'Poppins', sans-serif;
  font-size: 14px !important;
  letter-spacing: 0.03em;
}

.btn-primary__v2 {
  width: 100%;
  height: 42px;
  background: #8c52ff;
  background: var(--bg-purple);
  border-radius: 4px;
  color: #ffffff;
  color: var(--bg-white);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}
.add-more-session__text{
  font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 150%;
letter-spacing: 0.03em;
color: #444444;
}
.first__session-form-select {
  width: 100%;
  border: none;
}
.first__session-form-select:focus {
  border: none;
}
@media only screen and (max-width: 747px) {
  .mentor-form-box {
    padding: 20px 20px;
  }
}

.profile__edit-status{
  position: relative;
}
.checklist__card__container {
  position: relative;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
}
.checklist__paragraph--grey {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #c4c4c4;
  padding-bottom: 10px;
  cursor: pointer;
}
.checklist__paragraph--green {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #24aa42;
  padding-bottom: 10px;
  cursor: pointer;
}
.checklist__card--tick {
  margin-right: 5px;
}
.checklist__card--plus-icon {
  position: absolute;
  right: 8.67%;
  margin-top: -5px;
}

.modal--wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000057;
  -webkit-backdrop-filter: blur(2px) brightness(80%);
          backdrop-filter: blur(2px) brightness(80%);
}

.modal--wrapper h2,
.modal--wrapper p {
  margin: 0;
}

.custom__modal {
  width: 600px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 25px 30px;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal__header .header__info {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}

.modal__header .header--heading {
  font-size: 20px;
  font-weight: 500;
  color: var(--text-black-med);
}

.modal__header .header--desc {
  font-size: 16px;
  font-weight: 400;
  color: #949494;
}

.modal__close--btn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #CFCFCF;
  outline: none;
  background-color: transparent;
  padding: 5px;
  border-radius: 50%;
}
.modal__close--btn img { -webkit-transform: translateX(1px); transform: translateX(1px); }

.modal__input {
  width: 100%;
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  align-items: center;
  border: 1px dashed #BDBDBD;
  padding: 4px 6px;
  border-radius: 4px;
  overflow: hidden;
}

.modal__input .input__prefix {
  padding: 6px 10px;
  background-color: rgba(196, 196, 196, 0.24);
  border-radius: 4px;
  cursor: pointer;
}

.modal__input .input__prefix span {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
}

.modal__input .input--url {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}

.input--url .input__custom-url {
  width: 100%;
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  padding: 4px;
}

.input--url .valid--icon{
  position: absolute;
  top: 50%;
  right: 1%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 18px;
  height: 18px;
}

.modal__footer { align-self: flex-end; }

.modal__footer button {
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  background-color: #8C52FF;
  border-radius: 4px;
  color: #ffffff;
  padding: 8px 22px;
  transition: 0.2s ease-in-out;
}

.modal__footer button:disabled { background-color: #c4c4c4; }

.modal__footer button:hover { background-color: #7133eb; }
.modal__footer button:disabled:hover { 
  background-color: #c4c4c4; 
  cursor: not-allowed;
}

@media only screen and (max-width: 414px) {
  .custom__modal { 
    width: 90%;
    padding: 10px; 
  }
  .modal__header .header--heading { font-size: 18px; }
  .modal__header .header--desc { font-size: 15px; }
  .modal__close--btn { 
    width: 25px;
    height: 25px;
  }
  .modal__close--btn img {-webkit-transform: translateX(0px);transform: translateX(0px);}
  .modal__input {
    grid-gap: 4px;
    gap: 4px;
    padding: 4px;
  }
  .modal__input .input__prefix { padding: 6px; }
  .modal__input .input__prefix span,
  .input--url .input__custom-url { 
    font-size: 15px; 
  } 
  .input--url .valid--icon { right: 2%; }
  .modal__footer button { padding: 5px 22px; }
}

.videoUpload__heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 8px;
}
.videoUpload__sub-heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c8c8c;
  margin-bottom: 32px;
}
.videoUpload__btn {
  width: 262px;
  height: 40px;
  background: #8c52ff;
  border-radius: 4px;
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}
.videoUpload__clearn-btn{
  position: absolute;
  top: 20px;
  right: 15px;
}
.modal-dialog{
  max-width: 650px;
}

.edit__right-side--width-control {
  position: relative;
  width: 310px;
}
.profile__edit-status {
  display: flow-root;
}
.profile__edit-status--text {
  float: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #444444;
}
.profile__edit-status--percentage {
  float: right;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #fd8e4a;
}
.profile__edit-addsession {
  display: inline-block;
  width: 100%;
}
.profile__edit-addsession--text {
  float: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #444444;
}
.profile__edit-addsession--edit {
  float: right;
  padding: 5px 10px 10px 10px;
}
.modal__heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #000000;
}
.view__public__profile--button {
  width: 220px;
  border: 1px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 100%;
letter-spacing: 0.03em;
color: #8C8C8C;
padding: 12px 0px;
}
@media only screen and (max-width: 1366px) {
  .edit__right-side--width-control {
    position: relative;
    width: 100%;
  }
}
@media only screen and (max-width: 775px) {
  .edit__right-side--width-control {
    position: relative;
    width: 100%;
  }
}
@media only screen and (max-width: 414px) {
  .edit__right-side--width-control {
    position: relative;
    width: 100%;
  }
}

:root {
  --text-light: #8c8c8c;
  --text-bold: #000000;
  --text-medium: #444444;
  --text-purple-dark: #8c52ff;
  --text-gray: #c4c4c4;
  --bg-purple: #8c52ff;
  --bg-purple-dark: #7b45e7;
  --bg-white: #ffffff;
  --bg-gray-light: #f9f9f9;
  --bg-blue: #0a66c2;
}

.background-form {
  min-height: 100vh;
  background-color: #f9f9f9;
  background-color: var(--bg-gray-light);
}
.form-container {
  background: #ffffff;
  background: var(--bg-white);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.Mentor__detail__form--sub-container{
  max-width: 860px;
}
.Mentor__detail--top-margin{
  margin-top: 114px !important;
}
.image-round {
  width: 120px;
  height: 120px;
  background: #e5e5e5;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 50%;
}
.mentor-form-box {
  padding: 60px 120px;
}
.mentor-form-box > h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #000000;
  text-align: center;
}
.mentor-form-box > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.signUp-with-linkedin {
  background: #0a66c2;
  background: var(--bg-blue);
  color: #ffffff;
  color: var(--bg-white);
  border-radius: 4px;
}
.form-label__v2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
  color: var(--text-bold);
}
.mentor--tagged-input .css-1rhbuit-multiValue {
  border-radius: 6px !important;
  padding: 2px 6px;
}

.upload-button {
  height: 36px;
  background: #d3d3d3;
  border-radius: 4px;
  margin: 10px;
}
.form-input {
  width: 100%;
  height: 42px;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 6px 0px;
  padding: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.03em;
}
.form-input::-webkit-input-placeholder {
  color: #c4c4c4;
  font-size: 10px;
}
.form-input::placeholder {
  color: #c4c4c4;
  font-size: 10px;
}

.form-input:focus {
  outline: 2px solid #8c52ff;
  outline: 2px solid var(--bg-purple);
}
.form-textarea {
  width: 100%;
  height: 105px;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.03em;
}
.form-textarea:focus {
  outline: 2px solid #8c52ff;
  outline: 2px solid var(--bg-purple);
}
.form-textarea::-webkit-input-placeholder {
  font-size: 14px;
  color: #c4c4c4;
}
.form-textarea::placeholder {
  font-size: 14px;
  color: #c4c4c4;
}
.btn-primary__v2 {
  width: 100%;
  height: 42px;
  background: #8c52ff;
  background: var(--bg-purple);
  border-radius: 4px;
  color: #ffffff;
  color: var(--bg-white);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  transition: 0.2s ease;
}
.btn-grey__v2 {
  width: 100%;
  height: 42px;
  background: #8c8c8c;
  background: var(--text-light);
  border-radius: 4px;
  color: #ffffff;
  color: var(--bg-white);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  transition: 0.2s ease;
}
.btn-primary__v2:hover {
  color: #ffffff;
  color: var(--bg-white);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  background-color: #7b45e7;
  background-color: var(--bg-purple-dark);
}
.btn-primary__v2:active {
  -webkit-transform: scaleX(0.98);
          transform: scaleX(0.98);
}
.company-name-container {
  position: absolute;
  width: 250px;
  height: auto;
  background-color: #ffffff;
  background-color: var(--bg-white);
  border-radius: 8px;
  padding: 10px;
  z-index: 10000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 42px;
  width: 100%;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
}
.tags-input:focus-within {
  border: 2px solid #8c52ff;
  border: 2px solid var(--bg-purple);
}
.tags-input > input {
  width: auto;
  height: 42px;
  float: left;
  border: none;
  font-size: 12px;
  padding: 4px 0 0 0;
}
.tags-input > input::-webkit-input-placeholder {
  font-size: 14px;
  color: #c4c4c4;
}
.tags-input > input::placeholder {
  font-size: 14px;
  color: #c4c4c4;
}
input:focus {
  outline: transparent;
}
#tags {
  display: block;
  float: left;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  float: left;
  align-items: center;
  justify-content: center;
  color: #000;
  padding: 0 0px 0px 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #e6e6e6;
}
.tag-title {
  margin-top: 3px;
}
.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #0052cc;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}
.counter-label__v2{
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #8c8c8c;
  text-align: center;
}
input[type="file"]{
  visibility: hidden;
}
.image-warning-container{
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  text-transform: capitalize;
}
.image-heading-error{
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  letter-spacing: 0.03em;
}
.image-description-error{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #7b45e7;
}
@media only screen and (max-width: 747px) {
  .mentor-form-box {
    padding: 20px 20px;
  }
}

.announcement__btn {
  width: 160px;
  height: 40px;
  background: #8c52ff;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.85);
  float: right;
}

.announcementCard__container {
  padding: 20px;
  width: 310px;
  height: auto;
  left: 0px;
  background: #e5e5e5;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
}
.announcementCard__container--paragraph {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c8c8c;
  margin: 0;
}
@media only screen and (max-width: 1366px) {
  .announcementCard__container {
    width: 100%;
  }
}
@media only screen and (max-width: 775px){
  .announcementCard__container {
    width: 100%;
  }
}
@media only screen and (max-width: 414px){
  .announcementCard__container {
    width: 100%;
  }
}
:root {
    --text-light: #8C8C8C;
    --text-white: rgba(255, 255, 255, 0.85);
    --text-bold: #000000;
    --text-medium: #444444;
    --text-purple-dark: #8C52FF;
    --text-gray: #C4C4C4;
    --bg-purple-light: #8C52FF26;
    --bg-purple-bright: #8C52FF;
    --bg-purple-dark: #7B45E7;
    --bg-white: #ffffff;
    --bg-gray-light: #F3F3F3;
}

/* --------- CONFIRMATION STYLES */
.confirmation {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    min-height: 100vh;
    background-color: #f9f9f9;
    position: relative;
    z-index: 2;
}


.confirmation__box {
    width: 50%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding: 60px 40px;
    background-color: #ffffff;
    background-color: var(--bg-white);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.confirmation__head {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    grid-gap: 20px;
    gap: 20px;
    letter-spacing: 0.04em;
}

.confirmation__head--title {
    margin: 0;
    font-size: 28px;
    font-weight: 500;
    color: #000000;
    color: var(--text-bold);
}

.confirmation__head--description {
    width: 80%;
    margin: 0;
    font-size: 16px;
    color: #444444;
    color: var(--text-medium);
}

.confirmation__head--message {
    display: block;
    margin: 8px 0;
    font-weight: 500;
    color: #7B45E7;
    color: var(--bg-purple-dark);
}

.confirmation__links {
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
}

.confirmation__link {
    text-decoration: none;
    text-align: center;
    display: block;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #8C52FF;
    border: 1px solid var(--text-purple-dark);
    border-radius: 2px;
    padding: 8px 26px;
    color: #8C52FF;
    color: var(--text-purple-dark);
    transition: 0.2s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.confirmation__link:nth-child(even) {
    background: #8C52FF;
    background: var(--bg-purple-bright);
    border: none;
    color: rgba(255, 255, 255, 0.85);
    color: var(--text-white);
}

.confirmation__link:hover {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    background-color: #8C52FF;
    background-color: var(--text-purple-dark);
    color: rgba(255, 255, 255, 0.85);
}

@media only screen and (max-width: 414px) {
    .confirmation__box {
        width: 100%;
        padding: 30px 20px;
        background-color: transparent;
        box-shadow: unset;
    }
    .confirmation__head { text-align: left; }
    .confirmation__links { 
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
    }
    .confirmation__head--title { 
        text-align: center;
        font-size: 24px; 
    }
    .confirmation__head--description { 
        width: 100%;
        font-size: 14px; 
        text-align: center;
    }
}

/* ------------ LOADER STYLING */
.payment--spinner {
    display: inline-block;
    margin: 0 12px;
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    cursor: progress;
}

@media only screen and (max-width: 414px) {
    .payment--spinner { 
        display: block;
        margin: 0 auto;
    }
}

/* ----------- SESSION STYLES */
.session__container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.session {
    display: grid;
    grid-template-columns: 340px 480px;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    grid-auto-columns: 240px;
    grid-gap: 6px;
    gap: 6px;
    max-width: 1100px;
    height: 100%;
    background: #ffffff;
    background: var(--bg-white);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    position: relative;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.03em;
}

.session.session__form--active {
    width: 100%;
}

.back__button {
    position: absolute;
    width: 40px;
    top: 10px;
    left: 14px;
    transition: 0.2s ease;
}

.back__button:hover { -webkit-transform: translateX(-6px); transform: translateX(-6px); }

/* ------- SESSION BOOKING */   

.session__booking {
    width: 100%;
    padding: 60px 30px;
    display: flex;
    flex-direction: column;
    grid-gap: 22px;
    gap: 22px;
    align-items: flex-start;
    border-right: 1px solid #E5E5E5;
}

.session__booking--details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 22px;
    gap: 22px;
}

.session__booking--details.form__active {
    grid-gap: 10px;
    gap: 10px;
}

.session__booking--details.form__active .session__info {
    grid-gap: 6px;
    gap: 6px;
}

.session__head--wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 4px;
    gap: 4px;
}

.session__text {
    font-size: 16px;
    font-weight: 600;
    color: #8C8C8C;
    color: var(--text-light);
}

.session__type {
    font-size: 14px;
    font-weight: 500;
    color: #444444;
    color: var(--text-medium);
}

.session__heading {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    color: var(--text-bold);
    word-wrap: break-word;
}

.session__info {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
    align-items: flex-start;
}

.session__price {
    display: flex;
    align-items: center;
    font-size: 16px;
    grid-gap: 20px;
    gap: 20px;
}

.session__cost {
    font-weight: 500;
    color: #000000;
    color: var(--text-bold);
}

.session__cost--markthrough {
    font-weight: 500;
    color: #8C8C8C;
    color: var(--text-light);
    text-decoration: line-through;
}

.session__duration {
    font-size: 14px;
    font-weight: 500;
    color: #8C8C8C;
    color: var(--text-light);
    padding: 2px 12px;
}

.session__duration img {
    -webkit-transform: translate(-10px, -2px);
            transform: translate(-10px, -2px);
    cursor: pointer;
}

.session__description p{
    font-size: 14px;
    font-weight: 400;
    color: #444444;
    color: var(--text-medium);
    word-wrap: break-word;
}

/* TO REMOVE MARGIN COMING FROM REBOOT.SCSS */
.session__text,
.session__heading,
.session__duration,
.session__description p,
.form--schedule,
.session__head--wrapper .session__type {
    margin: 0;
}

/* ----------- SESSION CALENDAR */

.session__calendar {
    width: 100%;
    padding: 60px 15px 20px 15px;
}

.calendar__heading {
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    color: #000000;
    color: var(--text-bold);
}

.react-calendar {
    border: none !important;
    width: 100% !important;
    max-width: 480px !important;
    height: 100%;
    font-family: inherit !important;
    margin-bottom: 26px;
}

.react-calendar__navigation {
    display: block !important;
    margin-bottom: 20px;
    position: relative;
}

.react-calendar__navigation__label {
    font-size: 16px;
    color: #3a3a3a;
    -webkit-transform: translate(2px, 20px);
            transform: translate(2px, 20px);
    pointer-events: none;
}

.react-calendar__navigation__arrow {
    position: absolute;
    background: transparent !important;
    color: #8C8C8C;
    color: var(--text-light);
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    display: none !important;
}

.react-calendar__navigation__prev-button {
    top: 18px;
    right: 42px;
    font-size: 34px;
    cursor: pointer;
}
  
.react-calendar__navigation__next-button {
    top: 18px;
    right: 0px;
    font-size: 34px;
    cursor: pointer;
}

 /* ----------- TO CONFIGURE WEEKDAYS FROM CALENDAR */

.react-calendar__month-view__weekdays {
    font-size: 16px !important;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    color: #000000 !important;
    color: var(--text-bold) !important;
    margin-bottom: 8px;
    cursor: pointer;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
    font-weight: 500 !important;
}

.react-calendar__month-view__days {
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.react-calendar__tile {
    flex-basis: 10.2% !important;
    background: transparent !important;
    font-size: 18px;
    font-weight: 500;
    color: #C4C4C4 !important;
    color: var(--text-gray) !important;
    position: relative;
}

.react-calendar__tile--now::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    bottom: 6px;
    left: 50%;
    background-color: #8C52FF;
    background-color: var(--bg-purple-bright);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.react-calendar__tile:enabled {
    font-size: inherit;
    color: #8C52FF !important;
    color: var(--text-purple-dark) !important;
    font-weight: 600 !important;
    background: #8C52FF26 !important;
    background: var(--bg-purple-light) !important;
    border-radius: 50%;
    transition: .2s linear;
}

.react-calendar__tile:enabled:hover {
    background: #D8BFD8 !important;
    color: #ffffff !important;
    color: var(--bg-white) !important;
}

.react-calendar__tile.react-calendar__tile--active {
    background: #8C52FF !important;
    background: var(--bg-purple-bright) !important;
    color: rgba(255, 255, 255, 0.85) !important;
    box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.14);
    pointer-events: none;
}

/* -------------- SESSION FORM */
.session__form {
    width: 100%;
    height: 100%;
    padding: 60px 15px;
    position: relative;
}

.form--closebtn {
    position: absolute;
    top: 15px;
    right: 24px;
    transition: .2s ease;
}

.form--closebtn:hover { -webkit-transform: scale(1.2); transform: scale(1.2); }

.form--header {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    color: var(--text-bold);
    margin-bottom: 30px;
}

.form__container {
    display: block;
    width: 100%;
    margin: 0;
}

.form__field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 24px;
    gap: 24px;
    margin-bottom: 22px;
}

.form__field--group {
    width: 90%;
}

.form__field--label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    color: var(--text-bold);
    margin-bottom: 14px;
}

.form__field--textarea {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #444444;
    color: var(--text-medium);
    padding:  8px;
    line-height: 20px;
    resize: none;
    outline: none;
    border: 1.5px solid #D3D3D3;
    border-radius: 4px;
}

.form__tagged--input {    
    display: flex;
    align-items: center;
    padding: 2px 6px;
    border: 1.5px solid #D3D3D3;
    border-radius: 4px;
    overflow-x: hidden;
}

.form__tagged--input.wrapNeeded { flex-wrap: wrap; }

.form__tagged--input .form__field--input {
    display: inline;
    border: none;
    padding: 0;
    margin-left: 6px;
}

.tagged__input--filled {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    font-size: 12px;
    color: #444444;
    color: var(--text-medium);
    font-weight: 500;
    background-color: #E5E5E5;
    border-radius: 100px;
    padding: 6px 14px;
    margin: 4px;
}

.tagged__input--icon {
    border: none;
    outline: none;
    background-color: transparent;
}

.tagged__input--icon img { -webkit-transform: translateY(-1px); transform: translateY(-1px); }

.form__field--input {
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 500;
    color: #444444;
    color: var(--text-medium);
    border: 1.5px solid #D3D3D3;
    background-color: transparent;
    border-radius: 4px;
    outline: none;
}

.form--submit {
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    padding: 8px 34px;
    color: #F3F3F3;
    color: var(--bg-gray-light);
    background: #8C52FF;
    background: var(--bg-purple-bright);
    transition: 0.2s ease;
}

.form--submit:hover {
    color: #F3F3F3;
    color: var(--bg-gray-light);
    background: #7B45E7;
    background: var(--bg-purple-dark);
} 

/* TO HANDLE CONDITIONALLY RENDERING DATA  */
.form__data {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    align-items: flex-start;
}

.form--schedule {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    color: var(--text-bold);
}

.form--schedule img {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 8px;
}

.form__data--dropdown .btn {
    padding: 0.375rem 0;
    color: #000000;
    color: var(--text-bold);
    font-weight: 500;
}

/* DROPDOWN COMPONENT FROM CALENDAR SECTION */
.dropdown__container {
    font-family: inherit;
    color: #000000;
    color: var(--text-bold);
}

.dropdown img:first-of-type {
    margin-right: 10px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
}

.dropdown img:last-of-type {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    margin-left: 10px;
}

/* SESSION SLOTS */
.session__slots {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 20px 10px 20px 0;
    position: relative;
    overflow: hidden;
}

.close__button {
    position: absolute;
    top: 12px;
    right: 22px;
    border: none;
    outline: none;
    background: transparent;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: .2s ease;
    z-index: 8;
}

.close__button:hover { -webkit-transform: scale(1.2); transform: scale(1.2); }

.slots__container {
    margin-top: 85px;
    height: 100%;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.slots__container::-webkit-scrollbar { display: none; }

.session__date {
    display: block;
    font-size: 16px;
    color: #000000;
    color: var(--text-bold);
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
}

.slot {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}

.slots {
    height:50vh;
    overflow-y:auto;
    overflow-x:hidden;
}

.slots::-webkit-scrollbar { display: none; }

.slots--btn.disabled-btn {
    cursor: not-allowed;
}

.disabled-btn , 
.disabled-btn:hover{
    background: #F3F3F3;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #C4C4C4;
}

/* ------------ STYLING FOR MOBILE */

.hidden {
    opacity: 0;
    display: none;
}

.visible {
    opacity: 1;
    display: block;
}

.session__container--mobile {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.04em;
}

.session__header {
    display: block;
    width: 100%;
    height: 50px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}

.session__header .back__button {
    top: 4px;
    left: 10px;
}

.session__booking.session__booking--mobile {
    padding: 20px 24px 30px 24px;
    border-right: none;
    border-bottom: 2px solid #c4c4c4;
}

.form__data.form__data--mobile { padding: 10px 0; }

.session__calendar.session__calendar--mobile { padding: 35px 24px 60px 24px; }

.session__slots.session__slots--mobile { padding: 20px; }

.session__slots--mobile .slots__information {
    display: flex;
    flex-direction: column;
    grid-gap: 35px;
    gap: 35px;
}

.slots__information .slots__heading {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    color: var(--text-bold);
}

.slots__container.slots__container--mobile {
    margin-top: 35px;
    width: 100%;
    height: auto;
    max-height: unset;
    overflow: unset;
}

.session__form.session__form--mobile { padding: 40px 25px; }

@media only screen and (max-width: 414px) {

    /* ---------- SESSION BOOKING */
    .session__text {
        font-size: 14px;
        margin: 0 0 8px 0;
    }

    .session__heading {
        font-size: 18px;
        margin: 12px 0 6px 0;
    }

    .session__price {
        margin: 4px 0 16px 0;
        grid-gap: 16px;
        gap: 16px;
    }

    .session__duration {
        padding: 2px 8px;
        margin-bottom: 6px;
    }

    .session__description p{
        max-width: 35ch;
        word-wrap: break-word;
    }

    .form--schedule {
        max-width: 70%;
        line-height: 21px;
        word-wrap: break-word;
        margin: 4px 0;
    }

    .form__data--dropdown .btn{ font-size: 14px; }

    /* ------ SESSION CALENDAR MOBILE */

    .calendar__heading {
        font-size: 18px;
        margin-bottom: 18px;
    }

    .react-calendar {
        width: 100% !important;
        margin-bottom: 20px;
        max-width: 350px !important;
    }

    .react-calendar__navigation__prev-button {
        right: 24px;
        font-size: 40px;
    }

    .react-calendar__navigation__next-button {
        right: -10px;
        font-size: 40px;
    }

    .react-calendar__month-view__weekdays {
        font-size: 14px !important;
        grid-column-gap: 0px;
        -webkit-column-gap: 0px;
                column-gap: 0px;
        margin-bottom: 12px;
        font-weight: normal !important;
    }

    .react-calendar__month-view__days {
        grid-column-gap: 3.17%;
        -webkit-column-gap: 3.17%;
                column-gap: 3.17%;
    }

    .react-calendar__tile {
        padding: 10px 0 !important;
        flex-basis: 11.11% !important;
        max-width: 11.11% !important;
        font-size: 14px !important;
    }

    /* ----- SLOTS MOBILE */

    .dropdown__container--mobile .dropdown-toggle,
    .slots__information--time .dropdown-toggle {
        font-size: 14px;
        padding: 0.375rem 0.1rem;
        letter-spacing: 0.04em;
    }

    .session__date {
        text-align: unset;
        color: #333333;
        margin-bottom: 8px;
    }

    .slots.slots--mobile {
        height: 100%;
        overflow: unset;
    }

    /* --------- SESSION FORM  */

    .form__field { align-items: stretch; }

    .form--header {
        font-size: 16px;
        margin-bottom: 35px;
    }

    .form__field--label { font-size: 14px; }

    .form__field--input {
        width: 100%;
        font-size: 12px;
        letter-spacing: 0.04em;
        border-width: 1px;
    }
    
    .form__field--textarea {
        font-size: 12px;
        border-width: 1px;
    }

    .form__tagged--input { padding: 2px 4px; }
    .form__tagged--input .form__field--input { padding: 6px 2px; }
}
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --white: #FFFFFF;
}

.payment__portal {
    display: flex;
    position: fixed;
    inset: 0;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(4px) brightness(60%);
            backdrop-filter: blur(4px) brightness(60%);
    min-height: 100vh;
    z-index: 10000;
}

.payment__portal h3, h2, h4 {
    margin: 0;
}

.payment__dialog {
    background-color: #FFFFFF;
    background-color: var(--white);
    padding: 18px 24px;
    border-radius: 6px;
}

.payment__dialog.dialog__details {
    width: min(55%, 940px);
    height: min(60%, 620px);
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.payment__dialog .dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialog__header .dialog__header--heading {
    font-size: 20px;
    font-weight: 500;
}

.dialog__header .dialog__header--action {
    background-color: #ECECEC;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.details__container {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    margin-top: 20px;
    margin-bottom: auto;
}

.details__container .container__image {
    min-width: 200px;
    max-width: 200px;
    height: 200px;
    position: relative;
}

.details__container .container__image .container__image--info {
    position: absolute;
    bottom: 8px;
    left: 8px;
    display: inline-block;
    background-color: white;
    padding: 8px 18px;
    font-size: 14px;
    border-radius: 100px;
    text-transform: capitalize;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}

.details__container .container__image img { width: 100%; height: 100%; object-fit: cover; border-radius: 6px; }

.details__container .entity__details {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.entity__details .entity__details--primary,
.entity__details .entity__details--secondary {
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
}

.entity__details--primary .entity__details--name {
    display: flex;
    grid-gap: 6px;
    gap: 6px;
}

.entity__details--primary .entity__details--name h3 {
    font-size: 18px;
    color: #3A3939;
    font-weight: 500;
}

.entity__details--primary .entity__details--link { cursor: pointer; }

.entity__details--primary .entity__details--author .entity__details--type {
    font-size: 18px;
    font-weight: 600;
    color: #C058F0;
}

.entity__details--primary .entity__details--author .entity__details--type.type--webinar { color: #8C52FF; }

.entity__details--primary .entity__details--author .entity__details--author-name {
    text-decoration: underline;
    text-underline-offset: 4px;
    -webkit-text-decoration-color: #C7C7C7;
            text-decoration-color: #C7C7C7;
    cursor: pointer;
}

.entity__details--secondary .entity__details--info {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    width: 90%;
}

.entity__details--secondary .entity__details--perks {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
}

.entity__pill {
    display: inline-flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    padding: 2px 10px;
    text-transform: capitalize;
    align-self: flex-start;
    border-radius: 100px;
}

.entity__pill .entity__pill--text {
    font-size: 16px;
    font-weight: 400;
}

.entity__details--secondary .entity__details--price { margin-top: 12px; }

.entity__details--secondary .entity__details--price h4 {
    color: #3A3939;
    font-weight: 500;
    font-size: 18px;
}

.entity__details--secondary .entity__details--price .entity__details--price-original {
    font-size: 16px;
    margin: 0 4px;
}

.entity__details--secondary .entity__details--price .entity__details--price-original.striked { text-decoration: line-through; }

.entity__details--secondary .entity__details--price .entity__details--price-discounted {
    font-size: 20px;
    margin-left: 4px;
}

.payment__dialog .dialog__action {
    display: flex;
    justify-content: end;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}

.payment__dialog .dialog__action button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    color: white;
    padding: 5px 12px;
    cursor: pointer;
    transition: 0.3s ease;
}

.payment__dialog .dialog__action button.dialog__action--cancel { background-color: #FF5050; }
.payment__dialog .dialog__action button.dialog__action--continue { background-color: #AE5EFF; }

.payment__dialog .dialog__action button:hover { -webkit-transform: translateY(-2px); transform: translateY(-2px); }

.payment__dialog.dialog__bill {
    width: min(30%, 940px);
    height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
}

.dialog__bill .bill__table {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.bill__table .table__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 4px;
}

.bill__table .table__row:not(:last-child) { border-bottom: 1px solid #E4E4E4; }

.bill__table .table__row .table__row--title,
.bill__table .table__row .table__row--value {
    font-size: 18px;
    font-weight: 500;
    color: black;
}

.bill__table .table__row .table__row--title.highlighted,
.bill__table .table__row .table__row--value.highlighted {
    color: #8C52FF;
    font-weight: 600;
}

.dialog__bill .dialog__action {
    justify-content: center;
}

/* ---------------- PAYMENT STATUS STYLES ----------------- */

.payment__dialog.dialog__bill.status-mode .payment__status {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    flex-direction: column;
    justify-content: center;
}

.payment__status .status__action--close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.payment__status .status__info {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.status__info .info__icon {
    justify-self: center;
    align-self: center;
    position: relative;
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}

@keyframes rotate {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}

.status__info .info__icon .info__icon--wrapper {
    width: 70px;
    height: 70px;
    stroke-width: 1px !important;
    -webkit-animation: rotate 5s linear infinite;
            animation: rotate 5s linear infinite;
}

.status__info .info__icon .info__icon--wrapper path { stroke-width: 8; }

.info__icon--status-icon {
    position: absolute;
    top: 21%;
    left: 21%;
    width: 40px;
    height: 40px;
}

.status__info .info__heading {
    font-size: 24px;
    color: #353535;
    text-align: center;
}

.status__info .info__message {
    width: 90%;
    align-self: center;
    font-size: 16px;
    color: #444444;
    text-align: center;
    margin-bottom: 16px;
}

.payment__status .status__action {
    width: 80%;
    align-self: center;
    border: none;
    font-size: 16px;
    padding: 6px 0;
    border-radius: 4px;
    font-weight: 500;
    transition: 0.3s ease-in-out;
}

.payment__status .status__action:hover { box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); }

.payment__status .status__action.status__action--primary {
    background: transparent;
    border: 1px solid #8C52FF;
    color: #8C52FF;
    margin-bottom: 4px;
}

.payment__status .status__action.status__action--secondary {
    background: #8C52FF;
    color: #FFFFFF;
}

@media only screen and (max-width: 414px) {
    .payment__dialog.dialog__details {
        width: min(100%, 360px);
        height: auto;
        grid-gap: 10px;
        gap: 10px;
    }
    .dialog__header .dialog__header--heading { font-size: 16px; }
    .dialog__header .dialog__header--action { width: 25px; height: 25px; }
    .details__container { flex-direction: column; }
    .details__container .container__image {
        min-width: 100%;
        min-height: unset;
        height: 120px;
    }
    .entity__details--primary .entity__details--name h3 {
        font-size: 16px;
        font-weight: 600;
    }
    .entity__details--primary .entity__details--author,
    .entity__details--primary .entity__details--author .entity__details--type { font-size: 14px; }
    .entity__details--secondary .entity__details--info { font-size: 14px; width: 100%; }
    .entity__details--secondary .entity__details--perks { flex-direction: column; grid-gap: 8px; gap: 8px; }
    .entity__pill .entity__pill--text { font-size: 15px; }
    .entity__details--secondary .entity__details--price { margin-top: 14px; }
    .payment__dialog .dialog__action {
        justify-content: space-around;
        grid-gap: 12px;
        gap: 12px;
    }
    .payment__dialog .dialog__action button { flex-grow: 1; }
    .payment__dialog.dialog__bill {
        width: min(100%, 360px);
        height: 400px;
        grid-gap: 20px;
        gap: 20px;
    }
    .payment__dialog .dialog__header { margin-bottom: 15px; }
    .bill__table .table__row .table__row--title,
    .bill__table .table__row .table__row--value {
        font-size: 16px;
        font-weight: 400;
    }
    /* ---------------- PAYMENT STATUS STYLES ----------------- */
    .status__info .info__heading { font-size: 20px; }
    .status__info .info__message { font-size: 14px; width: 100%; margin-bottom: 10px; }
    .status__info .info__icon .info__icon--wrapper {
        width: 65px;
        height: 65px;
    }
    .info__icon--status-icon {
        width: 35px;
        height: 35px;
        top: 23%;
        left: 23%;
    }
}
:root {
    --bg-white-medium: #F9F9F9;
    --bg--white: #FFFFFF;
    --text-black: #000000;
    --text-black-med: #444444;
    --text-white: rgba(255, 255, 255, 0.85);
    --text-blue: #55ACEE;
    --icon--color: #8c8c8c;
    --color--purple: #8C52FF;
    --color--purple-dark: #7B45E7;
}

.availibility {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    background-color: #F9F9F9;
    background-color: var(--bg-white-medium);
}

.availibility__container {
    background-color: #FFFFFF;
    background-color: var(--bg--white);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin: 100px 0 60px 0;
    padding: 40px 60px 30px 60px;
    position: relative;
}

.availibility__container .close-button {
    position: absolute;
    top: 12px;
    right: 16px;
    text-decoration: none;
    background-color: transparent;
}

.availibility__head {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    letter-spacing: 0.04em;
}

.availibility__head--heading {
    display: block;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 40px;
    text-align: center;
    color: #000000;
    color: var(--text-black);
}

.availibility__image {
    margin-bottom: 30px;
}

.availibility__image img {
    width: 100%;
    background-size: cover;
}

.availibility__head--label {
    display: block;
    font-size: 16px;
    font-weight: 500;
}

.availibility .dropdown-toggle {
    font-weight: 400 !important;
}

.availibility .dropdown-toggle::before,
.availibility .dropdown-toggle::after {
    content: none;
}

/* ---------- SLOTS */

.availibility__slots { 
    padding: 30px 0; 
}

.availibility__slots form {
    display: flex;
    flex-direction: column;
    grid-row-gap: 22px;
    row-gap: 22px;
    margin: 0;
}

.availibility__slot {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(-webkit-max-content, 100px) 2fr 1fr;
    grid-template-columns: minmax(max-content, 100px) 2fr 1fr;
}

.availibility__start {
    display: flex;
    align-items: flex-start;
    padding: 12px 0;
}

.availibility__start--label {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    color: var(--text-black);
    letter-spacing: 0.04em;
    cursor: pointer;
    position: relative;
}

.availibility__start--checkbox[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

.availibility__start--label::before {
    content: '';
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c4c4c4;
    border-radius: 2px;
    margin-right: 6px;
}

.availibility__start--checkbox:checked + .availibility__start--label::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #8C52FF;
    background-color: var(--color--purple);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 1.5px;
}

.availibility__second {
    display: flex;
    flex-direction: column;
}

.availibility__second .availibility__not-available {
    font-size: 14px;
    font-weight: 500;
    color: #8c8c8c;
    color: var(--icon--color);
    padding: 10px 4px;
    letter-spacing: 0.03em;
}

.availibility__timeslot {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    grid-gap: 10px;
    gap: 10px;
}

.availibility__slotbox {
    width: 100%;
    height: 100%;
    max-width: 200px;
}

.availibility__timeslot--slot-1,
.availibility__timeslot--slot-2 {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: '';
    border: none;
    outline: none;
    background-color: transparent;
    text-align: center;
    width: 44%;
    padding: 10px 8px;
    border: 1px solid #8c8c8c;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
}

.availibility__timeslot--slot-2 { margin-right: 4px; }

.availibility__timeslot--slot-1:not(:last-child),
.availibility__timeslot--slot-2:not(:last-child) {
    margin-bottom: 6px;
}

.availibility__timeslot--slot-1::-webkit-scrollbar,
.availibility__timeslot--slot-2::-webkit-scrollbar {
    display: none;
}

.availibility__timeslot .trash--button {
    border: none;
    outline: none;
    background-color: transparent;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    transition: 0.2s ease;
}

.availibility__timeslot .trash--button:hover { 
    -webkit-transform: translateY(-4px); 
            transform: translateY(-4px); 
}

.availibility__third {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
    grid-gap: 10px;
    gap: 10px;
}

.availibility__third .add--button,
.availibility__third .copy--button {
    border: none;
    outline: none;
    background-color: transparent;
}

.availibility__third .dropdown span { padding: 0; }

/* --------- SYNC CALENDAR */

.sync__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 14px;
    gap: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 18px;
}

.sync__header {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    color: var(--text-black);
    margin-bottom: 8px;
}

.sync__desc {
    font-size: 12px;
    font-weight: normal;
    color: #444444;
    color: var(--text-black-med);
}

.sync__button { 
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.sync__service {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.sync__service .sync__text {
    font-size: 14px;
    font-weight: 600;
    color: #444444;
    color: var(--text-black-med);
    margin: 0;
}

.sync__service img { 
    width: 20px;
    height: 20px;
}

.sync__link {
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    color: #8C52FF;
    color: var(--color--purple);
    padding: 8px 20px;
    border-radius: 4px;
    background: #8C52FF33;
    transition: 0.2s linear;
    cursor: pointer;
}
.sync__link:hover { color: #7B45E7; color: var(--color--purple-dark); }

.sync__button button {
    padding: 8px 20px;
    border: none;
    outline: none;
    background: #8C52FF33;
    border-radius: 4px;
    color: #8C52FF;
    color: var(--color--purple);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.03em;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: 0.2s ease;
}

.sync__link:hover,
.sync__button button:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    box-shadow: 8px 8px 6px -4px rgba(0,0,0,0.08);
}

.sync__link:active,
.sync__button button:active {
    box-shadow: unset;
    -webkit-transform: scale(0.96);
            transform: scale(0.96);
}

.submit--button { margin-top: 10px; }

.submit--button button {
    border: none;
    outline: none;
    background: #8C52FF;
    background: var(--color--purple);
    color: rgba(255, 255, 255, 0.85);
    color: var(--text-white);
    padding: 8px 0;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    transition: 0.2s linear;
}

.submit--button button:hover {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    background: #7B45E7;
    background: var(--color--purple-dark);
}

.submit--button button:active{
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98);
}

.slot-overlap {
    color: #CC0000;
    font-weight: 600;
    border-color: #CC0000;
}

.availibility__overlapped {
    position: fixed;
    right: 10px;
    bottom: 12px;
}

@media only screen and (max-width: 414px) {
    .availibility {
        display: block;
        max-width: 100vw;
        height: 100%;
    }

    .availibility__container {
        display: block;
        width: 100%;
        height: 100%;
        box-shadow: unset;
        border-radius: unset;
        padding: 15px;
    }

    .availibility__container .close-button {
        display: none;
    }

    .availibility__head--heading {
        font-size: 20px;
    }

    .availibility__head--label {
        font-size: 16px;
        font-weight: 500;
        color: #444444;
        color: var(--text-black-med);
    }

    .availibility .dropdown-toggle {
        font-size: 14px;
        color: #444444;
        color: var(--text-black-med);
        padding: 0;
    }

    /* ---------- SLOTS  */
    .availibility__slot {
        grid-template-columns: minmax(60px, 80px) 230px 1fr;
    }

    .availibility__timeslot {
        grid-gap: 2px;
        gap: 2px;
    }

    .availibility__third {
        justify-content: center;
        grid-gap: 2px;
        gap: 2px;
        margin-left: 12px;
    }

    .availibility__timeslot--slot-1,
    .availibility__timeslot--slot-2 {
        text-align: center;
        color: #444444;
        color: var(--text-black-med);
        padding: 9px;
    }

    /* -------- SYNC CALENDAR */
    .sync__container {
        margin-bottom: 20px;
    }
}
.availability-checkbox{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
}
.btn-apply-timing{
    width: 90%;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    color: #8C52FF;
    color: var(--color--purple);
    padding: 8px 20px;
    border-radius: 4px;
    background: #8C52FF33;
    transition: 0.2s linear;
    cursor: pointer
}

.btn-apply-timing:hover{
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    box-shadow: 8px 8px 6px -4px rgba(0,0,0,0.08);
    color: #7B45E7;
    color: var(--color--purple-dark);
}
.timeCopier-checkbox{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: left;
    padding: 5px 10px 5px 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
}
.timeCopier__checkbox-btn{
    height: 16px;
    width: 16px;
    cursor: pointer;
}
.timeCopier__checkbox-btn::after {
    background-color: #8c52ff;
}
.landing_page--menu-wrapper {
  background: linear-gradient(89.19deg, #a97ffe 0.61%, #b28cfe 99.26%);
}
.landing__page--menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  padding: 10px 20px;
  background: linear-gradient(89.19deg, #a97ffe 0.61%, #b28cfe 99.26%);
}
.landing_page--menu {
  margin-left: 12rem;
  display: flex;
}
.landing_page--menu .explore-note-icon {
  -webkit-transform: translate(-0.1rem, -0.4rem);
          transform: translate(-0.1rem, -0.4rem);
  transition: 0.5s;
}
.landing_page--menu > .btn:hover .explore-note-icon {
  -webkit-transform: translate(-0.2rem, -0.3rem) scale(1.3) rotate(10deg);
          transform: translate(-0.2rem, -0.3rem) scale(1.3) rotate(10deg);
}
.landing_page--menu-btn {
  position: relative;
  border-radius: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.54px;
  color: #ffffff;
  padding: 5px;
  margin-right: 2rem;
  transition: 0.5s;
}
.landing_page--menu-btn:hover {
  color: #ffffff;
  -webkit-transform: translateY(-0.25rem);
          transform: translateY(-0.25rem);
}
.landing_page--menu-btn::before {
  content: '';
  position: absolute;
  top: 100%;
  right: 5%;
  width: 0;
  height: 1px;
  background-color: #fff;
  transition: 0.5s;
}
.landing_page--menu-btn:hover::before {
  width: 90%;
}
.landing_page--menu-auth {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}
.landing_page--menu-auth-btn {
  color: #8c52ff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.03rem;
  border-radius: 0.25rem;
}
.landing_page--menu-auth-btn.login {
  border: 2px solid #8c52ff;
}
.landing_page--menu-auth-btn.signup {
  color: #fff;
  background-color: #8c52ff;
}
.landing_page--menu-auth-btn.login:hover {
  color: #fff;
  background-color: #8c52ff;
}
.landing_page--menu-auth-btn.signup:hover {
  color: #fff;
  background-color: #7543db;
}

.landing__page--mobile-menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  height: 56px;
  background: linear-gradient(
    89.55deg,
    rgba(140, 82, 255, 0.65) -20.2%,
    rgba(140, 82, 255, 0.65) 107.05%
  );
  box-shadow: 0px 4px 8px rgba(140, 82, 255, 1);
}
.landing__page--mobile-menu-user {
  box-sizing: border-box;
}
.landing__page--mobile-menu-toggle {
  padding: 0;
}
.landing__page--mobile-menu-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #dfdfdf;
}

.landing__input--container {
  display: flex;
  align-items: center;
  padding: 16px;
  height: 52px;
  background: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  border-radius: 40px;
  margin: 36px 0px;
}
.landing__input--search-icon-img {
  width: 22px;
  height: 22px;
}
.landing__input-field {
  width: 100%;
  height: 100%;
  padding: 16px;
  border: none;
}
.landing__input-field::-webkit-input-placeholder {
  color: #aaa;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0.54px;
}
.landing__input-field::placeholder {
  color: #aaa;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0.54px;
}

.landing__screen--landing--page {
  position: relative;
  height: 556px;
  width: 100%;
  background-position: center;
  overflow: hidden;
  background-size: cover;
}
.landing__screen--landing--page:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 526px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
    45deg,
    rgba(140, 82, 255, 1),
    rgba(140, 82, 255, 0.58)
  ) !important;
  border-radius: 0 0 50% 50%/0 0 100% 100%;
  -webkit-transform: scaleX(2.4);
          transform: scaleX(2.4);
  z-index: -1;
}
.landing__page--wave {
  position: absolute;
  top: 25%;
  left: 0;
  height: 410px;
  width: 100%;
  background-size: cover;
  background-image: url(/static/media/landingWave.8c695b49.svg);
  z-index: -1;
}
.landing__screen-container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.landing__screen--slide {
  display: flex;
  flex-direction: row;
}
.landing__screen--box {
  max-width: 515px;
  margin: 4rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.landing__screen--text {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.landing__screen--animation {
  -webkit-animation: slide 15s ease-in-out infinite;
          animation: slide 15s ease-in-out infinite;
}
.landing__screen--box-primary-heading {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1.44px;
  color: #ffffff;
}
.landing__screen--box-secondary-heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.6px;
  color: #ffffffbf;
}
.landing__screen--media {
  flex: 1 1;
  margin: 1rem;
  padding: 0.5rem;
  max-height: 425px;
  max-width: 554px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing__screen--media img {
  min-width: 50%;
  height: 100%;
}

@-webkit-keyframes slide {
  0% {
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
    opacity: 0;
  }
  5% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  96% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
    opacity: 0;
  }
}

@keyframes slide {
  0% {
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
    opacity: 0;
  }
  5% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  96% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
    opacity: 0;
  }
}
.landing__screen--pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.landing__screen--bullets {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #d2bbff;
  cursor: pointer;
}
.landing__screen--bullets.active {
  background-color: #6520ee;
}

@media only screen and (max-width: 640px) {
  .landing__screen-container {
    height: 100%;
  }
  .landing__screen--slide {
    height: 80%;
  }
  .landing__screen--box {
    margin: 4rem 1rem;
  }
  .landing__screen--box-primary-heading {
    font-size: 32px;
    letter-spacing: 0.03em;
    line-height: 150%;
  }
  .landing__screen--box-secondary-heading {
    font-size: 18px;
  }
  .landing__screen--landing--page {
    position: relative;
    height: 560px;
    width: 100%;
  }
  .landing__screen--landing--page:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 520px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: linear-gradient(
      45deg,
      rgba(140, 82, 255, 1),
      rgba(140, 82, 255, 0.58)
    ) !important;
    border-radius: 0 0 50% 50%/0 0 100% 100%;
    -webkit-transform: scaleX(3.2);
            transform: scaleX(3.2);
    z-index: -1;
  }
  .landing__page--wave {
    position: absolute;
    top: 60%;
    left: 0;
    height: 200px;
    width: 100%;
    background-size: cover;
    background-image: url(/static/media/landingWave.8c695b49.svg);
  }
}

.become-a-mentor__container {
  max-width: 1298px;
  margin: auto;
  padding: 2rem 1rem;
}
.become-a-mentor__heading {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 0.84px;
  text-transform: uppercase;
}
.become-a-mentor__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4rem;
  gap: 4rem;
}
.become-a-mentor__mentor-image {
  height: 250px;
  width: 250px;
  border-radius: 50%;
}
.become-a-mentor__contents {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  justify-content: space-around;
}
.become-a-mentor__primary-text {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 1.2px;
  margin: 1rem 1rem 0;
}
.become-a-mentor__secondary-text {
  color: #444;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.6px;
  margin: 1rem;
}
.become-a-mentor__btn {
  border-radius: 4px;
  background: #8c52ff;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.6px;
  margin: 1rem;
  padding: 0.5rem 2rem;
  width: -webkit-max-content;
  width: max-content;
}
.become-a-mentor__btn:hover {
  color: #fff;
  background: #7543db;
}

@media only screen and (max-width: 640px) {
  .become-a-mentor__heading {
    font-size: 20px;
    letter-spacing: 0.4px;
  }
  .become-a-mentor__wrapper {
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
  }
  .become-a-mentor__contents {
    width: 100%;
    align-items: center;
  }
  .become-a-mentor__contents div {
    text-align: center;
  }
}

.footer__container {
  padding: 50px 73px 20px 73px;
  background: linear-gradient(
    0deg,
    rgba(243, 243, 243, 0.5),
    rgba(243, 243, 243, 0.5)
  );
}
.footer__logo {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: 0.03em;
  color: #444444;
}
.logo--footer{
  margin-bottom: 0px ;
}
.footer__social--icons {
  height: 40px;
  width: 40px;
  margin-left: 20px;
  text-align: end;
  margin-top: -10px;
}
.footer__bar {
  width: 100%;
  height: 0px;
  border: 1px solid #c4c4c4;
  margin: 32px 0px;
}
.footer__left--menu {
  list-style-type: none;
  display: block;
  padding: 0px;
}
.footer__left--menu > li {
  float: left;
  margin-right: 38px;
}
.footer__left--menu > li > a {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.03em;
  color: #444444;
  text-decoration: none;
}
.footer__right--menu {
  list-style-type: none;
  display: inline-block;
  padding: 0px;
}
.footer__right--menu > li {
  float: left;
  margin-left: 38px;
}
.footer__right--menu > li > a {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.03em;
  color: #444444;
  text-decoration: none;
}
.footer__copyright {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.footer__policy--menu {
    list-style-type: none;
    display: inline-block;
    padding: 0px;
  }
  .footer__policy--menu > li {
    float: left;
    margin-left: 16px;
  }
  .footer__policy--menu > li > a {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 110%;
    letter-spacing: 0.03em;
    color: #8C8C8C;
    text-decoration: none;
  }
  .footer__text-end{
    text-align: right !important;
}
.hide-on-big-policy{
  display: none;
}
  @media only screen and (max-width: 744px){
    .footer__container {
      padding: 20px;
      background: linear-gradient(
        0deg,
        rgba(243, 243, 243, 0.5),
        rgba(243, 243, 243, 0.5)
      );
    }
    .footer__text-end{
        text-align: left !important;
    }
    .footer__left--menu > li {
      float: none;
      margin-bottom: 26px;
    }
    .footer__right--menu > li {
        margin-left: 0px;
        margin-right: 10px;
        float: none;
        margin-bottom: 26px;
      }
      .footer__policy--menu > li {
        margin-left: 0px;
        margin-right: 10px;
      }
      .footer__social--icons {
        height: 40px;
        width: 40px;
        margin-left: 0px;
        margin-right: 20px;
        text-align: end;
        margin-top: 0px;
      }
      .hide-on-big-policy{
        display: block;
      }
      .hide-on-small-policy {
        display: none;
      }
      .logo--footer{
        margin-bottom: 20px ;
      }
}
.floating-message__container {
  max-width: 1300px;
  margin: auto;
  padding: 2rem 1rem;
}
.floating-message__wrapper {
  padding: 1rem;
  display: flex;
  grid-gap: 14rem;
  gap: 14rem;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(
    82deg,
    #eca231 0%,
    #fdc673 82.29%,
    #f2cf9b 91.15%,
    #ffb23c 98.96%
  );
}
.floating-message__pulsating-circle {
  position: relative;
  width: 25px;
  height: 25px;
}
.floating-message__pulsating-circle::before {
  content: '';
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #ffd08c;
  -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
          animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.floating-message__pulsating-circle::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #f9e3c3;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  -webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
          animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}
@-webkit-keyframes pulse-ring {
  0% {
    -webkit-transform: scale(0.33);
            transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
@keyframes pulse-ring {
  0% {
    -webkit-transform: scale(0.33);
            transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes pulse-dot {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}
@keyframes pulse-dot {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

.floating-message__contents {
  display: flex;
  grid-gap: 6rem;
  gap: 6rem;
  align-items: center;
}
.floating-message__message {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 1.2px;
  background: linear-gradient(90deg, #6129bf 0%, #3b3fc2 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.floating-message__btn {
  color: #3d3dc1;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.9px;
  border-radius: 6px;
  border: 2px solid #5d2bbf;
  background: rgba(140, 82, 255, 0);
  padding: 0 1rem;
  width: -webkit-max-content;
  width: max-content;
}
.floating-message__btn:hover {
  background-color: #5d2bbf;
  color: #ffffff;
}

.modal-open {
  padding: 0 !important;
}
.custom-modal-style {
  padding: 0;
}
.video-modal__title {
  margin-bottom: 1rem;
  font-weight: 600;
  letter-spacing: 0.8px;
}
.video-modal__container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 0 !important;
}
.video-modal__iframe {
  border-radius: 4px;
}

@media only screen and (max-width: 640px) {
  .floating-message__wrapper {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .floating-message__contents {
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .floating-message__message {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    text-align: center;
  }
  .floating-message__btn {
    font-size: 14px;
  }
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root {
    --bg-background: #F3F3F3;
    --bg-card: #FFFFFF;
    --text-black: #000000;
    --text-white: #FFFFFF;
    --text-black-med: #444444;
    --text-black-lite: #8C8C8C; 
    --text-purple: #8C52FF;
    --text-green: #24AA42;
    --border-color: #c4c4c4;
    --border-purple-lite: #F0B6FF;
}

 /* --------------- UTILITY CLASSES ---------- */
.mdashboard__card-rounded {
    background-color: #FFFFFF;
    background-color: var(--bg-card);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
/* ---------------------------- */

.mdashboard--wrapper {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-color: #F3F3F3;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.03em;
    padding: 100px 0 80px 0;
}

.mdashboard__navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

.mdashboard {
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    gap: 50px;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
}

.mdashboard p,
.mdashboard h2,
.mdashboard h3 { 
    margin: 0;
}

.mdashboard__header .header--heading {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    color: var(--text-black);
}

.mdashboard__stats {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.mdashboard__stats .stats__card {
    width: 33.3333%;
    display: grid;
    grid-template-columns: minmax(-webkit-max-content, 50px) 5fr 1fr;
    grid-template-columns: minmax(max-content, 50px) 5fr 1fr;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    cursor: pointer;
    transition: 0.2s ease;
}

.stats__card:hover { -webkit-transform: translateY(-6px) scale(1.02); transform: translateY(-6px) scale(1.02); }

.stats__card .card__sec--2,
.stats__card .card__sec--3 {
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    gap: 6px;
}

.card__title,
.card__value {
    font-size: 16px;
    font-weight: normal;
    color: #444444;
    color: var(--text-black-med);
}

.card__value.card__value--1 {
    color: #8C52FF;
    color: var(--text-purple);
}

/* --------- SESSIONS ----------- */

.mdashboard__sessions {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.mdashboard__sessions .sessions__bookings {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
}

.sessions__header {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}

.sessions__header .sessions__heading--main {
    font-size: 18px;
    font-weight: 500;
    color: #444444;
    color: var(--text-black-med);
}

.sessions__header .sessions__heading--sub {
    font-weight: 16px;
    font-weight: 400;
    color: #444444;
    color: var(--text-black-med);
}

.sessions__section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sessions__timeframe .timeframe__tabs {
    display: flex;
    border: 2px solid #D4D4D4;
    border-radius: 6px;
}

.timeframe__tabs .timeframe__tab {
    font-size: 16px;
    font-weight: 500;
    padding: 7px 14px;
    color: #444444;
    color: var(--text-black-med);
    cursor: pointer;
}

.timeframe__tabs .timeframe__tab:not(:last-child) { border-right: 2px solid #D4D4D4; }

.timeframe__tabs .timeframe__tab.timeframe__tab--active { 
    transition: 0.2s ease-in-out;
    color: #8C52FF;
    color: var(--text-purple); 
}

.sessions__toggle {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
}

.sessions__toggle label {
    font-size: 14px;
    font-weight: 500;
    color: #444444;
    color: var(--text-black-med);
    cursor: pointer;
}

/* ------------ TOGGLE BUTTON -------------*/

.sessions__toggle .sessions__switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.sessions__switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.sessions__switch--slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d6dce2;
    transition: 0.2s ease;
    border-radius: 100px;
}

.sessions__switch--slider::before {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: #8C8C8C;
    background-color: var(--text-black-lite);
    border-radius: 100px;
    transition: 0.2s ease;
}

.sessions__switch input:checked + .sessions__switch--slider::before {
    background-color: #FFFFFF;
    background-color: var(--bg-card);
    -webkit-transform: translateX(26px);
            transform: translateX(26px);
}

.sessions__switch input:checked + .sessions__switch--slider {
    background-color: #8C52FF;
    background-color: var(--text-purple);
}

/* ------------ SESSION CONTAINER ----------- */

.sessions__container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

/* .sessions__container .sessions__calendar {
    height: 100px;
    border-bottom: 0.5px solid var(--border-color);
} */

.upcoming__sessions--wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.upcoming__label {
    display: grid;
    /* grid-template-columns: 4fr 2fr 3fr minmax(max-content, 140px) minmax(max-content, 100px); */
    grid-template-columns: 4fr 3fr repeat(2, 2fr) minmax(-webkit-max-content, 100px);
    grid-template-columns: 4fr 3fr repeat(2, 2fr) minmax(max-content, 100px);
    padding: 8px;
    border-bottom: 0.5px solid #c4c4c4;
    border-bottom: 0.5px solid var(--border-color);
}

.upcoming__label * {
    font-size: 14px;
    font-weight: 500;
    color: #8C8C8C;
    color: var(--text-black-lite);
}

.upcoming__sessions--container {
    display: flex;
    flex-direction: column;
}

.upcoming__sessions--message {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    font-size: 16px;
    color: #444444;
    color: var(--text-black-med);
    font-weight: 500;
    padding: 30px 0;
}

.upcoming__sessions--message img {
    width: 22px;
    height: 22px;
}

.upcoming__session {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 2px;
    border-bottom: 0.5px solid #c4c4c4;
    border-bottom: 0.5px solid var(--border-color);
}

.upcoming__session .session__overview {
    display: grid;
    /* grid-template-columns: 4fr 2fr 3fr minmax(max-content, 140px) minmax(max-content, 100px); */
    grid-template-columns: 4fr 3fr repeat(2, 2fr) minmax(-webkit-max-content, 100px);
    grid-template-columns: 4fr 3fr repeat(2, 2fr) minmax(max-content, 100px);
    align-items: center;
    cursor: pointer;
}

.session__overview.session__overview--expanded { margin-bottom: 12px; }

.session__overview--mentee,
.session__overview--duration {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.mentee--img {
    width: 50px;
    height: 50px;
}

.mentee--img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
    object-fit: cover;
    background-position: center;
    overflow: hidden;
}

.mentee__details .mentee__details--name {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    color: var(--text-black);
}

.mentee__details .mentee__details--email {
    font-size: 12px;
    font-weight: normal;
    color: #8C8C8C;
    color: var(--text-black-lite);
}

.session__overview--type p,
.session__overview--duration p,
.session__overview--booking-day p {
    font-size: 14px;
    font-weight: 400;
    color: #444444;
    color: var(--text-black-med);
}

.session__overview--starts p {
    font-size: 20px;
    font-weight: 600;
    color: #444444;
    color: var(--text-black-med);
}

.upcoming__session .session__details {
    display: flex;
    width: 100%;
    height: 0px;
    overflow: hidden;
    padding: 0;
    background-color: #F3F3F3;
    background-color: var(--bg-background);
    grid-gap: 8px;
    gap: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.upcoming__session .session__details.show { 
    height: 100%;
    overflow: unset;
    padding: 15px 60px;
}

.session__details .details__sec {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 8px;
    gap: 8px;
}

.details__sec .details--header {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    color: var(--text-black);
}

.details__sec .details__box {
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    margin-bottom: 8px;
}

.details__sec .details__type img,
.details__sec .details__duration img,
.details__sec .details__calendar img,
.details__sec .details__time img,
.details__footer--reschedule a img,
.pricebox__actions button img {
    width: 18px;
    height: 18px;
}

.details__sec .details__box p {
    font-size: 14px;
    font-weight: normal;
    color: #444444;
    color: var(--text-black-med);
    word-wrap: break-word;
}

.details__price .details__price--text {
    display: flex !important;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    font-size: 16px !important;
}

.details__sec .details__type--text { font-weight: 500 !important; }

.details__sec .details__sec--grouping { width: 100%; }

.details__desc .details__desc--text {
    width: 100%;
    font-size: 14px;
    color: #444444;
    color: var(--text-black-med);
    font-weight: normal;
}

.details__sec--1 .details__sec--grouping .details__desc--text { width: 85%; }

.details__sec--2 .details__user .details__user--name {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
}

.details__sec--2 .details__user .details__user--email {
    display: block;
    font-size: 14px;
    font-weight: normal;
    text-decoration: underline;
    color: #8C8C8C;
    color: var(--text-black-lite);
}

.details__sec--2 .details__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.details__sec--2 .details__footer--links {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
}

.details__footer--reschedule a {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    color: #8C8C8C;
    color: var(--text-black-lite);
}

.details__sec--2 .details__footer--links img {
    width: 24px;
    height: 24px;
}

.details__sec.details__sec--2 { grid-gap: 20px; gap: 20px; }

.button__showmore {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button__showmore button {
    display: flex;
    grid-gap: 6px;
    gap: 6px;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    color: #8C8C8C;
    color: var(--text-black-lite);
    background-color: transparent;
}

.button__showmore button:disabled { cursor: not-allowed; }
.button__showmore button img { -webkit-transform: translateY(2px); transform: translateY(2px); }

/*------------ PROFILE PROGRESS BAR ------------- */

.mdashboard__progress {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mdashboard__progress .progress__header {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 20px;
}

.progress__header .progress__header--heading {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    color: var(--text-black);
}

.progress__header .progress__header--desc {
    font-size: 16px;
    font-weight: normal;
    color: #8C8C8C;
    color: var(--text-black-lite);
}

.progress__progressbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.progressbar__container { 
    width: 94%;
    cursor: pointer;
}

.progressbar__percentages span {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: #24AA42;
    color: var(--text-green);
}

.progress__checklist {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.checklist__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 30px;
    gap: 30px;
}

.checklist__item {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;
}

.checklist__item .checklist__item--label {
    font-size: 16px;
    font-weight: 500;
    color: #8C8C8C;
    color: var(--text-black-lite);
}

.checklist__item--label.completed {
    color: #24AA42;
    color: var(--text-green);
}

.checklist__item .checklist__item--link {
    text-decoration: none;
    margin: 0 3px;
    cursor: pointer;
}

.checklist__item img {
    width: 14px;
    height: 14px;
}

.checklist__link a {
    font-size: 16px;
    font-weight: 500;
    color: #8C8C8C;
    color: var(--text-black-lite);
}

/* ------------ SESSIONS CALENDAR ------------ */
/* .sessions__calendar,
.sessions__calendar--wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
}

.sessions__calendar { padding: 10px; } */

/* .sessions__calendar .calendar__navigate {
    border: none;
    outline: none;
    width: 20px;
    background: transparent;
    backface-visibility: hidden;
    transition: 0.2s ease-in;
} */

/* .calendar__navigate.calendar__navigate--left:active { transform: translateX(-4px); }
.calendar__navigate.calendar__navigate--right:active { transform: translateX(4px); } */

/* .sessions__calendar .calendar__dates {
    display: flex;
    width: 380px;
    overflow-x: hidden;
    align-items: center;
    gap: 12px;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
} */

/* .calendar__dates .calendar__dates--date {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid transparent;
    outline: none;
    padding: 6px 8px;
    border-radius: 4px;
    background-color: transparent;
    position: relative;
} */

/* .calendar__dates .calendar__dates--date.selected {
    border: 2px solid var(--text-purple);
    color: var(--text-purple);
} */

/* .calendar__dates .calendar__dates--date.today::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: yellowgreen;
    position: absolute;
    top: 0;
    left: 0;
}

.calendar__dates--date .calendar__dates--day {
    font-size: 12px;
    font-weight: normal;
    color: var(--text-black-lite);
}

.calendar__dates--date .calendar__dates--no {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-black-med);
} */

/* .sessions__calendar .sessions__datepicker {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 0 0 10px;
    position: relative;
}

.sessions__calendar .sessions__datepicker img {
    position: absolute;
    top: 7px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
} */

/* ------------ REACT DATEPICKER STYLES -----------*/
/* .sessions__datepicker .react-datepicker__input-container input {
    font-size: 14px;
    font-weight: normal;
    padding: 6px 8px;
    border: 1px solid var(--text-black-lite);
    border-radius: 4px;
    width: 252px;
    cursor: pointer;
}

.sessions__datepicker .react-datepicker__input-container input:focus { border: 1px solid var(--text-purple); }
.sessions__datepicker .react-datepicker__input-container input::placeholder { 
    font-size: 14px; 
    color: var(--text-black-lite);
}

.sessions__datepicker.react-datepicker--highlighted .react-datepicker__input-container input {
    box-shadow: 0px 4px 8px rgba(140, 82, 255, 0.25);
}

.sessions__datepicker .react-datepicker {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    position: relative;
    padding: 12px;
}

.react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous {
    position: absolute;
    left: 30px;
    top: 31px;
}

.react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next {
    position: absolute;
    right: 30px;
    top: 31px;
}

.react-datepicker .react-datepicker__month-container .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
    padding: 18px 0;
}

.react-datepicker__header .react-datepicker__current-month {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 8px;
}

.react-datepicker__day-names .react-datepicker__day-name {
    font-size: 14px;
    font-weight: normal;
}

.react-datepicker__week .react-datepicker__day {
    border-radius: 100px !important;
    font-weight: normal !important;
}

.react-datepicker__week .react-datepicker__day:hover {
    background-color: #beb7f2 !important;
}

.react-datepicker__week .react-datepicker__day--in-selecting-range,
.react-datepicker__week .react-datepicker__day--range-end,
.react-datepicker__week .react-datepicker__day--selected,
.react-datepicker__week .react-datepicker__day--keyboard-selected,
.react-datepicker__week .react-datepicker__day--in-range {
    background-color: #8a7ee7 !important;
} */

/* ------------- WEBINAR -------------- */
.mdashboard__webinar {
    display: flex;
    flex-direction: column;
    grid-gap: 22px;
    gap: 22px;
}

.webinar__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.webinar__head .navigation__controls {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
}

.navigation__controls .navigation__add--webinar {
    background-color: none;
    border: 2px solid #8C52FF;
    border: 2px solid var(--text-purple);
    color: #8C52FF;
    color: var(--text-purple);
    font-weight: 400;
    padding: 4px 12px;
    border-radius: 6px;
    font-size: 16px;
    margin-right: 8px;
    transition: 0.2s ease;
}

.navigation__controls .navigation__add--webinar:hover {
    background-color: #8C52FF;
    background-color: var(--text-purple);
    color: white;
}

.navigation__controls .navigation__controls--button {
    width: 30px;
    height: 30px;
    border: none;
    background-color: #e2e2e2;
    border-radius: 50%;
    transition: all 0.2s ease;
}

.navigation__controls .navigation__controls--button img {
    width: 13px;
    height: 13px;
}

.navigation__controls .navigation__controls--button:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.mdashboard__webinar--title {
    font-size: 18px;
}

.webinar__noEvents {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    padding: 26px 16px;
    border: 2px dashed #F0B6FF;
    border: 2px dashed var(--border-purple-lite);
    border-radius: 8px;
}

.webinar__noEvents--title {
    font-size: 22px;
    font-weight: 500;
    color: #444444;
    color: var(--text-black-med);
    letter-spacing: 0.04em;
}

.webinar__noEvents--createButton {
    border: none;
    outline: none;
    background: linear-gradient(94.25deg, #B698F1 0%, #AA7FFD 100%);
    padding: 4px 8px;
    border-radius: 4px;
    color: #FFFFFF;
    color: var(--text-white);
}

.webinar__events {
    width: 100%;
    padding: 8px 4px;
    display: flex;
    grid-gap: 40px;
    gap: 40px;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.webinar__events .event__card {
    width: 520px;
    min-width: 480px;
    display: flex;
    flex-direction: column;
    grid-gap: 22px;
    gap: 22px;
    padding: 18px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 1.2px 1.2px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.event__head {
    display: flex;
    justify-content: space-between;
}

.event__head .webinar--name {
    font-size: 20px;
    color: #000000;
    color: var(--text-black);
}

.event__head .event__options {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    align-items: center;
}

.event__head .event__options button {
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: #444444;
    color: var(--text-black-med);
}

.event__date {
    font-size: 17px;
    color: #444444;
    color: var(--text-black-med);
}

.event__info {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
}

.event__info--chip {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    grid-gap: 8px;
    gap: 8px;
    border: 1.2px solid #444444;
    border: 1.2px solid var(--text-black-med);
    border-radius: 50px;
    padding: 4px 10px;
    cursor: pointer;
}

.event__info--chip span {
    font-size: 14px;
    color: #444444;
    color: var(--text-black-med);
    font-weight: 500;
}

/* ------------ ENLISTED SESSIONS ---------------- */

.mdashboard__mini-sections {
    width: 100%;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

.mdashboard__mini-sections .mdashboard__enlisted,
.mdashboard__mini-sections .mdashboard__leads {
    width: 50%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.mdashboard__enlisted .enlisted__header,
.mdashboard__leads .leads__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mdashboard__leads .leads__header { padding: 6px; }

.enlisted__header .enlisted__header--heading,
.leads__header .leads__header--heading {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    color: var(--text-black);
}

.enlisted__header .enlisted__header--button {
    font-size: 14px;
    font-weight: 500;
    color: #8C52FF;
    color: var(--text-purple);
    border: 1px solid #8C52FF;
    border: 1px solid var(--text-purple);
    outline: none;
    padding: 6px;
    border-radius: 4px;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: 0.2s ease;
}

.enlisted__header .enlisted__header--button:active { -webkit-transform: translateY(2px); transform: translateY(2px); }


.enlisted__header--button img {
    margin-right: 5px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
}

.enlisted__sessions--container,
.leads__sessions--container {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}

.enlisted__sessions--wrapper,
.leads__sessions--wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sessions__enlisted,
.sessions__leads {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-bottom: 1px solid #c4c4c4;
    border-bottom: 1px solid var(--border-color);
}

.enlisted__overview {
    display: grid;
    grid-template-columns: repeat(2, 1fr) minmax(-webkit-max-content, 30px);
    grid-template-columns: repeat(2, 1fr) minmax(max-content, 30px);
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
}

.leads__overview {
    display: grid;
    grid-template-columns: 1fr 2fr minmax(-webkit-max-content, 30px);
    grid-template-columns: 1fr 2fr minmax(max-content, 30px);
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
}

.enlisted__overview .enlisted--wrapper,
.leads__overview .leads--wrapper {
    display: flex;
    align-items: flex-start;
    grid-gap: 8px;
    gap: 8px;
}

.enlisted__overview.enlisted__overview--expanded { margin-bottom: 10px; }

.enlisted__overview--number,
.leads__overview--number {
    font-size: 16px;
    font-weight: 500;
    color: #444444;
    color: var(--text-black-med);
}

.enlisted__overview--type {
    font-size: 16px;
    font-weight: 500;
    color: #444444;
    color: var(--text-black-med);
}

.leads__overview--details {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
}

.leads__overview--details .details--email {
    font-size: 12px;
    font-weight: normal;
    color: #8C8C8C;
    color: var(--text-black-lite);
}

.enlisted__info {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}

.leads__info { 
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 6px;
    gap: 6px;
}

.enlisted__info .enlisted__info--duration,
.enlisted__info .enlisted__info--category {
    font-size: 14px;
    font-weight: 500;
    color: #444444;
    color: var(--text-black-med);
}

.leads__info .leads__info--contact,
.leads__info .leads__info--date {
    display: flex;
    grid-gap: 4px;
    gap: 4px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #8C8C8C;
    color: var(--text-black-lite);
}

.enlisted__info .enlisted__info--duration img {
    margin: 0 6px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

.leads__info .leads__info--contact img {
    width: 20px;
    height: 22px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
}

.enlisted__actions button,
.leads__actions button {
    border: none;
    outline: none;
    background-color: transparent;
}

.sessions__enlisted .enlisted__details,
.sessions__leads .leads__details {
    width: 100%;
    height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}

.sessions__enlisted .enlisted__details.show,
.sessions__leads .leads__details.show {
    height: 100%;
    overflow: unset;
    padding: 10px 20px;
    margin-bottom: 10px;
}

.enlisted__details .enlisted__details--description,
.leads__details .leads__details--description {
    font-size: 14px;
    font-weight: normal;
    color: #444444;
    color: var(--text-black-med);
    word-wrap: break-word;
    line-height: 22px;
}

.leads__details .leads__details--date {
    font-size: 12px;
    font-weight: normal;
    color: #8C8C8C;
    color: var(--text-black-lite);
}

.enlisted__pricebox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pricebox__price {
    display: flex;
    width: 100%;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    font-size: 16px;
}

.pricebox__price--text,
.pricebox__price--original {
    font-weight: 500;
    color: #8C8C8C;
    color: var(--text-black-lite);
}

.pricebox__price--original { text-decoration: line-through; }

.pricebox__price--offer {
    color: #444444;
    color: var(--text-black-med);
    font-weight: 500;
}

.pricebox__actions {
    display: flex;
    grid-gap: 18px;
    gap: 18px;
    align-items: center;
}

.pricebox__actions button {
    border: none;
    outline: none;
    background-color: transparent;
}

/* ------------- PAGINATION STYLES ---------------- */
.sessions__pagination {
    max-width: 160px;
    display: flex;
    align-self: flex-end;
    align-items: center;
    padding: 6px;
    grid-gap: 4px;
    gap: 4px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    overflow-x: scroll;
}

.sessions__pagination::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
}

.sessions__pagination--page {
    width: 20px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    color: #444444;
    color: var(--text-black-med);
    background: transparent;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
}

.sessions__pagination--page-active {
    color: #8C52FF;
    color: var(--text-purple);
    border: 1px solid #8C52FF;
    margin: 0 2px;
}

.pagination--button {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0 4px;
    transition: 0.2s ease;
    border-radius: 4px;
}

.pagination--button:hover {
    background-color: #e9e2fa;
}

.pagination--button img {
    width: 11px;
    height: 11px;
}


/* ----------------- RESPONSIVE (MOBILE DESIGN) --------------- */

@media only screen and (max-width: 414px) {

    .mdashboard--wrapper {
        display: block;
        width: 100%;
    }
    .mdashboard {
        max-width: unset;
        padding: 12px 0;
    }

    .mdashboard__header,
    .enlisted__header,
    .leads__header {
        padding: 0 18px;
    }

    /* ----------------- PROGRESS BAR */
    .mdashboard .mdashboard__progress {
        width: 90%;
        align-self: center;
    }
    .progress__header .progress__header--desc { font-size: 14px; }
    .progress__progressbar .progressbar__container { width: 80%; }
    .progressbar__percentages span { font-size: 16px; }
    .checklist__container { display: none; }

    /* ----------------- STATS */
    .mdashboard__stats {
        width: 90%;
        flex-direction: column;
        align-items: flex-start;
        align-self: center;
        grid-gap: 0;
        gap: 0;
        padding: 6px;
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        background-color: #FFFFFF;
        background-color: var(--bg-card);
    }
    .mdashboard__stats .mdashboard__card-rounded {
        border-radius: 0;
        box-shadow: none;
        padding: 15px 12px;
    }
    .mdashboard__stats .stats__card {
        width: 100%;
        grid-template-columns: minmax(35px, -webkit-max-content) 80% 30px;
        grid-template-columns: minmax(35px, max-content) 80% 30px;
        background-color: transparent;
    }
    .mdashboard__stats .stats__card:not(:last-child) { border-bottom: 0.5px solid #e5e5e5; }
    .mdashboard__stats .stats__card:hover { -webkit-transform: none; transform: none; }
    .stats__card .card__title, .stats__card .card__value { font-size: 14px; }
    .stats__card .card__img {
        width: 20px;
        height: 22px;
    }

    /* --------------- SESSIONS & CALENDAR */
    .sessions__header .sessions__heading--main { font-size: 16px; }
    .sessions__header .sessions__heading--sub { font-size: 14px; }
    .mdashboard__sessions .sessions__bookings { padding: 20px 14px !important; }
    .sessions__section {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        grid-gap: 34px;
        gap: 34px;
    }
    .timeframe__tabs .timeframe__tab {
        font-size: 14px;
        padding: 5px 12px;
    }
    .sessions__toggle { 
        width: 100%;
        justify-content: space-between;
    }
    .sessions__container.mdashboard__card-rounded {
        padding: 10px 6px;
        border-radius: 0;
    }
    /* .sessions__container .sessions__calendar {
        height: auto;
        flex-direction: column;
        padding: 12px 6px 26px 6px;
        gap: 12px;
    }
    .sessions__calendar, .sessions__calendar--wrapper { width: 100%; }
    .sessions__calendar .calendar__dates { width: 210px; }
    .sessions__calendar .sessions__datepicker { margin: unset; } */
    .upcoming__label,
    .upcoming__session .session__overview { 
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .session__overview--type,
    .session__overview--duration,
    .session__overview--booking-day,
    .upcoming__label--session-type,
    .upcoming__label--duration,
    .upcoming__label--booking-day {
        display: none;
    }
    .mentee__details .mentee__details--name { font-size: 14px; }
    .mentee--img {
        width: 40px;
        height: 40px;
    }
    .session__overview--starts p { font-size: 18px; }
    .upcoming__session .session__details {
        flex-direction: column;
    }
    .upcoming__session .session__details.show { padding: 20px; }
    .session__details .details__sec {
        width: 100%;
        padding: 22px 0;
    }
    .session__overview--starts p { font-size: 18px; }
    .session__details .details__sec:first-of-type { border-bottom: 1.5px dotted #c4c4c4; border-bottom: 1.5px dotted var(--border-color); }
    .details__sec .details__box { align-items: flex-start; }
    .details__desc .details__desc--text { width: 100% !important; }
    .details__sec--1 .details__sec--grouping:first-of-type { margin-bottom: 8px; }
    .details__sec--2 .details__user .details__user--name { margin-bottom: 6px; }
    .upcoming__sessions--wrapper { grid-gap: 10px; gap: 10px; }

    /* ----------------- ENLISTED SESSIONS */
    .mdashboard__mini-sections { 
        flex-direction: column;
        grid-gap: 60px;
        gap: 60px; 
    }
    .mdashboard__mini-sections .mdashboard__enlisted,
    .mdashboard__mini-sections .mdashboard__leads { width: 100%; }
    .enlisted__sessions--container.mdashboard__card-rounded,
    .leads__sessions--container.mdashboard__card-rounded {
        padding: 12px;
        grid-gap: 8px;
        gap: 8px;
        border-radius: 0;
    }
    .sessions__enlisted, .sessions__leads { padding: 6px; }
    .enlisted__overview {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .enlisted__info { display: none; }
    .leads__overview { grid-template-columns: repeat(2, 1fr) minmax(-webkit-max-content, 30px); grid-template-columns: repeat(2, 1fr) minmax(max-content, 30px); }
    .leads__info { 
        display: flex;
        padding: 0; 
    }
    .leads__info .leads__info--date { display: none; }
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.attendeeList--wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    overflow-y: scroll;
    -webkit-backdrop-filter: blur(2px) brightness(40%);
            backdrop-filter: blur(2px) brightness(40%); 
    scrollbar-width: none;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.03em;
}

.attendeeList--wrapper h2,
.attendeeList--wrapper span {
    margin: 0;
}

.attendeeList--wrapper::-webkit-scrollbar { display: none; }

.attendeeList__container {
    width: 900px;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
    margin: 120px 0 60px 0;
    background-color: #FFFFFF;
    padding: 36px;
    border-radius: 8px;
}

.attendeeList__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.attendeeList__header .attendeeList__header--wrapper {
    display: flex;
    align-items: flex-start;
    width: 80%;
}

.attendeeList__header--heading {
    font-size: 28px;
    font-weight: 500;
    color: #8C52FF;
    word-break: break-all;
}

.attendeeList__header--preview {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 6px;
    border-radius: 8px;
    margin-left: 15px;
    transition: 0.2s ease;
}

.attendeeList__header--preview:hover { box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }

.attendeeList__header--close { cursor: pointer; }

.attendeeList__details {
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
    gap: 14px;
}

.attendeeList__info {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.attendeeList__info .attendeeList__info--icon {
    width: 30px;
    height: 30px;
    background: rgba(151, 151, 151, 0.21);
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}

.attendeeList__info .attendeeList__info--text {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    color: #403F40;
}

.publishWebinar__warning {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    grid-gap: 20px;
    gap: 20px;
}

.publishWebinar__warning .warning__messages {
    text-decoration: none;
    list-style-type: none;
}

.publishWebinar__warning .warning__messages .warning__message {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
}

.publishWebinar__warning .warning__action {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
}

.publishWebinar__warning .warning__action button {
    display: inline-block;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    padding: 4px 22px;
    color: #FFFFFF;
    transition: 0.3s ease;
}

.publishWebinar__warning .warning__action button:hover { -webkit-transform: translateY(-3px); transform: translateY(-3px); }

.warning__action .warning__action--cancel { background-color: #F61C1C; }
.warning__action .warning__action--publish { background-color: #8C52FF; }

.attendeeList__publishWebinar {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    align-items: center;
    border: 1.2px dashed #353535;
    padding: 20px;
    border-radius: 4px;
}

.publishWebinar__publish {
    border: none;
    background-color: #8C52FF;
    color: #FFFFFF;
    border-radius: 4px;
    font-size: 18px;
    padding: 4px 20px;
}

.attendeeList__publishWebinar.published { border-color: #8C52FF;}

.publishWebinar__title {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    color: #403F40;
}

.publishWebinar__title.published { color: #AD84FF; }

.attendeeList {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 18px;
    gap: 18px;
}

.attendeeList--blank { text-align: center; }
.attendeeList--blank h5 { color: #403F40; }

.attendeeList .attendeeList__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.attendeeList .attendeeList__search {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
    padding: 8px;
    background-color: #EFEFEF;
    border-radius: 100px;
}

.attendeeList .attendeeList__search .attendeeList__search--input {
    border: none;
    -webkit-transform: translateX(120px);
            transform: translateX(120px);
    opacity: 0;
    display: none;
    background-color: transparent;
    width: 200px;
    border-radius: 100px;
    padding-left: 10px;
    transition: 0.3s ease-in;
    font-size: 14px;
}

.attendeeList .attendeeList__search .attendeeList__search--input.active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    display: inline-block;
}

.attendeeList .attendeeList__search .attendeeList__search--button { cursor: pointer; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.attendeeList__head .attendeeList__head--text {
    font-size: 18px;
    font-weight: 500;
    color: #403F40;
}

.attendeeList__list {
    display: flex;
    width: 100%;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}

.attendeeList__list .attendeeList__attendee {
    background-color: #EFEFEF;
    padding: 14px;
    display: grid;
    grid-template-columns: repeat(2, 1fr) auto;
    align-items: center;
    border-radius: 2px;
}

.attendeeList__attendee .attendee__profile {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}

.attendee__profile .attendee__profile--icon {
    width: 30px;
    height: 30px;
    background-color: #97979736;
    padding: 4px;
    border-radius: 50%;
}

.attendee__profile .attendee__profile--name,
.attendee__email {
    font-size: 18px;
    font-weight: 500;
    color: #403F40;
}

.attendeeList__list .attendeeList__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.attendeeList__navigation--button {
    border: 2px solid #8C52FF;
    background: transparent;
    color: #8C52FF;
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px;
    padding: 4px 10px;
    margin-top: 8px;
}

.attendeeList__navigation--button:disabled {
    border-color: #8C8C8C;
    color: #8C8C8C;
    cursor: not-allowed;
}
.empty__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top:30px;
    padding-bottom:15px;
    border-radius: 5px;
    border: solid 1px #8C52FF;
}

.empty__para--text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #444444;
    margin: 30px 10px;
}
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* ------------- COLOR PALETTE */
:root {
    --font-dark: #000000;
    --font-medium: #444444;
    --font-light-1: #8C8C8C;
    --font-light-2: #C4C4C4;
    --font-green: #44ab43;
    --font-purple: #8C52FF;
    --font-orange: #FD8E4A;
    --container-bg: #F9F9F9;
    --card-bg: #FFFFFF;
    --bg-purple-dark: #7B45E7;
    --gray-lite: #E5E5E5;
}

/* ------------ MARGIN UTILITY CLASSES */
.marginHelper-bottom-small { margin-bottom: 8px !important; }
.marginHelper-bottom-med { margin-bottom: 22px !important; }
.marginHelper-bottom-lg { margin-bottom: 30px !important; }

.auth,
.auth__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    background-color: #F9F9F9;
    background-color: var(--container-bg);
    letter-spacing: 0.03em;
}

/* TO REMOVE THE MARGIN COMING FROM REBOOT & OTHER FILES */
.auth__container p,
.auth__container h2,
.auth__form {
    margin: 0;
}

.auth__container .auth__card {
    display: flex;
    width: 100%;
    max-width: 990px;
    height: 500px;
    margin-top: 30px;
    background-color: #FFFFFF;
    background-color: var(--card-bg);
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.auth__card .card__sec--1 { border-right: 1px solid #D3D3D3; }

.auth__image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth__image img {
    width: 360px;
    height: 270px;
    background-size: contain;
    object-fit: contain;
}

.auth__card .card__sec { width: 50%; }

.auth__card .card__sec--2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 60px;
    grid-gap: 10px;
    gap: 10px; 
}

.card__sec .auth__message {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    color: var(--font-dark);
}

.auth__otp--subheading {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}

.card__sec .auth__subheading {
    font-size: 16px;
    font-weight: 500;
    color: #444444;
    color: var(--font-medium);
    margin-bottom: 12px;
}

.card__sec .auth__subheading--link {
    border: none;
    outline: none;
    background-color: transparent;
    color: #FD8E4A;
    color: var(--font-orange);
    font-weight: 500;
}

/* ---------------- BACK BUTTON */
.auth__card .card__sec--2 .auth__back-button {
    align-self: flex-start;
    margin-bottom: 14px;
}

/* --------------- FORM */
.auth__form {
    display: flex;
    flex-direction: column;
    grid-gap: 35px;
    gap: 35px;
    margin-bottom: 6px;
}

/*--------------- LABELS, INPUT FIELD FOR PHONE NO., EMAIL, NAME, OTP */
.auth__card .auth__contact--field {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    padding: 8px;
    border: 1px solid #C4C4C4;
    border: 1px solid var(--font-light-2);
    border-radius: 4px;
    font-size: 16px;
}

.auth__card .auth__email--field,
.auth__card .auth__otp--field,
.auth__card .auth__name--field,
.auth__card .auth__signupEmail--field {
    width: 100%;
    display: block;
    padding: 8px;
    border: 1px solid #C4C4C4;
    border: 1px solid var(--font-light-2);
    border-radius: 4px;
    font-size: 16px;
}

.auth__card .auth__contact--field .auth__dialing-code,
.auth__card .auth__contact--field .auth__contact-no,
.auth__card .auth__email--field .auth__email,
.auth__card .auth__otp--field .auth__otp,
.auth__card .auth__name--field .auth__name,
.auth__card .auth__signupEmail--field .auth__signupEmail,
.auth__card .card__sec--2 .auth__back-button  {
    border: none;
    outline: none;
    background-color: transparent;
}

.auth__card .auth__contact--field .auth__dialing-code option { 
    font-size: 14px;
    text-align: center;
}

.auth__card .auth__contact--field,
.auth__card .auth__otp--field,
.auth__card .auth__signupEmail--field,
.auth__card .auth__email--field {
    position: relative;
}

.auth__card .auth__contact--field .auth__contact-no, 
.auth__card .auth__email--field .auth__email,
.auth__card .auth__otp--field .auth__otp,
.auth__card .auth__name--field .auth__name,
.auth__card .auth__signupEmail--field .auth__signupEmail {
    width: 100%;
    font-size: 16px;
    letter-spacing: 0.05em;
    font-weight: 500;
    color: #444444;
    color: var(--font-medium);
}

.auth__card .auth__error-msg {
    position: absolute;
    font-size: 12px;
    color: #F61C1C;
    left: 0;
    bottom: -21px;
}

.auth__card .auth__error-msg img {
    width: 13px;
    height: 13px;
    margin-right: 3px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
}

.auth__card .auth__contact--field .auth__contact-no::-webkit-input-placeholder, .auth__card .auth__email--field .auth__email::-webkit-input-placeholder, .auth__card .auth__otp--field .auth__otp::-webkit-input-placeholder { 
    font-size: 16px; 
}

.auth__card .auth__contact--field .auth__contact-no::placeholder,
.auth__card .auth__email--field .auth__email::placeholder,
.auth__card .auth__otp--field .auth__otp::placeholder { 
    font-size: 16px; 
}

.auth__card .auth__form .auth__name--label,
.auth__card .auth__form .auth__email--label {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    color: var(--font-dark);
}

/* ----------------- WRONG DATA HIGHLIGHT */

.auth__card .auth__contact--field.warning,
.auth__card .auth__email--field.warning,
.auth__card .auth__otp--field.warning,
.auth__card .auth__name--field.warning,
.auth__card .auth__signupEmail--field.warning {
    border: 1px solid red;
}

/* ---------------- INPUT FIELD + LABEL CONTAINER */
.auth__card .auth__form .auth__field-container {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}

/* ---------------- BUTTON DISABLED, ENABLED */
.auth__card .auth__button {
    display: block;
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    color: var(--card-bg);
    padding: 10px 0;
    border-radius: 4px;
    transition: 0.2s ease;
    background-color: #8C52FF;
    background-color: var(--font-purple);
}

.auth__card .auth__button:hover { 
    -webkit-transform: translateY(-3px); 
            transform: translateY(-3px);
    background-color: #7B45E7;
    background-color: var(--bg-purple-dark);
}
.auth__card .auth__button:active { -webkit-transform: translateY(0); transform: translateY(0); }
.auth__card .auth__button:focus { background-color: #7B45E7; background-color: var(--bg-purple-dark); }
.auth__card .auth__button:disabled {
    pointer-events: none;
    color: #8C8C8C;
    color: var(--font-light-1);
    background-color: #E5E5E5;
    background-color: var(--gray-lite);
    -webkit-transform: none;
            transform: none;
    box-shadow: none;
}

/* --------------- AUTH SWITCH LINK */
.auth__card .auth__switch-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.auth__card .auth__switch-link button {
    border: none;
    outline: none;
    background-color: transparent;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 500;
    color: #8C8C8C;
    color: var(--font-light-1);
} 

/* ------------------ AGREEMENT TEXT AND LINKS */
.auth__card .auth__agreement {
    display: block;
    width: 100%;
    margin-bottom: 16px;
}

.auth__card .auth__agreement p {
    font-size: 11px;
    font-weight: normal;
    color: #8C8C8C;
    color: var(--font-light-1);
    line-height: 160%;
    text-align: center;
}

.auth__card .auth__agreement .auth__agreement--link {
    color: #44ab43;
    color: var(--font-green);
    font-weight: 500;
    text-decoration: none;
}

/* --------------- OTP INFO BOX -> TEXT + COUNTER */
.auth__card .auth__otpInfo {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    font-weight: 400;
    color: #8C8C8C;
    color: var(--font-light-1);
    padding: 0 6px;
}

.auth__otpInfo .auth__otpInfo--text { font-size: 14px; }

.auth__otpInfo .auth__otpInfo--resend {
    border: none;
    outline: none;
    background-color: transparent;
    color: #8C8C8C;
    color: var(--font-light-1);
    text-decoration: underline;
    font-weight: 500;
}

.auth__otpInfo .auth__otpInfo--timer {
    font-size: 16px;
    font-weight: 500;
    padding: 0 3px;
}

/*------------------ ALTERNATIVE METHODS TEXT AND LINKS */
.auth__card .auth__alternatives {
    display: flex;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
}

.auth__card .auth__alternatives .auth__alternatives--link {
    text-decoration: none;
    color: #8C52FF;
    color: var(--font-purple);
    font-size: 14px;
    font-weight: 500;
    transition: 0.2s ease;
}

/* ------------------ MENTOR SIGNUP PART */

.auth__mentor-signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.auth__mentor-signup .auth__mentor-icon {
    width: 96px;
    height: 96px;
}

.auth__mentor-benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.auth__mentor-benefits .mentor__benefits--header {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    color: var(--font-dark);
    margin-bottom: 20px;
}

.auth__mentor-signup .mentor__benefit--box {
    display: flex;
    width: 100%;
    grid-gap: 16px;
    gap: 16px;
    padding: 0 40px;
}

.mentor__benefit--box .mentor__benefit--img {
    width: 32px;
    height: 32px;
}

.mentor__benefit--box .mentor__benefit--text {
    font-size: 16px;
    font-weight: normal;
    color: #444444;
    color: var(--font-medium);
}

.mentor__benefit--box .mentor__benefit--highlighted { font-weight: 600; }

/*------------------------------- RESPONSIVE LAYOUT FOR USER & MENTOR LOGIN/SIGNUP  */

@media only screen and (max-width: 414px) {
    .auth,
    .auth__container {
        background-color: #FFFFFF;
        background-color: var(--card-bg);
        max-height: 100vh;
        align-items: flex-start;
    }
    .auth__otp--subheading { 
        text-align: center;
        justify-content: center;
    }
    .auth__container .auth__card {
        max-width: unset;
        max-height: unset;
        margin-top: 80px;
        height: auto;
        box-shadow: unset;
        border-radius: unset;
    }
    .auth__card .card__sec { width: 100%; }
    .auth__card .card__sec--1 {
        border-right: unset;
        display: none;
    }
    .auth__card .card__sec--2 {
        width: 100%;
        justify-content: unset;
        padding: 0 20px;
    }
    .card__sec .auth__message { 
        width: 100%;
        line-height: 28px;
        text-align: center;
        font-size: 20px; 
    }
    .auth__form { grid-gap: 30px; gap: 30px; }
    .auth__card .auth__alternatives {
        flex-wrap: wrap;
        text-align: center;
        grid-gap: 6px;
        gap: 6px; 
    }
    .auth__card .auth__contact--field {
        grid-gap: 3px;
        gap: 3px;
        padding: 8px 4px;
    }
    .auth__card .auth__contact--field .auth__contact-no { width: 200px; }
    .auth__card .auth__contact--field .auth__dialing-code { font-size: 16px; }
    .auth__card .auth__button:hover { -webkit-transform: none; transform: none; }
    .auth__card .auth__button:active { -webkit-transform: translateY(-3px); transform: translateY(-3px); }

    /* ----- PLACEHOLDERS */
    .auth__card .auth__contact--field .auth__contact-no::-webkit-input-placeholder { color: #C4C4C4; color: var(--font-light-2); }
    .auth__card .auth__contact--field .auth__contact-no::placeholder { color: #C4C4C4; color: var(--font-light-2); }
}
.searchMentor__main--container {
  max-width: 1080px;
}
.searchMentor__input--container {
  display: block;
}
.searchMentor__inputBox {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  align-items: center;
}
.searchMentor__form {
  display: inline-flex;
  width: 640px;
}
.searchMentor__form--input {
  width: 90%;
  height: 52px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px 0px 0px 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: 0.03em;
  padding-left: 20px;
  border-right: none;
}
.searchMentor__form--input::-webkit-input-placeholder {
  /* Edge */
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: 0.03em;
}

.searchMentor__form--input::placeholder {
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: 0.03em;
}
.searchMentor__form--button {
  width: 10%;
  padding: 3px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 0px 8px 8px 0px;
  border-left: none;
  box-sizing: border-box;
}
.searchMentor__form--button > i {
  width: 18.71px;
  color: #444444;
}
.searchMentor__select--option {
  align-items: center;
  padding: 16px 24px;
  width: 265px;
  height: 52px;
  background: #f3f3f3;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 40px;
  align-self: flex-end;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #444444;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.searchMentor__filter-option {
  justify-content: center;
  align-items: center;
  padding: 18px 40px 18px 20px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  background-color: #ffffff;
  letter-spacing: 0.03em;
  color: #444444;
  margin-left: 32px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.searchMentor__filters {
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 22px;
  gap: 22px;
}
.searchMentor__filters .reset__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid #868686;
  background-color: transparent;
  color: #676767;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 14px;
  border-radius: 4px;
  transition: 0.2s ease-in;
}
.reset__filters:hover { -webkit-transform: scale(1.02); transform: scale(1.02); }
.searchMentor__select-container {
  position: relative;
  display: inline-flex;
}
.searchMentor__filterArrow {
  position: absolute;
  top: 45%;
  right: 10%;
}
.search__mentor--card-container {
  display: flex;
  width: 100%;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15), 2px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.search__mentor--profile-box {
  padding: 0px 31px;
}
.search__mentor--proile-pic {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 0.5px solid #8c8c8c;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 24px;
}
.search__mentor--detail-box {
  padding: 0px 30.5px;
  width: 499px;
}
.search__mentor--detail-box--user-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 8px;
}
.search__mentor--detail-box--tagLine {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #444444;
  margin-bottom: 8px;
}
.search__mentor--Rating{
  margin-bottom: 12px;
}
.search__mentor--detail-box--bio {
  display: block;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.03em;
  margin-bottom: 16px;
  color: #444444;
}
.search__mentor--skills-button {
  padding: 8px 16px;
  background: #e5e5e5;
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #444444;
  float: left;
  margin-right: 3px;
}
.search__mentor--session-box {
  padding: 0px 30.5px;
  border-left: 0.5px solid #c4c4c4;
}
.search__mentor--session-box-btn {
  width: 170px;
  padding: 10px;
  background: #8c52ff;
  border-radius: 4px;
  color: #ffffff;
  transition: 0.2s ease;
  text-align: center;
}
.search__mentor--session-box-btn:hover{
  color: #fff;
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  background-color: #7b45e7;
}
.search__mentor--session-box--exp-and-lang {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  color: #8c8c8c;
  
}
.search__mentor--session-box--year {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #444444;
  margin-bottom: 15px;
}
.search__mentor--session-box--price {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.03em;
  color: #24aa42;
  margin-bottom: 52px;
}
.searchMentor__load-more{
  width: 262px;
height: 48px;
background: #FFFFFF;
border: 1px solid #8C52FF;
box-sizing: border-box;
border-radius: 4px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 100%;
letter-spacing: 0.03em;
color: #8C52FF;
transition: 0.2s ease;
}
.searchMentor__load-more:hover{
  color: #ffffff;
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  background-color: #8C52FF;
}
.search__mentor--session-and-timeZone-Box{
  display: flex;
  width: 100%;
  padding-top: 32px;
  justify-content: space-between;
}
.search__mentor--time-zone-and-session{
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
/* identical to box height, or 24px */

display: flex;
align-items: center;
letter-spacing: 0.03em;

color: #8C8C8C;
}
.searchMentor__show-box{
  position: absolute;
  top: 60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 200px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15), 2px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
  z-index: 2000;
}
.searchMentor__btn-option-styling{
  width: 100%;
  display: flex;
  text-align: start;
}
.searchMentor__btn-option-styling:hover{
  background-color: #f1f1f1;
}
.searchMentor__show-box>p{
  margin-bottom: 0px;
  padding: 5px;
}
.searchMentor__Skills{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #444444;
  margin-bottom: 15px;
}
.searchMentor__checkbox{
  width: 20px;
  margin-right: 5px;
}
.mentorSearchCardMobile__container{
  width: 100%;
height: auto;
background: #FFFFFF;
box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15), 2px 0px 8px rgba(0, 0, 0, 0.15);
border-radius: 8px;
padding: 32px 24px;
}
.divider{
  width: 100%;
height: 0px;
margin: 32px 0px;
border: 0.5px solid #C4C4C4;
}
.btn-filter{
background: #F3F3F3;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 8px;
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 100%;
letter-spacing: 0.03em;
color: #444444;
transition: 0.4s ease;
}
.btn-filter:hover{
  color: #1a1a1a;
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}
.searchMentor__hide-one-big{
  display: none;
}
.searchMentor__myDeck-btn{
  height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: none;
    background: #8C52FF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    color: #FFF;
    padding: 10px;
    box-shadow: 2px 4px 4px rgb(0 0 0 / 25%);
    transition: .2s ease;
}
@media only screen and (max-width: 614px) {
.searchMentor__hide{
  display: none;
}
.searchMentor__hide-one-big{
  display: block;
}
.searchMentor__filter-option {
  align-items: center;
  padding: 18px 40px 18px 20px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  background-color: #ffffff;
  letter-spacing: 0.03em;
  color: #444444;
  margin-left: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.searchMentor__form {
  display: inline-flex;
  width: 70%;
}
}
@media only screen and (max-width: 393px) {
  .searchMentor__form {
    display: inline-flex;
    width: 70%;
  }
}
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.uprofile--wrapper {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-color: var(--bg-card);
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.03em;
    padding: 100px 0 80px 0;
}

.uprofile {
    display: grid;
    grid-template-columns: minmax(200px, 220px) 1fr;
    grid-column-gap: 20px;
    width: 100%;
    max-width: 92vw;
}

.uprofile h2,
.uprofile h3,
.uprofile p {
    margin: 0;
}

.uprofile .uprofile__sidebar {
    width: 100%;
}

.sidebar__profile {
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
}

.sidebar__profile .profile__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    /* border-bottom: 1px solid var(--border-color); */
    grid-gap: 15px;
    gap: 15px;
}

.profile__avatar .profile__avatar--image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    object-fit: cover;
}

.profile__avatar .profile__avatar--name {
    display: flex;
    align-items: center;
    text-align: center;
    grid-gap: 8px;
    gap: 8px;
    font-size: 18px;
    font-weight: 600;
}

.profile__avatar .profile__avatar--edit {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    width: 16px;
    height: 16px;
}

.profile__avatar .profile__avatar--edit img {
    width: 14px;
    height: 14px;
}

.sidebar__profile .profile__stats {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.profile__stats .profile__stat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
}

.profile__stat .profile__stat--label {
    font-size: 14px;
    font-weight: normal;
    color: var(--text-black-med);
}

.profile__stat .profile__stat--value {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-black-med);
}

.profile__stat .profile__stat--value.value--purple { color: #8C52FF; }

/* ------------------- PROFILE SESSIONS SECTION */
.uprofile__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
}

.main__intro {
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    gap: 6px;
}

.main__intro--mobile,
.session__intro--mobile {
    display: none;
}

.main__intro .main__intro--greetings {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-black);
}

.main__intro .main__intro--message {
    font-size: 14px;
    color: var(--text-black-lite);
    font-weight: normal;
}

.main__bookings {
    border: 0.5px solid #e5e5e5;
}

.sessions__empty-container {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    background-color: var(--bg-background);
    border-radius: 8px;
    border: 0.5px solid #e5e5e5;
}

.empty__container--heading {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-black-lite);
}

.empty__container--text {
    font-size: 14px;
    color: var(--text-black-lite);
    font-weight: normal;
}

.empty__container--link {
    color: #9281fc;
    font-weight: 500;
    transition: 0.2s ease;   
}

.empty__container--link:hover { color: #8C52FF; }

@media only screen and (max-width: 414px) {
    .uprofile--wrapper { 
        display: block;
        background-color: var(--bg-background); 
    }
    .uprofile {
        max-width: 100vw;
        grid-template-columns: 1fr;
        grid-template-rows: minmax(-webkit-max-content, 100px) 1fr;
        grid-template-rows: minmax(max-content, 100px) 1fr;
        grid-gap: 40px;
        gap: 40px;
        justify-items: center;
    }
    .uprofile .uprofile__sidebar { width: 92%; }
    .sidebar__profile { grid-gap: 25px; gap: 25px; }
    .sidebar__profile .profile__avatar { display: none; }
    .sidebar__profile .profile__stats {
        padding: 15px 0;
        background-color: var(--bg-card);
        margin-bottom: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
    }
    .main__intro { display: none; }
    .main__intro--mobile,
    .session__intro--mobile {
        display: block;
    }
    .main__intro--mobile h2,
    .session__intro--mobile h2 {
        font-size: 18px;
        color: var(--text-black);
        padding: 0 4px;
    }
    .uprofile__main {
        display: block;
        width: 100%;
    }
    .main__bookings { border: none; }
    .main__bookings.mdashboard__card-rounded {
        box-shadow: none;
        border-radius: 0;
    }
    .main__bookings .upcoming__sessions--wrapper { grid-gap: 16px; gap: 16px; }
    .sessions__empty-container {
        width: 90%;
        height: 500px;
        margin: 0 auto;
        background-color: var(--bg-card);
        border-color: var(--border-color);
    }
}
.settings__form-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000;
  color: var(--text-bold);
  margin-top: 10px;
  margin-bottom: 10px;
}
.setting__image-round {
  width: 120px;
  height: 120px;
  background: #e5e5e5;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 50%;
}
.setting__upload-button {
  height: 36px;
  background: #d3d3d3;
  border-radius: 4px;
  margin: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
.settings__btn--save {
  display: block;
  background: #8c52ff;
  border-radius: 4px;
  color: #fff;
  margin-top: 40px;
  transition: .4s ease;
}
.settings__btn--save:hover {
    color: #fff;
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
}
.settings__btn--save--disabled{
    display: block;
  background: #8c8c8c;
  border-radius: 4px;
  color: #000;
  margin-top: 40px;
}
.settings__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 645px;
  margin: 0 auto;
  padding: 0 10px;
}
.settings__heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
  margin-top: 96px;
}
.setting__options {
  display: block;
  text-decoration: none;
  list-style-type: none;
  padding: 0px;
  margin-top: 37px;
}
.setting__options--link {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  float: left;
  padding: 12px 16px;
  text-decoration: none;
  color: #000000;
  cursor: pointer;
}
.setting__options--link:hover {
  color: #8c52ff;
}
.setting__options--active {
  background: rgba(140, 82, 255, 0.15);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c52ff;
  border-radius: 4px;
}
.settings__divider {
  width: 100%;
  height: 1px;
  background: #c4c4c4;
  margin-top: 16px;
}

.sideNav {
  display: block;
  height: 100vh;
  position: fixed;
  text-decoration: none;
  list-style-type: none;
}
.sideNav > li {
  padding: 10px;
}
.sideNav > li > a {
  color: black;
  text-decoration: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.03em;
}
.policy__active {
  color: #8c52ff !important;
}
.tearmandcondition--text{
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 160%;
letter-spacing: 0.03em;
color: #444444;
}
.tearmandcondition--sub-heading{
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 100%;
display: flex;
align-items: center;
letter-spacing: 0.03em;
color: #000000;
}
.tearmandcondition--heading{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
}
@media only screen and (max-width: 770px) {
  .sideNav {
    display: flex;
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
    background: #fff;
    position: static;
    text-decoration: none;
    list-style-type: none;
  }
}

.About__sideNavbar {
  display: block;
  height: 100vh;
  position: fixed;
  text-decoration: none;
  list-style-type: none;
}

.About__sideNavbar > li {
  padding: 10px;
}
.About__sideNavbar > li > a {
  color: black;
  text-decoration: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.03em;
}
.About__active {
  color: #8c52ff !important;
}
.aboutAndContact__heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
}
.contactAndAbout__text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.03em;
  color: #444444;
}
@media only screen and (max-width: 770px) {
  .About__sideNavbar {
    display: flex;
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
    background: #fff;
    position: static;
    text-decoration: none;
    list-style-type: none;
  }
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --bg-light: #F3FAFB;
    --bg-white: #FFFFFF;
    --bg-purple-1: #8C52FF;
    --bg-purple-2: #7B45E7;
    --text-white: #FFFFFF;
}

.notFound--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    background-color: #F3FAFB;
    background-color: var(--bg-light);
    letter-spacing: 0.03em;
}

.notFound {
    width: 600px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-gap: 16px;
    gap: 16px;
    padding: 40px 60px;
    background-color: #FFFFFF;
    background-color: var(--bg-white);
    border-radius: 4px;
    box-shadow: 3px 3px 8px rgba(159, 98, 221, 0.25);
}

.notFound h2,
.notFound p {
    margin: 0;
}

.notFound__status {
    font-size: 66px;
    font-weight: 700;
    background: linear-gradient(90deg, #373737 -2.07%, #B0B0B0 102.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.notFound__info {
    font-size: 20px;
    font-weight: 600;
}

.notFound__message {
    font-size: 16px;
    font-weight: 400;
    color: #8C8C9C;
}

.notFound__button {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    border: none;
    outline: none;
    background-color: #8C52FF;
    background-color: var(--bg-purple-1);
    padding: 8px 20px;
    color: #FFFFFF;
    color: var(--text-white);
    border-radius: 4px;
    transition: 0.2s ease;
}

.notFound__button img {
    width: 20px;
    height: 20px;
    transition: 0.2s ease;
}

.notFound__button:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    background-color: #7B45E7;
    background-color: var(--bg-purple-2);
}

.notFound__button:hover img { -webkit-transform: translateX(-4px); transform: translateX(-4px); }
.errorPage__container {
  max-width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3fafb;
}
.errorPage__main {
  width: 100%;
  max-width: 800px;
  padding: 30px;
  box-shadow:
  0 1px 1px hsl(0deg 0% 0% / 0.075),
  0 2px 2px hsl(0deg 0% 0% / 0.075),
  0 4px 4px hsl(0deg 0% 0% / 0.075),
  0 8px 8px hsl(0deg 0% 0% / 0.075),
  0 16px 16px hsl(0deg 0% 0% / 0.075)
;
background-color: #fff;
}

.heading h1 {
    font-size: 38px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5em;
    padding-bottom: 15px;
    position: relative;
    color: #8c52ff;
  }
.errorPage__center--box{
    text-align: center;
    margin-top: 30px;
}
.errorpage_btn {
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.errorpage_btn:hover {
  box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
  -webkit-transform: scale(1.05) rotate(-1deg);
          transform: scale(1.05) rotate(-1deg);
  color: green;
}

  .errorPage__text{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 110%;
    letter-spacing: 0.03em;
    color: #666565;
  }
  .main-erro{
    text-align: center;
  }
  .error--500{
    font-size: 100px;
      background: -webkit-linear-gradient(rgb(186, 17, 238), rgb(0, 219, 226));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }
.faq__heading--bar {
  height: 3px;
  width: 200px;
  background: #8c52ff;
}
.faq__heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-left: 70px;
}
.container__faq--box {
  display: flex;
  max-width: 800px;
  justify-content: center;
  margin: 0 auto;
}
.faq__accordion--container {
  width: 100% !important;
}
.faq__accordion--header {
  background: #fff;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #444444;
}
.faq__content--left-border {
  width: 4px;
  height: 100%;
  min-height: 10px;
  background: #8c52ff;
}
.faq__content-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0px;
  letter-spacing: 0.03em;
  color: #444444;
  text-align: justify;
  text-justify: inter-word;
}

*,
*::before,
*::after {
    margin: none;
    padding: none;
    box-sizing: border-box;
}

:root {
    --bg-white: #FFFFFF;
    --text-black-med: #3C3B3B;
}

.webinar__form--wrapper h2,
.webinar__form--wrapper h3,
.webinar__form--wrapper p,
.webinar__form--wrapper .form__field {
    margin: 0;
}

.webinar__form--wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.03em;
}

.webinar__formContainer {
    width: 100%;
    max-width: 900px;
    margin: 140px 0 40px 0;
    border-radius: 8px;
    padding: 60px 30px;
    background-color: #FFFFFF;
    background-color: var(--bg-white);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.2);
}

.formContainer__head { 
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 25px;
    gap: 25px;
    position: relative;
    margin-bottom: 12px;
}

.formContainer--close {
    position: absolute;
    top: -25px;
    right: 10px;
    border: none;
    background: transparent;
}

.formContainer--step {
    width: 450px;
    height: 80px;
}

.formContainer__head h2 {
    font-size: 26px;
    color: #3C3B3B;
    color: var(--text-black-med);
    text-align: center;
}

.formContainer__form {
    width: 90%;
    display: flex;
    flex-direction: column;
    grid-gap: 34px;
    gap: 34px;
    margin: 0 auto;
    padding: 30px 60px;
}

.formContainer__form .form__field,
.form__field-group .form__price--original,
.form__field-group .form__price--discounted {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}

.formContainer__form .form__field-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    grid-gap: 60px;
    gap: 60px;
}

.formContainer__form .form__field .form__field--inputFull,
.formContainer__form .form__field-group .form__field--inputFull,
.formContainer__form .form__field--textarea {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #5F5F5F;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #3C3B3B;
    color: var(--text-black-med);
}

.formContainer__form .form__field .form__field--inputFull:disabled { cursor: not-allowed; }

.formContainer__form .form__field .form__field--inputFull::-webkit-input-placeholder, .formContainer__form .form__field--textarea::-webkit-input-placeholder {
    font-size: 14px;
    color: #A3A3A3;
}

.formContainer__form .form__field .form__field--inputFull::placeholder,
.formContainer__form .form__field--textarea::placeholder {
    font-size: 14px;
    color: #A3A3A3;
}

.form__field-group .form__price--original,
.form__field-group .form__price--discounted {
    width: 100%;
}

.formContainer__form .form__request--error {
    font-size: 14px;
    color: #FE3A3A;
    font-weight: 400;
}

.form__navigationButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.form__navigationButton--next { margin-left: auto; }

.form__navigationButton {
    border: none;
    background-color: #DCCAFF;
    color: #9B68FF;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    padding: 6px 20px;
    transition: 0.3s ease;
}

.form__navigationButton:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    box-shadow: 0px 8px 10px -2px rgba(0,0,0,0.1);
}

/* ------- RADIO BUTTON STYLES -------- */

.form__field--options {
    display: flex;
    width: 100%;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    height: auto;
}

.form__field .form__field--label {
    font-size: 16px;
    color: #505050;
    font-weight: 400;
}

.form__field .form__field--radio { margin-right: 6px; }

/* --------- KEYPOINT STYLES --------- */
.form__keypoints .keypoints__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 6px;
}

.form__keypoints .keypoints__input {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.keypoints__list .keypoints__listItem {
    display: flex;
    align-items: center;
    position: relative;
}

.form__keypoints .keypoints__input .form__field--inputFull {
    padding: 12px 14px !important;
}

.keypoints__list .keypoints__listItem .keyPoints__listItem--point, 
.keypoints__list .keypoints__listItem .keyPoints__listItem--point:disabled {
    padding: 12px 14px;
    font-size: 16px;
    color: #3C3B3B;
    color: var(--text-black-med);
    font-weight: 500;
    background-color: #EBEBEB;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.keypoints__list .keypoints__listItem .keyPoints__listItem--remove,
.keypoints__input .keyPoints__listItem--add {
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -webkit-transform: translate(-10px, 10px);
            transform: translate(-10px, 10px);
}

.keypoints__list .keypoints__listItem .keyPoints__listItem--remove img,
.keypoints__input .keyPoints__listItem--add img {
    width: 15px;
    height: 15px;
}

.form__field--error {
    display: inline-block;
    font-size: 12px;
    color: #FE3A3A;
    font-weight: 400;
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
}

.keypoints__list .keypoints__listItem .keyPoints__listItem--remove { background-color: #FFC3C3; }
.keypoints__input .keyPoints__listItem--add { background: rgba(156, 232, 173, 0.54); }

/* ------------ INPUTLABEL COMPONENT STYLES ------------ */
.inputLabel--wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.inputLabel {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}

.inputLabel .inputLabel--label {
    font-size: 18px;
    font-weight: 500;
    color: #3C3B3B;
    color: var(--text-black-med);
}

.inputLabel--required {
    display: inline-block;
    color: orangered;
    font-size: 12px;
    -webkit-transform: translate(3px, -6px);
            transform: translate(3px, -6px);
}

.inputLabel .Tooltip-Wrapper img {
    width: 18px;
    height: 18px;
}

.inputLabel__limit {
    font-size: 12px;
    font-weight: 400;
    color: #989898;
}

/* -------- DATEPICKER STYLES --------- */

.react-datepicker__input-container input {
    width: 50%;
    padding: 6px 8px;
    border: 1px solid #5F5F5F;
    border-radius: 4px;
    font-size: 14px;
    color: #3C3B3B;
    color: var(--text-black-med);
}

.react-datepicker__input-container input::-webkit-input-placeholder {
    font-size: 14px;
    color: #A3A3A3;
}

.react-datepicker__input-container input::placeholder {
    font-size: 14px;
    color: #A3A3A3;
}

/* -------- TIMEPICKER STYLES -------- */
.formContainer__form .form__field--select {
    width: 240px;
    padding: 6px 8px;
    border: 1px solid #5F5F5F;
    background-color: transparent;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #3C3B3B;
    color: var(--text-black-med);
    appearance: none;
    -moz-appearance:none;
    -webkit-appearance:none;
}

/* -------- WEBINAR CREATED POPUP ------- */
.webinar__card--wrapper {
    font-family: 'Poppins';
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.webinar__card {
    width: 600px;
    height: 330px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 36px;
    gap: 36px;
    background: #F8F8F8;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.webinar__card h2 { margin: 0; }

.webinar__card .card__header {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.webinar__card .card__header h2 {
    font-size: 28px;
    font-weight: 500;
    color: #9B68FF;
}

.webinar__card .card__actions {
    display: flex;
    grid-gap: 50px;
    gap: 50px;
    margin-top: 16px;
}

.webinar__card .card__actions .card__actions--button {
    display: inline-block;
    text-align: center;
    border: none;
    background-color: white;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.08);
    padding: 8px 30px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 500;
    color: #3C3B3B;
    color: var(--text-black-med);
    transition: 0.3s ease;
}

.webinar__card .card__actions .card__actions--button:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.webinar__card .card__tips {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}

.card__tips .card__tips--tip {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
} 

.card__tips .card__tips--tip span {
    font-size: 14px;
    color: #999999;
}
:root {
    --color-primary: #4A4A4A;
    --color-primary-dark: #575757;
    --color-secondary: #B189FF;
    --color-secondary-dark: #8C52FF;
    --color-white: #FFFFFF;
}


.webinar__about { background: linear-gradient(90deg, #FEEDFF 0%, #FFFFFF 62.08%); }

.webinar__about h2, h4, p { margin: 0; }

.webinar__about--wrapper {
    width: 100%;
    min-height: 420px;
    max-width: 1700px;
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    padding: 70px 60px;
    margin: 0 auto;
}

.webinar__about .about__video {
    width: 600px;
    height: 400px;
    display: flex;
    margin-right: auto;
}

.webinar__about .about__video .video__embedVideo {
    width: 550px;
    height: 350px;
    margin-top: 20px;
    border-radius: 8px;
    object-fit: cover;
}

.webinar__about .about__details,
.webinar__about .about__detailsContainer,
.webinar__about .about__keypoints {
    display: flex;
    flex-direction: column;
    grid-gap: 18px;
    gap: 18px;
    width: 50%;
}

.webinar__about .about__detailsContainer .about__details { width: 100%; }

.webinar__about .about__details { margin-right: auto; }
.webinar__about .about__keypoints { width: 45%; }
.webinar__about .about__detailsContainer .about__keypoints { width: 90%; }

.webinar__about .about__details .about__header,
.webinar__about .about__keypoints .about__header {
    font-size: 36px;
    color: #4A4A4A;
    color: var(--color-primary);
    font-weight: 500;
    letter-spacing: 0.04em;
}

.webinar__about .about__details .details__description {
    width: 90%;
    font-size: 16px;
    font-weight: 400;
    color: #4A4A4A;
    color: var(--color-primary);
}

.about__details .details__description .details__description--truncate {
    border: none;
    background-color: transparent;
    color: #B189FF;
    color: var(--color-secondary);
    font-size: 16px;
    font-weight: 500;
    margin-left: 4px;
} 

.webinar__about .about__keypoints .keypoints__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
}

.webinar__about .keypoints__list .keypoints__list--keypoint {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 20px;
    background-color: #FFFFFF;
    border: 1.4px solid #888888;
    color: #575757;
    color: var(--color-primary-dark);
    border-radius: 30px;
    transition: 0.3s ease;
    cursor: pointer;
}

.webinar__about .keypoints__list .keypoints__list--keypoint:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 414px) { 
    .webinar__about { background: #FFFFFF; background: var(--color-white); }
    .webinar__about--wrapper {
        min-height: auto;
        flex-direction: column-reverse;
        padding: 40px 20px;
        grid-gap: 10px;
        gap: 10px;
    }
    .webinar__about .about__details { grid-gap: 10px; gap: 10px; }
    .webinar__about .about__detailsContainer { width: 100%; }
    .webinar__about .about__details .about__header { font-size: 26px; }
    .webinar__about .about__video { width: 100%; height: auto; }
    .webinar__about .about__video .video__embedVideo {
        width: 100%;
        height: 260px;
    }
    .webinar__about .about__keypoints {
        width: 100%;
        padding: 0 20px 40px 20px;
    }
    .webinar__about .about__keypoints .about__header { 
        font-size: 26px;
        width: 100%;
    } 
    .webinar__about .about__keypoints .keypoints__list { grid-gap: 6px; gap: 6px; }
    .webinar__about .keypoints__list .keypoints__list--keypoint {
        font-size: 14px;
        border-radius: 4px;
        font-weight: 500;
        padding: 6px 16px;
    }
    .webinar__hostProfile--mobile {
        padding: 20px 20px;
        display: flex;
        align-items: center;
        grid-gap: 18px;
        gap: 18px;
        background-color: #FBF1FF;
        margin-bottom: 20px;
        border-radius: 6px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    }
    .webinar__hostProfile--mobile .hostProfile--avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
        background-position: center;
    }
    .webinar__hostProfile--mobile .hostProfile__details {
        display: flex;
        flex-direction: column;
        grid-gap: 6px;
        gap: 6px;
    }
    .webinar__hostProfile--mobile .hostProfile__details .hostProfile__details--name,
    .webinar__hostProfile--mobile .hostProfile__details .hostProfile__details--workingAt {
        font-size: 16px;
        color: #000000;
        font-weight: 600;
    }
    .webinar__hostProfile--mobile .hostProfile__details .hostProfile__details--workingAt { font-weight: 400; }
}

:root {
    --color-primary: #4A4A4A;
    --color-secondary: #B189FF;
    --color-secondary-dark: #8C52FF;
}

.webinar__hero { background: linear-gradient(103.89deg, #F9DEFF 0%, #FFFFFF 53.13%, #FBECFF 100%); }

.webinar__hero--wrapper {
    display: flex;
    max-width: 1700px;
    padding: 60px 160px 80px 60px;
    min-height: 640px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.webinar__hero h2, h4, p { margin: 0; }

.webinar__hero .hero__webinarDetails {
    display: flex;
    width: 50%;
    max-width: 640px;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 30px;
    gap: 30px;
    margin-right: auto;
}

.hero__webinarDetails .webinarDetails__header,
.hero__webinarDetails .webinarDetails__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 20px;
    gap: 20px;
}

.webinarDetails__header .header--date,
.webinarDetails__info .info--timeDuration {
    font-size: 18px;
    font-weight: 500;
    color: #4A4A4A;
    color: var(--color-primary);
}

.webinarDetails__header .header--title {
    display: inline-block;
    width: 90%;
    font-size: 40px;
    letter-spacing: 0.05em;
    color: #4A4A4A;
    color: var(--color-primary);
    font-weight: 500;
    word-wrap: break-word;
}

.webinarDetails__info .info--shortSummary {
    font-size: 16px;
    color: #4A4A4A;
    color: var(--color-primary);
    font-weight: 400;
    width: 80%;
    word-wrap: break-word;
}

.webinarDetails__action.webinarDetails__action--register {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    border: none;
    background-color: #8C52FF;
    background-color: var(--color-secondary-dark);
    border-radius: 8px;
    color: #FFFFFF;
    padding: 8px 32px;
    font-size: 16px;
    font-weight: 500;
    transition: 0.3s ease;
}

.webinarDetails__action--register .webinar__price {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
}

.webinarDetails__action--register .webinar__price--stricked {
    text-decoration: line-through;
}

.webinarDetails__action.webinarDetails__action--register:disabled:hover {
    -webkit-transform: none;
            transform: none;
    box-shadow: none;
    cursor: not-allowed;
}

.webinarDetails__action.webinarDetails__action--register:hover {
    -webkit-transform: translateY(-1.2px);
            transform: translateY(-1.2px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.webinar__hero .hero__hostDetails {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
}

.hero__hostDetails .hostDetails__info {
    width: 350px;
    height: 230px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0px 0px 30px 5px #F3EDFF;
}

.hostDetails__info .info__profile {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-position: center;
    box-shadow: 0px 0px 14px 8px #E8DDFF;
    margin-bottom: 10px;
}

.hostDetails__info .info__name,
.hostDetails__info .info__workingAt {
    font-size: 16px;
    font-weight: 600;
    color: #4A4A4A;
    color: var(--color-primary);
}

.hostDetails__info .info__name { border-bottom: 0.6px solid #B189FF; border-bottom: 0.6px solid var(--color-secondary); }

.hostDetails__info .info__name .info__isHost  { font-size: 12px !important; }

.hero__hostDetails .hostDetails__perks {
    display: flex;
    width: 100%;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.perk--wrapper {
    width: 350px;
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    padding: 15px 20px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 15px 2px #EEEEEE;
    border-radius: 8px;
}

.perk--wrapper .perk--name {
    font-size: 18px;
    font-weight: 500;
    color: #4A4A4A;
    color: var(--color-primary);
}

@media only screen and (max-width: 414px) {
    .webinar__hero .hero__hostDetails { display: none; }
    .webinarDetails__header .header--date { display: none; }
    .webinar__hero--wrapper {
        width: 100%;
        min-height: 500px;
        padding: 40px 20px;
        align-items: unset;
    }
    .hero__webinarDetails .webinarDetails__info { grid-gap: 8px; gap: 8px; }
    .webinar__hero .hero__webinarDetails { width: 100%; grid-gap: 20px; gap: 20px; }
    .webinarDetails__header .header--title { font-size: 28px; }
    .webinarDetails__info .info--shortSummary { margin-bottom: 10px; width: 100%; }
    .webinarDetails__info .info--timeDuration,
    .webinarDetails__info .info--host-mobile {
        font-size: 16px;
        color: #000000;
    }
    .hero__webinarDetails .webinarDetails__perks-mobile {
        display: flex;
        flex-direction: column;
        grid-gap: 8px;
        gap: 8px;
    }
    .perk--wrapper {
        width: -webkit-max-content;
        width: max-content;
        background-color: transparent;
        box-shadow: unset;
        border: 1px dashed #000000;
        padding: 4px 6px;
        grid-gap: 6px;
        gap: 6px;
        border-radius: 4px;
    }
    .perk--wrapper .perk--name { color: #000000; font-size: 14px; }
    .webinarDetails__action.webinarDetails__action--register {
        width: 100%;
        padding: 16px 0;
        border-radius: 4px;
    }
}
:root {
    --color-primary: #4A4A4A;
    --color-primary-dark: #171717;
    --color-secondary: #B189FF;
    --color-secondary-dark: #8C52FF;
}

.webinar__testimonials {
    width: 100%;
    background-color: #FFFFFF;
    padding: 80px 60px;
}

.testimonials--wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 65px;
    gap: 65px;
}

.testimonials--wrapper .testimonials__heading {
    display: block;
    text-align: center;
    font-size: 38px;
    line-height: 46px;
    font-weight: 500;
    letter-spacing: 0.04em;
    color: #4A4A4A;
    color: var(--color-primary);
}

.testimonials--wrapper .testimonial__image--addMore {
    display: inline-flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    padding: 10px 22px;
    border: none;
    font-size: 18px;
    font-weight: 400;
    background-color: #B189FF;
    background-color: var(--color-secondary);
    border-radius: 4px;
    color: white;
    transition: 0.3s ease;
}

.testimonials--wrapper .testimonial__image--addMore:hover {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    background-color: #8C52FF;
    background-color: var(--color-secondary-dark);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
}

.testimonial__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 14px;
    gap: 14px;
    height: -webkit-max-content;
    height: max-content;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 25px 20px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}

.testimonial__card .testimonial__image {
    width: 100%;
    height: auto;
    position: relative;
}

.testimonial__card .testimonial__image .testimonial__image--delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    background-color: white;
    border-radius: 50%;
    border: none;
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2);
}

.testimonial__card .testimonial__image img {
    width: 100%;
    background-size: cover;
    object-fit: cover;
    border-radius: 4px;
}

.testimonial__card .testimonial__profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.testimonial__profile .testimonial__profile--initials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #F5CCF9;
    font-size: 18px;
    color: #4A4A4A;
    color: var(--color-primary);
    font-weight: 500;
}

.testimonial__profile .testimonial__profile--name {
    font-size: 20px;
    font-weight: 500;
    color: #171717;
    color: var(--color-primary-dark);
}

.testimonial__card .details__feedback {
    font-size: 16px;
    color: #4A4A4A;
    color: var(--color-primary);
    font-weight: 400;
    text-align: center;
}

.webinar__testimonials .testimonials__noData {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    position: relative;
}

.testimonials__noData .noData--iconWrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4DBFD;
}

.testimonials__noData .noData--message {
    font-size: 24px;
    width: 600px;
    font-weight: 500;
    color: #4A4A4A;
    color: var(--color-primary);
    text-align: center;
}

.testimonials__noData .noData--createButton {
    border: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 500;
    color: #8C52FF;
    color: var(--color-secondary-dark);
    border-bottom: 1px solid #8C52FF;
    border-bottom: 1px solid var(--color-secondary-dark);
}

.my-masonry-grid {
    display: flex;
    margin-left: -30px;
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > .testimonial__card {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 14px;
    gap: 14px;
    height: -webkit-max-content;
    height: max-content;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 25px 20px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}

.webinar__addTestimonial {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(2px) brightness(40%);
            backdrop-filter: blur(2px) brightness(40%);

    display: flex;
    justify-content: center;
}

.webinar__addTestimonial .testimonial__form {
    width: 50%;
    max-width: 680px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 700px;
    margin-top: 90px;
    background-color: #FFFFFF;
    padding: 30px 40px;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.testimonial__form .addTestimonial__header {
    font-size: 22px;
    color: #8C52FF;
    color: var(--color-secondary-dark);
    font-weight: 500;
    text-align: center;
}

.addTestimonial__form {
    display: flex;
    flex-direction: column;
    grid-gap: 22px;
    gap: 22px;
    margin-top: 60px;
}

.addTestimonial__form .addTestimonial__form--upload {
    width: 100%;
    padding: 55px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    border: 1px solid #5F5F5F;
}

.addTestimonial__form--upload .uploadTestimonial--label {
    display: block;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    background-color: #B189FF;
    background-color: var(--color-secondary);
    padding: 8px 24px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s ease;
}

.addTestimonial__form--upload .uploadTestimonial--label:hover { background-color: #8C52FF; background-color: var(--color-secondary-dark); }

.testimonial__upload--error {
    color: rgb(242, 93, 93);
    font-weight: 500;
}

.addTestimonial__action {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    align-self: center;
    margin-top: 22px;
}

.addTestimonial__action button {
    border: 2px solid transparent;
    background-color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    padding: 3px 20px;
    border-radius: 6px;
    transition: 0.2s ease;
}

.addTestimonial__action .addTestimonial__action--cancel {
    color: #FF5555;
    border-color: #FF5555;
}

.addTestimonial__action .addTestimonial__action--submit {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
    color: #8C52FF;
    color: var(--color-secondary-dark);
    border-color: #8C52FF;
    border-color: var(--color-secondary-dark);
}

.addTestimonial__action .addTestimonial__action--submit:disabled:hover,
.addTestimonial__action .addTestimonial__action--cancel:disabled:hover {
    -webkit-transform: none;
            transform: none;
    box-shadow: none;
    cursor: not-allowed;
}

.addTestimonial__action button:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 414px) {
    .webinar__testimonials { padding: 0; }
    .webinar__testimonials.testimonials__noData--mobile { padding: 0; }
    .testimonials__noData.testimonials__noData--mobile {
        padding: 80px 60px;
        background-color: #FBF1FF;
    }
    .testimonials__noData .noData--iconWrapper {
        width: 54px;
        height: 54px;
    }
    .testimonials__noData .noData--message {
        width: 100%;
        font-size: 14px;
    }
    .testimonials--wrapper {
        padding: 0 20px 40px 20px;
        align-items: flex-start;
        grid-gap: 12px;
        gap: 12px;
    }
    .testimonials--wrapper .testimonials__heading { font-size: 26px; }
    .testimonials__imageContainer {
        width: 100%;
        display: flex;
        grid-gap: 30px;
        gap: 30px;
        overflow-x: scroll;
        padding: 10px 6px 20px 0;
    }
    .testimonials__imageContainer::-webkit-scrollbar {
        display: none;
    }
    .testimonial__card { 
        width: 290px;
        height: 340px;
        justify-content: center;
        padding: 5px;
    }
    .testimonial__card .testimonial__image { width: 270px; height: 340px; overflow: hidden; }
    .testimonial__card .testimonial__image img {
        width: 100%;
        height: 100%;
    }
    .testimonial__card .testimonial__image .testimonial__image--delete {
        top: unset;
        bottom: -5px;
        right: 135px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.22);
    }
    .testimonials--wrapper .testimonial__image--addMore {
        padding: 8px 10px;
        font-size: 16px;
        grid-gap: 8px;
        gap: 8px;
    }

    /* ---------------- TESTIMONIAL FORM */
    .webinar__addTestimonial .testimonial__form {
        width: 90%;
        max-width: 100%;
        padding: 20px;
    }
    .addTestimonial__form .addTestimonial__form--upload { 
        padding: 30px 0;
        text-align: center; 
    }
}
*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.webinar--wrapper {
    width: 100%;
    margin-top: 50px;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.03em;
}

.authModal {
    position: fixed;
    inset: 0;
    z-index: 10000;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.35);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
}

.authModal .authModal__container {
    display: flex;
    flex-direction: column;
    width: 480px;
    background-color: white;
    padding: 40px;
    height: auto;
    border-radius: 8px;
}

.authModal__container .authModal__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
    margin-bottom: 30px;
}

.authModal__header .authModal__header__title {
    font-size: 26px;
    font-weight: 600;
    color: #383838;
    letter-spacing: 0.2px;
}

.authModal__header .authModal__header__message {
    font-size: 14px;
    font-weight: 300;
    color: #565656;
    letter-spacing: 0.15px;
}

.authModal__container .authAgreement {
    display: flex;
    grid-gap: 6px;
    gap: 6px;
    align-items: start;
    margin-top: 25px;
}

.authModal__container .authAgreement-checkbox { margin-top: 2px; }

.authAlternatives .authAlternatives-message .authAlternatives-action {
    background-color: transparent;
    border: none;
    margin-left: 4px;
    text-decoration: underline;
    color: #8C52FF;
}

@media only screen and (max-width: 414px) {
    .authModal { align-items: flex-end; }
    .authModal .authModal__container {
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 30px;
    }
    .authModal__header .authModal__header__title { font-size: 22px; }
}
.authInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 5px;
    gap: 5px;
}

.authInput .authInput-label {
    font-size: 16px;
    color: #383838;
}

.authInput .authInput-label.authInput-label--error { color: #F55555; }

.authInput .authInput-label .authInput-label--required { color: #F55555; }

.authInput .authInput-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #A7A7A7;
    border-radius: 8px;
    padding: 12px;
    grid-gap: 12px;
    gap: 12px;
}

.authInput .authInput-wrapper .authInput-dialCode {
    font-size: 16px;
    color: #383838;
    font-weight: 500;
    letter-spacing: 0.15px;
}

.authInput .authInput-input {
    width: 100%;
    font-size: 16px;
    color: #383838;
    font-weight: 500;
    border: none;
    background-color: transparent;
}

.authInput .authInput-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

.authInput .authInput-wrapper:has(.authInput-input.authInput-input--error) {
    background-color: rgba(245, 85, 85, 0.10);
    border-color: #F55555;
}

.authInput .authInput-input::-webkit-input-placeholder {
    color: #A7A7A7;
    font-weight: 400;
}

.authInput .authInput-input::placeholder {
    color: #A7A7A7;
    font-weight: 400;
}

.authInput .authInput-errorBlock {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.authInput .authInput-errorBlock .errorBlock-message {
    font-size: 12px;
    color: #F55555;
}
.authButton {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    color: #383838;
    border-radius: 8px;
}

.authButton.authButton-primary {
    background-color: #8C52FF;
    color: #FFFFFF;
}

.authButton.authButton-primary:disabled {
    background-color: #C7C5C9;
    cursor: not-allowed;
}

.authButton.authButton-secondary {
    border: 1px solid #CDCDCD;
    background-color: #F4F4F4;
    color: #383838;
}

@media only screen and (max-width: 414px) {
    .authButton {
        font-size: 16px;
    }
}
.separator {
    display: flex;
    width: 100%;
    grid-gap: 14px;
    gap: 14px;
    align-items: center;
    padding: 12px;
}

.separator .separator-line {
    width: 100%;
    height: 0.8px;
    background-color: #ACABAB;
}

.separator .separator-text {
    font-size: 12px;
    font-weight: 500;
    color: #383838;
    letter-spacing: 0.15px;
}
.resendOtp {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
}

.resendOtp .resendOtp-action {
    border: none;
    border-bottom: 1px solid #8C52FF;
    background-color: transparent;
    color: #8C52FF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.15px;
}

.resendOtp .resendOtp-action:disabled { cursor: not-allowed; }

.resendOtp .resendOtp-message {
    color: #383838;
    font-size: 14px;
    letter-spacing: 0.15px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.authWrapper h1 { margin: 0; }

.authWrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.authWrapper .authContainer {
    width: 560px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 80px;
}

.authContainer .brandName {
    font-size: 24px;
    font-weight: 600;
    color: #FD8E4A;
    margin-bottom: 80px;
}

.authContainer .brandName .brandName--highlighted { color: #8C52FF; }

.authContainer .authForm {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.authContainer .authForm .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
}

.authForm .header .header__title {
    font-size: 30px;
    font-weight: 600;
    color: #383838;
    letter-spacing: 0.3px;
}

.authForm .header .header__message {
    font-size: 14px;
    font-weight: 400;
    color: #565656;
    letter-spacing: 0.14px;
}

.authForm .authAlternatives { align-self: center; }

.authForm .authAgreement {
    display: flex;
    grid-gap: 6px;
    gap: 6px;
    align-items: start;
    margin-top: 25px;
}

.authForm .authAgreement .authAgreement-checkbox {
    margin-top: 2px;
}

.authForm .authAgreement .authAgreement-message {
    font-size: 12px;
    color: #383838;
    font-weight: 400;
    letter-spacing: 0.12px;
}

.authAgreement .authAgreement-message .authAgreement-link {
    color: #353A47;
    font-weight: 500;
}

.authAlternatives .authAlternatives-message {
    font-size: 12px;
    font-weight: 400;
    color: #353A47;
}

.authAlternatives .authAlternatives-message .authAlternatives-link {
    text-decoration: underline;
    color: #8C52FF;
    font-weight: 500;
    margin-left: 6px;
}

.authWrapper .authImage { flex: 1 1; }

.authWrapper .authImage img {
    width: 100%;
    height: 100%;
    background-size: cover;
    object-fit: cover;
}

@media only screen and (max-width: 414px) {
    .authWrapper .authContainer { padding: 30px; }
    .authContainer .brandName {
        font-size: 20px;
        margin-top: 10px;
    }
    .authContainer .authForm .header { grid-gap: 4px; gap: 4px; }
    .authForm .header .header__title {
        font-size: 22px;
        font-weight: 500;
    }
    .authForm .header .header__message { font-size: 12px; }
    .authWrapper .authImage {
        display: none;
    }
}

.mobile__anki--view-container {
    width: 100%;
    padding: 20px;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
}

.mobile__anki--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.mobile__anki--card-container {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
}

.mobile__anki--image-Container {
    width: 100%;
}

.mobile__anki--card-container-image {
    width: 100%;
    height: 12.5em;
    border-radius: 8px 8px 0px 0px;
}

.mobile__anki--card-container-image--preview {
    width: 100%;
    height: 130px;
    border-radius: 8px 8px 0px 0px;
}

.mobile__anki--paragraph {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
    color: #000000;
    padding: 0.938em 1.25em;
    text-align: justify;
    padding-bottom: 0px;
}

.mobile__anki--hint-container {
    position: absolute;
    display: flex;
    width: 92%;
    bottom: 60px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.75em;
    line-height: 1.125em;
    color: #4EBD66;
    background: rgba(36, 170, 66, 0.34);
    border-radius: 8px;
    margin: 0px 10px;
    padding: 4px 6px;
}

.mobile__anki--hint-paragraph {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
    color: #0F8028;
}

.mobile__anki--btn-container {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.mobile__anki--btn-purple {
    width: 100%;
    border: none;
    background: #8C52FF;
    border-radius: 0px 0px 8px 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 2.25em;
    color: #FFFFFF;
}

.mobile__anki--btn-hard {
    width: 146px;
    height: 58px;
    border: none;
    left: 49px;
    top: 625px;
    background: rgba(255, 107, 74, 0.21);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2em;
    color: #F71C1C;
    margin-bottom: 10px;
}

.mobile__anki--btn-easy {
    width: 147px;
    height: 58px;
    border: none;
    left: 222px;
    top: 625px;
    background: rgba(36, 170, 66, 0.34);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2em;
    color: #0F8028;
    margin-bottom: 10px;
}
.anki__mobile--loading--screen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 100%;
    height: 100vh;
}
.anki__deck--name-onloading {
    display: flex;
    justify-content: center;
    width: 100%;
}
.btn-purple {
    background-color: #8C52FF;
    color: #FFFFFF;
    padding: 10px;
    border: none;
    border-radius: 5px;
    transition: .2s ease;
}
.btn-purple:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}
.note__watch--tutorials-link {
    color: #24AA42;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
}
.note__tutorial-video--btn-container {
    display: flex;
    justify-content: space-between;
}
.single-option-dev {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 93%;
  min-height: 49px;
  margin: 0 auto;
  background: rgba(140, 82, 255, 0.09);
  border-radius: 8px;
  color: #444444;
  cursor: pointer;
  margin: 10px;
}
.single-option-dev-green {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 93%;
  min-height: 49px;
  margin: 0 auto;
  background: #24aa42;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin: 10px;
  -webkit-animation: shake 0.5s;
          animation: shake 0.5s;
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

.single-option-dev--text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
}

.multiple-option--done-btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 152px;
  height: 42px;
  border: none;
  background: #7b45e7;
  border-radius: 4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.85);
  margin: 0 auto;
}

.confirmPopUp__container {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: #00000000;
  border-radius: 8px;
  z-index: 1000000000;
  -webkit-backdrop-filter: blur(2px) brightness(90%);
          backdrop-filter: blur(2px) brightness(90%);

}

.confirmPopup__main--container {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 0px;
  padding: 20px;
  -webkit-animation: popup .2s linear forwards;
          animation: popup .2s linear forwards;
}

@-webkit-keyframes popup {
  0% {
    opacity: 0;
  }

  100% {
    height: 271px;
    opacity: 1;
  }
}

@keyframes popup {
  0% {
    opacity: 0;
  }

  100% {
    height: 271px;
    opacity: 1;
  }
}

.confirmPopup__icon {
  width: 40px;
  height: 40px;
}

.green {
  color: #24AA42;
}

.red {
  color: #FC0303
}

.confirmPopup__bar--main-container {
  position: relative;
  width: 290px;
  height: 52px;
  background: rgba(90, 76, 117, 0.92);
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  z-index: 1;
  overflow: auto;
  cursor: pointer;

}

@-webkit-keyframes loadbar {
  0% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes loadbar {
  0% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

.confirmPopup__bar--sub-container {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-animation: loadbar 1s linear forwards;
          animation: loadbar 1s linear forwards;
  /*   -webkit-animation: loadbar 1s forwards; */
  opacity: 0;
  background-color: #8C52FF;
  border-radius: 8px;
}

.well-background--concept1 {
  background: #8C52FF;
}

.confirmPopup__btn-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.confirmPopup__gotIt-btn {
  width: 290px;
  height: 52px;
  background: #8C52FF;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  border: none;
}
.create-note__modal > .modal-dialog {
  max-width: 900px !important;
}
.create-note__wrapper {
  padding: 10px 60px;
  display: flex;
  justify-content: space-around;
  grid-gap: 60px;
  gap: 60px;
  color: #757575;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}
.create-note__col-preview {
  display: flex;
  flex-direction: column;
  width: 35%;
}
.create-note__col-preview > .preview-title {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.create-note__notecard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}
.create-note__col-form {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.create-note__label {
  color: #403f40;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.create-note__label.disabled {
  color: #757575;
}
.create-note__field {
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.field-multiselect .field-multiselect__control:hover {
  border: 1px solid #8c52ff;
}
.field-multiselect .field-multiselect__control:focus {
  outline: none;
}
.field-multiselect .field-multiselect__value-container {
  padding: 0;
}
.field-multiselect .field-multiselect__placeholder {
  color: #757575;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
.field-multiselect .field-multiselect__control,
.create-note__field > input,
.create-note__field > textarea {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  height: 36px;
  min-height: 36px;
  width: 100%;
  min-width: 0;
  padding: 4px 10px;
  color: #000000d9;
  font-size: 12px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  cursor: text;
}
.field-multiselect .field-multiselect__control {
  display: flex;
  box-shadow: none;
  align-items: center;
  padding: 0 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.field-multiselect .field-multiselect__indicator {
  cursor: pointer;
}
.field-multiselect.disable input {
  display: none;
}
.create-note__field > input:hover,
.create-note__field > textarea:hover {
  border: 1px solid #8c52ff;
}
.create-note__field > input:disabled,
.create-note__field > textarea:disabled {
  background: #dddddd;
  border: 1px solid #c1c1c1;
}
.create-note__field > textarea {
  height: 100px;
}
.create-note__field > textarea:focus {
  outline: none;
}
.field-checkbox {
  flex-direction: row;
  align-items: center;
}
.field-checkbox > input {
  height: 15px;
  width: 15px;
  display: flex;
  accent-color: #8c52ff;
  cursor: pointer;
}

.create-note__field.public-fields {
  border-radius: 4px;
  background: #ece8f5;
  margin-left: 20px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.public-fields > .field-checkbox {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.public-fields .field-checkbox {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
}
.public-fields > .field-price {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  justify-content: flex-end;
}
.public-fields > .field-price input {
  height: 30px;
}

.create-note__footer {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px 10px;
}
.create-note__footer > p {
  color: #d4574c;
  width: 45%;
}
.create-note__footer-btns {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.create-note__btn-primary {
  height: 35px;
  background: #8c52ff;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-note__btn-secondary {
  height: 35px;
  background: rgba(140, 82, 255, 0.26);
  border-radius: 4px;
  font-weight: 400;
  color: #8c52ff;
}
.create-note__btn-upload {
  height: 35px;
  border-radius: 4px;
  background: rgba(15, 128, 40, 0.12);
  color: #24aa42;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-note__upload-image {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.error {
  color: #d4574c;
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  -webkit-animation: shake 0.2s ease-in-out 0s 2;
          animation: shake 0.2s ease-in-out 0s 2;
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  75% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  75% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.crop-image__modal > .modal-dialog {
  max-width: 800px !important;
}
.crop-image__editor {
  width: 80%;
}
.crop-image__preview-container {
  display: flex;
  grid-gap: 8%;
  gap: 8%;
}
.crop-image__preview-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25) inset;
  padding: 10px;
  flex: 1 1;
}
.crop-image__label {
  color: red;
  text-align: right;
  font-size: 14px;
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .create-note__wrapper {
    flex-direction: column-reverse;
    padding: 10px;
  }
  .create-note__col-preview {
    width: 100%;
  }
  .create-note__footer {
    flex-direction: column;
    margin: 10px;
  }
  .create-note__footer > p {
    width: 100%;
    margin-bottom: 20px;
  }
  .create-note__field.public-fields {
    margin-left: 0;
  }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --white: #ffffff;
    --black: #000000;
    --neutral-100: #F5F5F5;
    --neutral-200: #3E3E3E;
    --neutral-300: #434343;
    --neutral-400: #201D1D;
    --purple-100: #8C52FF38;
    --purple-200: #8C52FF;
    --red-100: #FFE9E9;
    --red-200: #F61C1C;
    --placeholder: #6b6b6b;
}

.sdashboard__wrapper {
    display: block;
    width: 100%;
    height: 100%;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.03em;
}

.sdashboard {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 100vh;
    position: relative;
    margin-top: 50px;
    padding-right: 20px;
    background-color: #ffffff;
    background-color: var(--white);
}

.sdashboard .sdashboard__sidebar {
    width: 260px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50px;
    bottom: 0;
    padding: 20px 20px 40px 20px;
    border-right: 1px solid #ECECEC;
    overflow: hidden;
}

.sdashboard .sdashboard__sidebar--responsive {
    position: fixed;
    top: 50px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 414px;
    background-color: white;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    z-index: 2;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

.sdashboard__sidebar--responsive.open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: all .3s ease-in-out;
}

.sdashboard__sidebar--responsive .responsive__navigation {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    margin-top: 120px;
}

.sdashboard__sidebar--responsive .sidebar__action--close {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background: #F5F5F5;
    background: var(--neutral-100);
}

.sdashboard__sidebar--responsive .sidebar__action--logout {
    display: flex;
    width: 140px;
    height: 48px;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    background-color: #FFE9E9;
    background-color: var(--red-100);
    border-radius: 30px;
    border: none;
    margin-top: 80px;
    color: #201D1D;
    color: var(--neutral-400);
}

.sdashboard__sidebar .userProfile { margin-bottom: 40px; }

.sdashboard__sidebar .sidebar__navigation .navButton:not(:last-child) {
    margin-bottom: 6px;
}

.sdashboard__sidebar .sidebar__promotions {
    margin-top: auto;
}

.sdashboard .sdashboard__container {
    width: 100%;
    height: auto;
    margin-left: 265px;
    padding: 25px;
    overflow: hidden;
}

.sdashboard__container .container__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;
}

.container__header .header__greetings {
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
}

.header__greetings .greetings__welcome {
    font-size: 30px;
    font-weight: 500;
    color: #201D1D;
    color: var(--neutral-400);
}

.header__greetings .greetings__message {
    font-size: 16px;
    font-weight: 400;
    color: #434343;
    color: var(--neutral-300);
}

/* ------------ SEARCH BOX STYLES 👇 ------------ */

.header__searchbox {
    display: flex;
    grid-gap: 6px;
    gap: 6px;
}

.searchbox__menu {
    width: 60px;
    height: 40px;
    border-radius: 4px;
    border: none;
}

.searchbox__search {
    display: flex;
    justify-content: space-between;
    width: 340px;
    background-color: #F5F5F5;
    background-color: var(--neutral-100);
    overflow: hidden;
    border-radius: 4px;
}

.searchbox__search .search__input {
    width: 100%;
    border: none;
    padding: 8px 16px 8px 18px;
    background-color: transparent;
    font-size: 16px;
    color: #201D1D;
    color: var(--neutral-400);
}

.searchbox__search .search__button {
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px 0 15px;
    background-color: #ECECEC
}

.searchbox__search .search__input::-webkit-input-placeholder {
    font-size: 14px;
    font-weight: 300;
    color: #6b6b6b;
    color: var(--placeholder);
}

.searchbox__search .search__input::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: #6b6b6b;
    color: var(--placeholder);
}

.searchbox__filter {
    border: none;
}

.sdashboard__container .search__result-heading {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    font-size: 24px;
    font-weight: 500;
    color: #201D1D;
    color: var(--neutral-400);
    margin: 50px 0;
}

.sdashboard__container .search__result-heading .search__term { color: #8C52FF; color: var(--purple-200); }

.sdashboard__container .sdashboard__entityContainer .entityContainer__heading {
    font-size: 24px;
    font-weight: 500;
    color: #201D1D;
    color: var(--neutral-400);
    margin-left: 12px;
}

.sdashboard__container .sdashboard__entityContainer .favourites__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
}

.sdashboard__entityContainer .favourites__container .favourites__header {
    font-size: 24px;
    color: #201D1D;
    color: var(--neutral-400);
    font-weight: 600;
}

.sdashboard__entityContainer .favourites__container .favourites__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    grid-gap: 25px;
    gap: 25px;
    grid-row-gap: 35px;
    row-gap: 35px;
}

@media only screen and (max-width: 414px) {
    .sdashboard {
        padding-right: unset;
    }
    .sdashboard__container .container__header { width: 100%; margin-bottom: 70px; }
    .sdashboard .sdashboard__sidebar { display: none; }
    .sdashboard .sdashboard__container { margin-left: 0; padding: 20px; }
    .container__header .header__greetings { display: none; }
    /* searchbox styles */
    .header__searchbox { width: 100%; justify-content: space-between; }
    .searchbox__search { width: 100%; margin-right: auto; }
    .searchbox__search .search__input { padding: 8px 12px; }
    .searchbox__search .search__button { padding: 0 12px 0 12px; }
    /* sidebar styles */
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.userProfile {
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    background-color: var(--neutral-100);
    border-radius: 4px;
    padding: 12px 10px;
}

.userProfile .userProfile__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    background-color: var(--purple-100);
    color: var(--black);
}

.userProfile .userProfile__info { font-size: 14px; }

.userProfile__info .userProfile__username { 
    font-weight: 500;
    color: var(--black);
}

.userProfile__info .userProfile__email { 
    font-weight: 300;
    color: var(--neutral-200);
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navButton {
    display: flex;
    align-items: center;
    width: 100%;
    height: 46px;
    border: none;
    font-size: 16px;
    color: var(--black);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: transparent;
    border-left: 4px solid transparent;
    padding: 0 10px;
    transition: 0.2s ease-in;
}

.navButton:disabled {
    cursor: not-allowed;
    background: transparent;
}
.navButton:disabled:hover { background: transparent; }
.navButton.navButton--active:hover { background: var(--purple-100); }

.navButton--responsive {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 48px;
    border: none;
    font-size: 16px;
    color: var(--black);
    border-radius: 4px;
    background: transparent;
    transition: 0.2s ease-in;
}

.navButton:hover {
    background: var(--purple-100);
}

.navButton.navButton--active {
    color: var(--black);
    background: var(--purple-100);
    border-left: 4px solid var(--purple-200);
}

.navButton--responsive.navButton--responsive--active {
    color: var(--purple-200);
    background: var(--purple-100);
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --text-green: #48E56E;
}

.promotedCard h1, h2, h3, h4, h5, h6 { margin: 0; }

.promotedCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    height: 205px;
    background: linear-gradient(132.17deg, #FBFFFD 0%, #E5FFF6 100%);
    border-radius: 6px;
    padding: 16px;
    transition: 0.3s ease-in-out;
}

.promotedCard:hover { box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15); }

.promotedCard .promotedCard__heading {
    font-size: 10px;
    font-weight: 600;
    color: #48E56E;
    color: var(--text-green);
    text-transform: uppercase;
}

.promotedCard .promotedCard__info {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 6px;
}

.promotedCard__info .info__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: var(--neutral-400);
}

.promotedCard__info .info__subtext {
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    color: var(--neutral-400);
}

.promotedCard .promotedCard__link {
    display: inline-block;
    color: var(--black);
    padding: 6px 16px;
    font-size: 12px;
    border: none;
    background-color: var(--white);
    border-radius: 100px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 414px) {
    .promotedCard {
        padding: 14px;
        height: 160px;
    }
    .promotedCard__info .info__title { font-size: 14px; }
}
.sdashboard__container .emptyContainer {
    width: 100%;
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    background-color: #FBFBFB;
    border-radius: 8px;
    margin: 36px 0;
}

.emptyContainer .emptyContainer__icon--wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
}

.emptyContainer__favourites .emptyContainer__icon--wrapper { background-color: #FEE; }
.emptyContainer__myNotes .emptyContainer__icon--wrapper { background: linear-gradient(90deg, #C072FF, #5753FF) }
.emptyContainer__search .emptyContainer__icon--wrapper { background-color: transparent; }

.emptyContainer .emptyContainer__info {
    display: flex;
    flex-direction: column;
    text-align: center;
    grid-gap: 15px;
    gap: 15px;
}

.emptyContainer__info .info__heading {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: capitalize;
}

.emptyContainer__info .info__subtext {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

@media only screen and (max-width: 414px) {
    .sdashboard__container .emptyContainer {
        margin: 25px 0;
        grid-gap: 12px;
        gap: 12px;
        height: 295px;
    }
    .emptyContainer .emptyContainer__info {
        grid-gap: 10px;
        gap: 10px;
    }
    .emptyContainer__info .info__heading {
        font-size: 20px;
    }
    .emptyContainer__info .info__subtext {
        font-size: 14px;
    }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.entity__showcase {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
}

.entity__showcase .showcase__header {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.showcase__header .showcase__title {
    font-size: 24px;
    color: var(--neutral-400);
    font-weight: 500;
}

.showcase__header .showcase__action { cursor: pointer; }

.entity__showcase .showcase__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    gap: 15px;
}

.entity__showcase .showcase__pagination { margin-left: auto; }

@media only screen and (max-width: 414px) {
    .entity__showcase .showcase__container {
        justify-content: center;
    }
    .entity__showcase .showcase__pagination { margin-right: auto; }
}
.pagination__container {
  margin: 2rem;
  width: auto;
  height: 37px;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  border: 0.5px solid #e1e1e1;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
}
.pagination__content {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #909090;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.pagination__current-page {
  color: #000;
}
.pagination__buttons {
  border-color: transparent;
  border-left-color: #e1e1e1;
  background-color: #fff;
  height: 100%;
  width: 36px;
  cursor: pointer;
}
.disabled {
  opacity: 0.5;
  cursor: auto !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.favourite__card {
    width: 230px;
    background: white;
    border-radius: 6px;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    position: relative;

    display: flex;
    flex-direction: column;
}

.favourite__card .card__type--wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;

    background: linear-gradient(90deg, #8672FF 0.01%, #5753FF 100%);
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.30);
}

.favourite__card .card__image {
    width: 100%;
    height: 145px;
    background-position: center;
    background-size: contain;
    object-fit: cover;
}

.favourite__card .card__info {
    display: flex;
    flex-direction: column;
    padding: 14px;
}

.favourite__card .card__info .info__title {
    font-size: 18px;
    font-weight: 600;
    color: #232323;
    margin-bottom: 12px;
}

.favourite__card .card__info .info__tags {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 18px;
}

.card__info .info__tags p {
    font-size: 10px;
    font-weight: 500;
    color: #4A4A4A;
    letter-spacing: auto;
    background-color: #F4F4F4;
    padding: 2px 8px;
    border-radius: 4px;
}

.favourite__card .card__info .card__actions {
    display: flex;
    grid-gap: 6px;
    gap: 6px;
}

.card__info .card__actions .action__explore {
    flex: 1 1;
    border: none;
    padding: 6px 0;
    background: linear-gradient(90deg, #E477FF 0.01%, #B04DFE 100%);
    border-radius: 4px;

    color: white;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.15px;
}

.card__info .card__actions .action__favourite {
    border: none;
    border-radius: 4px;
    padding: 6px 16px;
    font-size: 14px;
    background: rgba(246, 28, 28, 0.10);
}
.card-renderer__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.card-renderer__header {
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #cdcdcd;
  background: #fff;
}

.card-renderer__page-number {
  height: 70%;
  width: 65px;
  background: #825cff;
  color: #fff;
  display: flex;
  padding-right: 14px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  position: relative;
}
.card-renderer__page-number::after {
  content: '';
  position: absolute;
  right: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-right: 14px solid #fff;
}

.card-renderer__topic {
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-renderer__tools {
  padding: 0 0.5rem;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.card-renderer__tool {
  cursor: pointer;
}
.card-renderer__tool.icon-disabled {
  opacity: 0.5;
  cursor: auto;
}

.card-renderer__card {
  position: relative;
  height: calc(100% - 40px);
}

.card-renderer-hint__layer {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: #8172a180;
  -webkit-backdrop-filter: blur(7.5px);
          backdrop-filter: blur(7.5px);
  overflow: hidden;
}

.card-renderer-hint__container {
  border-radius: 0px 0px 6px 6px;
  background: #fff;
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  max-height: 80%;
  translate: 0 -110%;
  transition: translate 0.4s ease-in-out;
}

.card-renderer-hint__container span {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.card-renderer-hint__container p {
  padding-right: 0.5rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow-y: auto;
}
.card-renderer-hint__container p::-webkit-scrollbar {
  width: 0.25rem;
  background: #f2ebff;
}
.card-renderer-hint__container p::-webkit-scrollbar-thumb {
  border-radius: 0.2rem;
  background: #cfbcff;
}
.card-renderer-hint__container button {
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  font-size: 14px !important;
  padding: 0.25rem 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: #8c52ff;
}

.card__body {
  width: 100%;
}
.card__body > body {
  background-image: none;
}

.card__extras {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.card__input-container {
  display: flex;
  flex-direction: column;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  width: 100%;
}
.card__input-label {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.card__input-field {
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  outline: none;
  background: #fff;
  width: 100%;
  padding: 5px 10px;
  line-height: normal;
  font-size: 14px;
}

.card__btn-grp {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}
.card__btn-grp button {
  height: 40px;
  width: 50%;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 6px;
}
.card__btn-secondary {
  background: #d9d9d9;
  color: #000;
}
.card__btn-secondary:hover {
  background: #dfdfdf;
}
.card__btn-secondary:disabled {
  cursor: auto;
  background: #dfdfdf;
  color: #666;
}
.card__btn-primary {
  background: #8c52ff;
  color: #fff;
}
.card__btn-primary:hover {
  background: #763de9;
}
.card__btn-primary:disabled {
  cursor: auto;
  background: #905ff1;
  color: #ddd;
}

.anki__form-box--deck-creaton {
    width: 100%;
    margin: 0 auto;
}

.anki__deck-creation--input {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-family: 'Poppins';
    position: relative;
    display: inline-block;
    height: 36px;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: #000000d9;
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all .3s;
}

.deck-creation__thumbanil-image--preview {
    width: 146px;
    height: 142px;
    border-radius: 4px 0px 0px 4px;
    transition: all .3s;
}

.anki__deck-creation--input:hover {
    border: 1px solid #8C52FF;
}

.anki__deck-creation--input:disabled {
    background: #dddddd;
    border: 1px solid #c1c1c1;
  }

.dummy__thumbnail--deck {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #8C52FF;
    padding: 5px;
    transition: all .3s;
}

.dummy__thumbnail--border {
    position: absolute;
    width: 90px;
    height: 90px;
    background: #fcf4ff;
    border: 1px solid #8C52FF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbnail-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: #8C52FF;
}

.anki__deck-creation>label {
    width: 100%;
}

.anki__upload-button-create {
    height: 142px;
    width: 146px;
    border-radius: 4px;
    transition: border-color .3s;
    margin-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #929292;
}

.anki__radio-input {
    margin-top: 20px;
}

.anki__radio-input>p {
    margin: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #929292;
}

.anki__form-control {
    font-family: system-ui, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    grid-gap: 0.5em;
    gap: 0.5em;
}

.anki__deck-creation--footer {
    display: flex;
    justify-content: end;
}

.anki__deck-creation-primary {
    height: 35px;
    background: #8C52FF;
    border-radius: 4px;
    color: #fff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
}

.anki__deck-creation-secondary {
    height: 35px;
    background: rgba(140, 82, 255, 0.26);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #8C52FF;
}

.anki__deck-creation-primary-grey {
    height: 35px;
    background: #969696;
    border-radius: 4px;
    color: rgb(37, 37, 37);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
}

.create__card--heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #737373;
    margin-bottom: 0px;
    /* margin-left: 25px; */
}

.anki__deck--owner-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    color: #262D3D;
}

.anki__deck-preview--text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #999999;
    margin-left: 25px;
}

.anki__btn--float-end {
    position: absolute;
    right: 0px;
    margin: 10px;
}

.anki__modal--padding {
    padding: 20px;
}

.anki__deck-creation--footer-create-card {
    display: flex;
    justify-content: space-between;
}

.anki__deck-creation-green {
    background: rgba(55, 153, 59, 0.19);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    color: #1B811F;
    height: 35px;
    border: none;
    padding: 0px 10px;
}

.previewCard__btn {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 40px;
    background: #8C52FF;
    color: #fff;
    border: none;
    border-radius: 0px 0px 8px 8px;
}

.anki__modal--width-change>.modal-dialog {
    max-width: 1300px !important
}

.subscribe-btn {
    background: #7B45E7;
    height: 35px;
    padding: 10px;
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #FFF;
    border: none;
    border-radius: 4px;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(56, 36, 88, 0.28));
            filter: drop-shadow(0px 4px 4px rgba(56, 36, 88, 0.28));
    margin-bottom: 10px;
    transition: .2s ease;
}

.subscribe-btn:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

.share-btn {
    background: #0F802812;
    height: 35px;
    padding: 10px;
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #37993B;
    border: none;
    border-radius: 4px;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(17, 92, 17, 0.28));
            filter: drop-shadow(0px 4px 4px rgba(17, 92, 17, 0.28));
    margin-bottom: 10px;
    transition: .2s ease;
}

.share-btn:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

input[type="file"] {
    visibility: hidden;
    position: absolute;
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
}

.container-new__deck {
    display: flex;
    height: auto;
    background: #F8F8F8;
    border-radius: 4px;
}

.image__container {
    width: 146px;
    height: 142px;
    background: #D9D9D9;
    border-radius: 4px 0px 0px 4px;
}

.input__container {
    width: 100%;
    margin: 20px;
}

.anki__card-creation--container-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(140, 82, 255, 0.09);
    border-radius: 4px;
    width: 100%;
    height: 40px;
}

.anki__preview-green-btn {
    height: 35px;
    background: rgba(55, 153, 59, 0.25);
    box-shadow: 0px 4px 4px rgba(15, 128, 40, 0.12);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #1B811F;
    border: none;
    padding: 0px 10px;
    transition: .2s ease;
    float: right;
}

.anki__preview-green-btn:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

.guide-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
}

.go-back {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #5B5B5B;
}

.anki__upload-button-create-green {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 146px;
    height: 23px;
    background: rgba(15, 128, 40, 0.12);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #24AA42;
}

  .anki__modal--width-change-edit>.modal-dialog {
    max-width: 1100px !important
}
.note-creation-more-option {
    padding: 10px;
    margin: 10px;
    background-color: #ECE8F5;
    border-radius: 8px;
}
.single-choice__input-box-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}

.single-choice__add--more {
  border: none;
  background-color: #fff;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #24aa42;
}

.single-choice__remove {
  border: none;
  background-color: #fff;
  font-family: 'Poppins';
  font-style: normal;
}

.single-choice__input--field {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  font-family: 'Poppins';
  position: relative;
  display: inline-block;
  height: 36px;
  width: 75%;
  min-width: 0;
  padding: 4px 11px;
  color: #000000d9;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

.single-choice__input--field:hover {
  border: 1px solid #8c52ff;
}

.single-choice__preview {
  background: #ffffff;
  box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  height: 510px;
  overflow: hidden;
  padding-top: 40px;
}

.quill-editor {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.quill-editor .ql-container {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #fff;
  flex: 1 1;
  overflow: hidden;
}

.quill-editor .ql-toolbar {
  background: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 4px 0;
}

.quill-editor .ql-formats {
  margin-right: 0 !important;
}

.quill-editor .ql-formats .ql-header,
.quill-editor .ql-formats .ql-font {
  font-size: 12px;
}

.quill-editor .ql-formats button {
  width: 24px;
}

.features__container {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.features__input-container {
  display: flex;
  flex-direction: column;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.features__input {
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  outline: none;
  background: #fff;
  width: 100%;
  padding: 5px 10px;
  line-height: normal;
  font-size: 14px;
}

textarea.features__input {
  height: 100px;
}

textarea.features__input:disabled {
  background: #eee;
  color: #333;
}

.features__toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.features__switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
}

.features__switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.features__switch .slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: #8c8c8c;
  border-radius: 9px;
  transition: 0.4s;
}

.features__switch .slider::before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 2px;
  top: 2px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.4s;
}

.features__switch input:checked + .slider {
  background-color: #8c52ff;
}

.features__switch input:focus + .slider {
  box-shadow: 0 0 1px #8c52ff;
}

.features__switch input:checked + .slider:before {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.features__option-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  margin-top: 0.25rem;
}

.features__option-btn-grp {
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  grid-gap: 3px;
  gap: 3px;
  justify-content: end;
}
.option-visible {
  display: flex;
}
.option-hidden {
  display: none;
}
.features__option-btn {
  background-color: #fff;
  border: 1px solid #c3a5ff;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.features__option-wrapper textarea {
  height: 80px;
}
.features__option-field-container {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.features__option-field-input {
  accent-color: #8c52ff;
  cursor: pointer;
}
.features__option-field-box {
  background: #e9e9e9;
  border-radius: 4px;
  width: 100%;
  padding: 0.5rem;
  min-height: 30px;
}
.features__option-field-input:checked + .features__option-field-box {
  background: #24aa42;
  color: #fff;
}
.add__btn {
  color: #24aa42;
}
.delete__btn {
  color: #f61c1c;
}

.features__btn-addMore {
  background: transparent;
  margin-top: 0.5rem;
  text-align: left;
  border: none;
  outline: none;
  color: #8c52ff;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.manage-card__modal > .modal-dialog {
  max-width: 1200px !important;
}

.manage-card__header {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.manage-card__select-card {
  width: 250px;
}
.manage-card__select-card .select__control {
  cursor: pointer;
  border-radius: 0;
  border-style: none;
  border-bottom: 2px solid #8c8c8c;
  box-shadow: none;
}
.manage-card__select-card .select__value-container {
  padding: 0;
}
.manage-card__select-card .select__single-value,
.manage-card__select-card .select__input-container,
.manage-card__select-card .select__indicator {
  color: #8c8c8c;
}
.manage-card__select-card .select__indicator:hover {
  color: #8c52ff;
}

.manage-card__body {
  padding: 1rem 2rem;
  display: flex;
}

.manage-card__col {
  flex: 1 1;
  padding: 0 1rem;
}
.manage-card__separator {
  width: 2px;
  background: #eee;
  margin: 0 0.5rem;
}

.manage-card__title {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.manage-card__text {
  color: #757575;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.manage-card__col-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.manage-card__col-header button {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0.5rem 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  font-size: 14px;
}

.manage-card__preview-wrapper {
  margin: auto;
  background: #fff;
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #cdcdcd;
  border-radius: 6px;
  overflow: hidden;
}

.manage-card__editor-wrapper {
  margin: auto;
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.manage-card__input-container {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.manage-card__option-container {
  margin-top: 2rem;
  max-height: 432px;
  overflow-y: auto;
}
.manage-card__option-container::-webkit-scrollbar {
  width: 0.25rem;
  background: #f2ebff;
}
.manage-card__option-container::-webkit-scrollbar-thumb {
  border-radius: 0.2rem;
  background: #cfbcff;
}

.manage-card__btn-grp {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.manage-card__btn-grp button {
  height: 40px;
  width: 150px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.manage-card__btn-secondary {
  background: #d9d9d9;
  color: #000;
}
.manage-card__btn-secondary:hover {
  background: #dfdfdf;
}
.manage-card__btn-secondary:disabled {
  cursor: auto;
  background: #dfdfdf;
  color: #666;
}
.manage-card__btn-primary {
  background: #8c52ff;
  color: #fff;
}
.manage-card__btn-primary:hover {
  background: #763de9;
}
.manage-card__btn-primary:disabled {
  cursor: auto;
  background: #828282;
  color: #ddd;
}

@media screen and (max-width: 640px) {
  .manage-card__select-card {
    width: 150px;
  }
  .manage-card__body {
    padding: 1rem 0;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .manage-card__separator {
    display: none;
  }
  .manage-card__btn-grp {
    justify-content: space-between;
    padding: 1rem;
  }
  .manage-card__btn-grp button {
    width: 50%;
  }
}
.warning-div {
  color: red;
  position: absolute;
  bottom: 10% !important;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.waitlist {
    margin: 0 auto;
    width: 100%;
    max-width: 1800px;
    height: 100%;
    background-color: white;
}

.waitlist__hero {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    z-index: 1;
    background-image: url(/static/media/waitlist-bg.90bf3087.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}

/* BG image stylings */
.waitlist__hero .hero__bgPattern1 {
    position: absolute;
    width: 404px;
    height: 404px;
    object-fit: cover;
    top: -100px;
    left: -100px;
    z-index: 0;
}

/* Hero content stylings */
.waitlist__hero .waitlist__content {
    width: 100%;
    max-width: 700px;
    position: relative;
    z-index: 1;
    padding: 80px;
}

.waitlist__content .content__brand {
    font-size: 24px;
    font-weight: 500;
    color: 383838;
    margin-bottom: 100px;
}

.waitlist__content .content__info {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 35px;
}

.waitlist__content .content__info .info__heading {
    font-size: 40px;
    line-height: 56px;
    font-weight: 600;
    color: #383838;
}

.waitlist__content .content__info .info__description {
    max-width: 400px;
    font-size: 20px;
    font-weight: 300;
    color: #383838;
}

.waitlist__content .content__actions {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
}

.waitlist__content .content__actions .action {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
}

.waitlist__content .content__actions .action:hover {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
}

.content__actions .action.action--primary {
    background-color: #8C52FF;
    color: white;
}

.content__actions .action.action--secondary {
    background-color: #F6F1FF;
    color: #383838;
}

/* Feature stylings */
.waitlist__features {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    min-height: 100vh;
    background-color: white;
    background-image: url(/static/media/waitlist-bg-pattern-2.2fb07785.png);
}

.waitlist__features .features__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 65px;
}

.waitlist__features .features__header .header__heading {
    font-size: 32px;
    font-weight: 600;
    color: #383838;
}

.waitlist__features .features__header .header__subText {
    font-size: 18px;
    font-weight: 400;
}

.features__cards {
    max-width: 80%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 50px;
    gap: 50px;
    justify-content: center;
}

.features__cards .cards__card {
    width: 300px;
    height: 375px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 24px 20px;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.08);
    transition: 0.2s ease-in-out;
}

.features__cards .cards__card:hover { -webkit-transform: translateY(-8px); transform: translateY(-8px); }

.features__cards .cards__card .card__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 140px;
    margin-bottom: 12px;
}

.features__cards .cards__card .card__info {
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    gap: 6px;
    margin-bottom: auto;
}

.cards__card .card__info .info__title {
    font-size: 18px;
    font-weight: 600;
    color: #383838;
}

.cards__card .card__info .info__description {
    font-size: 14px;
    font-weight: 400;
    color: #383838;;
}

.cards__card .card__link {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.cards__card .card__link:hover {
    grid-gap: 13px;
    gap: 13px;
}

/* Form stylings */
.waitlist__form {
    width: 100%;
    min-height: 100vh;
    padding: 100px 140px;
    display: flex;
    align-items: center;
}

.waitlist__form .form__image { margin-right: auto; }

.waitlist__form .form__container {
    width: 50%;
    max-width: 480px;
}

.waitlist__form .form__container .form__header {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
    margin-bottom: 35px;
}

.form__container .form__header .form__heading {
    font-size: 36px;
    font-weight: 600;
    color: #383838;
}

.form__container .form__header .form__description {
    font-size: 18px;
    font-weight: 300;
    color: #383838;
}

.form__container .form {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}

.form__container .form .form__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    gap: 6px;
}

.form__container .form .form__input .form__label {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    font-size: 18px;
    font-weight: 500;
    color: #383838;
}

.form__container .form .form__input .form__label .form__label--required {
    font-size: 12px;
    color: #F43333;
    -webkit-transform: translateX(-3px) translateY(-2px);
            transform: translateX(-3px) translateY(-2px);
}

.form__container .form .form__input .form__input {
    background-color: white;
    border: 1px solid #383838;
    border-radius: 4px;
    padding: 8px 14px;
    color: #383838;
    font-weight: 400;
}

.form__container .form .form__input .form__input::-webkit-input-placeholder { color: #B5B5B5; }

.form__container .form .form__input .form__input::placeholder { color: #B5B5B5; }

.form__container .form .form__error {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    margin-top: 8px;
}

.form__container .form .form__error .error__message {
    font-size: 16px;
    color: #F43333;
    font-weight: 400;
}

.form__container .form .form__button {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #8C52FF;
    padding: 12px;
    border: none;
    border-radius: 8px;
    margin-top: 16px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.form__container .form .form__button:hover {
    background-color: #7a38fd;
}

.form__container .form .form__button:disabled {
    background-color: #B5B5B5;
    cursor: not-allowed;
}

.form__container .form .form__helper {
    font-size: 16px;
    color: #4E4B4B;
    font-weight: 300;
    align-self: center;
    margin-top: -6px;
}

.form__container .form .form__helper .form__helper--highlight {
    font-weight: 600;
    color: #8C52FF;
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 414px) {
    /* ----------- Hero content stylings ----------- */
    .waitlist__hero {
        min-height: 860px;
        background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 80%), url(/static/media/waitlist-bg.90bf3087.png);
        background-size: cover;
        background-position: left;
    }
    .waitlist__content .content__brand { 
        font-size: 20px;
        margin-bottom: 180px;
    }
    .waitlist__hero .hero__bgPattern1 { display: none; }
    .waitlist__hero .waitlist__content { padding: 30px; }
    .waitlist__content .content__info {
        grid-gap: 2px;
        gap: 2px;
        margin-bottom: 30px;
    }
    .waitlist__content .content__info .info__heading {
        font-size: 26px;
        line-height: normal;
    }
    .waitlist__content .content__info .info__description {
        width: 80%;
        font-size: 16px;
    }
    .waitlist__content .content__actions {
        flex-direction: column;
        align-items: start;
        grid-gap: 14px;
        gap: 14px;
    }
    .waitlist__content .content__actions .action { width: 100%; }
    .content__actions .action.action--secondary { background: rgba(140, 82, 255, 0.25); }

    /* ----------- Feature stylings ----------- */
    .waitlist__features { padding: 100px 30px; }
    .waitlist__features .features__header {
        grid-gap: 6px;
        gap: 6px;
        margin-bottom: 45px;
    }
    .waitlist__features .features__header .header__heading { font-size: 26px; }
    .waitlist__features .features__header .header__subText { 
        font-size: 16px;
        text-align: center;
    }
    .features__cards {
        max-width: 100%;
    }

    /* ----------- Form stylings ----------- */
    .waitlist__form {
        min-height: auto;
        padding: 80px 30px 120px 30px;
    }
    .waitlist__form .form__image {
        display: none;
    }
    .waitlist__form .form__container { width: 100%; }
    .waitlist__form .form__container .form__header { margin-bottom: 25px; }
    .form__container .form__header .form__heading { font-size: 26px; }
    .form__container .form__header .form__description { font-size: 16px; }
    .form__container .form .form__input .form__label { 
        font-size: 15px;
        grid-gap: 4px;
        gap: 4px;
    }
    .form__container .form .form__input .form__input { padding: 8px 12px; }
    .form__container .form .form__error .error__message { font-size: 14px;}
    .form__container .form .form__button { 
        font-size: 16px;
        margin-top: 12px;
    }
    .form__container .form .form__helper {
        font-size: 14px;
        margin-top: -4px;
    }
}
.dialog--wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.20);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog--wrapper .dialog__container {
    width: 450px;
    padding: 35px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #9B9B9B;
}

.dialog__container .container__heading {
    font-size: 20px;
    font-weight: 500;
    color: #231F20;
    margin-bottom: 12px;
}

.dialog__container .container__heading .container__heading--highlight { color: #8C52FF; }

.dialog__container .container__description {
    font-size: 16px;
    font-weight: 400;
    color: #231F20;
}

.dialog__container .container__description .container__description--highlight { font-weight: 500; }

.dialog__container .container__action {
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0;
    border-radius: 8px;
    border: none;
}

.dialog__container .container__action--primary { background-color: #8C52FF; color: white; }
.dialog__container .container__action--secondary { background-color: #E7DBFF; }

.dialog__container.container__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.dialog__container .dialog__successIcon {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: #FBE3FF;
    margin-bottom: 20px;
}

@media only screen and (max-width: 414px) {
    .dialog--wrapper {
        align-items: end;
    }
    .dialog--wrapper .dialog__container {
        padding: 45px 35px;
        border-radius: 0;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        border: none;
    }
    .dialog__container .container__heading { font-size: 18px; }
    .dialog__container .container__description { font-size: 14px; }
    .dialog__container .container__action { font-size: 15px; }
    .dialog__container.container__success { padding: 55px 35px; }
}
