.faq__heading--bar {
  height: 3px;
  width: 200px;
  background: #8c52ff;
}
.faq__heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-left: 70px;
}
.container__faq--box {
  display: flex;
  max-width: 800px;
  justify-content: center;
  margin: 0 auto;
}
.faq__accordion--container {
  width: 100% !important;
}
.faq__accordion--header {
  background: #fff;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #444444;
}
.faq__content--left-border {
  width: 4px;
  height: 100%;
  min-height: 10px;
  background: #8c52ff;
}
.faq__content-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0px;
  letter-spacing: 0.03em;
  color: #444444;
  text-align: justify;
  text-justify: inter-word;
}
