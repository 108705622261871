:root {
    --color-primary: #4A4A4A;
    --color-secondary: #B189FF;
    --color-secondary-dark: #8C52FF;
}

.webinar__hero { background: linear-gradient(103.89deg, #F9DEFF 0%, #FFFFFF 53.13%, #FBECFF 100%); }

.webinar__hero--wrapper {
    display: flex;
    max-width: 1700px;
    padding: 60px 160px 80px 60px;
    min-height: 640px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.webinar__hero h2, h4, p { margin: 0; }

.webinar__hero .hero__webinarDetails {
    display: flex;
    width: 50%;
    max-width: 640px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    margin-right: auto;
}

.hero__webinarDetails .webinarDetails__header,
.hero__webinarDetails .webinarDetails__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.webinarDetails__header .header--date,
.webinarDetails__info .info--timeDuration {
    font-size: 18px;
    font-weight: 500;
    color: var(--color-primary);
}

.webinarDetails__header .header--title {
    display: inline-block;
    width: 90%;
    font-size: 40px;
    letter-spacing: 0.05em;
    color: var(--color-primary);
    font-weight: 500;
    word-wrap: break-word;
}

.webinarDetails__info .info--shortSummary {
    font-size: 16px;
    color: var(--color-primary);
    font-weight: 400;
    width: 80%;
    word-wrap: break-word;
}

.webinarDetails__action.webinarDetails__action--register {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    background-color: var(--color-secondary-dark);
    border-radius: 8px;
    color: #FFFFFF;
    padding: 8px 32px;
    font-size: 16px;
    font-weight: 500;
    transition: 0.3s ease;
}

.webinarDetails__action--register .webinar__price {
    display: flex;
    gap: 8px;
}

.webinarDetails__action--register .webinar__price--stricked {
    text-decoration: line-through;
}

.webinarDetails__action.webinarDetails__action--register:disabled:hover {
    transform: none;
    box-shadow: none;
    cursor: not-allowed;
}

.webinarDetails__action.webinarDetails__action--register:hover {
    transform: translateY(-1.2px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.webinar__hero .hero__hostDetails {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.hero__hostDetails .hostDetails__info {
    width: 350px;
    height: 230px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0px 0px 30px 5px #F3EDFF;
}

.hostDetails__info .info__profile {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-position: center;
    box-shadow: 0px 0px 14px 8px #E8DDFF;
    margin-bottom: 10px;
}

.hostDetails__info .info__name,
.hostDetails__info .info__workingAt {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-primary);
}

.hostDetails__info .info__name { border-bottom: 0.6px solid var(--color-secondary); }

.hostDetails__info .info__name .info__isHost  { font-size: 12px !important; }

.hero__hostDetails .hostDetails__perks {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
}

.perk--wrapper {
    width: 350px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px 20px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 15px 2px #EEEEEE;
    border-radius: 8px;
}

.perk--wrapper .perk--name {
    font-size: 18px;
    font-weight: 500;
    color: var(--color-primary);
}

@media only screen and (max-width: 414px) {
    .webinar__hero .hero__hostDetails { display: none; }
    .webinarDetails__header .header--date { display: none; }
    .webinar__hero--wrapper {
        width: 100%;
        min-height: 500px;
        padding: 40px 20px;
        align-items: unset;
    }
    .hero__webinarDetails .webinarDetails__info { gap: 8px; }
    .webinar__hero .hero__webinarDetails { width: 100%; gap: 20px; }
    .webinarDetails__header .header--title { font-size: 28px; }
    .webinarDetails__info .info--shortSummary { margin-bottom: 10px; width: 100%; }
    .webinarDetails__info .info--timeDuration,
    .webinarDetails__info .info--host-mobile {
        font-size: 16px;
        color: #000000;
    }
    .hero__webinarDetails .webinarDetails__perks-mobile {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .perk--wrapper {
        width: max-content;
        background-color: transparent;
        box-shadow: unset;
        border: 1px dashed #000000;
        padding: 4px 6px;
        gap: 6px;
        border-radius: 4px;
    }
    .perk--wrapper .perk--name { color: #000000; font-size: 14px; }
    .webinarDetails__action.webinarDetails__action--register {
        width: 100%;
        padding: 16px 0;
        border-radius: 4px;
    }
}