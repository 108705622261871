:root {
    --color-primary: #4A4A4A;
    --color-primary-dark: #171717;
    --color-secondary: #B189FF;
    --color-secondary-dark: #8C52FF;
}

.webinar__testimonials {
    width: 100%;
    background-color: #FFFFFF;
    padding: 80px 60px;
}

.testimonials--wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 65px;
}

.testimonials--wrapper .testimonials__heading {
    display: block;
    text-align: center;
    font-size: 38px;
    line-height: 46px;
    font-weight: 500;
    letter-spacing: 0.04em;
    color: var(--color-primary);
}

.testimonials--wrapper .testimonial__image--addMore {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    padding: 10px 22px;
    border: none;
    font-size: 18px;
    font-weight: 400;
    background-color: var(--color-secondary);
    border-radius: 4px;
    color: white;
    transition: 0.3s ease;
}

.testimonials--wrapper .testimonial__image--addMore:hover {
    transform: translateY(-4px);
    background-color: var(--color-secondary-dark);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
}

.testimonial__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    height: max-content;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 25px 20px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}

.testimonial__card .testimonial__image {
    width: 100%;
    height: auto;
    position: relative;
}

.testimonial__card .testimonial__image .testimonial__image--delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(50%, -50%);
    background-color: white;
    border-radius: 50%;
    border: none;
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2);
}

.testimonial__card .testimonial__image img {
    width: 100%;
    background-size: cover;
    object-fit: cover;
    border-radius: 4px;
}

.testimonial__card .testimonial__profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.testimonial__profile .testimonial__profile--initials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #F5CCF9;
    font-size: 18px;
    color: var(--color-primary);
    font-weight: 500;
}

.testimonial__profile .testimonial__profile--name {
    font-size: 20px;
    font-weight: 500;
    color: var(--color-primary-dark);
}

.testimonial__card .details__feedback {
    font-size: 16px;
    color: var(--color-primary);
    font-weight: 400;
    text-align: center;
}

.webinar__testimonials .testimonials__noData {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    position: relative;
}

.testimonials__noData .noData--iconWrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4DBFD;
}

.testimonials__noData .noData--message {
    font-size: 24px;
    width: 600px;
    font-weight: 500;
    color: var(--color-primary);
    text-align: center;
}

.testimonials__noData .noData--createButton {
    border: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 500;
    color: var(--color-secondary-dark);
    border-bottom: 1px solid var(--color-secondary-dark);
}

.my-masonry-grid {
    display: -webkit-box; 
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > .testimonial__card {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    height: max-content;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 25px 20px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}

.webinar__addTestimonial {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px) brightness(40%);

    display: flex;
    justify-content: center;
}

.webinar__addTestimonial .testimonial__form {
    width: 50%;
    max-width: 680px;
    height: fit-content;
    max-height: 700px;
    margin-top: 90px;
    background-color: #FFFFFF;
    padding: 30px 40px;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.testimonial__form .addTestimonial__header {
    font-size: 22px;
    color: var(--color-secondary-dark);
    font-weight: 500;
    text-align: center;
}

.addTestimonial__form {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-top: 60px;
}

.addTestimonial__form .addTestimonial__form--upload {
    width: 100%;
    padding: 55px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border: 1px solid #5F5F5F;
}

.addTestimonial__form--upload .uploadTestimonial--label {
    display: block;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    background-color: var(--color-secondary);
    padding: 8px 24px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s ease;
}

.addTestimonial__form--upload .uploadTestimonial--label:hover { background-color: var(--color-secondary-dark); }

.testimonial__upload--error {
    color: rgb(242, 93, 93);
    font-weight: 500;
}

.addTestimonial__action {
    display: flex;
    gap: 20px;
    align-items: center;
    align-self: center;
    margin-top: 22px;
}

.addTestimonial__action button {
    border: 2px solid transparent;
    background-color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    padding: 3px 20px;
    border-radius: 6px;
    transition: 0.2s ease;
}

.addTestimonial__action .addTestimonial__action--cancel {
    color: #FF5555;
    border-color: #FF5555;
}

.addTestimonial__action .addTestimonial__action--submit {
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--color-secondary-dark);
    border-color: var(--color-secondary-dark);
}

.addTestimonial__action .addTestimonial__action--submit:disabled:hover,
.addTestimonial__action .addTestimonial__action--cancel:disabled:hover {
    transform: none;
    box-shadow: none;
    cursor: not-allowed;
}

.addTestimonial__action button:hover {
    transform: translateY(-2px);
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 414px) {
    .webinar__testimonials { padding: 0; }
    .webinar__testimonials.testimonials__noData--mobile { padding: 0; }
    .testimonials__noData.testimonials__noData--mobile {
        padding: 80px 60px;
        background-color: #FBF1FF;
    }
    .testimonials__noData .noData--iconWrapper {
        width: 54px;
        height: 54px;
    }
    .testimonials__noData .noData--message {
        width: 100%;
        font-size: 14px;
    }
    .testimonials--wrapper {
        padding: 0 20px 40px 20px;
        align-items: flex-start;
        gap: 12px;
    }
    .testimonials--wrapper .testimonials__heading { font-size: 26px; }
    .testimonials__imageContainer {
        width: 100%;
        display: flex;
        gap: 30px;
        overflow-x: scroll;
        padding: 10px 6px 20px 0;
    }
    .testimonials__imageContainer::-webkit-scrollbar {
        display: none;
    }
    .testimonial__card { 
        width: 290px;
        height: 340px;
        justify-content: center;
        padding: 5px;
    }
    .testimonial__card .testimonial__image { width: 270px; height: 340px; overflow: hidden; }
    .testimonial__card .testimonial__image img {
        width: 100%;
        height: 100%;
    }
    .testimonial__card .testimonial__image .testimonial__image--delete {
        top: unset;
        bottom: -5px;
        right: 135px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.22);
    }
    .testimonials--wrapper .testimonial__image--addMore {
        padding: 8px 10px;
        font-size: 16px;
        gap: 8px;
    }

    /* ---------------- TESTIMONIAL FORM */
    .webinar__addTestimonial .testimonial__form {
        width: 90%;
        max-width: 100%;
        padding: 20px;
    }
    .addTestimonial__form .addTestimonial__form--upload { 
        padding: 30px 0;
        text-align: center; 
    }
}