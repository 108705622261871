.single-option-dev {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 93%;
  min-height: 49px;
  margin: 0 auto;
  background: rgba(140, 82, 255, 0.09);
  border-radius: 8px;
  color: #444444;
  cursor: pointer;
  margin: 10px;
}
.single-option-dev-green {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 93%;
  min-height: 49px;
  margin: 0 auto;
  background: #24aa42;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin: 10px;
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.single-option-dev--text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
}

.multiple-option--done-btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 152px;
  height: 42px;
  border: none;
  background: #7b45e7;
  border-radius: 4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.85);
  margin: 0 auto;
}
