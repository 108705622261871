.authButton {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    color: #383838;
    border-radius: 8px;
}

.authButton.authButton-primary {
    background-color: #8C52FF;
    color: #FFFFFF;
}

.authButton.authButton-primary:disabled {
    background-color: #C7C5C9;
    cursor: not-allowed;
}

.authButton.authButton-secondary {
    border: 1px solid #CDCDCD;
    background-color: #F4F4F4;
    color: #383838;
}

@media only screen and (max-width: 414px) {
    .authButton {
        font-size: 16px;
    }
}