*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.uprofile--wrapper {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-color: var(--bg-card);
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.03em;
    padding: 100px 0 80px 0;
}

.uprofile {
    display: grid;
    grid-template-columns: minmax(200px, 220px) 1fr;
    grid-column-gap: 20px;
    width: 100%;
    max-width: 92vw;
}

.uprofile h2,
.uprofile h3,
.uprofile p {
    margin: 0;
}

.uprofile .uprofile__sidebar {
    width: 100%;
}

.sidebar__profile {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.sidebar__profile .profile__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    /* border-bottom: 1px solid var(--border-color); */
    gap: 15px;
}

.profile__avatar .profile__avatar--image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    object-fit: cover;
}

.profile__avatar .profile__avatar--name {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 8px;
    font-size: 18px;
    font-weight: 600;
}

.profile__avatar .profile__avatar--edit {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    width: 16px;
    height: 16px;
}

.profile__avatar .profile__avatar--edit img {
    width: 14px;
    height: 14px;
}

.sidebar__profile .profile__stats {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profile__stats .profile__stat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
}

.profile__stat .profile__stat--label {
    font-size: 14px;
    font-weight: normal;
    color: var(--text-black-med);
}

.profile__stat .profile__stat--value {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-black-med);
}

.profile__stat .profile__stat--value.value--purple { color: #8C52FF; }

/* ------------------- PROFILE SESSIONS SECTION */
.uprofile__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.main__intro {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.main__intro--mobile,
.session__intro--mobile {
    display: none;
}

.main__intro .main__intro--greetings {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-black);
}

.main__intro .main__intro--message {
    font-size: 14px;
    color: var(--text-black-lite);
    font-weight: normal;
}

.main__bookings {
    border: 0.5px solid #e5e5e5;
}

.sessions__empty-container {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    background-color: var(--bg-background);
    border-radius: 8px;
    border: 0.5px solid #e5e5e5;
}

.empty__container--heading {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-black-lite);
}

.empty__container--text {
    font-size: 14px;
    color: var(--text-black-lite);
    font-weight: normal;
}

.empty__container--link {
    color: #9281fc;
    font-weight: 500;
    transition: 0.2s ease;   
}

.empty__container--link:hover { color: #8C52FF; }

@media only screen and (max-width: 414px) {
    .uprofile--wrapper { 
        display: block;
        background-color: var(--bg-background); 
    }
    .uprofile {
        max-width: 100vw;
        grid-template-columns: 1fr;
        grid-template-rows: minmax(max-content, 100px) 1fr;
        gap: 40px;
        justify-items: center;
    }
    .uprofile .uprofile__sidebar { width: 92%; }
    .sidebar__profile { gap: 25px; }
    .sidebar__profile .profile__avatar { display: none; }
    .sidebar__profile .profile__stats {
        padding: 15px 0;
        background-color: var(--bg-card);
        margin-bottom: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
    }
    .main__intro { display: none; }
    .main__intro--mobile,
    .session__intro--mobile {
        display: block;
    }
    .main__intro--mobile h2,
    .session__intro--mobile h2 {
        font-size: 18px;
        color: var(--text-black);
        padding: 0 4px;
    }
    .uprofile__main {
        display: block;
        width: 100%;
    }
    .main__bookings { border: none; }
    .main__bookings.mdashboard__card-rounded {
        box-shadow: none;
        border-radius: 0;
    }
    .main__bookings .upcoming__sessions--wrapper { gap: 16px; }
    .sessions__empty-container {
        width: 90%;
        height: 500px;
        margin: 0 auto;
        background-color: var(--bg-card);
        border-color: var(--border-color);
    }
}