*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.waitlist {
    margin: 0 auto;
    width: 100%;
    max-width: 1800px;
    height: 100%;
    background-color: white;
}

.waitlist__hero {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    z-index: 1;
    background-image: url("../AssetV2/waitlist-bg.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}

/* BG image stylings */
.waitlist__hero .hero__bgPattern1 {
    position: absolute;
    width: 404px;
    height: 404px;
    object-fit: cover;
    top: -100px;
    left: -100px;
    z-index: 0;
}

/* Hero content stylings */
.waitlist__hero .waitlist__content {
    width: 100%;
    max-width: 700px;
    position: relative;
    z-index: 1;
    padding: 80px;
}

.waitlist__content .content__brand {
    font-size: 24px;
    font-weight: 500;
    color: 383838;
    margin-bottom: 100px;
}

.waitlist__content .content__info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 35px;
}

.waitlist__content .content__info .info__heading {
    font-size: 40px;
    line-height: 56px;
    font-weight: 600;
    color: #383838;
}

.waitlist__content .content__info .info__description {
    max-width: 400px;
    font-size: 20px;
    font-weight: 300;
    color: #383838;
}

.waitlist__content .content__actions {
    display: flex;
    gap: 20px;
    align-items: center;
}

.waitlist__content .content__actions .action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
}

.waitlist__content .content__actions .action:hover {
    transform: translateY(-4px);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
}

.content__actions .action.action--primary {
    background-color: #8C52FF;
    color: white;
}

.content__actions .action.action--secondary {
    background-color: #F6F1FF;
    color: #383838;
}

/* Feature stylings */
.waitlist__features {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    min-height: 100vh;
    background-color: white;
    background-image: url("../AssetV2/waitlist-bg-pattern-2.png");
}

.waitlist__features .features__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 65px;
}

.waitlist__features .features__header .header__heading {
    font-size: 32px;
    font-weight: 600;
    color: #383838;
}

.waitlist__features .features__header .header__subText {
    font-size: 18px;
    font-weight: 400;
}

.features__cards {
    max-width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
}

.features__cards .cards__card {
    width: 300px;
    height: 375px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 24px 20px;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.08);
    transition: 0.2s ease-in-out;
}

.features__cards .cards__card:hover { transform: translateY(-8px); }

.features__cards .cards__card .card__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 140px;
    margin-bottom: 12px;
}

.features__cards .cards__card .card__info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: auto;
}

.cards__card .card__info .info__title {
    font-size: 18px;
    font-weight: 600;
    color: #383838;
}

.cards__card .card__info .info__description {
    font-size: 14px;
    font-weight: 400;
    color: #383838;;
}

.cards__card .card__link {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.cards__card .card__link:hover {
    gap: 13px;
}

/* Form stylings */
.waitlist__form {
    width: 100%;
    min-height: 100vh;
    padding: 100px 140px;
    display: flex;
    align-items: center;
}

.waitlist__form .form__image { margin-right: auto; }

.waitlist__form .form__container {
    width: 50%;
    max-width: 480px;
}

.waitlist__form .form__container .form__header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 35px;
}

.form__container .form__header .form__heading {
    font-size: 36px;
    font-weight: 600;
    color: #383838;
}

.form__container .form__header .form__description {
    font-size: 18px;
    font-weight: 300;
    color: #383838;
}

.form__container .form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.form__container .form .form__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.form__container .form .form__input .form__label {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 18px;
    font-weight: 500;
    color: #383838;
}

.form__container .form .form__input .form__label .form__label--required {
    font-size: 12px;
    color: #F43333;
    transform: translateX(-3px) translateY(-2px);
}

.form__container .form .form__input .form__input {
    background-color: white;
    border: 1px solid #383838;
    border-radius: 4px;
    padding: 8px 14px;
    color: #383838;
    font-weight: 400;
}

.form__container .form .form__input .form__input::placeholder { color: #B5B5B5; }

.form__container .form .form__error {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 8px;
}

.form__container .form .form__error .error__message {
    font-size: 16px;
    color: #F43333;
    font-weight: 400;
}

.form__container .form .form__button {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #8C52FF;
    padding: 12px;
    border: none;
    border-radius: 8px;
    margin-top: 16px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.form__container .form .form__button:hover {
    background-color: #7a38fd;
}

.form__container .form .form__button:disabled {
    background-color: #B5B5B5;
    cursor: not-allowed;
}

.form__container .form .form__helper {
    font-size: 16px;
    color: #4E4B4B;
    font-weight: 300;
    align-self: center;
    margin-top: -6px;
}

.form__container .form .form__helper .form__helper--highlight {
    font-weight: 600;
    color: #8C52FF;
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 414px) {
    /* ----------- Hero content stylings ----------- */
    .waitlist__hero {
        min-height: 860px;
        background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 80%), url("../AssetV2/waitlist-bg.png");
        background-size: cover;
        background-position: left;
    }
    .waitlist__content .content__brand { 
        font-size: 20px;
        margin-bottom: 180px;
    }
    .waitlist__hero .hero__bgPattern1 { display: none; }
    .waitlist__hero .waitlist__content { padding: 30px; }
    .waitlist__content .content__info {
        gap: 2px;
        margin-bottom: 30px;
    }
    .waitlist__content .content__info .info__heading {
        font-size: 26px;
        line-height: normal;
    }
    .waitlist__content .content__info .info__description {
        width: 80%;
        font-size: 16px;
    }
    .waitlist__content .content__actions {
        flex-direction: column;
        align-items: start;
        gap: 14px;
    }
    .waitlist__content .content__actions .action { width: 100%; }
    .content__actions .action.action--secondary { background: rgba(140, 82, 255, 0.25); }

    /* ----------- Feature stylings ----------- */
    .waitlist__features { padding: 100px 30px; }
    .waitlist__features .features__header {
        gap: 6px;
        margin-bottom: 45px;
    }
    .waitlist__features .features__header .header__heading { font-size: 26px; }
    .waitlist__features .features__header .header__subText { 
        font-size: 16px;
        text-align: center;
    }
    .features__cards {
        max-width: 100%;
    }

    /* ----------- Form stylings ----------- */
    .waitlist__form {
        min-height: auto;
        padding: 80px 30px 120px 30px;
    }
    .waitlist__form .form__image {
        display: none;
    }
    .waitlist__form .form__container { width: 100%; }
    .waitlist__form .form__container .form__header { margin-bottom: 25px; }
    .form__container .form__header .form__heading { font-size: 26px; }
    .form__container .form__header .form__description { font-size: 16px; }
    .form__container .form .form__input .form__label { 
        font-size: 15px;
        gap: 4px;
    }
    .form__container .form .form__input .form__input { padding: 8px 12px; }
    .form__container .form .form__error .error__message { font-size: 14px;}
    .form__container .form .form__button { 
        font-size: 16px;
        margin-top: 12px;
    }
    .form__container .form .form__helper {
        font-size: 14px;
        margin-top: -4px;
    }
}