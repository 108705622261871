body {
    font-family: 'Poppins';
    background-color: #FBFAFF;
    ;
}

.notes__container {
    max-width: 1440px;
    width: 100%;
}

.notes__header {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.notes__header>p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 48px;
}

.notes__header>button {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
}

.card__container {
    width: 100%;
    overflow: scroll;
}

.slider {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.notes__bottom--layer {

    position: relative;
    display: inline-block;
    width: 340px;
    height: 150px;
    margin-right: 10px;
    border-radius: 8px;
    background: #EEEEEE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin: 20px;
}

.notes__bottom--layer-mentor--profile {

    position: relative;
    display: inline-block;
    width: 290px;
    height: 140px;
    margin-right: 10px;
    border-radius: 8px;
    background: #EEEEEE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin: 20px;
}

.notes__middle--layer {
    position: absolute;
    width: 340px;
    height: 150px;
    top: -8px;
    left: -8px;
    background: #EDEDED;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.notes__middle--layer-mentor--profile {
    position: absolute;
    width: 290px;
    height: 140px;
    top: -8px;
    left: -8px;
    background: #EDEDED;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.notes__top--layer {
    position: absolute;
    width: 340px;
    height: 150px;
    top: -8px;
    left: -8px;
    background: #F8F8F8;
    border: 1px solid #8C52FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.notes__top--layer-mentor--profile {
    position: absolute;
    width: 290px;
    height: 140px;
    top: -8px;
    left: -8px;
    background: #F8F8F8;
    border: 1px solid #8C52FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.img-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 130px;
    height: 150px;
    border-radius: 8px 0px 0px 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-container-anki__mentor-Profile {
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 138px;
    border-radius: 8px 0px 0px 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-container>img {
    height: 100%;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #7B45E7;
    margin-top: -1.2px;
}
.img-container-anki__mentor-Profile>img {
    height: 100%;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #7B45E7;
    margin-top: -1.2px;
}
.notes__privacy {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    margin: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.03em;
    color: #8C8C8C;
}

.notes__content--container {
    position: absolute;
    display: flex;
    width: 220px;
    top: 50%;
    left: 50%;
    transform: translate(-20%, -50%);
    white-space: pre-line;
    align-items: center;
}

.notes__content--container-mentor--profile {
    position: absolute;
    display: flex;
    top: 55%;
    left: 50%;
    transform: translate(-20%, -50%);
    white-space: pre-line;
    align-items: center;
}

.notes__icon--container {
    width: 20px;
    top: 10%;
    left: 50%;
    transform: translate(-20%, -80%);
    margin: 10px;
}

.notes__deck-name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.03em;
    margin-bottom: 10px;
    color: #454545;
}

.notes__deck-likes {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.03em;
    color: #8C52FF;
    margin-bottom: 4px;
}

.notes__card--count {
    font-weight: 500;
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.03em;
    color: #223447;
}

.slider::-webkit-scrollbar {
    display: none;
}

.card>img {
    width: 279px;
    height: 100%;
    border-radius: 8px;
}

.notes__arrow-btn {
    font-weight: 500;
    background-color: #37993a3b;
    border-radius: 50%;
    color: #1B811F;
    margin: 10px 0px 0px 10px;
}

.my__notes__header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my__notes__header>p {
    margin: 0px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 29px;
    color: #5A5A5A;
}

.cursor-pointer {
    cursor: pointer;
}

.anki__card {
    position: relative;
    width: 259px;
    height: 200px;
    padding: 0px;
    border-radius: 8px;
    margin: 10px;
}

.anki__card>img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.anki__overlay--card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    background: rgba(4, 4, 4, 0.53);
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.anki__kabab-menu {
    float: left;
    margin: 10px;
}

.bar {
    width: 100%;
    height: 1px;
    background-color: #5A5A5A;
}

.anki__card--name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    color: #5A5A5A;
}

.anki__create-btn {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: none;
    background: #8C52FF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #FFF;
    padding: 10px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    transition: .2s ease;
}

.anki__create-btn:hover {
    transform: translateY(-2px);
}

.previewCard__card--container {
    position: relative;
    width: 250px;
    height: 400px;
    background: #FFFFFF;
    box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 0px;
}

.anki_preview--image-card {
    width: 100%;
    height: 130px;
    border-radius: 8px;
}

.ans-que {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 20px;
    color: #000000;
    margin-bottom: 0px;
}

.hint {
    position: absolute;
    display: flex;
    width: 92%;
    bottom: 34px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #4EBD66;
    margin: 0px 10px 10px 10px;
    background: #E5F0EB;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
}

.anki__previewCard--group-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.anki__previewCard--btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
}

.hard {
    width: 120px;
    height: 40px;
    border: none;
    left: 49px;
    top: 625px;
    background: rgba(255, 107, 74, 0.21);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5em;
    color: #F71C1C;
    margin-bottom: 10px;
}

.easy {
    width: 120px;
    height: 40px;
    border: none;
    left: 222px;
    top: 625px;
    background: rgba(36, 170, 66, 0.34);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5em;
    color: #0F8028;
    margin-bottom: 10px;
}

.anki__card--slider-container {
    position: absolute;
    max-width: 320px;
    width: 100%;
    height: 85vh;
    padding: 20px;
    margin-top: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.25);
}
.anki__card--slider-container-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.slick-prev {
    left: 0px !important;
}

.slick-next {
    right: 0px !important;
}

.slick-prev,
.slick-next {
    display: none !important;
    width: 34px !important;
    height: 32px !important;
}

.anki__heading--para {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
}

.anki__card-top--layer {
    position: relative;
    width: 350px;
    height: 150px;
    background: #FFFFFF;
    border: 1px solid #8C52FF;
    box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin: 15px;
}

.anki__card-content--container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 220px;
    top: 50%;
    left: 50%;
    transform: translate(-20%, -50%);
    white-space: pre-line;
    margin: 10px;
}

.anki__card-content--container>img {
    width: 20px;
    height: 18px;
}

.anki__card--question-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.03em;

    color: #444444;
}

.anki__card-question {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    color: #000000;
    margin-bottom: 0px;
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.anki__card-action--btn {
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.notes__add--create-card-btn {
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    background: #7B45E7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #fff;
    transition: .2s;
}

.notes__add--create-card-btn:hover {
    transform: translateY(-2px);
}

.notes_dropdown {
    position: absolute;
    top: 1%;
    right: 1%;
    z-index: 200;
}

.notes_dropdown .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: none !important;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.notes_dropdown .btn-primary {
    color: #fff;
    transition: .2s ease;
    background-color: #00000000;
    border-color: #00000000;
}

.notes_dropdown .btn-primary:hover {
    color: #fff;
    background-color: #00000000;
    border-color: #00000000;
}

.notes_dropdown .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #00000000;
    border-color: #00000000;
}

.notes__menu-item {
    padding: 5px;
}

.notes__card-menu-style {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #646464;
}

.deck_delete-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3efff;
    height: 40px;
    width: 40px;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: .2s ease;
    margin: 10px;
}

.deck_delete-icon:hover {
    transform: translateY(-2px);
}

.my__notes__header-text {
    position: absolute;
    top: -5%;
}

.my__notes__header-text>p {
    margin: 0px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #5A5A5A;
}

.notes-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 25px;
    background: rgba(140, 82, 255, 0.15);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 120%;
    letter-spacing: 0.03em;
    color: #8C52FF !important;
}

.confirmation-success-container {
    display: block;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    min-height: auto;
    background-color: #f9f9f9;
    position: relative;
    z-index: 20000000000;
    border-radius: 8px;
}

.confirmation-modal-box {
    display: block;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    min-height: auto;
    background-color: #f9f9f9;
    position: relative;
    z-index: 20000000000;
    border-radius: 8px;
}

.notes__text--over-flow--with-image-and-hint {
    height: 192px;
    overflow: hidden;
}
.notes__text--over-flow--without-image {
    height: 310px;
    overflow: hidden;
}
.notes__text--over-flow--with-image {
    height: 230px;
    overflow: hidden;
}
.notes__text--over-flow--without-image-and-hint {
    height: 355px;
    overflow: hidden;
}
.notes__text--over-flow--with-hint {
    height: 335px;
    overflow: hidden;
}