:root {
    --bg-white: #FFFFFF;
    --bg-purple-lite: #E6DBFD;
    --bg-purple-med: #D8C5FD;
    --bg-gray-lite: #EFEFEF;
    --border-primary: #AFAFAF;
    --border-success: #7BC27A;
    --border-warning: #F65050;
    --text-gray-dark: #454545;
    --text-gray-med: #7a7a7a;
    --text-gray-lite: #9E9E9E;
    --text-purple: #8C52FF;
    --text-warning: #E82B1F;
}

.socials--wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.03em;
    background: #00000057;
    backdrop-filter: blur(2px) brightness(80%);
    z-index: 100000;
}

.socials--wrapper p, .socials--wrapper h2, .socials--wrapper h3, .socials--wrapper h4 { margin: 0; }

.socials__box {
    width: 600px;
    padding: 26px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: var(--bg-white);
    border-radius: 6px;
}

.socials__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.socials__header .header__heading {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-gray-med);
}

.header__heading .header__heading--highlighted { color: var(--text-purple); }

.socials__header .header__closeBtn {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    background-color: transparent;
    transform: translateX(-6px);
}

.socials__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* ------ VERIFIED LIST  */

.socials__verifiedList {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.socials__verifiedList .verified__profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    background-color: var(--bg-gray-lite);
}

.verified__profile .profile__info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile__info .profile__info--platform {
    width: 22px;
    height: 22px;
}

.profile__info .profile__info--url {
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    color: var(--text-gray-dark);
}

.verified__profile .profile__action button {
    border: none;
    background-color: transparent;
    padding: 2px 4px;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
}

.verified__profile .profile__action button:hover {
    background-color: #e82c1f4e;
}

.social__input--block {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.social__input--block .block--wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 5px 6px;
    border: 1.2px solid var(--border-primary);
    border-radius: 4px;
    position: relative;
}

.socials__block .block--wrapper.warning { border-color: var(--border-warning); }
.socials__block .block--wrapper.success { border-color: var(--border-success); }

.block--wrapper .socials__option {
    display: flex;
    justify-content: center;
    align-items: center;
}

.socials__option .socials__option--icon{
    width: 20px;
    height: 20px;
}

.block--wrapper .socials__input {
    border: none;
    background: transparent;
    font-size: 18px;
    padding: 2px 4px;
    color: var(--text-gray-dark);
}

.socials__input--invalid {
    color: var(--text-warning);
    font-size: 14px;
    margin-top: 1.5px;
}

.block--wrapper .socials__confirmButton {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 26px;
    font-weight: 400;
    color: var(--text-gray-med);
    transition: 0.2s ease-in-out;
}
.block--wrapper .socials__confirmButton.green { color: var(--border-success) }

.block--wrapper .socials__confirmButton:hover,
.block--wrapper .socials__confirmButton:focus {
    color: var(--text-gray-dark);
}

.socials__addButton {
    align-self: flex-start;
    border: none;
    background-color: var(--bg-purple-lite);
    padding: 6px 16px;
    border-radius: 4px;
    color: var(--text-purple);
    font-size: 16px;
    font-weight: 500;
    transition: 0.2s ease-in-out;    
}

.socials__addButton:hover { 
    background: var(--bg-purple-med);
    transform: scale(0.94);
}