.navbar__desktop--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 50px;
  box-shadow: 0px 1px 4px rgba(140, 140, 140, 0.5);
  background: #ffffff;
  z-index: 2000;
}

.navbar__desktop--logo {
  float: left;
}
.navbar__desktop--user-box {
  float: right;
}
.log__out--btn-V2{
  background: inherit;
  border: none;
  padding: 5px;
  width: 100%;
  display: block;
  text-align: left;
}
.dropdown-item{
  padding: 0px;
}