.anki__form-box--deck-creaton {
    width: 100%;
    margin: 0 auto;
}

.anki__deck-creation--input {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    font-family: 'Poppins';
    position: relative;
    display: inline-block;
    height: 36px;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: #000000d9;
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all .3s;
}

.deck-creation__thumbanil-image--preview {
    width: 146px;
    height: 142px;
    border-radius: 4px 0px 0px 4px;
    transition: all .3s;
}

.anki__deck-creation--input:hover {
    border: 1px solid #8C52FF;
}

.anki__deck-creation--input:disabled {
    background: #dddddd;
    border: 1px solid #c1c1c1;
  }

.dummy__thumbnail--deck {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #8C52FF;
    padding: 5px;
    transition: all .3s;
}

.dummy__thumbnail--border {
    position: absolute;
    width: 90px;
    height: 90px;
    background: #fcf4ff;
    border: 1px solid #8C52FF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbnail-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: #8C52FF;
}

.anki__deck-creation>label {
    width: 100%;
}

.anki__upload-button-create {
    height: 142px;
    width: 146px;
    border-radius: 4px;
    transition: border-color .3s;
    margin-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #929292;
}

.anki__radio-input {
    margin-top: 20px;
}

.anki__radio-input>p {
    margin: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #929292;
}

.anki__form-control {
    font-family: system-ui, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
}

.anki__deck-creation--footer {
    display: flex;
    justify-content: end;
}

.anki__deck-creation-primary {
    height: 35px;
    background: #8C52FF;
    border-radius: 4px;
    color: #fff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
}

.anki__deck-creation-secondary {
    height: 35px;
    background: rgba(140, 82, 255, 0.26);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #8C52FF;
}

.anki__deck-creation-primary-grey {
    height: 35px;
    background: #969696;
    border-radius: 4px;
    color: rgb(37, 37, 37);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
}

.create__card--heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #737373;
    margin-bottom: 0px;
    /* margin-left: 25px; */
}

.anki__deck--owner-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    color: #262D3D;
}

.anki__deck-preview--text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #999999;
    margin-left: 25px;
}

.anki__btn--float-end {
    position: absolute;
    right: 0px;
    margin: 10px;
}

.anki__modal--padding {
    padding: 20px;
}

.anki__deck-creation--footer-create-card {
    display: flex;
    justify-content: space-between;
}

.anki__deck-creation-green {
    background: rgba(55, 153, 59, 0.19);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    color: #1B811F;
    height: 35px;
    border: none;
    padding: 0px 10px;
}

.previewCard__btn {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 40px;
    background: #8C52FF;
    color: #fff;
    border: none;
    border-radius: 0px 0px 8px 8px;
}

.anki__modal--width-change>.modal-dialog {
    max-width: 1300px !important
}

.subscribe-btn {
    background: #7B45E7;
    height: 35px;
    padding: 10px;
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #FFF;
    border: none;
    border-radius: 4px;
    filter: drop-shadow(0px 4px 4px rgba(56, 36, 88, 0.28));
    margin-bottom: 10px;
    transition: .2s ease;
}

.subscribe-btn:hover {
    transform: translateY(-2px);
}

.share-btn {
    background: #0F802812;
    height: 35px;
    padding: 10px;
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #37993B;
    border: none;
    border-radius: 4px;
    filter: drop-shadow(0px 4px 4px rgba(17, 92, 17, 0.28));
    margin-bottom: 10px;
    transition: .2s ease;
}

.share-btn:hover {
    transform: translateY(-2px);
}

input[type="file"] {
    visibility: hidden;
    position: absolute;
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
}

.container-new__deck {
    display: flex;
    height: auto;
    background: #F8F8F8;
    border-radius: 4px;
}

.image__container {
    width: 146px;
    height: 142px;
    background: #D9D9D9;
    border-radius: 4px 0px 0px 4px;
}

.input__container {
    width: 100%;
    margin: 20px;
}

.anki__card-creation--container-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(140, 82, 255, 0.09);
    border-radius: 4px;
    width: 100%;
    height: 40px;
}

.anki__preview-green-btn {
    height: 35px;
    background: rgba(55, 153, 59, 0.25);
    box-shadow: 0px 4px 4px rgba(15, 128, 40, 0.12);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #1B811F;
    border: none;
    padding: 0px 10px;
    transition: .2s ease;
    float: right;
}

.anki__preview-green-btn:hover {
    transform: translateY(-2px);
}

.guide-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
}

.go-back {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #5B5B5B;
}

.anki__upload-button-create-green {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 146px;
    height: 23px;
    background: rgba(15, 128, 40, 0.12);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #24AA42;
}

  .anki__modal--width-change-edit>.modal-dialog {
    max-width: 1100px !important
}
.note-creation-more-option {
    padding: 10px;
    margin: 10px;
    background-color: #ECE8F5;
    border-radius: 8px;
}