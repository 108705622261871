.sdashboard__container .emptyContainer {
    width: 100%;
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: #FBFBFB;
    border-radius: 8px;
    margin: 36px 0;
}

.emptyContainer .emptyContainer__icon--wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
}

.emptyContainer__favourites .emptyContainer__icon--wrapper { background-color: #FEE; }
.emptyContainer__myNotes .emptyContainer__icon--wrapper { background: linear-gradient(90deg, #C072FF, #5753FF) }
.emptyContainer__search .emptyContainer__icon--wrapper { background-color: transparent; }

.emptyContainer .emptyContainer__info {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 15px;
}

.emptyContainer__info .info__heading {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: capitalize;
}

.emptyContainer__info .info__subtext {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

@media only screen and (max-width: 414px) {
    .sdashboard__container .emptyContainer {
        margin: 25px 0;
        gap: 12px;
        height: 295px;
    }
    .emptyContainer .emptyContainer__info {
        gap: 10px;
    }
    .emptyContainer__info .info__heading {
        font-size: 20px;
    }
    .emptyContainer__info .info__subtext {
        font-size: 14px;
    }
}