.landing_page--menu-wrapper {
  background: linear-gradient(89.19deg, #a97ffe 0.61%, #b28cfe 99.26%);
}
.landing__page--menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  padding: 10px 20px;
  background: linear-gradient(89.19deg, #a97ffe 0.61%, #b28cfe 99.26%);
}
.landing_page--menu {
  margin-left: 12rem;
  display: flex;
}
.landing_page--menu .explore-note-icon {
  transform: translate(-0.1rem, -0.4rem);
  transition: 0.5s;
}
.landing_page--menu > .btn:hover .explore-note-icon {
  transform: translate(-0.2rem, -0.3rem) scale(1.3) rotate(10deg);
}
.landing_page--menu-btn {
  position: relative;
  border-radius: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.54px;
  color: #ffffff;
  padding: 5px;
  margin-right: 2rem;
  transition: 0.5s;
}
.landing_page--menu-btn:hover {
  color: #ffffff;
  transform: translateY(-0.25rem);
}
.landing_page--menu-btn::before {
  content: '';
  position: absolute;
  top: 100%;
  right: 5%;
  width: 0;
  height: 1px;
  background-color: #fff;
  transition: 0.5s;
}
.landing_page--menu-btn:hover::before {
  width: 90%;
}
.landing_page--menu-auth {
  display: flex;
  gap: 1rem;
}
.landing_page--menu-auth-btn {
  color: #8c52ff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.03rem;
  border-radius: 0.25rem;
}
.landing_page--menu-auth-btn.login {
  border: 2px solid #8c52ff;
}
.landing_page--menu-auth-btn.signup {
  color: #fff;
  background-color: #8c52ff;
}
.landing_page--menu-auth-btn.login:hover {
  color: #fff;
  background-color: #8c52ff;
}
.landing_page--menu-auth-btn.signup:hover {
  color: #fff;
  background-color: #7543db;
}

.landing__page--mobile-menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  height: 56px;
  background: linear-gradient(
    89.55deg,
    rgba(140, 82, 255, 0.65) -20.2%,
    rgba(140, 82, 255, 0.65) 107.05%
  );
  box-shadow: 0px 4px 8px rgba(140, 82, 255, 1);
}
.landing__page--mobile-menu-user {
  box-sizing: border-box;
}
.landing__page--mobile-menu-toggle {
  padding: 0;
}
.landing__page--mobile-menu-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #dfdfdf;
}
