:root {
    --color-primary: #4A4A4A;
    --color-primary-dark: #575757;
    --color-secondary: #B189FF;
    --color-secondary-dark: #8C52FF;
    --color-white: #FFFFFF;
}


.webinar__about { background: linear-gradient(90deg, #FEEDFF 0%, #FFFFFF 62.08%); }

.webinar__about h2, h4, p { margin: 0; }

.webinar__about--wrapper {
    width: 100%;
    min-height: 420px;
    max-width: 1700px;
    display: flex;
    gap: 30px;
    padding: 70px 60px;
    margin: 0 auto;
}

.webinar__about .about__video {
    width: 600px;
    height: 400px;
    display: flex;
    margin-right: auto;
}

.webinar__about .about__video .video__embedVideo {
    width: 550px;
    height: 350px;
    margin-top: 20px;
    border-radius: 8px;
    object-fit: cover;
}

.webinar__about .about__details,
.webinar__about .about__detailsContainer,
.webinar__about .about__keypoints {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 50%;
}

.webinar__about .about__detailsContainer .about__details { width: 100%; }

.webinar__about .about__details { margin-right: auto; }
.webinar__about .about__keypoints { width: 45%; }
.webinar__about .about__detailsContainer .about__keypoints { width: 90%; }

.webinar__about .about__details .about__header,
.webinar__about .about__keypoints .about__header {
    font-size: 36px;
    color: var(--color-primary);
    font-weight: 500;
    letter-spacing: 0.04em;
}

.webinar__about .about__details .details__description {
    width: 90%;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-primary);
}

.about__details .details__description .details__description--truncate {
    border: none;
    background-color: transparent;
    color: var(--color-secondary);
    font-size: 16px;
    font-weight: 500;
    margin-left: 4px;
} 

.webinar__about .about__keypoints .keypoints__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.webinar__about .keypoints__list .keypoints__list--keypoint {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 20px;
    background-color: #FFFFFF;
    border: 1.4px solid #888888;
    color: var(--color-primary-dark);
    border-radius: 30px;
    transition: 0.3s ease;
    cursor: pointer;
}

.webinar__about .keypoints__list .keypoints__list--keypoint:hover {
    transform: translateY(-2px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 414px) { 
    .webinar__about { background: var(--color-white); }
    .webinar__about--wrapper {
        min-height: auto;
        flex-direction: column-reverse;
        padding: 40px 20px;
        gap: 10px;
    }
    .webinar__about .about__details { gap: 10px; }
    .webinar__about .about__detailsContainer { width: 100%; }
    .webinar__about .about__details .about__header { font-size: 26px; }
    .webinar__about .about__video { width: 100%; height: auto; }
    .webinar__about .about__video .video__embedVideo {
        width: 100%;
        height: 260px;
    }
    .webinar__about .about__keypoints {
        width: 100%;
        padding: 0 20px 40px 20px;
    }
    .webinar__about .about__keypoints .about__header { 
        font-size: 26px;
        width: 100%;
    } 
    .webinar__about .about__keypoints .keypoints__list { gap: 6px; }
    .webinar__about .keypoints__list .keypoints__list--keypoint {
        font-size: 14px;
        border-radius: 4px;
        font-weight: 500;
        padding: 6px 16px;
    }
    .webinar__hostProfile--mobile {
        padding: 20px 20px;
        display: flex;
        align-items: center;
        gap: 18px;
        background-color: #FBF1FF;
        margin-bottom: 20px;
        border-radius: 6px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    }
    .webinar__hostProfile--mobile .hostProfile--avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
        background-position: center;
    }
    .webinar__hostProfile--mobile .hostProfile__details {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
    .webinar__hostProfile--mobile .hostProfile__details .hostProfile__details--name,
    .webinar__hostProfile--mobile .hostProfile__details .hostProfile__details--workingAt {
        font-size: 16px;
        color: #000000;
        font-weight: 600;
    }
    .webinar__hostProfile--mobile .hostProfile__details .hostProfile__details--workingAt { font-weight: 400; }
}
