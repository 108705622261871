.empty__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top:30px;
    padding-bottom:15px;
    border-radius: 5px;
    border: solid 1px #8C52FF;
}

.empty__para--text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #444444;
    margin: 30px 10px;
}