@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  background: #ffffff;
  overflow: visible !important;
}
/*///////////////////////////////////////////////////

                  colors and backgrounds

 /////////////////////////////////////////////////*/
.orange {
  color: #e18044;
}
.grey {
  color: #535461;
}
.green {
  color: #0f9d58
}
.purple {
  color: #8c52ff !important;
}
.purple-gradient-bg {
  background: linear-gradient(-45deg, #8c52ff, #0c041d);
}
.gb-white {
  background-color: #fff;
}
.bg-purple {
  background: #8c52ff;
}
/*///////////////////////////////////////////////////

                  buttons

 /////////////////////////////////////////////////*/
.btn:focus {
  box-shadow: none !important;
}
.white-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-radius: 4px;
  color: #8c52ff;
}
.purple-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #8c52ff;
  border-radius: 4px;
  color: #fff;
}
.purple-btn:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #8c52ff;
  color: #8c52ff;
}
.green-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #0f9d58;
  border-radius: 4px;
  color: #fff;
}
.white-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-radius: 4px;
  color: #DB4437;
}

.greybtn ,
.greybtn:hover{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #949494;
  border-radius: 4px;
  color: #fff;
}
.green-btn:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #0f9d58;
  color: #0f9d58;
}
.orange-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #e18044;
  border-radius: 4px;
  color: #fff;
}
.orange-btn:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e18044;
  color: #e18044;
}
.orange-outline-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e18044;
  color: #e18044;
}
.orange-outline-btn:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #e18044;
  border-radius: 4px;
  color: #fff;
}

.purple-outline-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  border: 1px solid #8c52ff;
  color: #8c52ff;
}
.purple-outline-btn:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #8c52ff;
  border-radius: 4px;
  color: #fff;
}
.grey-outline-btn {
  border: 1px solid  #C4C4C4;
  border-radius: 4px;
  width:95%;
  box-sizing:border-box;
}
/*///////////////////////////////////////////////////

 CSS For Create Account and Login

 /////////////////////////////////////////////////*/
.round-boarder {
  border-radius: 15px;
}
.login-box {
  margin: 0 auto;
  margin-top: 50px;
  width: 368px;
  height: 420px;
}
.btn-verify {
  width: 100%;
  background-color: #949494;
  color: white;
}
.btn-signin {
  background-color: #8c52ff;
  color: #fff;
}
label {
  color: #949494;
}
.form-label {
  margin-bottom: 0rem;
}
.btn-otp {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #8c52ff;
  text-decoration: underline;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.resend {
  font-size: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: #949494;
}
.resend-time {
  font-size: 16px;
  text-align: center;
  color: #949494;
}
.form-control {
  border: none;
  border-bottom: 2px solid #949494;
  border-radius: 0;
  box-shadow: none !important;
  outline: none !important;
}
.form-control-error {
  border: none;
  width: 100%;
  border-bottom: 2px solid #e60000;
  border-radius: 0;
  box-shadow: none !important;
  outline: none !important;
  display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control-otp {
  display: inline-block;
  width: 50%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom: 2px solid #949494;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control-otp-error{
  display: inline-block;
  width: 50%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom: 2px solid #e60000;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control-otp:focus {
  border: none;
  border-bottom: 2px solid #8c52ff;
  outline: none;
  transition-duration: 0.4s linear ease;
}
.input-group {
  margin-bottom: 56px;
}
.login-bg {
  background: linear-gradient(0deg, rgba(53, 53, 53, 1), rgba(0, 0, 0, 1));
  color: #fff;
  height: 100vh;
}
.orange-color {
  color: #fd7e31;
  font-family: Asap Condensed;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: none;
  border-bottom: 2px solid #8c52ff;
  background-color: #fff;
  border-radius: 0;
}
.input-group-text-error {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: none;
  border-bottom: 2px solid #e60000;
  background-color: #fff;
  border-radius: 0;
}
.form-control:focus {
  outline: none !important;
  border: none;
  border-bottom: 2px solid #8c52ff;
  border-radius: 0;
}
.send-otp {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #0f9d58;
  padding: 20px;
}
/*////////////////////////////////////

  courses page

 ////////////////////////////////////*/
.course {
  text-align: center;
  width: 100%;
  list-style-type: none;
  margin-bottom: 0px;
}
.course > li {
  padding: 5.5px 15px;
  display: inline-block;
}
.course > li > a {
  text-decoration: none;
  color: #fff;
}
.courseli::before {
  content: "\2022";
  color: #e18044;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/*////////////////////////////////////

 Course-Content

 ////////////////////////////////////*/
.course-content {
  width: 320px;
  border-radius: 15px;
  background-color: #fff;
  margin: 0 auto;
}

.video-frame {
  border-radius: 10px;
  padding: 20px;
}
/* accordian */
.course-sub-heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
}

.icon {
  width: 20px;
  display: flex;
  color: #666;
  float: right;
}
.icon .fa {
  font-size: 20px;
  transition: all 0.1s;
}
.icon .fa.up {
  transform: rotate(180deg);
}
.modal-line {
  width: 95%;
  height: 1px;
  background: black;
  margin: 0 auto;
}
.text-dec {
  text-decoration: none;
}
.error {
  color: red;
  font-size: 12px;
}
.modal-header{
  border-bottom: none;
}
.modal-open .modal{
  z-index: 4000;
}
.progress{
  height: 6px;
}
.progress-bar{
  background: #24aa42;
}
blockquote {
  background: #f3f3f3;
  border-left: 4px solid #ccc;
  padding: 5px;
  border-radius: 2px;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-font-serif {
  font-family: serif;
}
.ql-font-monospace {
  font-family: 'Courier New', Courier, monospace;
}
.justify-content-space-between {
  justify-content: space-between !important;
}
pre {
  text-wrap: wrap;
  line-break: anywhere;
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
  border-radius: 8px;
}