.modal--wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000057;
  backdrop-filter: blur(2px) brightness(80%);
}

.modal--wrapper h2,
.modal--wrapper p {
  margin: 0;
}

.custom__modal {
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 25px 30px;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal__header .header__info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.modal__header .header--heading {
  font-size: 20px;
  font-weight: 500;
  color: var(--text-black-med);
}

.modal__header .header--desc {
  font-size: 16px;
  font-weight: 400;
  color: #949494;
}

.modal__close--btn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #CFCFCF;
  outline: none;
  background-color: transparent;
  padding: 5px;
  border-radius: 50%;
}
.modal__close--btn img { transform: translateX(1px); }

.modal__input {
  width: 100%;
  display: flex;
  gap: 6px;
  align-items: center;
  border: 1px dashed #BDBDBD;
  padding: 4px 6px;
  border-radius: 4px;
  overflow: hidden;
}

.modal__input .input__prefix {
  padding: 6px 10px;
  background-color: rgba(196, 196, 196, 0.24);
  border-radius: 4px;
  cursor: pointer;
}

.modal__input .input__prefix span {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
}

.modal__input .input--url {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}

.input--url .input__custom-url {
  width: 100%;
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  padding: 4px;
}

.input--url .valid--icon{
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
}

.modal__footer { align-self: flex-end; }

.modal__footer button {
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  background-color: #8C52FF;
  border-radius: 4px;
  color: #ffffff;
  padding: 8px 22px;
  transition: 0.2s ease-in-out;
}

.modal__footer button:disabled { background-color: #c4c4c4; }

.modal__footer button:hover { background-color: #7133eb; }
.modal__footer button:disabled:hover { 
  background-color: #c4c4c4; 
  cursor: not-allowed;
}

@media only screen and (max-width: 414px) {
  .custom__modal { 
    width: 90%;
    padding: 10px; 
  }
  .modal__header .header--heading { font-size: 18px; }
  .modal__header .header--desc { font-size: 15px; }
  .modal__close--btn { 
    width: 25px;
    height: 25px;
  }
  .modal__close--btn img {transform: translateX(0px);}
  .modal__input {
    gap: 4px;
    padding: 4px;
  }
  .modal__input .input__prefix { padding: 6px; }
  .modal__input .input__prefix span,
  .input--url .input__custom-url { 
    font-size: 15px; 
  } 
  .input--url .valid--icon { right: 2%; }
  .modal__footer button { padding: 5px 22px; }
}
