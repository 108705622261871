.mobile__anki--view-container {
    width: 100%;
    padding: 20px;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
}

.mobile__anki--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.mobile__anki--card-container {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
}

.mobile__anki--image-Container {
    width: 100%;
}

.mobile__anki--card-container-image {
    width: 100%;
    height: 12.5em;
    border-radius: 8px 8px 0px 0px;
}

.mobile__anki--card-container-image--preview {
    width: 100%;
    height: 130px;
    border-radius: 8px 8px 0px 0px;
}

.mobile__anki--paragraph {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
    color: #000000;
    padding: 0.938em 1.25em;
    text-align: justify;
    padding-bottom: 0px;
}

.mobile__anki--hint-container {
    position: absolute;
    display: flex;
    width: 92%;
    bottom: 60px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.75em;
    line-height: 1.125em;
    color: #4EBD66;
    background: rgba(36, 170, 66, 0.34);
    border-radius: 8px;
    margin: 0px 10px;
    padding: 4px 6px;
}

.mobile__anki--hint-paragraph {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
    color: #0F8028;
}

.mobile__anki--btn-container {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.mobile__anki--btn-purple {
    width: 100%;
    border: none;
    background: #8C52FF;
    border-radius: 0px 0px 8px 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 2.25em;
    color: #FFFFFF;
}

.mobile__anki--btn-hard {
    width: 146px;
    height: 58px;
    border: none;
    left: 49px;
    top: 625px;
    background: rgba(255, 107, 74, 0.21);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2em;
    color: #F71C1C;
    margin-bottom: 10px;
}

.mobile__anki--btn-easy {
    width: 147px;
    height: 58px;
    border: none;
    left: 222px;
    top: 625px;
    background: rgba(36, 170, 66, 0.34);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2em;
    color: #0F8028;
    margin-bottom: 10px;
}
.anki__mobile--loading--screen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 100%;
    height: 100vh;
}
.anki__deck--name-onloading {
    display: flex;
    justify-content: center;
    width: 100%;
}
.btn-purple {
    background-color: #8C52FF;
    color: #FFFFFF;
    padding: 10px;
    border: none;
    border-radius: 5px;
    transition: .2s ease;
}
.btn-purple:hover {
    transform: translateY(-2px);
}
.note__watch--tutorials-link {
    color: #24AA42;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
}
.note__tutorial-video--btn-container {
    display: flex;
    justify-content: space-between;
}