.About__sideNavbar {
  display: block;
  height: 100vh;
  position: fixed;
  text-decoration: none;
  list-style-type: none;
}

.About__sideNavbar > li {
  padding: 10px;
}
.About__sideNavbar > li > a {
  color: black;
  text-decoration: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.03em;
}
.About__active {
  color: #8c52ff !important;
}
.aboutAndContact__heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
}
.contactAndAbout__text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.03em;
  color: #444444;
}
@media only screen and (max-width: 770px) {
  .About__sideNavbar {
    display: flex;
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
    background: #fff;
    position: static;
    text-decoration: none;
    list-style-type: none;
  }
}
