@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
:root {
  --text-light: #8c8c8c;
  --text-bold: #000000;
  --text-medium: #444444;
  --text-purple-dark: #8c52ff;
  --text-gray: #c4c4c4;
  --bg-purple: #8c52ff;
  --bg-purple-dark: #7b45e7;
  --bg-white: #ffffff;
  --bg-gray-light: #f9f9f9;
  --bg-blue: #0a66c2;
}

.background-form {
  min-height: 100vh;
  background-color: var(--bg-gray-light);
}
.form-container {
  background: var(--bg-white);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.Mentor__detail__form--sub-container{
  max-width: 860px;
}
.Mentor__detail--top-margin{
  margin-top: 114px !important;
}
.image-round {
  width: 120px;
  height: 120px;
  background: #e5e5e5;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 50%;
}
.mentor-form-box {
  padding: 60px 120px;
}
.mentor-form-box > h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #000000;
  text-align: center;
}
.mentor-form-box > p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.signUp-with-linkedin {
  background: var(--bg-blue);
  color: var(--bg-white);
  border-radius: 4px;
}
.form-label__v2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  align-items: center;
  letter-spacing: 0.03em;
  color: var(--text-bold);
}
.mentor--tagged-input .css-1rhbuit-multiValue {
  border-radius: 6px !important;
  padding: 2px 6px;
}

.upload-button {
  height: 36px;
  background: #d3d3d3;
  border-radius: 4px;
  margin: 10px;
}
.form-input {
  width: 100%;
  height: 42px;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 6px 0px;
  padding: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.03em;
}
.form-input::placeholder {
  color: #c4c4c4;
  font-size: 10px;
}

.form-input:focus {
  outline: 2px solid var(--bg-purple);
}
.form-textarea {
  width: 100%;
  height: 105px;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.03em;
}
.form-textarea:focus {
  outline: 2px solid var(--bg-purple);
}
.form-textarea::placeholder {
  font-size: 14px;
  color: #c4c4c4;
}
.btn-primary__v2 {
  width: 100%;
  height: 42px;
  background: var(--bg-purple);
  border-radius: 4px;
  color: var(--bg-white);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  transition: 0.2s ease;
}
.btn-grey__v2 {
  width: 100%;
  height: 42px;
  background: var(--text-light);
  border-radius: 4px;
  color: var(--bg-white);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  transition: 0.2s ease;
}
.btn-primary__v2:hover {
  color: var(--bg-white);
  transform: translateY(-4px);
  background-color: var(--bg-purple-dark);
}
.btn-primary__v2:active {
  transform: scaleX(0.98);
}
.company-name-container {
  position: absolute;
  width: 250px;
  height: auto;
  background-color: var(--bg-white);
  border-radius: 8px;
  padding: 10px;
  z-index: 10000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 42px;
  width: 100%;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
}
.tags-input:focus-within {
  border: 2px solid var(--bg-purple);
}
.tags-input > input {
  width: auto;
  height: 42px;
  float: left;
  border: none;
  font-size: 12px;
  padding: 4px 0 0 0;
}
.tags-input > input::placeholder {
  font-size: 14px;
  color: #c4c4c4;
}
input:focus {
  outline: transparent;
}
#tags {
  display: block;
  float: left;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  float: left;
  align-items: center;
  justify-content: center;
  color: #000;
  padding: 0 0px 0px 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #e6e6e6;
}
.tag-title {
  margin-top: 3px;
}
.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #0052cc;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}
.counter-label__v2{
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #8c8c8c;
  text-align: center;
}
input[type="file"]{
  visibility: hidden;
}
.image-warning-container{
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  text-transform: capitalize;
}
.image-heading-error{
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  letter-spacing: 0.03em;
}
.image-description-error{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #7b45e7;
}
@media only screen and (max-width: 747px) {
  .mentor-form-box {
    padding: 20px 20px;
  }
}
