.session-edit-card__container {
  display: block;
  padding: 20px;
  position: static;
  width: 100%;
  height: auto;
  left: 0px;
  top: 52px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
}
.session__edit__card__buttons {
  float: right;
}
.session__edit__card__buttons--button {
  border: none;
  background: #ffffff;
  padding: 10px;
}
.session__edit__card__buttons--button:hover {
  border: none;
  background: #e2e2e2;
  border-radius: 20px;
}
.session-edit-card--sessiontype {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  float: left;
  margin-top: 10px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #8c8c8c;
}
.session-edit-card {
  display: inline-block;
  width: 100%;
}
.sessionCardEdit__body {
  position: relative;
}
.sessionCardEdit__body--sessionName {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #444444;
}
.sessionCardEdit__body--price {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.03em;
  color: #24aa42;
}
.sessionCardEdit__body--off {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-decoration-line: line-through;
  color: #8c8c8c;
}
.sessionCardEdit__body--duration {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.sessionCardEdit__body--paragraph {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.sessionCard__deleteMessage{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  margin-bottom: 0.5rem;
  color: #000000;
}
.sessionCard__deleteMessage-sub-text{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8C8C8C; 
  margin-bottom: 32px;
}
.sessionCard__delete-btn{
  width: 212px;
height: 40px;
color: #ffffff;
background: #D4574C;;
border-radius: 4px;
float: right;
}
.sessionCard__cancel-btn{
  width: 212px;
height: 40px;
border: 1px solid #8C8C8C;
box-sizing: border-box;
border-radius: 4px;
float: left;
}
.custom-modal-style {
  position: absolute;
  max-width: 504px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  }
  @media only screen and (max-width: 506px){
    .sessionCard__delete-btn{
      width: 150px;
    }
    .sessionCard__cancel-btn{
      width: 150px;
    }
}