.confirmPopUp__container {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: #00000000;
  border-radius: 8px;
  z-index: 1000000000;
  backdrop-filter: blur(2px) brightness(90%);

}

.confirmPopup__main--container {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 0px;
  padding: 20px;
  animation: popup .2s linear forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
  }

  100% {
    height: 271px;
    opacity: 1;
  }
}

.confirmPopup__icon {
  width: 40px;
  height: 40px;
}

.green {
  color: #24AA42;
}

.red {
  color: #FC0303
}

.confirmPopup__bar--main-container {
  position: relative;
  width: 290px;
  height: 52px;
  background: rgba(90, 76, 117, 0.92);
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  z-index: 1;
  overflow: auto;
  cursor: pointer;

}

@keyframes loadbar {
  0% {
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.confirmPopup__bar--sub-container {
  transform: translateX(-100%);
  animation: loadbar 1s linear forwards;
  /*   -webkit-animation: loadbar 1s forwards; */
  opacity: 0;
  background-color: #8C52FF;
  border-radius: 8px;
}

.well-background--concept1 {
  background: #8C52FF;
}

.confirmPopup__btn-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.confirmPopup__gotIt-btn {
  width: 290px;
  height: 52px;
  background: #8C52FF;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  border: none;
}