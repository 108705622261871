* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.userProfile {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: var(--neutral-100);
    border-radius: 4px;
    padding: 12px 10px;
}

.userProfile .userProfile__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    background-color: var(--purple-100);
    color: var(--black);
}

.userProfile .userProfile__info { font-size: 14px; }

.userProfile__info .userProfile__username { 
    font-weight: 500;
    color: var(--black);
}

.userProfile__info .userProfile__email { 
    font-weight: 300;
    color: var(--neutral-200);
}