.quill-editor {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.quill-editor .ql-container {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #fff;
  flex: 1;
  overflow: hidden;
}

.quill-editor .ql-toolbar {
  background: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 4px 0;
}

.quill-editor .ql-formats {
  margin-right: 0 !important;
}

.quill-editor .ql-formats .ql-header,
.quill-editor .ql-formats .ql-font {
  font-size: 12px;
}

.quill-editor .ql-formats button {
  width: 24px;
}
