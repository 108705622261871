*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --white: #FFFFFF;
}

.payment__portal {
    display: flex;
    position: fixed;
    inset: 0;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px) brightness(60%);
    min-height: 100vh;
    z-index: 10000;
}

.payment__portal h3, h2, h4 {
    margin: 0;
}

.payment__dialog {
    background-color: var(--white);
    padding: 18px 24px;
    border-radius: 6px;
}

.payment__dialog.dialog__details {
    width: min(55%, 940px);
    height: min(60%, 620px);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.payment__dialog .dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialog__header .dialog__header--heading {
    font-size: 20px;
    font-weight: 500;
}

.dialog__header .dialog__header--action {
    background-color: #ECECEC;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.details__container {
    display: flex;
    gap: 30px;
    margin-top: 20px;
    margin-bottom: auto;
}

.details__container .container__image {
    min-width: 200px;
    max-width: 200px;
    height: 200px;
    position: relative;
}

.details__container .container__image .container__image--info {
    position: absolute;
    bottom: 8px;
    left: 8px;
    display: inline-block;
    background-color: white;
    padding: 8px 18px;
    font-size: 14px;
    border-radius: 100px;
    text-transform: capitalize;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}

.details__container .container__image img { width: 100%; height: 100%; object-fit: cover; border-radius: 6px; }

.details__container .entity__details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.entity__details .entity__details--primary,
.entity__details .entity__details--secondary {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.entity__details--primary .entity__details--name {
    display: flex;
    gap: 6px;
}

.entity__details--primary .entity__details--name h3 {
    font-size: 18px;
    color: #3A3939;
    font-weight: 500;
}

.entity__details--primary .entity__details--link { cursor: pointer; }

.entity__details--primary .entity__details--author .entity__details--type {
    font-size: 18px;
    font-weight: 600;
    color: #C058F0;
}

.entity__details--primary .entity__details--author .entity__details--type.type--webinar { color: #8C52FF; }

.entity__details--primary .entity__details--author .entity__details--author-name {
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-color: #C7C7C7;
    cursor: pointer;
}

.entity__details--secondary .entity__details--info {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    width: 90%;
}

.entity__details--secondary .entity__details--perks {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.entity__pill {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 2px 10px;
    text-transform: capitalize;
    align-self: flex-start;
    border-radius: 100px;
}

.entity__pill .entity__pill--text {
    font-size: 16px;
    font-weight: 400;
}

.entity__details--secondary .entity__details--price { margin-top: 12px; }

.entity__details--secondary .entity__details--price h4 {
    color: #3A3939;
    font-weight: 500;
    font-size: 18px;
}

.entity__details--secondary .entity__details--price .entity__details--price-original {
    font-size: 16px;
    margin: 0 4px;
}

.entity__details--secondary .entity__details--price .entity__details--price-original.striked { text-decoration: line-through; }

.entity__details--secondary .entity__details--price .entity__details--price-discounted {
    font-size: 20px;
    margin-left: 4px;
}

.payment__dialog .dialog__action {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 16px;
}

.payment__dialog .dialog__action button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    color: white;
    padding: 5px 12px;
    cursor: pointer;
    transition: 0.3s ease;
}

.payment__dialog .dialog__action button.dialog__action--cancel { background-color: #FF5050; }
.payment__dialog .dialog__action button.dialog__action--continue { background-color: #AE5EFF; }

.payment__dialog .dialog__action button:hover { transform: translateY(-2px); }

.payment__dialog.dialog__bill {
    width: min(30%, 940px);
    height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
}

.dialog__bill .bill__table {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.bill__table .table__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 4px;
}

.bill__table .table__row:not(:last-child) { border-bottom: 1px solid #E4E4E4; }

.bill__table .table__row .table__row--title,
.bill__table .table__row .table__row--value {
    font-size: 18px;
    font-weight: 500;
    color: black;
}

.bill__table .table__row .table__row--title.highlighted,
.bill__table .table__row .table__row--value.highlighted {
    color: #8C52FF;
    font-weight: 600;
}

.dialog__bill .dialog__action {
    justify-content: center;
}

/* ---------------- PAYMENT STATUS STYLES ----------------- */

.payment__dialog.dialog__bill.status-mode .payment__status {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: center;
}

.payment__status .status__action--close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.payment__status .status__info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.status__info .info__icon {
    justify-self: center;
    align-self: center;
    position: relative;
}

@keyframes rotate {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.status__info .info__icon .info__icon--wrapper {
    width: 70px;
    height: 70px;
    stroke-width: 1px !important;
    animation: rotate 5s linear infinite;
}

.status__info .info__icon .info__icon--wrapper path { stroke-width: 8; }

.info__icon--status-icon {
    position: absolute;
    top: 21%;
    left: 21%;
    width: 40px;
    height: 40px;
}

.status__info .info__heading {
    font-size: 24px;
    color: #353535;
    text-align: center;
}

.status__info .info__message {
    width: 90%;
    align-self: center;
    font-size: 16px;
    color: #444444;
    text-align: center;
    margin-bottom: 16px;
}

.payment__status .status__action {
    width: 80%;
    align-self: center;
    border: none;
    font-size: 16px;
    padding: 6px 0;
    border-radius: 4px;
    font-weight: 500;
    transition: 0.3s ease-in-out;
}

.payment__status .status__action:hover { box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); }

.payment__status .status__action.status__action--primary {
    background: transparent;
    border: 1px solid #8C52FF;
    color: #8C52FF;
    margin-bottom: 4px;
}

.payment__status .status__action.status__action--secondary {
    background: #8C52FF;
    color: #FFFFFF;
}

@media only screen and (max-width: 414px) {
    .payment__dialog.dialog__details {
        width: min(100%, 360px);
        height: auto;
        gap: 10px;
    }
    .dialog__header .dialog__header--heading { font-size: 16px; }
    .dialog__header .dialog__header--action { width: 25px; height: 25px; }
    .details__container { flex-direction: column; }
    .details__container .container__image {
        min-width: 100%;
        min-height: unset;
        height: 120px;
    }
    .entity__details--primary .entity__details--name h3 {
        font-size: 16px;
        font-weight: 600;
    }
    .entity__details--primary .entity__details--author,
    .entity__details--primary .entity__details--author .entity__details--type { font-size: 14px; }
    .entity__details--secondary .entity__details--info { font-size: 14px; width: 100%; }
    .entity__details--secondary .entity__details--perks { flex-direction: column; gap: 8px; }
    .entity__pill .entity__pill--text { font-size: 15px; }
    .entity__details--secondary .entity__details--price { margin-top: 14px; }
    .payment__dialog .dialog__action {
        justify-content: space-around;
        gap: 12px;
    }
    .payment__dialog .dialog__action button { flex-grow: 1; }
    .payment__dialog.dialog__bill {
        width: min(100%, 360px);
        height: 400px;
        gap: 20px;
    }
    .payment__dialog .dialog__header { margin-bottom: 15px; }
    .bill__table .table__row .table__row--title,
    .bill__table .table__row .table__row--value {
        font-size: 16px;
        font-weight: 400;
    }
    /* ---------------- PAYMENT STATUS STYLES ----------------- */
    .status__info .info__heading { font-size: 20px; }
    .status__info .info__message { font-size: 14px; width: 100%; margin-bottom: 10px; }
    .status__info .info__icon .info__icon--wrapper {
        width: 65px;
        height: 65px;
    }
    .info__icon--status-icon {
        width: 35px;
        height: 35px;
        top: 23%;
        left: 23%;
    }
}