.settings__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 645px;
  margin: 0 auto;
  padding: 0 10px;
}
.settings__heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
  margin-top: 96px;
}
.setting__options {
  display: block;
  text-decoration: none;
  list-style-type: none;
  padding: 0px;
  margin-top: 37px;
}
.setting__options--link {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  float: left;
  padding: 12px 16px;
  text-decoration: none;
  color: #000000;
  cursor: pointer;
}
.setting__options--link:hover {
  color: #8c52ff;
}
.setting__options--active {
  background: rgba(140, 82, 255, 0.15);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c52ff;
  border-radius: 4px;
}
.settings__divider {
  width: 100%;
  height: 1px;
  background: #c4c4c4;
  margin-top: 16px;
}
