.sideNav {
  display: block;
  height: 100vh;
  position: fixed;
  text-decoration: none;
  list-style-type: none;
}
.sideNav > li {
  padding: 10px;
}
.sideNav > li > a {
  color: black;
  text-decoration: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.03em;
}
.policy__active {
  color: #8c52ff !important;
}
.tearmandcondition--text{
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 160%;
letter-spacing: 0.03em;
color: #444444;
}
.tearmandcondition--sub-heading{
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 100%;
display: flex;
align-items: center;
letter-spacing: 0.03em;
color: #000000;
}
.tearmandcondition--heading{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
}
@media only screen and (max-width: 770px) {
  .sideNav {
    display: flex;
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
    background: #fff;
    position: static;
    text-decoration: none;
    list-style-type: none;
  }
}
