.toast__Message--success {
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 150000;
  background: #f9f9f9;
  color: #24AA42;
  border: 1px solid #24AA42;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 140%;
}
.toast__Message--warning {
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 150000;
  background: #f9f9f9;
  color: #ffae00;
  border: 1px solid #ffae00;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 140%;
}
.toast__Message--error {
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 150000;
  background: #f9f9f9;
  color: #dc3545;
  border: 1px solid #dc3545;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 140%;
}
