.resendOtp {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.resendOtp .resendOtp-action {
    border: none;
    border-bottom: 1px solid #8C52FF;
    background-color: transparent;
    color: #8C52FF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.15px;
}

.resendOtp .resendOtp-action:disabled { cursor: not-allowed; }

.resendOtp .resendOtp-message {
    color: #383838;
    font-size: 14px;
    letter-spacing: 0.15px;
}
