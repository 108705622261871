.manage-card__modal > .modal-dialog {
  max-width: 1200px !important;
}

.manage-card__header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.manage-card__select-card {
  width: 250px;
}
.manage-card__select-card .select__control {
  cursor: pointer;
  border-radius: 0;
  border-style: none;
  border-bottom: 2px solid #8c8c8c;
  box-shadow: none;
}
.manage-card__select-card .select__value-container {
  padding: 0;
}
.manage-card__select-card .select__single-value,
.manage-card__select-card .select__input-container,
.manage-card__select-card .select__indicator {
  color: #8c8c8c;
}
.manage-card__select-card .select__indicator:hover {
  color: #8c52ff;
}

.manage-card__body {
  padding: 1rem 2rem;
  display: flex;
}

.manage-card__col {
  flex: 1;
  padding: 0 1rem;
}
.manage-card__separator {
  width: 2px;
  background: #eee;
  margin: 0 0.5rem;
}

.manage-card__title {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.manage-card__text {
  color: #757575;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.manage-card__col-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.manage-card__col-header button {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0.5rem 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  font-size: 14px;
}

.manage-card__preview-wrapper {
  margin: auto;
  background: #fff;
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #cdcdcd;
  border-radius: 6px;
  overflow: hidden;
}

.manage-card__editor-wrapper {
  margin: auto;
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.manage-card__input-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.manage-card__option-container {
  margin-top: 2rem;
  max-height: 432px;
  overflow-y: auto;
}
.manage-card__option-container::-webkit-scrollbar {
  width: 0.25rem;
  background: #f2ebff;
}
.manage-card__option-container::-webkit-scrollbar-thumb {
  border-radius: 0.2rem;
  background: #cfbcff;
}

.manage-card__btn-grp {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
.manage-card__btn-grp button {
  height: 40px;
  width: 150px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.manage-card__btn-secondary {
  background: #d9d9d9;
  color: #000;
}
.manage-card__btn-secondary:hover {
  background: #dfdfdf;
}
.manage-card__btn-secondary:disabled {
  cursor: auto;
  background: #dfdfdf;
  color: #666;
}
.manage-card__btn-primary {
  background: #8c52ff;
  color: #fff;
}
.manage-card__btn-primary:hover {
  background: #763de9;
}
.manage-card__btn-primary:disabled {
  cursor: auto;
  background: #828282;
  color: #ddd;
}

@media screen and (max-width: 640px) {
  .manage-card__select-card {
    width: 150px;
  }
  .manage-card__body {
    padding: 1rem 0;
    flex-direction: column;
    gap: 1rem;
  }
  .manage-card__separator {
    display: none;
  }
  .manage-card__btn-grp {
    justify-content: space-between;
    padding: 1rem;
  }
  .manage-card__btn-grp button {
    width: 50%;
  }
}
.warning-div {
  color: red;
  position: absolute;
  bottom: 10% !important;
}
