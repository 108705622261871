:root {
    --text-light: #8C8C8C;
    --text-white: rgba(255, 255, 255, 0.85);
    --text-bold: #000000;
    --text-medium: #444444;
    --text-purple-dark: #8C52FF;
    --text-gray: #C4C4C4;
    --bg-purple-light: #8C52FF26;
    --bg-purple-bright: #8C52FF;
    --bg-purple-dark: #7B45E7;
    --bg-white: #ffffff;
    --bg-gray-light: #F3F3F3;
}

/* --------- CONFIRMATION STYLES */
.confirmation {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    min-height: 100vh;
    background-color: #f9f9f9;
    position: relative;
    z-index: 2;
}


.confirmation__box {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 60px 40px;
    background-color: var(--bg-white);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.confirmation__head {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
    letter-spacing: 0.04em;
}

.confirmation__head--title {
    margin: 0;
    font-size: 28px;
    font-weight: 500;
    color: var(--text-bold);
}

.confirmation__head--description {
    width: 80%;
    margin: 0;
    font-size: 16px;
    color: var(--text-medium);
}

.confirmation__head--message {
    display: block;
    margin: 8px 0;
    font-weight: 500;
    color: var(--bg-purple-dark);
}

.confirmation__links {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.confirmation__link {
    text-decoration: none;
    text-align: center;
    display: block;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid var(--text-purple-dark);
    border-radius: 2px;
    padding: 8px 26px;
    color: var(--text-purple-dark);
    transition: 0.2s ease;
    backface-visibility: hidden;
}

.confirmation__link:nth-child(even) {
    background: var(--bg-purple-bright);
    border: none;
    color: var(--text-white);
}

.confirmation__link:hover {
    transform: scale(0.98);
    background-color: var(--text-purple-dark);
    color: rgba(255, 255, 255, 0.85);
}

@media only screen and (max-width: 414px) {
    .confirmation__box {
        width: 100%;
        padding: 30px 20px;
        background-color: transparent;
        box-shadow: unset;
    }
    .confirmation__head { text-align: left; }
    .confirmation__links { 
        flex-direction: column;
        gap: 10px;
    }
    .confirmation__head--title { 
        text-align: center;
        font-size: 24px; 
    }
    .confirmation__head--description { 
        width: 100%;
        font-size: 14px; 
        text-align: center;
    }
}

/* ------------ LOADER STYLING */
.payment--spinner {
    display: inline-block;
    margin: 0 12px;
    transform: translateY(-4px);
    cursor: progress;
}

@media only screen and (max-width: 414px) {
    .payment--spinner { 
        display: block;
        margin: 0 auto;
    }
}

/* ----------- SESSION STYLES */
.session__container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.session {
    display: grid;
    grid-template-columns: 340px 480px;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    grid-auto-columns: 240px;
    gap: 6px;
    max-width: 1100px;
    height: 100%;
    background: var(--bg-white);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    position: relative;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.03em;
}

.session.session__form--active {
    width: 100%;
}

.back__button {
    position: absolute;
    width: 40px;
    top: 10px;
    left: 14px;
    transition: 0.2s ease;
}

.back__button:hover { transform: translateX(-6px); }

/* ------- SESSION BOOKING */   

.session__booking {
    width: 100%;
    padding: 60px 30px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: flex-start;
    border-right: 1px solid #E5E5E5;
}

.session__booking--details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;
}

.session__booking--details.form__active {
    gap: 10px;
}

.session__booking--details.form__active .session__info {
    gap: 6px;
}

.session__head--wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.session__text {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-light);
}

.session__type {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-medium);
}

.session__heading {
    font-size: 20px;
    font-weight: 500;
    color: var(--text-bold);
    word-wrap: break-word;
}

.session__info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
}

.session__price {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 20px;
}

.session__cost {
    font-weight: 500;
    color: var(--text-bold);
}

.session__cost--markthrough {
    font-weight: 500;
    color: var(--text-light);
    text-decoration: line-through;
}

.session__duration {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-light);
    padding: 2px 12px;
}

.session__duration img {
    transform: translate(-10px, -2px);
    cursor: pointer;
}

.session__description p{
    font-size: 14px;
    font-weight: 400;
    color: var(--text-medium);
    word-wrap: break-word;
}

/* TO REMOVE MARGIN COMING FROM REBOOT.SCSS */
.session__text,
.session__heading,
.session__duration,
.session__description p,
.form--schedule,
.session__head--wrapper .session__type {
    margin: 0;
}

/* ----------- SESSION CALENDAR */

.session__calendar {
    width: 100%;
    padding: 60px 15px 20px 15px;
}

.calendar__heading {
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    color: var(--text-bold);
}

.react-calendar {
    border: none !important;
    width: 100% !important;
    max-width: 480px !important;
    height: 100%;
    font-family: inherit !important;
    margin-bottom: 26px;
}

.react-calendar__navigation {
    display: block !important;
    margin-bottom: 20px;
    position: relative;
}

.react-calendar__navigation__label {
    font-size: 16px;
    color: #3a3a3a;
    transform: translate(2px, 20px);
    pointer-events: none;
}

.react-calendar__navigation__arrow {
    position: absolute;
    background: transparent !important;
    color: var(--text-light);
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    display: none !important;
}

.react-calendar__navigation__prev-button {
    top: 18px;
    right: 42px;
    font-size: 34px;
    cursor: pointer;
}
  
.react-calendar__navigation__next-button {
    top: 18px;
    right: 0px;
    font-size: 34px;
    cursor: pointer;
}

 /* ----------- TO CONFIGURE WEEKDAYS FROM CALENDAR */

.react-calendar__month-view__weekdays {
    font-size: 16px !important;
    column-gap: 10px;
    color: var(--text-bold) !important;
    margin-bottom: 8px;
    cursor: pointer;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
    font-weight: 500 !important;
}

.react-calendar__month-view__days {
    row-gap: 10px;
    column-gap: 20px;
}

.react-calendar__tile {
    flex-basis: 10.2% !important;
    background: transparent !important;
    font-size: 18px;
    font-weight: 500;
    color: var(--text-gray) !important;
    position: relative;
}

.react-calendar__tile--now::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    bottom: 6px;
    left: 50%;
    background-color: var(--bg-purple-bright);
    transform: translateX(-50%);
}

.react-calendar__tile:enabled {
    font-size: inherit;
    color: var(--text-purple-dark) !important;
    font-weight: 600 !important;
    background: var(--bg-purple-light) !important;
    border-radius: 50%;
    transition: .2s linear;
}

.react-calendar__tile:enabled:hover {
    background: #D8BFD8 !important;
    color: var(--bg-white) !important;
}

.react-calendar__tile.react-calendar__tile--active {
    background: var(--bg-purple-bright) !important;
    color: rgba(255, 255, 255, 0.85) !important;
    box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.14);
    pointer-events: none;
}

/* -------------- SESSION FORM */
.session__form {
    width: 100%;
    height: 100%;
    padding: 60px 15px;
    position: relative;
}

.form--closebtn {
    position: absolute;
    top: 15px;
    right: 24px;
    transition: .2s ease;
}

.form--closebtn:hover { transform: scale(1.2); }

.form--header {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: var(--text-bold);
    margin-bottom: 30px;
}

.form__container {
    display: block;
    width: 100%;
    margin: 0;
}

.form__field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 22px;
}

.form__field--group {
    width: 90%;
}

.form__field--label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-bold);
    margin-bottom: 14px;
}

.form__field--textarea {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-medium);
    padding:  8px;
    line-height: 20px;
    resize: none;
    outline: none;
    border: 1.5px solid #D3D3D3;
    border-radius: 4px;
}

.form__tagged--input {    
    display: flex;
    align-items: center;
    padding: 2px 6px;
    border: 1.5px solid #D3D3D3;
    border-radius: 4px;
    overflow-x: hidden;
}

.form__tagged--input.wrapNeeded { flex-wrap: wrap; }

.form__tagged--input .form__field--input {
    display: inline;
    border: none;
    padding: 0;
    margin-left: 6px;
}

.tagged__input--filled {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: var(--text-medium);
    font-weight: 500;
    background-color: #E5E5E5;
    border-radius: 100px;
    padding: 6px 14px;
    margin: 4px;
}

.tagged__input--icon {
    border: none;
    outline: none;
    background-color: transparent;
}

.tagged__input--icon img { transform: translateY(-1px); }

.form__field--input {
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-medium);
    border: 1.5px solid #D3D3D3;
    background-color: transparent;
    border-radius: 4px;
    outline: none;
}

.form--submit {
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    padding: 8px 34px;
    color: var(--bg-gray-light);
    background: var(--bg-purple-bright);
    transition: 0.2s ease;
}

.form--submit:hover {
    color: var(--bg-gray-light);
    background: var(--bg-purple-dark);
} 

/* TO HANDLE CONDITIONALLY RENDERING DATA  */
.form__data {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
}

.form--schedule {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-bold);
}

.form--schedule img {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 8px;
}

.form__data--dropdown .btn {
    padding: 0.375rem 0;
    color: var(--text-bold);
    font-weight: 500;
}

/* DROPDOWN COMPONENT FROM CALENDAR SECTION */
.dropdown__container {
    font-family: inherit;
    color: var(--text-bold);
}

.dropdown img:first-of-type {
    margin-right: 10px;
    transform: translateY(-1px);
}

.dropdown img:last-of-type {
    transform: translateY(-1px);
    margin-left: 10px;
}

/* SESSION SLOTS */
.session__slots {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 20px 10px 20px 0;
    position: relative;
    overflow: hidden;
}

.close__button {
    position: absolute;
    top: 12px;
    right: 22px;
    border: none;
    outline: none;
    background: transparent;
    backface-visibility: hidden;
    transition: .2s ease;
    z-index: 8;
}

.close__button:hover { transform: scale(1.2); }

.slots__container {
    margin-top: 85px;
    height: 100%;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.slots__container::-webkit-scrollbar { display: none; }

.session__date {
    display: block;
    font-size: 16px;
    color: var(--text-bold);
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
}

.slot {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}

.slots {
    height:50vh;
    overflow-y:auto;
    overflow-x:hidden;
}

.slots::-webkit-scrollbar { display: none; }

.slots--btn.disabled-btn {
    cursor: not-allowed;
}

.disabled-btn , 
.disabled-btn:hover{
    background: #F3F3F3;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #C4C4C4;
}

/* ------------ STYLING FOR MOBILE */

.hidden {
    opacity: 0;
    display: none;
}

.visible {
    opacity: 1;
    display: block;
}

.session__container--mobile {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.04em;
}

.session__header {
    display: block;
    width: 100%;
    height: 50px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}

.session__header .back__button {
    top: 4px;
    left: 10px;
}

.session__booking.session__booking--mobile {
    padding: 20px 24px 30px 24px;
    border-right: none;
    border-bottom: 2px solid #c4c4c4;
}

.form__data.form__data--mobile { padding: 10px 0; }

.session__calendar.session__calendar--mobile { padding: 35px 24px 60px 24px; }

.session__slots.session__slots--mobile { padding: 20px; }

.session__slots--mobile .slots__information {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.slots__information .slots__heading {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-bold);
}

.slots__container.slots__container--mobile {
    margin-top: 35px;
    width: 100%;
    height: auto;
    max-height: unset;
    overflow: unset;
}

.session__form.session__form--mobile { padding: 40px 25px; }

@media only screen and (max-width: 414px) {

    /* ---------- SESSION BOOKING */
    .session__text {
        font-size: 14px;
        margin: 0 0 8px 0;
    }

    .session__heading {
        font-size: 18px;
        margin: 12px 0 6px 0;
    }

    .session__price {
        margin: 4px 0 16px 0;
        gap: 16px;
    }

    .session__duration {
        padding: 2px 8px;
        margin-bottom: 6px;
    }

    .session__description p{
        max-width: 35ch;
        word-wrap: break-word;
    }

    .form--schedule {
        max-width: 70%;
        line-height: 21px;
        word-wrap: break-word;
        margin: 4px 0;
    }

    .form__data--dropdown .btn{ font-size: 14px; }

    /* ------ SESSION CALENDAR MOBILE */

    .calendar__heading {
        font-size: 18px;
        margin-bottom: 18px;
    }

    .react-calendar {
        width: 100% !important;
        margin-bottom: 20px;
        max-width: 350px !important;
    }

    .react-calendar__navigation__prev-button {
        right: 24px;
        font-size: 40px;
    }

    .react-calendar__navigation__next-button {
        right: -10px;
        font-size: 40px;
    }

    .react-calendar__month-view__weekdays {
        font-size: 14px !important;
        column-gap: 0px;
        margin-bottom: 12px;
        font-weight: normal !important;
    }

    .react-calendar__month-view__days {
        column-gap: 3.17%;
    }

    .react-calendar__tile {
        padding: 10px 0 !important;
        flex-basis: 11.11% !important;
        max-width: 11.11% !important;
        font-size: 14px !important;
    }

    /* ----- SLOTS MOBILE */

    .dropdown__container--mobile .dropdown-toggle,
    .slots__information--time .dropdown-toggle {
        font-size: 14px;
        padding: 0.375rem 0.1rem;
        letter-spacing: 0.04em;
    }

    .session__date {
        text-align: unset;
        color: #333333;
        margin-bottom: 8px;
    }

    .slots.slots--mobile {
        height: 100%;
        overflow: unset;
    }

    /* --------- SESSION FORM  */

    .form__field { align-items: stretch; }

    .form--header {
        font-size: 16px;
        margin-bottom: 35px;
    }

    .form__field--label { font-size: 14px; }

    .form__field--input {
        width: 100%;
        font-size: 12px;
        letter-spacing: 0.04em;
        border-width: 1px;
    }
    
    .form__field--textarea {
        font-size: 12px;
        border-width: 1px;
    }

    .form__tagged--input { padding: 2px 4px; }
    .form__tagged--input .form__field--input { padding: 6px 2px; }
}