.become-a-mentor__container {
  max-width: 1298px;
  margin: auto;
  padding: 2rem 1rem;
}
.become-a-mentor__heading {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 0.84px;
  text-transform: uppercase;
}
.become-a-mentor__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}
.become-a-mentor__mentor-image {
  height: 250px;
  width: 250px;
  border-radius: 50%;
}
.become-a-mentor__contents {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  justify-content: space-around;
}
.become-a-mentor__primary-text {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 1.2px;
  margin: 1rem 1rem 0;
}
.become-a-mentor__secondary-text {
  color: #444;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.6px;
  margin: 1rem;
}
.become-a-mentor__btn {
  border-radius: 4px;
  background: #8c52ff;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.6px;
  margin: 1rem;
  padding: 0.5rem 2rem;
  width: max-content;
}
.become-a-mentor__btn:hover {
  color: #fff;
  background: #7543db;
}

@media only screen and (max-width: 640px) {
  .become-a-mentor__heading {
    font-size: 20px;
    letter-spacing: 0.4px;
  }
  .become-a-mentor__wrapper {
    flex-direction: column;
    gap: 2rem;
  }
  .become-a-mentor__contents {
    width: 100%;
    align-items: center;
  }
  .become-a-mentor__contents div {
    text-align: center;
  }
}
