.create-note__modal > .modal-dialog {
  max-width: 900px !important;
}
.create-note__wrapper {
  padding: 10px 60px;
  display: flex;
  justify-content: space-around;
  gap: 60px;
  color: #757575;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}
.create-note__col-preview {
  display: flex;
  flex-direction: column;
  width: 35%;
}
.create-note__col-preview > .preview-title {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.create-note__notecard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.create-note__col-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.create-note__label {
  color: #403f40;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.create-note__label.disabled {
  color: #757575;
}
.create-note__field {
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.field-multiselect .field-multiselect__control:hover {
  border: 1px solid #8c52ff;
}
.field-multiselect .field-multiselect__control:focus {
  outline: none;
}
.field-multiselect .field-multiselect__value-container {
  padding: 0;
}
.field-multiselect .field-multiselect__placeholder {
  color: #757575;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
.field-multiselect .field-multiselect__control,
.create-note__field > input,
.create-note__field > textarea {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  height: 36px;
  min-height: 36px;
  width: 100%;
  min-width: 0;
  padding: 4px 10px;
  color: #000000d9;
  font-size: 12px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  cursor: text;
}
.field-multiselect .field-multiselect__control {
  display: flex;
  box-shadow: none;
  align-items: center;
  padding: 0 10px;
  height: fit-content;
}
.field-multiselect .field-multiselect__indicator {
  cursor: pointer;
}
.field-multiselect.disable input {
  display: none;
}
.create-note__field > input:hover,
.create-note__field > textarea:hover {
  border: 1px solid #8c52ff;
}
.create-note__field > input:disabled,
.create-note__field > textarea:disabled {
  background: #dddddd;
  border: 1px solid #c1c1c1;
}
.create-note__field > textarea {
  height: 100px;
}
.create-note__field > textarea:focus {
  outline: none;
}
.field-checkbox {
  flex-direction: row;
  align-items: center;
}
.field-checkbox > input {
  height: 15px;
  width: 15px;
  display: flex;
  accent-color: #8c52ff;
  cursor: pointer;
}

.create-note__field.public-fields {
  border-radius: 4px;
  background: #ece8f5;
  margin-left: 20px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.public-fields > .field-checkbox {
  display: flex;
  gap: 5px;
  align-items: center;
}
.public-fields .field-checkbox {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.public-fields > .field-price {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-end;
}
.public-fields > .field-price input {
  height: 30px;
}

.create-note__footer {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px 10px;
}
.create-note__footer > p {
  color: #d4574c;
  width: 45%;
}
.create-note__footer-btns {
  display: flex;
  align-items: center;
  gap: 10px;
}
.create-note__btn-primary {
  height: 35px;
  background: #8c52ff;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-note__btn-secondary {
  height: 35px;
  background: rgba(140, 82, 255, 0.26);
  border-radius: 4px;
  font-weight: 400;
  color: #8c52ff;
}
.create-note__btn-upload {
  height: 35px;
  border-radius: 4px;
  background: rgba(15, 128, 40, 0.12);
  color: #24aa42;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-note__upload-image {
  display: flex;
  align-items: center;
  gap: 20px;
}
.error {
  color: #d4574c;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  animation: shake 0.2s ease-in-out 0s 2;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.crop-image__modal > .modal-dialog {
  max-width: 800px !important;
}
.crop-image__editor {
  width: 80%;
}
.crop-image__preview-container {
  display: flex;
  gap: 8%;
}
.crop-image__preview-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25) inset;
  padding: 10px;
  flex: 1;
}
.crop-image__label {
  color: red;
  text-align: right;
  font-size: 14px;
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .create-note__wrapper {
    flex-direction: column-reverse;
    padding: 10px;
  }
  .create-note__col-preview {
    width: 100%;
  }
  .create-note__footer {
    flex-direction: column;
    margin: 10px;
  }
  .create-note__footer > p {
    width: 100%;
    margin-bottom: 20px;
  }
  .create-note__field.public-fields {
    margin-left: 0;
  }
}
