* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.authWrapper h1 { margin: 0; }

.authWrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.authWrapper .authContainer {
    width: 560px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 80px;
}

.authContainer .brandName {
    font-size: 24px;
    font-weight: 600;
    color: #FD8E4A;
    margin-bottom: 80px;
}

.authContainer .brandName .brandName--highlighted { color: #8C52FF; }

.authContainer .authForm {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.authContainer .authForm .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
}

.authForm .header .header__title {
    font-size: 30px;
    font-weight: 600;
    color: #383838;
    letter-spacing: 0.3px;
}

.authForm .header .header__message {
    font-size: 14px;
    font-weight: 400;
    color: #565656;
    letter-spacing: 0.14px;
}

.authForm .authAlternatives { align-self: center; }

.authForm .authAgreement {
    display: flex;
    gap: 6px;
    align-items: start;
    margin-top: 25px;
}

.authForm .authAgreement .authAgreement-checkbox {
    margin-top: 2px;
}

.authForm .authAgreement .authAgreement-message {
    font-size: 12px;
    color: #383838;
    font-weight: 400;
    letter-spacing: 0.12px;
}

.authAgreement .authAgreement-message .authAgreement-link {
    color: #353A47;
    font-weight: 500;
}

.authAlternatives .authAlternatives-message {
    font-size: 12px;
    font-weight: 400;
    color: #353A47;
}

.authAlternatives .authAlternatives-message .authAlternatives-link {
    text-decoration: underline;
    color: #8C52FF;
    font-weight: 500;
    margin-left: 6px;
}

.authWrapper .authImage { flex: 1; }

.authWrapper .authImage img {
    width: 100%;
    height: 100%;
    background-size: cover;
    object-fit: cover;
}

@media only screen and (max-width: 414px) {
    .authWrapper .authContainer { padding: 30px; }
    .authContainer .brandName {
        font-size: 20px;
        margin-top: 10px;
    }
    .authContainer .authForm .header { gap: 4px; }
    .authForm .header .header__title {
        font-size: 22px;
        font-weight: 500;
    }
    .authForm .header .header__message { font-size: 12px; }
    .authWrapper .authImage {
        display: none;
    }
}
