.separator {
    display: flex;
    width: 100%;
    gap: 14px;
    align-items: center;
    padding: 12px;
}

.separator .separator-line {
    width: 100%;
    height: 0.8px;
    background-color: #ACABAB;
}

.separator .separator-text {
    font-size: 12px;
    font-weight: 500;
    color: #383838;
    letter-spacing: 0.15px;
}