*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.attendeeList--wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    overflow-y: scroll;
    backdrop-filter: blur(2px) brightness(40%); 
    scrollbar-width: none;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.03em;
}

.attendeeList--wrapper h2,
.attendeeList--wrapper span {
    margin: 0;
}

.attendeeList--wrapper::-webkit-scrollbar { display: none; }

.attendeeList__container {
    width: 900px;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 120px 0 60px 0;
    background-color: #FFFFFF;
    padding: 36px;
    border-radius: 8px;
}

.attendeeList__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.attendeeList__header .attendeeList__header--wrapper {
    display: flex;
    align-items: flex-start;
    width: 80%;
}

.attendeeList__header--heading {
    font-size: 28px;
    font-weight: 500;
    color: #8C52FF;
    word-break: break-all;
}

.attendeeList__header--preview {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 6px;
    border-radius: 8px;
    margin-left: 15px;
    transition: 0.2s ease;
}

.attendeeList__header--preview:hover { box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }

.attendeeList__header--close { cursor: pointer; }

.attendeeList__details {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.attendeeList__info {
    display: flex;
    gap: 10px;
    align-items: center;
}

.attendeeList__info .attendeeList__info--icon {
    width: 30px;
    height: 30px;
    background: rgba(151, 151, 151, 0.21);
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}

.attendeeList__info .attendeeList__info--text {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    color: #403F40;
}

.publishWebinar__warning {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
}

.publishWebinar__warning .warning__messages {
    text-decoration: none;
    list-style-type: none;
}

.publishWebinar__warning .warning__messages .warning__message {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
}

.publishWebinar__warning .warning__action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.publishWebinar__warning .warning__action button {
    display: inline-block;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    padding: 4px 22px;
    color: #FFFFFF;
    transition: 0.3s ease;
}

.publishWebinar__warning .warning__action button:hover { transform: translateY(-3px); }

.warning__action .warning__action--cancel { background-color: #F61C1C; }
.warning__action .warning__action--publish { background-color: #8C52FF; }

.attendeeList__publishWebinar {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    border: 1.2px dashed #353535;
    padding: 20px;
    border-radius: 4px;
}

.publishWebinar__publish {
    border: none;
    background-color: #8C52FF;
    color: #FFFFFF;
    border-radius: 4px;
    font-size: 18px;
    padding: 4px 20px;
}

.attendeeList__publishWebinar.published { border-color: #8C52FF;}

.publishWebinar__title {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    color: #403F40;
}

.publishWebinar__title.published { color: #AD84FF; }

.attendeeList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.attendeeList--blank { text-align: center; }
.attendeeList--blank h5 { color: #403F40; }

.attendeeList .attendeeList__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.attendeeList .attendeeList__search {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    padding: 8px;
    background-color: #EFEFEF;
    border-radius: 100px;
}

.attendeeList .attendeeList__search .attendeeList__search--input {
    border: none;
    transform: translateX(120px);
    opacity: 0;
    display: none;
    background-color: transparent;
    width: 200px;
    border-radius: 100px;
    padding-left: 10px;
    transition: 0.3s ease-in;
    font-size: 14px;
}

.attendeeList .attendeeList__search .attendeeList__search--input.active {
    transform: translateX(0);
    opacity: 1;
    display: inline-block;
}

.attendeeList .attendeeList__search .attendeeList__search--button { cursor: pointer; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.attendeeList__head .attendeeList__head--text {
    font-size: 18px;
    font-weight: 500;
    color: #403F40;
}

.attendeeList__list {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
}

.attendeeList__list .attendeeList__attendee {
    background-color: #EFEFEF;
    padding: 14px;
    display: grid;
    grid-template-columns: repeat(2, 1fr) auto;
    align-items: center;
    border-radius: 2px;
}

.attendeeList__attendee .attendee__profile {
    display: flex;
    gap: 8px;
    align-items: center;
}

.attendee__profile .attendee__profile--icon {
    width: 30px;
    height: 30px;
    background-color: #97979736;
    padding: 4px;
    border-radius: 50%;
}

.attendee__profile .attendee__profile--name,
.attendee__email {
    font-size: 18px;
    font-weight: 500;
    color: #403F40;
}

.attendeeList__list .attendeeList__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.attendeeList__navigation--button {
    border: 2px solid #8C52FF;
    background: transparent;
    color: #8C52FF;
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px;
    padding: 4px 10px;
    margin-top: 8px;
}

.attendeeList__navigation--button:disabled {
    border-color: #8C8C8C;
    color: #8C8C8C;
    cursor: not-allowed;
}