.demo-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  letter-spacing: 0.03em;
  color: #000000 !important;
}
.demo-input-field {
  width: 100%;
  height: 42px;
  left: 0px;
  top: 28px;
  padding: 0 6px;
  letter-spacing: 0.04em;
  font-family: 'Poppins', sans-serif;

  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 4px;
}
.requestDemo-btn {
  width: 270px;
  height: 40px;
  left: 540px;
  top: 660px;
  border-radius: 4px;
  background: #9281fc;
}
.demo-textarea-field {
  width: 100%;
  height: 75px;
  left: 0px;
  top: 28px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 4px;
  letter-spacing: 0.04em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}