@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

.mentor-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.03em;
}
.mentor-profile-box {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.mentor-proile-pic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 0.5px solid #8c8c8c;
  box-sizing: border-box;
  margin: 0 auto;
}
.icon-box {
  display: block;
  margin: 0 auto;
  text-align: center;
  margin: 10px 0px;
}
.icon-img {
  width: 24px;
  height: 24px;
  margin: 0 10px;
}
.skills-box {
  display: block;
  width: 200px;
  margin-top: 20px;
  margin-left: 50px;
}
.paragraph {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0px;
  letter-spacing: 0.03em;
  color: #444444;
}
.paragraph__semi-bold{
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
letter-spacing: 0.03em;
color: #444444;
}
.button-box {
  display: block;
  height: auto;
  margin-left: 40px;
  padding-right: 20px;
}
.skills-button {
  padding: 8px 16px;
  background: #e5e5e5;
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #444444;
  float: left;
  margin: 3px;
}
.icon-company-box {
  display: block;
  margin-left: 45px;
}
.icon-company-img {
  height: 24px;
  margin: 0 5px;
  aspect-ratio: 1 / 1;
}
.icon-company-img-rect{
  height: 24px;
  width: auto;
  margin: 0 5px;
}
.booking-card {
  width: 310px;
  height: auto;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: (1px 1px 10px rgba(0, 0, 0, 0.62));
  border-radius: 8px;
  padding: 20px;
}
.one-on-one {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
}
.price {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */
  letter-spacing: 0.03em;
  color: #24aa42;
}
.off {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */
  letter-spacing: 0.03em;
  text-decoration-line: line-through;
  color: #8c8c8c;
}
.duration {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.annuncement-card {
  width: 100%;
  max-width: 300px;
  height: auto;
  left: 1062px;
  top: 523px;
  background: rgba(36, 170, 66, 0.34);
  border-radius: 8px;
  padding: 20px;
  margin-left: 10px;
}
.announcement-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #0f8028;
}
.announcement-box {
  margin-top: -35px;
  margin-left: -20px;
}
.demo-book {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  align-items: center;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.spacing {
  margin-top: 55px;
}
.slider {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.image-slider-container {
  display: inline-block;
  width: 318px;
  height: 203px;
  margin-right: 10px;
}
.slider::-webkit-scrollbar {
  display: none !important;
}
.hide-on-big-screen {
  display: none;
}
.scroll-card {
  position: static;
  height: auto;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scroll-card::-webkit-scrollbar {
  display: none;
}
.scroll-card-see-more {
  position: static;
  height: 90vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scroll-card-see-more::-webkit-scrollbar {
  display: none;
}
.scroll-right-section__v2 {
  position: static;
  height: 82vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scroll-right-section__v2::-webkit-scrollbar {
  display: none;
}
.scroll-card-see-more-desktop {
  position: static;
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scroll-card-see-more-desktop::-webkit-scrollbar {
  display: none;
}
.centerlized-card {
  align-self: center;
}
.fixed-Right-section {
  position: fixed;
  width: 310px;
  height: auto;
}
.btn-outline-green {
  width: 310px;
  padding: 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  background-color: #0f802812;
  border-radius: 8px;
  color: #0f8028;
}
.btn-outline-green:hover {
  background-color: #0f802812;
  color: #0f8028;
}

.card-bg {
  width: 100%;
  padding: 0px 15px;
  border-radius: 8px;
}
.justify-content-center-profile {
  justify-content: center !important;
}
.divider-bar {
  width: 80%;
  height: 0.5px;
  margin: 0 auto;
  background-color: #c4c4c4;
  margin-top: 25px;
}
.show-hide-text {
  background-color: transparent;
  border: 1px solid transparent;
  color: #8c52ff;
}
@media only screen and (max-width: 1366px) {
  .booking-card {
    width: 100%;
  }
  .btn-outline-green {
    width: 100%;
  }
}
@media only screen and (max-width: 747px) {
  .scroll-right-section__v2 {
    height: auto;
  }
  .divider-bar {
    display: none;
  }
  .hide-on-big-screen {
    display: block;
  }
  .annuncement-card {
      width: 100%;
      max-width: 95%;
      margin: 10px;
  }
  .mentor-proile-pic {
    margin: 0px;
  }
  .mentor-proile-pic {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    border: 0.5px solid #8c8c8c;
    box-sizing: border-box;
  }
  .hide-on-small-screen {
    display: none;
  }
  .icon-box {
    text-align: left;
    margin: 0px;
    margin-top: 10px;
  }
  .icon-img {
    width: 24 px;
    height: 24 px;
    margin: 0;
    margin-right: 10px;
  }
  .scroll-card {
    position: static;
  }
  .booking-card {
    width: 100%;
    background: white;
  }
  .card-bg {
    width: 95%;
    margin: 140px 10px 10px 10px;
    padding: 20px;
    background: #f3f3f3;
    border-radius: 8px;
  }
  .fixed-Right-section {
    position: static;
  }
  .profile-pic-on-mobile {
    width: 140px;
  }
  .skills-box {
    display: block;
    width: 200px;
    margin-top: 20px;
    margin-left: 0px;
  }
  .button-box {
    display: block;
    width: 100%;
    height: auto;
    margin-left: 0px;
  }
  .scroll-card {
    position: static;
    height: auto;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .scroll-card {
    position: static;
}
}

@media only screen and (max-width: 414px) {
  .scroll-right-section__v2 {
    height: auto;
  }
  .justify-content-center-profile {
    justify-content: left !important;
  }
  .hide-on-big-screen {
    display: block;
  }
  .annuncement-card {
    width: 100%;
    max-width: 95%;
    margin: 10px;
  }
  .mentor-proile-pic {
    margin: 0px;
  }
  .mentor-proile-pic {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    border: 0.5px solid #8c8c8c;
    box-sizing: border-box;
  }
  .scroll-card {
    position: fixed;
    height: auto;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .hide-on-small-screen {
    display: none;
  }
  .icon-box {
    text-align: left;
    margin: 0px;
    margin-top: 10px;
  }
  .icon-img {
    width: 24 px;
    height: 24 px;
    margin: 0;
    margin-right: 10px;
  }
  .scroll-card {
    position: static;
  }
  .booking-card {
    width: 100%;
    background: white;
  }
  .card-bg {
    margin: 10px;
    padding: 20px;
    background: #f3f3f3;
    border-radius: 8px;
  }
  .fixed-Right-section {
    position: static;
  }
  .profile-pic-on-mobile {
    width: 140px;
  }
  .skills-box {
    display: block;
    width: 200px;
    margin-top: 20px;
    margin-left: 0px;
  }
  .button-box {
    display: block;
    width: 100%;
    height: auto;
    margin-left: 0px;
  }
}
