.landing__input--container {
  display: flex;
  align-items: center;
  padding: 16px;
  height: 52px;
  background: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  border-radius: 40px;
  margin: 36px 0px;
}
.landing__input--search-icon-img {
  width: 22px;
  height: 22px;
}
.landing__input-field {
  width: 100%;
  height: 100%;
  padding: 16px;
  border: none;
}
.landing__input-field::placeholder {
  color: #aaa;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0.54px;
}
