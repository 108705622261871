*,
*::before,
*::after {
    margin: none;
    padding: none;
    box-sizing: border-box;
}

:root {
    --bg-white: #FFFFFF;
    --text-black-med: #3C3B3B;
}

.webinar__form--wrapper h2,
.webinar__form--wrapper h3,
.webinar__form--wrapper p,
.webinar__form--wrapper .form__field {
    margin: 0;
}

.webinar__form--wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.03em;
}

.webinar__formContainer {
    width: 100%;
    max-width: 900px;
    margin: 140px 0 40px 0;
    border-radius: 8px;
    padding: 60px 30px;
    background-color: var(--bg-white);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.2);
}

.formContainer__head { 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    position: relative;
    margin-bottom: 12px;
}

.formContainer--close {
    position: absolute;
    top: -25px;
    right: 10px;
    border: none;
    background: transparent;
}

.formContainer--step {
    width: 450px;
    height: 80px;
}

.formContainer__head h2 {
    font-size: 26px;
    color: var(--text-black-med);
    text-align: center;
}

.formContainer__form {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 34px;
    margin: 0 auto;
    padding: 30px 60px;
}

.formContainer__form .form__field,
.form__field-group .form__price--original,
.form__field-group .form__price--discounted {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.formContainer__form .form__field-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 60px;
}

.formContainer__form .form__field .form__field--inputFull,
.formContainer__form .form__field-group .form__field--inputFull,
.formContainer__form .form__field--textarea {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #5F5F5F;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: var(--text-black-med);
}

.formContainer__form .form__field .form__field--inputFull:disabled { cursor: not-allowed; }

.formContainer__form .form__field .form__field--inputFull::placeholder,
.formContainer__form .form__field--textarea::placeholder {
    font-size: 14px;
    color: #A3A3A3;
}

.form__field-group .form__price--original,
.form__field-group .form__price--discounted {
    width: 100%;
}

.formContainer__form .form__request--error {
    font-size: 14px;
    color: #FE3A3A;
    font-weight: 400;
}

.form__navigationButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.form__navigationButton--next { margin-left: auto; }

.form__navigationButton {
    border: none;
    background-color: #DCCAFF;
    color: #9B68FF;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    padding: 6px 20px;
    transition: 0.3s ease;
}

.form__navigationButton:hover {
    transform: translateY(-2px);
    box-shadow: 0px 8px 10px -2px rgba(0,0,0,0.1);
}

/* ------- RADIO BUTTON STYLES -------- */

.form__field--options {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 20px;
    height: auto;
}

.form__field .form__field--label {
    font-size: 16px;
    color: #505050;
    font-weight: 400;
}

.form__field .form__field--radio { margin-right: 6px; }

/* --------- KEYPOINT STYLES --------- */
.form__keypoints .keypoints__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 6px;
}

.form__keypoints .keypoints__input {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.keypoints__list .keypoints__listItem {
    display: flex;
    align-items: center;
    position: relative;
}

.form__keypoints .keypoints__input .form__field--inputFull {
    padding: 12px 14px !important;
}

.keypoints__list .keypoints__listItem .keyPoints__listItem--point, 
.keypoints__list .keypoints__listItem .keyPoints__listItem--point:disabled {
    padding: 12px 14px;
    font-size: 16px;
    color: var(--text-black-med);
    font-weight: 500;
    background-color: #EBEBEB;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.keypoints__list .keypoints__listItem .keyPoints__listItem--remove,
.keypoints__input .keyPoints__listItem--add {
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: translate(-10px, 10px);
}

.keypoints__list .keypoints__listItem .keyPoints__listItem--remove img,
.keypoints__input .keyPoints__listItem--add img {
    width: 15px;
    height: 15px;
}

.form__field--error {
    display: inline-block;
    font-size: 12px;
    color: #FE3A3A;
    font-weight: 400;
    transform: translateY(-4px);
}

.keypoints__list .keypoints__listItem .keyPoints__listItem--remove { background-color: #FFC3C3; }
.keypoints__input .keyPoints__listItem--add { background: rgba(156, 232, 173, 0.54); }

/* ------------ INPUTLABEL COMPONENT STYLES ------------ */
.inputLabel--wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.inputLabel {
    display: flex;
    gap: 8px;
    align-items: center;
}

.inputLabel .inputLabel--label {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-black-med);
}

.inputLabel--required {
    display: inline-block;
    color: orangered;
    font-size: 12px;
    transform: translate(3px, -6px);
}

.inputLabel .Tooltip-Wrapper img {
    width: 18px;
    height: 18px;
}

.inputLabel__limit {
    font-size: 12px;
    font-weight: 400;
    color: #989898;
}

/* -------- DATEPICKER STYLES --------- */

.react-datepicker__input-container input {
    width: 50%;
    padding: 6px 8px;
    border: 1px solid #5F5F5F;
    border-radius: 4px;
    font-size: 14px;
    color: var(--text-black-med);
}

.react-datepicker__input-container input::placeholder {
    font-size: 14px;
    color: #A3A3A3;
}

/* -------- TIMEPICKER STYLES -------- */
.formContainer__form .form__field--select {
    width: 240px;
    padding: 6px 8px;
    border: 1px solid #5F5F5F;
    background-color: transparent;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: var(--text-black-med);
    appearance: none;
    -moz-appearance:none;
    -webkit-appearance:none;
}

/* -------- WEBINAR CREATED POPUP ------- */
.webinar__card--wrapper {
    font-family: 'Poppins';
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.webinar__card {
    width: 600px;
    height: 330px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 36px;
    background: #F8F8F8;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.webinar__card h2 { margin: 0; }

.webinar__card .card__header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.webinar__card .card__header h2 {
    font-size: 28px;
    font-weight: 500;
    color: #9B68FF;
}

.webinar__card .card__actions {
    display: flex;
    gap: 50px;
    margin-top: 16px;
}

.webinar__card .card__actions .card__actions--button {
    display: inline-block;
    text-align: center;
    border: none;
    background-color: white;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.08);
    padding: 8px 30px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 500;
    color: var(--text-black-med);
    transition: 0.3s ease;
}

.webinar__card .card__actions .card__actions--button:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.webinar__card .card__tips {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}

.card__tips .card__tips--tip {
    display: flex;
    align-items: center;
    gap: 6px;
} 

.card__tips .card__tips--tip span {
    font-size: 14px;
    color: #999999;
}