* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.favourite__card {
    width: 230px;
    background: white;
    border-radius: 6px;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    position: relative;

    display: flex;
    flex-direction: column;
}

.favourite__card .card__type--wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;

    background: linear-gradient(90deg, #8672FF 0.01%, #5753FF 100%);
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.30);
}

.favourite__card .card__image {
    width: 100%;
    height: 145px;
    background-position: center;
    background-size: contain;
    object-fit: cover;
}

.favourite__card .card__info {
    display: flex;
    flex-direction: column;
    padding: 14px;
}

.favourite__card .card__info .info__title {
    font-size: 18px;
    font-weight: 600;
    color: #232323;
    margin-bottom: 12px;
}

.favourite__card .card__info .info__tags {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 18px;
}

.card__info .info__tags p {
    font-size: 10px;
    font-weight: 500;
    color: #4A4A4A;
    letter-spacing: auto;
    background-color: #F4F4F4;
    padding: 2px 8px;
    border-radius: 4px;
}

.favourite__card .card__info .card__actions {
    display: flex;
    gap: 6px;
}

.card__info .card__actions .action__explore {
    flex: 1;
    border: none;
    padding: 6px 0;
    background: linear-gradient(90deg, #E477FF 0.01%, #B04DFE 100%);
    border-radius: 4px;

    color: white;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.15px;
}

.card__info .card__actions .action__favourite {
    border: none;
    border-radius: 4px;
    padding: 6px 16px;
    font-size: 14px;
    background: rgba(246, 28, 28, 0.10);
}