.shadow__v2 {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 4px 0px 8px rgba(0, 0, 0, 0.15);
}
.Booking__session--sessiontype {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  float: left;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #8c8c8c;
  margin-bottom: 8px;
}
.Booking__session--one-on-one {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
}
