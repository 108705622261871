* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root {
    --bg-background: #F3F3F3;
    --bg-card: #FFFFFF;
    --text-black: #000000;
    --text-white: #FFFFFF;
    --text-black-med: #444444;
    --text-black-lite: #8C8C8C; 
    --text-purple: #8C52FF;
    --text-green: #24AA42;
    --border-color: #c4c4c4;
    --border-purple-lite: #F0B6FF;
}

 /* --------------- UTILITY CLASSES ---------- */
.mdashboard__card-rounded {
    background-color: var(--bg-card);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
/* ---------------------------- */

.mdashboard--wrapper {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-color: #F3F3F3;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.03em;
    padding: 100px 0 80px 0;
}

.mdashboard__navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

.mdashboard {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
}

.mdashboard p,
.mdashboard h2,
.mdashboard h3 { 
    margin: 0;
}

.mdashboard__header .header--heading {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: var(--text-black);
}

.mdashboard__stats {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 20px;
}

.mdashboard__stats .stats__card {
    width: 33.3333%;
    display: grid;
    grid-template-columns: minmax(max-content, 50px) 5fr 1fr;
    backface-visibility: hidden;
    cursor: pointer;
    transition: 0.2s ease;
}

.stats__card:hover { transform: translateY(-6px) scale(1.02); }

.stats__card .card__sec--2,
.stats__card .card__sec--3 {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.card__title,
.card__value {
    font-size: 16px;
    font-weight: normal;
    color: var(--text-black-med);
}

.card__value.card__value--1 {
    color: var(--text-purple);
}

/* --------- SESSIONS ----------- */

.mdashboard__sessions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.mdashboard__sessions .sessions__bookings {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.sessions__header {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.sessions__header .sessions__heading--main {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-black-med);
}

.sessions__header .sessions__heading--sub {
    font-weight: 16px;
    font-weight: 400;
    color: var(--text-black-med);
}

.sessions__section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sessions__timeframe .timeframe__tabs {
    display: flex;
    border: 2px solid #D4D4D4;
    border-radius: 6px;
}

.timeframe__tabs .timeframe__tab {
    font-size: 16px;
    font-weight: 500;
    padding: 7px 14px;
    color: var(--text-black-med);
    cursor: pointer;
}

.timeframe__tabs .timeframe__tab:not(:last-child) { border-right: 2px solid #D4D4D4; }

.timeframe__tabs .timeframe__tab.timeframe__tab--active { 
    transition: 0.2s ease-in-out;
    color: var(--text-purple); 
}

.sessions__toggle {
    display: flex;
    align-items: center;
    gap: 12px;
}

.sessions__toggle label {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-black-med);
    cursor: pointer;
}

/* ------------ TOGGLE BUTTON -------------*/

.sessions__toggle .sessions__switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.sessions__switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.sessions__switch--slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d6dce2;
    transition: 0.2s ease;
    border-radius: 100px;
}

.sessions__switch--slider::before {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: var(--text-black-lite);
    border-radius: 100px;
    transition: 0.2s ease;
}

.sessions__switch input:checked + .sessions__switch--slider::before {
    background-color: var(--bg-card);
    transform: translateX(26px);
}

.sessions__switch input:checked + .sessions__switch--slider {
    background-color: var(--text-purple);
}

/* ------------ SESSION CONTAINER ----------- */

.sessions__container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

/* .sessions__container .sessions__calendar {
    height: 100px;
    border-bottom: 0.5px solid var(--border-color);
} */

.upcoming__sessions--wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.upcoming__label {
    display: grid;
    /* grid-template-columns: 4fr 2fr 3fr minmax(max-content, 140px) minmax(max-content, 100px); */
    grid-template-columns: 4fr 3fr repeat(2, 2fr) minmax(max-content, 100px);
    padding: 8px;
    border-bottom: 0.5px solid var(--border-color);
}

.upcoming__label * {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-black-lite);
}

.upcoming__sessions--container {
    display: flex;
    flex-direction: column;
}

.upcoming__sessions--message {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: var(--text-black-med);
    font-weight: 500;
    padding: 30px 0;
}

.upcoming__sessions--message img {
    width: 22px;
    height: 22px;
}

.upcoming__session {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 2px;
    border-bottom: 0.5px solid var(--border-color);
}

.upcoming__session .session__overview {
    display: grid;
    /* grid-template-columns: 4fr 2fr 3fr minmax(max-content, 140px) minmax(max-content, 100px); */
    grid-template-columns: 4fr 3fr repeat(2, 2fr) minmax(max-content, 100px);
    align-items: center;
    cursor: pointer;
}

.session__overview.session__overview--expanded { margin-bottom: 12px; }

.session__overview--mentee,
.session__overview--duration {
    display: flex;
    align-items: center;
    gap: 10px;
}

.mentee--img {
    width: 50px;
    height: 50px;
}

.mentee--img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
    object-fit: cover;
    background-position: center;
    overflow: hidden;
}

.mentee__details .mentee__details--name {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-black);
}

.mentee__details .mentee__details--email {
    font-size: 12px;
    font-weight: normal;
    color: var(--text-black-lite);
}

.session__overview--type p,
.session__overview--duration p,
.session__overview--booking-day p {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-black-med);
}

.session__overview--starts p {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-black-med);
}

.upcoming__session .session__details {
    display: flex;
    width: 100%;
    height: 0px;
    overflow: hidden;
    padding: 0;
    background-color: var(--bg-background);
    gap: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.upcoming__session .session__details.show { 
    height: 100%;
    overflow: unset;
    padding: 15px 60px;
}

.session__details .details__sec {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.details__sec .details--header {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-black);
}

.details__sec .details__box {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
}

.details__sec .details__type img,
.details__sec .details__duration img,
.details__sec .details__calendar img,
.details__sec .details__time img,
.details__footer--reschedule a img,
.pricebox__actions button img {
    width: 18px;
    height: 18px;
}

.details__sec .details__box p {
    font-size: 14px;
    font-weight: normal;
    color: var(--text-black-med);
    word-wrap: break-word;
}

.details__price .details__price--text {
    display: flex !important;
    align-items: center;
    gap: 10px;
    font-size: 16px !important;
}

.details__sec .details__type--text { font-weight: 500 !important; }

.details__sec .details__sec--grouping { width: 100%; }

.details__desc .details__desc--text {
    width: 100%;
    font-size: 14px;
    color: var(--text-black-med);
    font-weight: normal;
}

.details__sec--1 .details__sec--grouping .details__desc--text { width: 85%; }

.details__sec--2 .details__user .details__user--name {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
}

.details__sec--2 .details__user .details__user--email {
    display: block;
    font-size: 14px;
    font-weight: normal;
    text-decoration: underline;
    color: var(--text-black-lite);
}

.details__sec--2 .details__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.details__sec--2 .details__footer--links {
    display: flex;
    align-items: center;
    gap: 15px;
}

.details__footer--reschedule a {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    color: var(--text-black-lite);
}

.details__sec--2 .details__footer--links img {
    width: 24px;
    height: 24px;
}

.details__sec.details__sec--2 { gap: 20px; }

.button__showmore {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button__showmore button {
    display: flex;
    gap: 6px;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-black-lite);
    background-color: transparent;
}

.button__showmore button:disabled { cursor: not-allowed; }
.button__showmore button img { transform: translateY(2px); }

/*------------ PROFILE PROGRESS BAR ------------- */

.mdashboard__progress {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mdashboard__progress .progress__header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
}

.progress__header .progress__header--heading {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-black);
}

.progress__header .progress__header--desc {
    font-size: 16px;
    font-weight: normal;
    color: var(--text-black-lite);
}

.progress__progressbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.progressbar__container { 
    width: 94%;
    cursor: pointer;
}

.progressbar__percentages span {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: var(--text-green);
}

.progress__checklist {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.checklist__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
}

.checklist__item {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.checklist__item .checklist__item--label {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-black-lite);
}

.checklist__item--label.completed {
    color: var(--text-green);
}

.checklist__item .checklist__item--link {
    text-decoration: none;
    margin: 0 3px;
    cursor: pointer;
}

.checklist__item img {
    width: 14px;
    height: 14px;
}

.checklist__link a {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-black-lite);
}

/* ------------ SESSIONS CALENDAR ------------ */
/* .sessions__calendar,
.sessions__calendar--wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
}

.sessions__calendar { padding: 10px; } */

/* .sessions__calendar .calendar__navigate {
    border: none;
    outline: none;
    width: 20px;
    background: transparent;
    backface-visibility: hidden;
    transition: 0.2s ease-in;
} */

/* .calendar__navigate.calendar__navigate--left:active { transform: translateX(-4px); }
.calendar__navigate.calendar__navigate--right:active { transform: translateX(4px); } */

/* .sessions__calendar .calendar__dates {
    display: flex;
    width: 380px;
    overflow-x: hidden;
    align-items: center;
    gap: 12px;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
} */

/* .calendar__dates .calendar__dates--date {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid transparent;
    outline: none;
    padding: 6px 8px;
    border-radius: 4px;
    background-color: transparent;
    position: relative;
} */

/* .calendar__dates .calendar__dates--date.selected {
    border: 2px solid var(--text-purple);
    color: var(--text-purple);
} */

/* .calendar__dates .calendar__dates--date.today::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: yellowgreen;
    position: absolute;
    top: 0;
    left: 0;
}

.calendar__dates--date .calendar__dates--day {
    font-size: 12px;
    font-weight: normal;
    color: var(--text-black-lite);
}

.calendar__dates--date .calendar__dates--no {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-black-med);
} */

/* .sessions__calendar .sessions__datepicker {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 0 0 10px;
    position: relative;
}

.sessions__calendar .sessions__datepicker img {
    position: absolute;
    top: 7px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
} */

/* ------------ REACT DATEPICKER STYLES -----------*/
/* .sessions__datepicker .react-datepicker__input-container input {
    font-size: 14px;
    font-weight: normal;
    padding: 6px 8px;
    border: 1px solid var(--text-black-lite);
    border-radius: 4px;
    width: 252px;
    cursor: pointer;
}

.sessions__datepicker .react-datepicker__input-container input:focus { border: 1px solid var(--text-purple); }
.sessions__datepicker .react-datepicker__input-container input::placeholder { 
    font-size: 14px; 
    color: var(--text-black-lite);
}

.sessions__datepicker.react-datepicker--highlighted .react-datepicker__input-container input {
    box-shadow: 0px 4px 8px rgba(140, 82, 255, 0.25);
}

.sessions__datepicker .react-datepicker {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    position: relative;
    padding: 12px;
}

.react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous {
    position: absolute;
    left: 30px;
    top: 31px;
}

.react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next {
    position: absolute;
    right: 30px;
    top: 31px;
}

.react-datepicker .react-datepicker__month-container .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
    padding: 18px 0;
}

.react-datepicker__header .react-datepicker__current-month {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 8px;
}

.react-datepicker__day-names .react-datepicker__day-name {
    font-size: 14px;
    font-weight: normal;
}

.react-datepicker__week .react-datepicker__day {
    border-radius: 100px !important;
    font-weight: normal !important;
}

.react-datepicker__week .react-datepicker__day:hover {
    background-color: #beb7f2 !important;
}

.react-datepicker__week .react-datepicker__day--in-selecting-range,
.react-datepicker__week .react-datepicker__day--range-end,
.react-datepicker__week .react-datepicker__day--selected,
.react-datepicker__week .react-datepicker__day--keyboard-selected,
.react-datepicker__week .react-datepicker__day--in-range {
    background-color: #8a7ee7 !important;
} */

/* ------------- WEBINAR -------------- */
.mdashboard__webinar {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.webinar__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.webinar__head .navigation__controls {
    display: flex;
    align-items: center;
    gap: 12px;
}

.navigation__controls .navigation__add--webinar {
    background-color: none;
    border: 2px solid var(--text-purple);
    color: var(--text-purple);
    font-weight: 400;
    padding: 4px 12px;
    border-radius: 6px;
    font-size: 16px;
    margin-right: 8px;
    transition: 0.2s ease;
}

.navigation__controls .navigation__add--webinar:hover {
    background-color: var(--text-purple);
    color: white;
}

.navigation__controls .navigation__controls--button {
    width: 30px;
    height: 30px;
    border: none;
    background-color: #e2e2e2;
    border-radius: 50%;
    transition: all 0.2s ease;
}

.navigation__controls .navigation__controls--button img {
    width: 13px;
    height: 13px;
}

.navigation__controls .navigation__controls--button:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.mdashboard__webinar--title {
    font-size: 18px;
}

.webinar__noEvents {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 26px 16px;
    border: 2px dashed var(--border-purple-lite);
    border-radius: 8px;
}

.webinar__noEvents--title {
    font-size: 22px;
    font-weight: 500;
    color: var(--text-black-med);
    letter-spacing: 0.04em;
}

.webinar__noEvents--createButton {
    border: none;
    outline: none;
    background: linear-gradient(94.25deg, #B698F1 0%, #AA7FFD 100%);
    padding: 4px 8px;
    border-radius: 4px;
    color: var(--text-white);
}

.webinar__events {
    width: 100%;
    padding: 8px 4px;
    display: flex;
    gap: 40px;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.webinar__events .event__card {
    width: 520px;
    min-width: 480px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 18px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 1.2px 1.2px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.event__head {
    display: flex;
    justify-content: space-between;
}

.event__head .webinar--name {
    font-size: 20px;
    color: var(--text-black);
}

.event__head .event__options {
    display: flex;
    gap: 12px;
    align-items: center;
}

.event__head .event__options button {
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: var(--text-black-med);
}

.event__date {
    font-size: 17px;
    color: var(--text-black-med);
}

.event__info {
    display: flex;
    align-items: center;
    gap: 12px;
}

.event__info--chip {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
    border: 1.2px solid var(--text-black-med);
    border-radius: 50px;
    padding: 4px 10px;
    cursor: pointer;
}

.event__info--chip span {
    font-size: 14px;
    color: var(--text-black-med);
    font-weight: 500;
}

/* ------------ ENLISTED SESSIONS ---------------- */

.mdashboard__mini-sections {
    width: 100%;
    display: flex;
    gap: 20px;
}

.mdashboard__mini-sections .mdashboard__enlisted,
.mdashboard__mini-sections .mdashboard__leads {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.mdashboard__enlisted .enlisted__header,
.mdashboard__leads .leads__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mdashboard__leads .leads__header { padding: 6px; }

.enlisted__header .enlisted__header--heading,
.leads__header .leads__header--heading {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-black);
}

.enlisted__header .enlisted__header--button {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-purple);
    border: 1px solid var(--text-purple);
    outline: none;
    padding: 6px;
    border-radius: 4px;
    backface-visibility: hidden;
    transition: 0.2s ease;
}

.enlisted__header .enlisted__header--button:active { transform: translateY(2px); }


.enlisted__header--button img {
    margin-right: 5px;
    transform: translateY(-1px);
}

.enlisted__sessions--container,
.leads__sessions--container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.enlisted__sessions--wrapper,
.leads__sessions--wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sessions__enlisted,
.sessions__leads {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-bottom: 1px solid var(--border-color);
}

.enlisted__overview {
    display: grid;
    grid-template-columns: repeat(2, 1fr) minmax(max-content, 30px);
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
}

.leads__overview {
    display: grid;
    grid-template-columns: 1fr 2fr minmax(max-content, 30px);
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
}

.enlisted__overview .enlisted--wrapper,
.leads__overview .leads--wrapper {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.enlisted__overview.enlisted__overview--expanded { margin-bottom: 10px; }

.enlisted__overview--number,
.leads__overview--number {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-black-med);
}

.enlisted__overview--type {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-black-med);
}

.leads__overview--details {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
}

.leads__overview--details .details--email {
    font-size: 12px;
    font-weight: normal;
    color: var(--text-black-lite);
}

.enlisted__info {
    display: flex;
    gap: 8px;
    align-items: center;
}

.leads__info { 
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
}

.enlisted__info .enlisted__info--duration,
.enlisted__info .enlisted__info--category {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-black-med);
}

.leads__info .leads__info--contact,
.leads__info .leads__info--date {
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-black-lite);
}

.enlisted__info .enlisted__info--duration img {
    margin: 0 6px;
    transform: translateY(-2px);
}

.leads__info .leads__info--contact img {
    width: 20px;
    height: 22px;
    transform: translateY(-1px);
}

.enlisted__actions button,
.leads__actions button {
    border: none;
    outline: none;
    background-color: transparent;
}

.sessions__enlisted .enlisted__details,
.sessions__leads .leads__details {
    width: 100%;
    height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.sessions__enlisted .enlisted__details.show,
.sessions__leads .leads__details.show {
    height: 100%;
    overflow: unset;
    padding: 10px 20px;
    margin-bottom: 10px;
}

.enlisted__details .enlisted__details--description,
.leads__details .leads__details--description {
    font-size: 14px;
    font-weight: normal;
    color: var(--text-black-med);
    word-wrap: break-word;
    line-height: 22px;
}

.leads__details .leads__details--date {
    font-size: 12px;
    font-weight: normal;
    color: var(--text-black-lite);
}

.enlisted__pricebox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pricebox__price {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    font-size: 16px;
}

.pricebox__price--text,
.pricebox__price--original {
    font-weight: 500;
    color: var(--text-black-lite);
}

.pricebox__price--original { text-decoration: line-through; }

.pricebox__price--offer {
    color: var(--text-black-med);
    font-weight: 500;
}

.pricebox__actions {
    display: flex;
    gap: 18px;
    align-items: center;
}

.pricebox__actions button {
    border: none;
    outline: none;
    background-color: transparent;
}

/* ------------- PAGINATION STYLES ---------------- */
.sessions__pagination {
    max-width: 160px;
    display: flex;
    align-self: flex-end;
    align-items: center;
    padding: 6px;
    gap: 4px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    overflow-x: scroll;
}

.sessions__pagination::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
}

.sessions__pagination--page {
    width: 20px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-black-med);
    background: transparent;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
}

.sessions__pagination--page-active {
    color: var(--text-purple);
    border: 1px solid #8C52FF;
    margin: 0 2px;
}

.pagination--button {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0 4px;
    transition: 0.2s ease;
    border-radius: 4px;
}

.pagination--button:hover {
    background-color: #e9e2fa;
}

.pagination--button img {
    width: 11px;
    height: 11px;
}


/* ----------------- RESPONSIVE (MOBILE DESIGN) --------------- */

@media only screen and (max-width: 414px) {

    .mdashboard--wrapper {
        display: block;
        width: 100%;
    }
    .mdashboard {
        max-width: unset;
        padding: 12px 0;
    }

    .mdashboard__header,
    .enlisted__header,
    .leads__header {
        padding: 0 18px;
    }

    /* ----------------- PROGRESS BAR */
    .mdashboard .mdashboard__progress {
        width: 90%;
        align-self: center;
    }
    .progress__header .progress__header--desc { font-size: 14px; }
    .progress__progressbar .progressbar__container { width: 80%; }
    .progressbar__percentages span { font-size: 16px; }
    .checklist__container { display: none; }

    /* ----------------- STATS */
    .mdashboard__stats {
        width: 90%;
        flex-direction: column;
        align-items: flex-start;
        align-self: center;
        gap: 0;
        padding: 6px;
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        background-color: var(--bg-card);
    }
    .mdashboard__stats .mdashboard__card-rounded {
        border-radius: 0;
        box-shadow: none;
        padding: 15px 12px;
    }
    .mdashboard__stats .stats__card {
        width: 100%;
        grid-template-columns: minmax(35px, max-content) 80% 30px;
        background-color: transparent;
    }
    .mdashboard__stats .stats__card:not(:last-child) { border-bottom: 0.5px solid #e5e5e5; }
    .mdashboard__stats .stats__card:hover { transform: none; }
    .stats__card .card__title, .stats__card .card__value { font-size: 14px; }
    .stats__card .card__img {
        width: 20px;
        height: 22px;
    }

    /* --------------- SESSIONS & CALENDAR */
    .sessions__header .sessions__heading--main { font-size: 16px; }
    .sessions__header .sessions__heading--sub { font-size: 14px; }
    .mdashboard__sessions .sessions__bookings { padding: 20px 14px !important; }
    .sessions__section {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 34px;
    }
    .timeframe__tabs .timeframe__tab {
        font-size: 14px;
        padding: 5px 12px;
    }
    .sessions__toggle { 
        width: 100%;
        justify-content: space-between;
    }
    .sessions__container.mdashboard__card-rounded {
        padding: 10px 6px;
        border-radius: 0;
    }
    /* .sessions__container .sessions__calendar {
        height: auto;
        flex-direction: column;
        padding: 12px 6px 26px 6px;
        gap: 12px;
    }
    .sessions__calendar, .sessions__calendar--wrapper { width: 100%; }
    .sessions__calendar .calendar__dates { width: 210px; }
    .sessions__calendar .sessions__datepicker { margin: unset; } */
    .upcoming__label,
    .upcoming__session .session__overview { 
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .session__overview--type,
    .session__overview--duration,
    .session__overview--booking-day,
    .upcoming__label--session-type,
    .upcoming__label--duration,
    .upcoming__label--booking-day {
        display: none;
    }
    .mentee__details .mentee__details--name { font-size: 14px; }
    .mentee--img {
        width: 40px;
        height: 40px;
    }
    .session__overview--starts p { font-size: 18px; }
    .upcoming__session .session__details {
        flex-direction: column;
    }
    .upcoming__session .session__details.show { padding: 20px; }
    .session__details .details__sec {
        width: 100%;
        padding: 22px 0;
    }
    .session__overview--starts p { font-size: 18px; }
    .session__details .details__sec:first-of-type { border-bottom: 1.5px dotted var(--border-color); }
    .details__sec .details__box { align-items: flex-start; }
    .details__desc .details__desc--text { width: 100% !important; }
    .details__sec--1 .details__sec--grouping:first-of-type { margin-bottom: 8px; }
    .details__sec--2 .details__user .details__user--name { margin-bottom: 6px; }
    .upcoming__sessions--wrapper { gap: 10px; }

    /* ----------------- ENLISTED SESSIONS */
    .mdashboard__mini-sections { 
        flex-direction: column;
        gap: 60px; 
    }
    .mdashboard__mini-sections .mdashboard__enlisted,
    .mdashboard__mini-sections .mdashboard__leads { width: 100%; }
    .enlisted__sessions--container.mdashboard__card-rounded,
    .leads__sessions--container.mdashboard__card-rounded {
        padding: 12px;
        gap: 8px;
        border-radius: 0;
    }
    .sessions__enlisted, .sessions__leads { padding: 6px; }
    .enlisted__overview {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .enlisted__info { display: none; }
    .leads__overview { grid-template-columns: repeat(2, 1fr) minmax(max-content, 30px); }
    .leads__info { 
        display: flex;
        padding: 0; 
    }
    .leads__info .leads__info--date { display: none; }
}
